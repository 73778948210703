import React from 'react'
import PropTypes from 'prop-types'

import { getPartyName } from 'utils/party'

import RequestedProductsTable from '../RequestedProductsTable'
import SectionHeading from 'lib/components/headings/SectionHeading'

import styles from './styles.scss'

const BasketDetail = ({ basket, requestedProducts, prices }) => {
  const basketDetails = () => {
    return (
      <div className={styles.pageSection}>
        <SectionHeading>Basket details</SectionHeading>
        <table className={styles.tableDetail}>
          <thead>
            <tr>
              <th>
                <small>Initiating Party Name</small>
              </th>
              <th>
                <small>Purchasing Party Name</small>
              </th>
              <th>
                <small>Billing Party Name</small>
              </th>
              <th>
                <small>Assign to Party Name</small>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{getPartyName(basket['urn:lic:predicate:order_initiator'])}</td>
              <td>{getPartyName(basket['urn:lic:predicate:order_purchaser'])}</td>
              <td>{getPartyName(basket['urn:lic:predicate:bill_to_party'])}</td>
              <td>{getPartyName(basket['urn:lic:predicate:assign_to_party'])}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {basketDetails()}
      <div className={styles.pageSection}>
        <SectionHeading>Requested products</SectionHeading>
        {requestedProducts ? (
          <RequestedProductsTable requestedProducts={requestedProducts} prices={prices} />
        ) : null}
      </div>
    </div>
  )
}

BasketDetail.propTypes = {
  basket: PropTypes.object.isRequired,
  requestedProducts: PropTypes.array,
  prices: PropTypes.shape({
    naitCharge: PropTypes.number,
    retailPrice: PropTypes.number,
    sellPrice: PropTypes.number,
  }).isRequired,
}

export default BasketDetail
