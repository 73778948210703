import React from 'react'
import PropTypes from 'prop-types'

import { convertToSentenceCase } from 'utils/util'
import { getDiscountState } from 'utils/discountUtils'

import DateTime from 'lib/components/DateTime'
import StatusInfo from 'lib/components/StatusInfo'
import { Link } from 'react-router-dom'

import styles from './styles.scss'

const DiscountCard = ({ discount }) => {
  let discountState = getDiscountState(discount.valid_from, discount.valid_to)

  let pricePattern = discount.applied_on_price_pattern.split(':').join(' ')

  return (
    <Link
      to={`/discounts/${encodeURIComponent(discount.id)}`}
      className={`${styles.resultCard} ${styles.discountCard}`}
    >
      <dl>
        <dt className={styles.name}>Name</dt>
        <dd className={styles.name}>
          {discount.name} ({pricePattern})
        </dd>
        <dt>Active from:</dt>
        <dd>
          <DateTime date={discount.valid_from} />
        </dd>
        <dt>Active to:</dt>
        <dd>
          <DateTime date={discount.valid_to} />
        </dd>
        <dt className={styles.status}>Status</dt>
        <dd className={styles.status}>
          <StatusInfo id={discountState} label={convertToSentenceCase(discountState)} />
        </dd>
      </dl>
    </Link>
  )
}

DiscountCard.propTypes = {
  discount: PropTypes.object.isRequired,
}

export default DiscountCard
