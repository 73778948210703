import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Field, getFormValues, reduxForm } from 'redux-form'

import { required } from 'utils/formValidations'

import FormField from 'lib/components/FormField'
import FormRow from 'lib/components/FormRow'
import SectionHeading from 'lib/components/headings/SectionHeading'

import styles from './styles.scss'

const FORM_NAME = 'create_rating'

class CreateRatingForm extends Component {
  componentDidUpdate(prevProps) {
    let { formValues, onChange } = this.props

    if (prevProps.formValues !== formValues) onChange(formValues)
  }

  render() {
    let { product, ratingConfigReqs } = this.props

    return (
      <Fragment>
        <SectionHeading>{product.name}</SectionHeading>
        <FormRow label='Base price'>
          <Field
            className={styles.formField}
            component={FormField}
            inline
            label='Cost'
            medium
            name={`${product.slug}__cost`}
            type='number'
            validate={required}
          />
          <Field
            className={styles.formField}
            component={FormField}
            inline
            label='Retail'
            medium
            name={`${product.slug}__retail`}
            type='number'
            validate={required}
          />
        </FormRow>
        {ratingConfigReqs
          .sort((a, b) => {
            if (a.identity > b.identity) {
              return -1
            }
            if (a.identity < b.identity) {
              return 1
            }
            return 0
          })
          .map(configReq => (
            <FormRow key={configReq.identity} label={`${configReq.name}`}>
              <Field
                className={styles.formField}
                component={FormField}
                inline
                label='Cost'
                medium
                name={`${configReq.identity}__cost`}
                type='number'
                validate={required}
              />
              <Field
                className={styles.formField}
                component={FormField}
                inline
                label='Retail'
                medium
                name={`${configReq.identity}__retail`}
                type='number'
                validate={required}
              />
            </FormRow>
          ))}
      </Fragment>
    )
  }
}

export { CreateRatingForm }
export default compose(
  connect(state => {
    return {
      formValues: getFormValues(FORM_NAME)(state),
    }
  }),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  })
)(CreateRatingForm)
