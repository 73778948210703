import React from 'react'
import PropTypes from 'prop-types'

import ProductCardWithRating from '../ProductCardWithRating'
import QueryList from 'lib/components/QueryList'

function ProductsList({ products, productLinkFromSlug }) {
  return (
    <QueryList>
      {products.map(product => (
        <ProductCardWithRating
          key={product.id}
          product={product}
          productLinkFromSlug={productLinkFromSlug}
        />
      ))}
    </QueryList>
  )
}

ProductsList.propTypes = {
  products: PropTypes.array.isRequired,
}

export default ProductsList
