import React, { useEffect } from 'react'
import { hot } from 'react-hot-loader'
import { useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { setUserScope } from 'utils/monitoringClient'
import GlobalNav from '@licnz/react-global-nav'
import { userInfoData } from 'selectors/userInfoSelectors'

import AppBar from 'components/AppBar'
import Banner from 'lib/components/layout/Banner'
import ContextBanner from 'lib/components/ContextBanner'
import CustomerContextHandler from 'components/CustomerContextHandler'
import Main from 'components/Main'
import RedirectParamsHandler from 'components/RedirectParamsHandler'
import ReduxNotificationController from 'lib/components/GlobalNotifications'
import ToastNotificationsController from '@licnz/react-toast-notifications'

import SiteFooter from 'lib/components/SiteFooter'
import VersionManager from 'components/VersionManager'

import 'assets/scss/global.scss'
import styles from './styles.scss'

function AppRoot() {
  const userInfo = useSelector(userInfoData)

  useEffect(() => {
    if (userInfo.sub) {
      setUserScope(userInfo)
    }
  }, [userInfo.sub])

  return (
    <Router>
      <div>
        <RedirectParamsHandler />
        <CustomerContextHandler />
        <VersionManager />
        <GlobalNav shopLink={global.config.SHOP_FRONTEND_ENDPOINT} />
        <AppBar />
        <ContextBanner />
        <ToastNotificationsController />
        <div role='main' className={styles.main}>
          <ReduxNotificationController />
          <Banner />
          <Main />
        </div>
        <SiteFooter />
      </div>
    </Router>
  )
}

export default hot(module)(AppRoot)
