import React, { Component } from 'react'
import { connect } from 'react-redux'
import { href } from '@licnz/js-utils'

import {
  fetchAdminProduct,
  fetchProduct,
  updateProductCategorisations,
} from 'actions/productActions'

import PageSection from 'lib/components/layout/PageSection'
import PageHeading from 'lib/components/headings/PageHeading'
import CategorisationsFormWrapper from 'components/CategorisationsFormWrapper'

import { extractIdFromUrl } from 'utils/util'

class EditCategorisations extends Component {
  constructor(props) {
    super(props)

    this.handleBack = this.handleBack.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    let {
      fetchAdminProduct,
      fetchProduct,
      match: {
        params: { id },
      },
    } = this.props
    let extractedId = extractIdFromUrl(id)
    fetchAdminProduct(extractedId)
    fetchProduct(id)
  }

  componentDidUpdate(prevProps) {
    let { history, updateRequestState } = this.props
    if (
      !prevProps.updateRequestState.fetched &&
      updateRequestState.fetched &&
      updateRequestState.errors === null
    ) {
      let {
        match: {
          params: { id },
        },
      } = this.props
      history.push(`/products/${id}`)
    }
  }

  handleBack(e) {
    e.preventDefault()
    this.props.history.goBack()
  }

  handleSubmit(values) {
    let { adminProduct, updateProductCategorisations } = this.props
    let linkToUpdateCategorisations =
      adminProduct && href({ links: adminProduct.links, rel: 'product_taxonomies' })

    return updateProductCategorisations(values, linkToUpdateCategorisations)
  }

  render() {
    let { adminProduct, product } = this.props

    return (
      <PageSection>
        <PageHeading
          heading={adminProduct && adminProduct.name}
          size='h2'
          onAction={this.handleBack}
          actionText='Cancel'
        />
        {product && (
          <CategorisationsFormWrapper
            initialValues={{
              categories: product.categories.toString(),
              specification: product.specification || '',
            }}
            onSubmit={this.handleSubmit}
          />
        )}
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  fetchAdminProduct,
  fetchProduct,
  updateProductCategorisations,
}

export { EditCategorisations }
export default connect(state => {
  return {
    adminProduct: state.adminProduct.data,
    product: state.product.data,
    updateRequestState: state.adminProduct.updateProductCategorisations,
  }
}, mapDispatchToProps)(EditCategorisations)
