import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import axios from 'lib/utils/axios'
import { proxyUrl } from 'lib/utils/proxy'
import { href } from '@licnz/js-utils'

import { createRatingRules } from 'actions/ratingRulesActions'
import { enqueueError } from 'lib/components/GlobalNotifications'
import {
  extractDefaultMarkingOptions,
  generateRatingConfigReqsFromProductTree,
} from 'utils/ratingRulesUtils'
import { extractIdFromUrl } from 'utils/util'
import { fetchProduct } from 'actions/productActions'
import { getRequestStatus } from 'lib/selectors/requestStateSelectors'
import { RATING_PRICE_URN_MAPPINGS } from 'constants/priceMappings'

import BlockContent from 'lib/components/layout/BlockContent'
import Button from 'lib/components/Button'
import ConfirmCreateRatingModal from './ConfirmCreateRatingModal'
import CreateRatingForm from './CreateRatingForm'
import FormField from 'lib/components/FormField'
import FormRow from 'lib/components/FormRow'
import Grid from 'lib/components/layout/Grid'
import PageSection from 'lib/components/layout/PageSection'
import PageHeading from 'lib/components/headings/PageHeading'
import RequestWrapper from '@licnz/react-request-wrapper'
import SectionHeading from 'lib/components/headings/SectionHeading'
import VariantsTable from 'components/ProductRating/VariantsTable'

import styles from './styles.scss'

class CreateRating extends Component {
  constructor() {
    super()

    this.state = {
      ratingConfigReqs: [],
      ratingRules: [],
      showPreview: false,
      showConfirmationModal: false,
      versionedAt: null,
      currentUser: null,
    }

    this.handleBack = this.handleBack.bind(this)
    this.handleChangeVersionedAt = this.handleChangeVersionedAt.bind(this)
    this.handleCreateRating = this.handleCreateRating.bind(this)
    this.handleGenerateRatingRules = this.handleGenerateRatingRules.bind(this)
    this.handleShowPreview = this.handleShowPreview.bind(this)
    this.handleToggleModal = this.handleToggleModal.bind(this)
  }

  componentDidMount() {
    let { currentProfile } = this.props
    this.getProduct()

    if (currentProfile) {
      this.getCurrentUser()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { product, currentProfile } = this.props
    let { currentUser } = this.state

    if (product && prevProps.product !== product) {
      let ratingConfigReqs = generateRatingConfigReqsFromProductTree(product)
      this.setState({ ratingConfigReqs })
    }

    if (currentProfile && this.compareCurrentUser(prevState.currentUser, currentUser)) {
      this.getCurrentUser()
    }
  }

  getProduct() {
    let {
      enqueueError,
      fetchProduct,
      match: {
        params: { id },
      },
    } = this.props

    let extractedId = extractIdFromUrl(id)
    fetchProduct(extractedId).catch(() =>
      enqueueError({
        message: "We were unable to fetch the product to create it's rating rules",
      })
    )
  }

  handleBack() {
    this.props.history.goBack()
  }

  handleShowPreview() {
    this.setState({ showPreview: true })
  }

  handleToggleModal() {
    const { enqueueError } = this.props
    const isValidRuleset = (() => {
      const ratingRules = this.state.ratingRules
      const hasCost = ratingRules.find(rule => rule.price_type === "urn:lic:product:price:base:unit:cost")
      const hasRetail = ratingRules.find(rule => rule.price_type === "urn:lic:product:price:base:unit:retail")
      return hasCost !== undefined && hasRetail !== undefined
    })()
    if (!isValidRuleset) {
      enqueueError('You must enter both a Cost and a Retail rating first, in the format 0.00')
    } else {
      this.setState({ showConfirmationModal: !this.state.showConfirmationModal })
    }
  }

  handleChangeVersionedAt(e) {
    this.setState({ versionedAt: e.target.value })
  }

  handleCreateRating() {
    let { createRatingRules, history, product } = this.props
    let { ratingRules, versionedAt } = this.state

    createRatingRules({
      rootProductId: product.slug,
      rules: ratingRules,
      versionedAt: moment(versionedAt).toISOString()
    }).then(() => {
      history.push(`/products/${product.slug}/rating/variants`)
    })
  }

  generateInitialValues() {
    let { product } = this.props

    let initialValues = {
      [`${product.slug}__cost`]: '0',
      [`${product.slug}__retail`]: '0',
    }

    this.state.ratingConfigReqs.map(configReq => {
      initialValues[`${configReq.identity}__cost`] = '0'
      initialValues[`${configReq.identity}__retail`] = '0'
    })

    return initialValues
  }

  generateRatingRuleDescription(ruleId, configReq, value) {
    let description = 'Base price'

    if (ruleId === RATING_PRICE_URN_MAPPINGS.CHARGE_LEVY_UNIT_NAIT) {
      description = 'NAIT Levy'
    }

    if (configReq && configReq.type === 'size') {
      let isPositiveValue = Math.sign(Number(value)) === 1
      description = isPositiveValue
        ? `Additional cost for ${configReq.name} Size`
        : `Deduction in cost for ${configReq.name} Size`
    }

    if (configReq && configReq.type === 'marking_option') {
      let isPositiveValue = Math.sign(Number(value)) === 1
      description = isPositiveValue
        ? `Additional cost for selecting ${configReq.name}`
        : `Deduction in cost for selecting ${configReq.name}`
    }

    return description
  }

  handleGenerateRatingRules(formValues) {
    let { product } = this.props

    let ratingRules = Object.entries(formValues)
      .map(([key, value]) => {
        if (!value || value === '0') return

        let ruleId = key.split('__')[0]
        let priceType = `urn:lic:product:price:base:unit:${key.split('__')[1]}`
        let ruleValue = null

        let configReq = this.state.ratingConfigReqs.find(
          configReq => configReq.identity === ruleId
        )

        let description = this.generateRatingRuleDescription(ruleId, configReq, value)

        if (configReq && configReq.type === 'size') {
          ruleId = 'urn:lic:product:size'
          ruleValue = configReq.identity
          priceType = `urn:lic:product:price:configreq:unit:${key.split('__')[1]}`
        }

        if (configReq && configReq.type === 'marking_option') {
          priceType = `urn:lic:product:price:configreq:unit:${key.split('__')[1]}`
        }

        if (configReq && configReq.type === 'charge') {
          ruleId = product.slug
          priceType = RATING_PRICE_URN_MAPPINGS.CHARGE_LEVY_UNIT_NAIT
        }

        let rule = {
          rule_id: ruleId,
          price_type: priceType,
          description,
          value,
        }
        if (ruleValue) rule.rule_value = ruleValue

        return rule
      })
      .filter(ratingRule => ratingRule)

    this.setState({ ratingRules })
  }

  getDefaultConfiguration() {
    let { product } = this.props

    let size = product.available_sizes.find(size => size.default)
    let markingOptions = extractDefaultMarkingOptions(product)

    return { size, markingOptions }
  }

  renderDefaultConfiguration() {
    let { size, markingOptions } = this.getDefaultConfiguration()
    if (!size && !Object.keys(markingOptions).length) return null

    return (
      <BlockContent>
        <SectionHeading>Default configuration</SectionHeading>
        <dl className={styles.defaultConfigurationList}>
          <dt>Size:</dt>
          <dd>{size ? size.display_name : 'Not applicable'}</dd>
          {Object.keys(markingOptions).map(componentName => (
            <Fragment key={componentName}>
              <dt>{componentName}:</dt>
              <dd>{markingOptions[componentName].name}</dd>
            </Fragment>
          ))}
        </dl>
      </BlockContent>
    )
  }

  renderVersionedAtSelectionAndSave() {
    let { ratingRules, versionedAt, currentUser } = this.state

    let tomorrow = moment().add(1, 'days')
    let dateTimeExample = `${tomorrow.format('DD/MM/YYYY')}, 01:30 PM`

    return (
      <Fragment>
        <VariantsTable product={this.props.product} ratingRules={ratingRules} user={currentUser} />
        <FormRow
          label='Select a date and time for these rating rules to be applicable from'
          helpText='If nothing is selected, the rating rules will be applicable from the date and time of creation'
        >
          <FormField
            name='versionedAt'
            inline
            label={`E.g. ${dateTimeExample}`}
            type='datetime-local'
            onChange={this.handleChangeVersionedAt}
            value={versionedAt}
          />
        </FormRow>
        <Button className='buttonPrimary' onClick={this.handleToggleModal}>
          Save and create rating rules
        </Button>
      </Fragment>
    )
  }

  getCurrentUser() {
    let { currentProfile } = this.props

    let partyLink = href({ links: currentProfile?.links, rel: 'party'})
    axios
      .get(proxyUrl({ link: partyLink }))
      .then(party => {
        if (party?.data) {
          this.setState({ currentUser: party.data.party.metadata })
        }
      })
      .catch(err =>
        enqueueError({
          message:
            'We were unable to get the current user for ratings. Error: ' + err,
        })
      )
  }

  compareCurrentUser(previousCurrentUser, currentUser) {
    return previousCurrentUser?.name !== currentUser?.name && previousCurrentUser?.email !== currentUser?.email ? true : false
  }

  render() {
    let { product, productRequestStatus } = this.props
    let { ratingConfigReqs, showConfirmationModal, showPreview, versionedAt } = this.state

    let initialValues = product && ratingConfigReqs.length && this.generateInitialValues()
    let currentUser = this.state.currentUser;

    return (
      <PageSection>
        <PageHeading
          heading='Configure rating'
          onAction={this.handleBack}
          actionText='Cancel'
        />
        <RequestWrapper requestState={productRequestStatus}>
          <Grid col>
            <div className={`${styles.gridItem} ${styles.defaultConfiguration}`}>
              {product && this.renderDefaultConfiguration()}
            </div>
            <div className={styles.gridItem}>
              {product?.slug ? (
                <CreateRatingForm
                  onChange={this.handleGenerateRatingRules}
                  initialValues={initialValues}
                  product={product}
                  ratingConfigReqs={ratingConfigReqs}
                />
              ) : null}
            </div>
          </Grid>
          {!showPreview && (
            <Button className='buttonPrimary' onClick={this.handleShowPreview}>
              Preview
            </Button>
          )}
          {showPreview && this.renderVersionedAtSelectionAndSave()}
        </RequestWrapper>
        <ConfirmCreateRatingModal
          onClose={this.handleToggleModal}
          onConfirm={this.handleCreateRating}
          showModal={showConfirmationModal}
          versionedAt={versionedAt}
          user={currentUser}
        />
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  createRatingRules,
  enqueueError,
  fetchProduct,
}

export { CreateRating }
export default connect(state => {
  return {
    product: state.product.data,
    productRequestStatus: getRequestStatus(state.adminProduct.requestState),
    currentProfile: state.currentProfile.data, 
  }
}, mapDispatchToProps)(CreateRating)
