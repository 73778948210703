import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import styles from './styles.scss'

const ChildrenData = ({ productChildren }) => {
  return productChildren.length > 0 ? (
    <ul className={styles.list}>
      {productChildren.map(child => (
        <li key={child.slug}>
          <Link to={`/products/${child.slug}`}>{child.name}</Link>
        </li>
      ))}
    </ul>
  ) : (
    <div>No children found</div>
  )
}
ChildrenData.propTypes = {
  productChildren: PropTypes.array.isRequired,
}

export default ChildrenData
