import { Component } from 'react'
import { connect } from 'react-redux'

import { customerContextIsComplete } from 'selectors/customerContextSelectors'
import { setCustomerContext } from 'actions/customerContextActions'
import { fetchOrganisation } from 'actions/organisationActions'
import { fetchPerson } from 'actions/personActions'

class CustomerContextHandler extends Component {
  componentDidUpdate(prevProps) {
    let { customerContextIsComplete } = this.props
    if (!prevProps.customerContextIsComplete && customerContextIsComplete)
      this.dereferenceBaseContext()
  }

  dereferenceBaseContext() {
    let { customerContext, fetchOrganisation, fetchPerson } = this.props
    fetchOrganisation(customerContext.organisation_party_link)
    fetchPerson(customerContext.party_link)
  }

  render() {
    return null
  }
}

const mapDispatchToProps = {
  fetchOrganisation,
  fetchPerson,
  setCustomerContext,
}

export { CustomerContextHandler }
export default connect(state => {
  return {
    customerContext: state.customerContext.data,
    customerContextIsComplete: customerContextIsComplete(state),
  }
}, mapDispatchToProps)(CustomerContextHandler)
