import React from 'react'

import styles from './styles.scss'

function SectionHeading({ button, children }) {
  return (
    <div className={styles.container}>
      <h4 className={`${styles.heading} ${styles.headingSection}`}>{children}</h4>
      {button}
    </div>
  )
}

export default SectionHeading
