import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { extractIdFromUrl } from 'utils/util'
import { fetchSupplier } from 'actions/supplierActions'
import { fetchShippingRates, clearShippingRates } from 'actions/shippingRatesActions'

import RequestWrapper from '@licnz/react-request-wrapper'
import PageSection from 'lib/components/layout/PageSection'
import PageHeading from 'lib/components/headings/PageHeading'
import SupplierDetail from './SupplierDetail'
import ShippingRatesDetail from './ShippingRatesDetail'

class Supplier extends Component {
  constructor(props) {
    super(props)
    this.handleBack = this.handleBack.bind(this)
  }

  handleBack() {
    this.props.history.push('/suppliers')
  }

  componentDidMount() {
    let {
      fetchSupplier,
      match: {
        params: { id },
      },
    } = this.props
    fetchSupplier(id)
  }

  componentDidUpdate(prevProps) {
    let { supplier, fetchShippingRates } = this.props

    if (prevProps.supplier !== supplier && supplier) {
      let supplierName = extractIdFromUrl(supplier.id)
      supplierName && fetchShippingRates(supplierName)
    }
  }

  componentWillUnmount() {
    this.props.clearShippingRates()
  }

  render() {
    let { supplier, supplierRequestStatus, shippingRates } = this.props

    return (
      <PageSection>
        <RequestWrapper requestState={supplierRequestStatus}>
          {supplier ? (
            <Fragment>
              <PageHeading
                heading={supplier.name === 'Zee Tags' ? global.FEATURE_FLAGS.Z_TAGS_BRANDING ? 'Z Tags' : 'Zee Tags' : supplier.name}
                onAction={this.handleBack}
                actionText='Back'
              />
              <SupplierDetail supplier={supplier} />
              <ShippingRatesDetail shippingRates={shippingRates} />
            </Fragment>
          ) : null}
        </RequestWrapper>
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  fetchSupplier,
  fetchShippingRates,
  clearShippingRates,
}

export { Supplier }
export default connect(state => {
  return {
    shippingRates: state.shippingRates.data,
    supplier: state.supplier.data,
    supplierRequestStatus: state.supplier.requestState,
  }
}, mapDispatchToProps)(Supplier)
