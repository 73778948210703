import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import axios from 'lib/utils/axios';
import { proxyUrl } from 'lib/utils/proxy';
import { clearProducts } from 'actions/productsSearchActions';
import { productsSearchTypes } from 'actions/types'

import { useQuery } from 'react-query';
import { sentryEnhance401Error } from 'utils/monitoringClient';
import useCachedState from 'utils/useCachedState';

const baseType = productsSearchTypes.SEARCH_PRODUCTS;

const useProductsSearch = () => {
  const dispatch = useDispatch();
  const errorContext = new Error('Thrown at:');
  // This custom hook, utilises react-query to re-implement 
  // redux actions and cache network calls to prevent queries with
  // the same parameter to be called multiple times
  const [enabled, setEnabled] = useCachedState('productSearchEnabled', true);
  const [q, setQ] = useState(null);

  const { status, data, isLoading, error } = useQuery(['productSearch', q || ''], ({ signal }) => {
    let params = {
      page: 1,
      per_page: 10,
      offering_type: 'urn:lic:id:offering_type:good',
      statuses: ['approved'],
    }
    if (q && q !== '') params.q = q // product service rejects q if empty string

    const link = `${global.config.PRODUCT_SERVICE_ENDPOINT}/admin/products/search`;
    return axios
      .post(proxyUrl({ link }), params, { signal })
      .then(response => response.data)
      .catch(error => {
        throw error
      })
  }, {
    staleTime: 60 * 60 * 1000, // 1 hour (60 min) in ms
    enabled,
    onError: (error) => {
      if (error.request.status === 401 && error?.response?.data?.table?.errors) {
        // If request failed due to a 401, the user likely doesn't have permission to fetch this.
        // As such disable the react-query
        setEnabled(false);

        error.stack = errorContext.stack.replace(errorContext.message, error.message);
        if (!sentryEnhance401Error(error)) {
          throw error;
        }
      }
    }
  });

  useEffect(() => {
    // On mount/dismount, clearProducts
    dispatch(clearProducts());
    return () => {
      dispatch(clearProducts());
    }
  }, []);

  useEffect(() => {
    dispatch(clearProducts());
    if (status === 'success') {
      dispatch({ type: `${baseType}_FULFILLED`, payload: data });
    } else if (status === 'error') {
      dispatch({ type: `${baseType}_REJECTED`, payload: error });
    }
  }, [status, data, error, isLoading]);

  return {
    setQ,
    enabled,
    isLoading,
  }
};
export default useProductsSearch;