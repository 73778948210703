import Decimal from 'decimal.js/decimal'
import slugify from 'slugify'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

function titleCase(string) {
  return string.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

function convertUnderscoresToSpaces(string) {
  return string.split('_').join(' ')
}

function convertToSentenceCase(string) {
  // A naive implementation only for a single sentence. Only used for labels so this should be ok.
  if (string.includes('_')) string = convertUnderscoresToSpaces(string)
  let lc = string.toLowerCase()
  return lc[0].toUpperCase() + lc.slice(1)
}

function formatSlugAsTitle(str) {
  return titleCase(str.split('-').join(' '))
}

function formatStatus(status) {
  return convertToSentenceCase(status)
}

function formattedPrice(price) {
  return price
    ? new Decimal(Math.abs(price)).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
    : new Decimal(0).toFixed(2)
}

function stringAsDecimal(string) {
  return new Decimal(string).toNumber()
}

function uniqueArrayElements(array) {
  let uniqueArray = array.filter(function (item, i, ar) {
    return ar.indexOf(item) === i
  })
  return uniqueArray
}

function findPrice(collection, urn) {
  let foundPrice =
    collection.length > 0 &&
    collection.find(price => {
      return price['_type'] === urn
    })
  return foundPrice
}

function pad(n) {
  return n > 0 && n < 10 ? '0' + n : n
}

function stringDateToIsoDateString(string_date) {
  return new Date(string_date).toISOString()
}

function extractIdFromUrl(url) {
  return /[^/]*$/.exec(decodeURIComponent(url))[0]
}

function extendedSlugify(string) {
  slugify.extend({ ':': '-' })
  return slugify(string, { lower: true })
}

function resourceMap({ links }) {
  if (isEmpty(links)) return {}

  return links.reduce((resourceMap, link) => {
    resourceMap[link.rel] = link.href
    return resourceMap
  }, {})
}

const addBusinessDays = (date, daysToAdd, format = 'YYYY/MM/DD') => {
  const WEEKEND = [moment().day('Saturday').weekday(), moment().day('Sunday').weekday()]
  var daysAdded = 0,
    momentDate = moment(new Date(date))
  while (daysAdded < daysToAdd) {
    momentDate = momentDate.add(1, 'days')
    if (!WEEKEND.includes(momentDate.weekday())) {
      daysAdded++
    }
  }

  return momentDate.format(format)
}

export {
  resourceMap,
  convertToSentenceCase,
  convertUnderscoresToSpaces,
  extendedSlugify as slugify,
  extractIdFromUrl,
  findPrice,
  formatSlugAsTitle,
  formatStatus,
  formattedPrice,
  pad,
  stringAsDecimal,
  stringDateToIsoDateString,
  titleCase,
  uniqueArrayElements,
  addBusinessDays,
}
