import { combineReducers } from 'redux'
import { productImageTypes } from 'actions/types'
import requestStateReducer from 'lib/reducers/requestStateReducer'

const reducer = combineReducers({
  addProductImage: requestStateReducer(productImageTypes.ADD_PRODUCT_IMAGE),
  deleteProductImage: requestStateReducer(productImageTypes.DELETE_PRODUCT_IMAGE),
  updateProductImage: requestStateReducer(productImageTypes.UPDATE_PRODUCT_IMAGE),
})

export default reducer
