import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import axios from 'lib/utils/axios'
import fileDownload from 'js-file-download'
import moment from 'moment-timezone'
import pluralize from 'pluralize'
import queryString from 'query-string'
import ReactModal from 'react-modal'

import orderFilterConstants from 'constants/orderFilters'
import { enqueueError, enqueueInfo } from 'lib/components/GlobalNotifications'
import { isAdmin } from 'selectors/userInfoSelectors'
import { ordersSearch, clearOrders } from 'actions/ordersSearchActions'
import { proxyUrl } from 'lib/utils/proxy'
import { setCustomerContext } from 'actions/customerContextActions'

import Button from 'lib/components/Button'
import DropdownFilters from 'lib/components/DropdownFilters'
import FormField from 'lib/components/FormField'
import FormRow from 'lib/components/FormRow'
import OrdersList from 'components/OrdersList'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import Pagination from '@licnz/react-pagination'
import RequestWrapper from '@licnz/react-request-wrapper'
import SearchBarContainer from 'lib/components/SearchBarContainer'
import SelectProductAutocomplete from 'components/SelectProductAutocomplete'

import styles from './styles.scss'
const customStyles = {
  overlay: {
    animation: 'modal-in 0.15s ease-out 0s backwards',
    backgroundColor: 'rgba(0, 30, 98, .5)',
    zIndex: '4',
  },
}

const initialState = {
  page: 1,
  perPage: 10,
}
class Orders extends Component {
  constructor(props) {
    super(props)

    this.state = {
      orderFilters: [],
      orderReferenceSearchSubmitting: false,
      isExporting: false,
    }

    this.buildSearchFilters = this.buildSearchFilters.bind(this)
    this.filterTagOnlyOperations = this.filterTagOnlyOperations.bind(this)
    this.handleChangeFieldValue = this.handleChangeFieldValue.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleClearFilters = this.handleClearFilters.bind(this)
    this.handleExport = this.handleExport.bind(this)
    this.handleUpdateFilters = this.handleUpdateFilters.bind(this)

    this.productAutocompleteRef = React.createRef()
  }

  buildSearchFilters() {
    let { location } = this.props

    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let {
      q,
      createdAfter,
      createdBefore,
      customerPurchaseOrderNumber,
      status,
      tagOnlyOperations,
    } = params

    let filters = {}
    if (status) filters['statuses'] = status
    if (q) filters['order_reference'] = q
    if (createdBefore) filters['created_at_before'] = moment(createdBefore)
    if (createdAfter) filters['created_at_after'] = moment(createdAfter)
    if (customerPurchaseOrderNumber)
      filters['customer_purchase_order_number'] = customerPurchaseOrderNumber
    if (tagOnlyOperations == 'true') filters['regulated'] = false

    return filters
  }

  componentDidMount() {
    let { location, setCustomerContext, clearOrders } = this.props
    clearOrders()

    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    this.setState({ orderFilters: orderFilterConstants }, () => {
      if (Object.keys(params).length == 0) {
        this.updateUrl(initialState)
      } else {
        this.performSearch()
      }
    })

    let { person_id, organisation_id } = params

    if (organisation_id && person_id) {
      setCustomerContext({
        party_link: person_id,
        organisation_party_link: organisation_id,
      })
    }
  }

  componentDidUpdate(prevProps) {
    let { location } = this.props

    if (prevProps.location.search !== location.search) {
      let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
      if (Object.keys(params).length == 0) {
        this.updateUrl(initialState)
      } else {
        this.performSearch()
      }
    }
  }

  handleChangeFieldValue(e) {
    let params = queryString.parse(this.props.location.search, { arrayFormat: 'bracket' })
    let {
      perPage,
      createdAfter,
      createdBefore,
      customerPurchaseOrderNumber,
      salesOrderNumber,
      sapPurchaseOrderNumber,
      status,
      tagOnlyOperations,
      productSearch,
      productColour,
      productSize,
    } = params

    if(e.target?.name === 'productSearch' && e.target?.value === undefined) {
      productSearch = undefined
      productColour = undefined
      productSize = undefined
    }

    this.updateUrl(
      { page: 1, perPage, tagOnlyOperations },
      {
        createdAfter,
        createdBefore,
        customerPurchaseOrderNumber,
        salesOrderNumber,
        sapPurchaseOrderNumber,
        status,
        productSearch,
        productColour,
        productSize,
        [e.target.name]: e.target.value,
      }
    )
  }

  filterTagOnlyOperations(e) {
    let params = queryString.parse(this.props.location.search, { arrayFormat: 'bracket' })
    let {
      perPage,
      createdAfter,
      createdBefore,
      customerPurchaseOrderNumber,
      salesOrderNumber,
      sapPurchaseOrderNumber,
      status,
    } = params

    this.updateUrl(
      { page: 1, perPage, tagOnlyOperations: e.target.checked },
      {
        createdAfter,
        createdBefore,
        customerPurchaseOrderNumber,
        salesOrderNumber,
        sapPurchaseOrderNumber,
        status,
      }
    )
  }

  handleChangePage(page) {
    this.updateUrl({ page })
  }

  handleClearFilters() {
    if(this.productAutocompleteRef.current) this.productAutocompleteRef.current.clearSearch()
    this.updateUrl(initialState, {})
  }

  handleExport(includeProducts) {
    let { location, enqueueInfo, enqueueError, totalResults } = this.props
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let { organisation_id } = params
    let filters = this.buildSearchFilters()

    if (organisation_id) {
      params = {
        organisation_party_link: organisation_id,
        include_products: includeProducts,
        ...filters,
      }
    } else {
      params = { include_products: includeProducts, ...filters }
    }

    let link = `${global.config.ORDER_SERVICE_ENDPOINT}/orders/export_search_to_csv/`
    let dateTimeString = moment().toISOString().replace(/[-:.]/g, '')

    this.setState({ isExporting: true })

    return axios
      .post(proxyUrl({ link }), params)
      .then(response => {
        var blob = new Blob([response.data], { type: 'text/csv' })
        fileDownload(blob, `orders-${dateTimeString}.csv`)
        this.setState({ isExporting: false })

        let exportCountLimit = global.config.ORDER_EXPORT_COUNT_LIMIT
        if (totalResults > exportCountLimit) {
          enqueueInfo({
            message: `The first ${exportCountLimit} orders in the search results have only been returned.`,
          })
        }
      })
      .catch(err =>
        enqueueError({ message: 'We were unable to export the orders. Error: ' + err })
      )
  }

  handleUpdateFilters(filters) {
    let { location } = this.props
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let {
      perPage,
      createdAfter,
      createdBefore,
      customerPurchaseOrderNumber,
      salesOrderNumber,
      sapPurchaseOrderNumber,
      tagOnlyOperations,
    } = params

    let newFilters = {}
    filters.forEach(filter => {
      newFilters[filter.id] = filter.selectedOptions
    })

    this.updateUrl(
      { perPage, page: 1, tagOnlyOperations },
      {
        ...newFilters,
        createdAfter,
        createdBefore,
        customerPurchaseOrderNumber,
        salesOrderNumber,
        sapPurchaseOrderNumber,
      }
    )
  }

  performSearch() {
    let { location, ordersSearch, enqueueError } = this.props
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let {
      q,
      page,
      perPage,
      organisation_id,
      createdAfter,
      createdBefore,
      customerPurchaseOrderNumber,
      salesOrderNumber,
      sapPurchaseOrderNumber,
      status,
      tagOnlyOperations,
      productSearch,
      productColour,
      productSize,
    } = params

    let filters = {}
    if (status) filters['statuses'] = status
    if (q) filters['order_reference'] = q
    if (createdBefore) filters['created_at_before'] = moment(createdBefore)
    if (createdAfter) filters['created_at_after'] = moment(createdAfter)
    if (customerPurchaseOrderNumber)
      filters['customer_purchase_order_number'] = customerPurchaseOrderNumber
    if (salesOrderNumber) filters['sales_order_number'] = salesOrderNumber
    if (sapPurchaseOrderNumber) filters['purchase_order_number'] = sapPurchaseOrderNumber
    if (tagOnlyOperations == 'true') filters['regulated'] = false
    if (productSearch) filters['product_url'] = productSearch
    if (productColour) filters['product_colour'] = productColour
    if (productSize) filters['product_size'] = productSize

    page = page ? Number(page) : initialState.page
    perPage = perPage ? Number(perPage) : initialState.perPage
    if (organisation_id) {
      params = {
        organisation_party_link: organisation_id,
        page,
        per_page: perPage,
        ...filters,
      }
    } else {
      params = { page, per_page: perPage, ...filters }
    }

    this.setState({ orderReferenceSearchSubmitting: true })
    ordersSearch(params)
      .then(() => {
        this.setState({ orderReferenceSearchSubmitting: false })
      })
      .catch(() => {
        enqueueError({ message: 'We were unable to fetch any orders' })
        this.setState({ orderReferenceSearchSubmitting: true })
      })
  }

  updateUrl(updateParams, filters) {
    let { location, history } = this.props

    let currentParams = queryString.parse(location.search, { arrayFormat: 'bracket' })
    if (filters)
      currentParams = {
        page: currentParams.page,
        perPage: currentParams.perPage,
        ...filters,
      }
    let params = { ...currentParams, ...updateParams }

    history.push(`/orders?${queryString.stringify(params, { arrayFormat: 'bracket' })}`)
  }

  render() {
    let { isAdmin, location, searchResults, ordersSearchRequestState, totalResults } = this.props

    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let {
      createdAfter,
      createdBefore,
      customerPurchaseOrderNumber,
      salesOrderNumber,
      sapPurchaseOrderNumber,
      tagOnlyOperations,
    } = params

    let page = params.page ? Number(params.page) : 1
    let perPage = params.perPage ? Number(params.perPage) : 10

    let newFilters = this.state.orderFilters.map(filter => ({
      ...filter,
      selectedOptions: params[filter.id] || [],
    }))

    let isTagOnlyChecked = tagOnlyOperations === 'true'

    return (
      <PageSection>
        <PageHeading heading='Orders' onAction={this.handleExport} actionText='Export' />
        <DropdownFilters
          filters={newFilters}
          onChange={this.handleUpdateFilters}
          onClearFilters={this.handleClearFilters}
        />
        <SearchBarContainer
          placeholder='Search for an Order Reference...'
          q={params.q || ''}
          searchUrl='/orders'
          submitting={this.state.orderReferenceSearchSubmitting}
        />
        <FormRow>
          <FormField
            name='createdAfter'
            label='Created after (inclusive)'
            inline
            type='date'
            onChange={this.handleChangeFieldValue}
            value={createdAfter}
          />
          <FormField
            name='createdBefore'
            label='Created before (exclusive)'
            inline
            type='date'
            onChange={this.handleChangeFieldValue}
            value={createdBefore}
          />
          <FormField
            name='customerPurchaseOrderNumber'
            label='Customer PO Number'
            inline
            type='search'
            onChange={this.handleChangeFieldValue}
            value={customerPurchaseOrderNumber}
          />
          <FormField
            name='salesOrderNumber'
            label='Sales Order Number'
            inline
            type='search'
            onChange={this.handleChangeFieldValue}
            value={salesOrderNumber}
          />
          <FormField
            name='sapPurchaseOrderNumber'
            label='SAP PO Number'
            inline
            type='search'
            onChange={this.handleChangeFieldValue}
            value={sapPurchaseOrderNumber}
          />
          {global.FEATURE_FLAGS.SEARCH_PRODUCT && (
            <SelectProductAutocomplete
              type='text'
              onChange={this.handleChangeFieldValue}
              enqueueError={enqueueError}
              ref={this.productAutocompleteRef}
            />
          )}
          <FormField
            name='tagOnlyOperations'
            label='Tag only operations'
            inline
            type='checkbox'
            onChange={this.filterTagOnlyOperations}
            checked={isTagOnlyChecked}
          />
        </FormRow>
        {this.state.isExporting && (
          <ReactModal
            isOpen={this.state.isExporting}
            contentLabel='Exporting Orders'
            className={styles.content}
            style={customStyles}
            ariaHideApp={false}
          >
            <p>The CSV file is currently being prepared......</p>
            <RequestWrapper loading />
          </ReactModal>
        )}
        <RequestWrapper requestState={ordersSearchRequestState}>
          {totalResults && !this.state.isExporting ? (
            <div>
              <p className={styles.totalResultsDescription}>
                {pluralize('result', totalResults, true)} found
              </p>
              <p className={styles.exportButton}>
                <Button
                  className='buttonSecondary'
                  onClick={() => this.handleExport(false)}
                >
                  Export
                </Button>
                &nbsp;
                <Button
                  className='buttonSecondary'
                  onClick={() => this.handleExport(true)}
                >
                  Export with products
                </Button>
              </p>
            </div>
          ) : null}
          {searchResults && searchResults.length ? (
            <Fragment>
              <OrdersList orders={searchResults} isAdmin={isAdmin}/>
              <Pagination
                border={false}
                currentPage={page}
                items={searchResults}
                onChangePage={this.handleChangePage}
                pageSize={perPage}
                totalItems={totalResults}
              />
            </Fragment>
          ) : (
            <h4>No orders found</h4>
          )}
        </RequestWrapper>
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  clearOrders,
  enqueueError,
  enqueueInfo,
  ordersSearch,
  setCustomerContext,
}

export { Orders }
export default connect(state => {
  return {
    isAdmin: isAdmin(state),
    searchResults: state.ordersSearch.data.items,
    totalResults: state.ordersSearch.data.total_results,
    ordersSearchRequestState: state.ordersSearch.requestState,
    customerContext: state.customerContext.data,
  }
}, mapDispatchToProps)(Orders)
