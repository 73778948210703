import React from 'react'
import PropTypes from 'prop-types'

import ReactModal from 'react-modal'
import Button from 'lib/components/Button'

import styles from './styles.scss'

const DeleteBasketModal = ({ itemCount, showModal, toggleModal, handleDelete }) => {
  return (
    <ReactModal
      isOpen={showModal}
      contentLabel='Delete Basket Modal'
      onRequestClose={toggleModal}
      overlayClassName={styles.overlay}
      className={styles.content}
      ariaHideApp={false}
    >
      <div className={styles.textContent}>
        <p>Are you sure you want to delete this basket?</p>
        <p>All items will be removed, and you will need to create a new basket.</p>
        <p>{`(${itemCount} items in basket)`}</p>
      </div>
      <div class={styles.buttonContent}>
        <Button className='buttonOutline' onClick={handleDelete} autoFocus>
          Yes
        </Button>
        <Button className='buttonSecondary' onClick={toggleModal}>
          No
        </Button>
      </div>
    </ReactModal>
  )
}

DeleteBasketModal.propTypes = {
  itemCount: PropTypes.number.isRequired,
  showModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
}

export default DeleteBasketModal
