import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { requestedProductsTypes } from 'actions/types'

function fetchRequestedProducts(requestedProductsLink) {
  let baseType = requestedProductsTypes.FETCH_REQUESTED_PRODUCTS
  let url = proxyUrl({ link: requestedProductsLink })

  return get({ baseType, url })
}

function clearRequestedProducts() {
  return { type: requestedProductsTypes.CLEAR_FETCH_REQUESTED_PRODUCTS }
}

export { fetchRequestedProducts, clearRequestedProducts }
