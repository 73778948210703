import { combineReducers } from 'redux'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import dataReducer from 'lib/reducers/dataReducer'

import { productTypes } from 'actions/types'

const initialState = null
const baseType = productTypes.FETCH_ADMIN_PRODUCT
const updateProductCategorisationsType = productTypes.UPDATE_PRODUCT_CATEGORISATIONS

const reducer = combineReducers({
  requestState: requestStateReducer(baseType),
  data: dataReducer({ initialState, baseType }),
  updateProductCategorisations: requestStateReducer(updateProductCategorisationsType),
})

export default reducer
