import { post } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'

import { basketsSearchTypes } from 'actions/types'

function basketsSearch(params) {
  let baseType = basketsSearchTypes.SEARCH_BASKETS
  let link = `${global.config.ORDER_SERVICE_ENDPOINT}/basket/search`
  let body = params
  let url = proxyUrl({ link })

  return post({ baseType, url, body })
}

function clearBaskets() {
  return { type: basketsSearchTypes.CLEAR_SEARCH_BASKETS }
}

export { clearBaskets, basketsSearch }
