/**
 *  This is a helper function to use when stubbing an API request via redux thunk.
 *  It simulates a network request's delay and dispatches an action to store a
 * given payload in redux. Example usage:
 *
 *  function apiCallActionGenerator() {
 *    let baseType = 'API_CALL'
 *    let payload = apiResponseDataFixture
 *    return mockThunk({ baseType, payload })
 *  }
 *
 * @param {*} param0 an object containing the mocked thunk's action `baseType`
 * and the `payload` to store in redux on completion.
 */
function mockThunk({ baseType, payload }) {
  return function (dispatch) {
    dispatch({ type: baseType })

    return new Promise(resolve => setTimeout(resolve, 500))
      .then(() => {
        dispatch({ type: `${baseType}_FULFILLED`, payload })
      })
      .catch(err => {
        dispatch({ type: `${baseType}_REJECTED`, payload: err })
        throw err
      })
  }
}

export default mockThunk
