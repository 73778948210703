import React from 'react'

import PageSection from 'lib/components/layout/PageSection'
import PageHeading from 'lib/components/headings/PageHeading'

const Unauthorised = () => {
  return (
    <PageSection>
      <PageHeading heading='Whoops!' />
      <p>It looks like you don't have permission to do that.</p>
      <p>Maybe you were trying to:</p>
      <ul>
        <li>
          <a href={global.config.SHOP_FRONTEND_ENDPOINT}>
            View and order products in the shop
          </a>
        </li>
        <li>
          <a href={global.config.LIC_CUSTOMER_ENDPOINT}>
            Manage your profile, operations and organisations
          </a>
        </li>
        <li>
          <a href='https://www.lic.co.nz'>Visit the LIC website</a>
        </li>
      </ul>
    </PageSection>
  )
}

export default Unauthorised
