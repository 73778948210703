import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DropdownTrigger from './DropdownTrigger'
import DropdownCheckboxOption from './DropdownCheckboxOption'

import styles from './styles.scss'

class MultiSelectDropdown extends Component {
  constructor() {
    super()
    this.state = { isOpen: false }
    this.handleToggle = this.handleToggle.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
  }

  componentDidMount() {
    window.addEventListener('click', this.handleDocumentClick)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleDocumentClick)
  }

  handleDocumentClick(e) {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target) && this.state.isOpen) {
      this.handleToggle()
    } else {
      e.stopPropagation()
    }
  }

  handleSelect(option) {
    let { identifier, onSelect } = this.props
    onSelect(option, identifier)
  }

  handleToggle() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  optionComponents() {
    let { options, selectedOptions } = this.props

    return options.map(opt => {
      let checked = selectedOptions.includes(opt.id)

      return (
        <DropdownCheckboxOption
          key={opt.id}
          id={opt.id}
          onSelect={this.handleSelect}
          checked={checked}
          name={opt.name}
        />
      )
    })
  }

  selectedOptionName() {
    let { options, selectedOptions, selectOptionText } = this.props

    const selectedOptionsNames = () =>
      options
        .filter(opt => selectedOptions.includes(opt.id))
        .map(opt => opt.name)
        .join(', ')

    return selectedOptions.length
      ? `${selectOptionText}: ${selectedOptionsNames()}`
      : selectOptionText
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  render() {
    let { options, identifier } = this.props
    let { isOpen } = this.state

    return (
      <nav ref={this.setWrapperRef} className={styles.nav} id={identifier}>
        <DropdownTrigger
          identifier={identifier}
          onToggle={this.handleToggle}
          name={this.selectedOptionName()}
          enabled={options && options.length > 0}
          active={isOpen}
        />

        <ul className={`${styles.ul} ${isOpen ? styles.active : ''}`}>
          {this.optionComponents()}
        </ul>
      </nav>
    )
  }
}

MultiSelectDropdown.propTypes = {
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOptions: PropTypes.array.isRequired,
  selectOptionText: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
}

export default MultiSelectDropdown
