import React, { Fragment } from 'react'

import { ORDERED_PRODUCT_STATE_MAPPINGS } from 'constants/stateMappings'
import { OFFER_PRICE_URN_MAPPINGS } from 'constants/priceMappings'
import { convertToSentenceCase, findPrice, stringAsDecimal } from 'utils/util'

import BlockContent from 'lib/components/layout/BlockContent'
import Currency from 'lib/components/Currency'
import DateTime from 'lib/components/DateTime'

import styles from './styles.scss'

const {
  offerPriceDiscountRetail,
  offerPriceDiscount,
  offerPriceRetail,
  offerPriceSell,
} = OFFER_PRICE_URN_MAPPINGS

const OrderedProductDetails = ({ orderedProduct }) => {
  const isCancelled = orderedProduct.state === ORDERED_PRODUCT_STATE_MAPPINGS.CANCELLED

  const itemSubtotal = () => {
    if (!orderedProduct) return null
    let retail = findPrice(orderedProduct.offered_prices, offerPriceSell)
    return retail && stringAsDecimal(retail.price)
  }

  const subtotalRetailPrice = () => {
    if (!orderedProduct) return null
    let retail = findPrice(orderedProduct.offered_prices, offerPriceRetail)
    return retail && stringAsDecimal(retail.price)
  }

  let discount = orderedProduct
    ? findPrice(orderedProduct.offered_prices, offerPriceDiscountRetail) ||
      findPrice(orderedProduct.offered_prices, offerPriceDiscount)
    : null

  const renderPricingDetails = () => {
    return isCancelled ? (
      <s className={styles.cancelledPrice}>
        <Currency id='itemSubtotal' amount={itemSubtotal()} />
      </s>
    ) : (
      <Fragment>
        {discount ? (
          <s className={styles.nonDiscountedPrice}>
            <Currency id='subtotalRetailPrice' amount={subtotalRetailPrice()} />
          </s>
        ) : null}
        <Currency amount={itemSubtotal()} />
      </Fragment>
    )
  }

  const renderCancellationDetails = () => {
    let canceller = orderedProduct['urn:lic:predicate:ordered_product_canceller']
    let cancelledAt = orderedProduct.timestamps.cancelled_time

    return canceller && cancelledAt ? (
      <span>
        {canceller.name} (<DateTime date={cancelledAt} format='DD/MM/YY, h:mm A' />)
      </span>
    ) : (
      'Not found'
    )
  }

  return (
    <div className={styles.lineLength}>
      <BlockContent>
        <ul className={styles.details}>
          <li>Quantity: {orderedProduct.quantity}</li>
          <li>Price: {renderPricingDetails()}</li>
          {orderedProduct.customer_purchase_order_number ? (
            <li>My reference: {orderedProduct.customer_purchase_order_number}</li>
          ) : null}
          <li>Status: {convertToSentenceCase(orderedProduct.state)}</li>
          {isCancelled ? <li>Cancelled by: {renderCancellationDetails()}</li> : null}
        </ul>
      </BlockContent>
    </div>
  )
}

export default OrderedProductDetails
