import React from 'react'
import PropTypes from 'prop-types'

import { formattedPrice, titleCase } from 'utils/util'

import Currency from 'lib/components/Currency'

import styles from './styles.scss'

const ShippingRatesTableRow = ({ shippingRate }) => {
  let price = shippingRate && formattedPrice(shippingRate.rate)

  return shippingRate ? (
    <tr key={shippingRate.id}>
      <td>{titleCase(shippingRate.rate_method)}</td>
      <td className={styles.pullRight}>{shippingRate.lower_bound}</td>
      <td className={styles.pullRight}>{shippingRate.upper_bound}</td>
      <td className={styles.pullRight}>
        <Currency amount={price} />
      </td>
    </tr>
  ) : null
}

ShippingRatesTableRow.propTypes = {
  shippingRate: PropTypes.object,
}

export default ShippingRatesTableRow
