import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { reduxForm, reset } from 'redux-form'
import { href } from '@licnz/js-utils'

import { fetchAdminProduct, clearProduct } from 'actions/productActions'
import { addProductImage } from 'actions/productImageActions'

import PageSection from 'lib/components/layout/PageSection'
import PageHeading from 'lib/components/headings/PageHeading'
import ImageForm from 'components/ImageForm'

import { extractIdFromUrl } from 'utils/util'

class CreateImage extends Component {
  constructor(props) {
    super(props)
    this.handleBack = this.handleBack.bind(this)
    this.handleAddProductImage = this.handleAddProductImage.bind(this)
  }

  componentDidMount() {
    let {
      fetchAdminProduct,
      match: {
        params: { id },
      },
    } = this.props
    let extractedId = extractIdFromUrl(id)
    fetchAdminProduct(extractedId)
  }

  componentWillUnmount() {
    let { clearProduct } = this.props
    this.props.dispatch(reset('image'))
    clearProduct()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.addProductImageAction.fetched &&
      this.props.addProductImageAction.errors == null &&
      prevProps.addProductImageAction.fetching !==
        this.props.addProductImageAction.fetching
    ) {
      let {
        match: {
          params: { id },
        },
      } = this.props
      this.props.history.push(`/products/${id}`)
    }
  }

  handleBack(e) {
    e.preventDefault()
    this.props.history.goBack()
  }

  handleAddProductImage(values) {
    let { addProductImage, adminProduct } = this.props
    let link = adminProduct && href({ links: adminProduct.links, rel: 'product_images' })
    if (values.alt == null) {
      values.alt = adminProduct.name
    }
    return addProductImage(values, link)
  }

  render() {
    let { handleSubmit: handleReduxFormSubmit, adminProduct } = this.props
    let imageFormProps = {
      handleReduxFormSubmit,
      productImageAction: this.handleAddProductImage,
      productName: adminProduct && adminProduct.name,
    }

    return (
      <PageSection>
        <PageHeading
          heading={adminProduct ? adminProduct.name : ''}
          size='h2'
          onAction={this.handleBack}
          actionText='Cancel'
        />
        <ImageForm {...imageFormProps} />
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  addProductImage,
  clearProduct,
  fetchAdminProduct,
}

export { CreateImage }
export default compose(
  reduxForm({
    form: 'image',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  }),
  connect(state => {
    return {
      adminProduct: state.adminProduct.data,
      addProductImageAction: state.productImage.addProductImage,
    }
  }, mapDispatchToProps)
)(CreateImage)
