import React from 'react'
import PropTypes from 'prop-types'

import Button from 'lib/components/Button'
import FormFieldSet from 'lib/components/FormFieldSet'
import FormRow from 'lib/components/FormRow'
import TagAutocomplete from 'lib/components/TagAutocomplete'

import { Field } from 'redux-form'
import { ReduxFormTagAutocomplete } from 'lib/components/TagAutocomplete'
import WysiwygField from 'lib/components/WysiwygField'

import './styles.scss'

const CategorisationsForm = ({ onSubmit }) => {
  const formatTags = tags => {
    if (typeof tags === 'string') {
      return tags.split(',').map(tag => {
        return { name: tag }
      })
    } else {
      return tags
    }
  }

  const CategoriesAutocomplete = props => {
    const formName = props.meta.form
    const fieldName = 'categories'

    let selector = state => {
      return state.form && state.form[formName] && state.form[formName].values
        ? formatTags(state.form[formName].values[fieldName])
        : []
    }

    return (
      <ReduxFormTagAutocomplete
        formName={formName}
        fieldName={fieldName}
        selector={selector}
        render={(onAdd, onDelete, tags) => {
          return (
            <TagAutocomplete {...props} onAdd={onAdd} onDelete={onDelete} tags={tags} />
          )
        }}
      />
    )
  }

  return (
    <form>
      <h3>Product Categorisations</h3>
      <FormFieldSet>
        <FormRow label='Categories*'>
          <Field
            name='categories'
            component={CategoriesAutocomplete}
            placeholder='Add new category'
          />
        </FormRow>
        <FormRow label='Specification*'>
          <Field name='specification' component={WysiwygField} />
        </FormRow>
      </FormFieldSet>
      <Button onClick={onSubmit} className='buttonSecondary'>
        Save
      </Button>
    </form>
  )
}

CategorisationsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default CategorisationsForm
