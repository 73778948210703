import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const ButtonToolbar = ({ children, fullWidth }) => {
  return (
    <div className={`${styles.buttonToolbar} ${fullWidth ? styles.fullWidth : ''}`}>
      {children}
    </div>
  )
}

ButtonToolbar.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ButtonToolbar
