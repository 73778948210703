import { notificationTypes } from '../constants'

// TO DO: Update the header text, waiting on confirmation from Dom
const HEADER_TEXT = {
  [notificationTypes.SUCCESS]: 'Success!',
  [notificationTypes.INFO]: 'Hello!',
  [notificationTypes.WARNING]: 'Warning!',
  [notificationTypes.ERROR]: 'Looks like something went wrong!',
}

export default HEADER_TEXT
