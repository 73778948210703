import axios from 'lib/utils/axios'
import { productImageTypes } from 'actions/types'
import { proxyUrl } from 'lib/utils/proxy'

const addProductImage = (values, link) => async dispatch => {
  let data = new FormData()
  data.append('attachment', values.attachment)
  data.append('alt', values.alt)
  data.append('type', 'product_image')

  dispatch({ type: productImageTypes.ADD_PRODUCT_IMAGE })
  return axios
    .post(
      `${global.config.UI_PROXY_ENDPOINT}/api/image_upload/${encodeURIComponent(link)}`,
      data
    )
    .then(response => {
      dispatch({
        type: productImageTypes.ADD_PRODUCT_IMAGE_FULFILLED,
        payload: response.data,
      })
    })
    .catch(err => {
      dispatch({ type: productImageTypes.ADD_PRODUCT_IMAGE_REJECTED, payload: err })
    })
}

const deleteProductImage = (productId, imageId) => async dispatch => {
  let updateProductImageUrl = `${global.config.PRODUCT_SERVICE_ENDPOINT}/admin/products/${productId}/product_images/${imageId}?type=product_image`
  let url = proxyUrl({ link: updateProductImageUrl })

  dispatch({ type: productImageTypes.DELETE_PRODUCT_IMAGE })
  try {
    const response = await axios.delete(url)
    dispatch({
      type: productImageTypes.DELETE_PRODUCT_IMAGE_FULFILLED,
      payload: response.data,
    })
  } catch (err) {
    dispatch({
      type: productImageTypes.DELETE_PRODUCT_IMAGE_REJECTED,
      payload: err,
    })
  }
}

const updateProductImage = (values, productId, imageId) => async dispatch => {
  let data = new FormData()
  if (values.attachment) {
    data.append('attachment', values.attachment)
  }
  if (values.alt) {
    data.append('alt', values.alt)
  }

  data.append('is_default_image', values.is_default_image === true)

  data.append('type', 'product_image')
  let updateProductImageUrl = `${global.config.PRODUCT_SERVICE_ENDPOINT}/admin/products/${productId}/product_images/${imageId}`
  let url = `${global.config.UI_PROXY_ENDPOINT}/api/image_upload/${encodeURIComponent(
    updateProductImageUrl
  )}`

  dispatch({ type: productImageTypes.UPDATE_PRODUCT_IMAGE })
  try {
    const response = await axios.put(url, data)
    dispatch({
      type: productImageTypes.UPDATE_PRODUCT_IMAGE_FULFILLED,
      payload: response.data,
    })
  } catch (err) {
    dispatch({ type: productImageTypes.UPDATE_PRODUCT_IMAGE_REJECTED, payload: err })
  }
}

export { addProductImage, deleteProductImage, updateProductImage }
