import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { enqueueError } from 'lib/components/GlobalNotifications'
import FormField from 'lib/components/FormField'
import { exportRatingRules } from 'actions/ratingRulesActions'
import { useDispatch } from 'react-redux'
import fileDownload from 'js-file-download'
import RequestWrapper from '@licnz/react-request-wrapper'

import styles from './styles.scss'
import Button from '../Button'

const customStyles = {
  overlay: {
    animation: 'modal-in 0.15s ease-out 0s backwards',
    backgroundColor: 'rgba(0, 30, 98, .5)',
    zIndex: '4',
  },
}

const FROM_DATE = 'FROM_DATE'
const TO_DATE = 'TO_DATE'

const DownloadAuditReportModal = ({ showModal, onClose }) => {
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [isExporting, setIsExporting] = useState(false)
  const dispatch = useDispatch()

  const clearDateFields = () => {
    setFromDate('')
    setToDate('')
  }

  const downLoadReport = () => {
    setIsExporting(true)

    dispatch(exportRatingRules(fromDate, toDate))
      .then(({ data }) => {
        let blob = new Blob([data], { type: 'text/csv' })
        fileDownload(blob, 'rating-details-report.csv')
        setIsExporting(false)
      })
      .catch(err => {
        setIsExporting(false)
        enqueueError({
          message: 'We were unable to fetch rating report details',
          detail: err,
        })
      })
    clearDateFields()
  }

  const handleOnClose = () => {
    clearDateFields()
    onClose()
  }

  const handleDateChange = e => {
    e.preventDefault()
    if (e.target.id === FROM_DATE) {
      setFromDate(e.target.value)
    }

    if (e.target.id === TO_DATE) {
      setToDate(e.target.value)
    }
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel='Product rating details report'
      onRequestClose={handleOnClose}
      className={styles.content}
      style={customStyles}
      ariaHideApp={false}
    >
      <img
        class={styles.closeButton}
        onClick={handleOnClose}
        src='/images/close-icon.svg'
        alt='close button'
      />
      <h1 className={styles.titleUnderline}>
        <span className={styles.titleUnderlineSpan}>Product rating details report</span>
      </h1>

      <p className={styles.subTitle}>
        Please select a date range for the required product rating details report
      </p>
      <RequestWrapper loading={isExporting}>
        <div className={styles.controls}>
          <FormField
            name={FROM_DATE}
            label='From'
            inline
            type='date'
            onChange={handleDateChange}
            value={fromDate}
          />
          <FormField
            name={TO_DATE}
            label='To'
            inline
            type='date'
            onChange={handleDateChange}
            value={toDate}
          />

          <Button onClick={downLoadReport} className='buttonSecondary'>
            Download report
          </Button>
        </div>
      </RequestWrapper>
    </ReactModal>
  )
}

export default DownloadAuditReportModal
