import React from 'react'
import PropTypes from 'prop-types'

import Currency from 'lib/components/Currency'
import RequestedProductsTableRow from '../RequestedProductsTableRow'

import styles from './styles.scss'

const RequestedProductsTable = ({ requestedProducts, prices }) => {
  if (!requestedProducts.length) return null
  return (
    <table className={styles.table}>
      <thead className={styles.headerTableRow}>
        <tr>
          <th id='product'>Product</th>
          <th id='quantity'>Quantity</th>
          <th id='price'>Price</th>
          <th id='birth_id_replacement'>Replacement?</th>
          <th id='customer_po_number'>Customer PO Number</th>
          <th id='animal_group_reservations' className={styles.pullRight}>
            Animal Group Reservations?
          </th>
        </tr>
      </thead>
      <tbody className={styles.tableBody}>
        {requestedProducts.map(rp => {
          return <RequestedProductsTableRow key={rp.id} requestedProduct={rp} />
        })}
      </tbody>
      <tfoot className={styles.tableFooter}>
        {prices.retailPrice ? (
          <tr>
            <th colSpan='5' className={styles.pullRight}>
              Subtotal
            </th>
            <td>
              <Currency amount={prices.retailPrice} />
            </td>
          </tr>
        ) : null}
        {prices.naitCharge ? (
          <tr>
            <th colSpan='5' className={styles.pullRight}>
              NAIT levy
            </th>
            <td>
              <Currency amount={prices.naitCharge} />
            </td>
          </tr>
        ) : null}
        {prices.sellPrice ? (
          <tr>
            <th colSpan='5' className={styles.pullRight}>
              Total
            </th>
            <td>
              <Currency amount={prices.sellPrice} />
            </td>
          </tr>
        ) : null}
      </tfoot>
    </table>
  )
}

RequestedProductsTable.propTypes = {
  requestedProducts: PropTypes.array,
  prices: PropTypes.shape({
    naitCharge: PropTypes.number,
    retailPrice: PropTypes.number,
    sellPrice: PropTypes.number,
  }).isRequired,
}

export default RequestedProductsTable
