import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { supplierTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload ? action.payload : INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [supplierTypes.FETCH_SUPPLIER_FULFILLED]: dataHandler,
  [supplierTypes.UPDATE_SUPPLIER_FULFILLED]: dataHandler,
  [supplierTypes.CLEAR_FETCH_SUPPLIER]: dataHandler,
})

const reducer = combineReducers({
  requestState: requestStateReducer(supplierTypes.FETCH_SUPPLIER),
  data: dataReducer,
  updateSupplier: requestStateReducer(supplierTypes.UPDATE_SUPPLIER),
})

export default reducer
