import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { href } from '@licnz/js-utils'
import pluralize from 'pluralize'
import queryString from 'query-string'

import { convertToSentenceCase, extractIdFromUrl } from 'utils/util'
import { fetchOverdueSupplyOrders, clearSupplyOrders } from 'actions/supplyOrdersActions'
import { fetchSupplier } from 'actions/supplierActions'

import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import Pagination from '@licnz/react-pagination'
import RequestWrapper from '@licnz/react-request-wrapper'
import SupplyOrdersData from 'components/SupplyOrdersData'

import styles from './styles.scss'

const initialState = {
  page: 1,
  perPage: 10,
}
class OverdueSupplyOrders extends Component {
  constructor(props) {
    super(props)

    this.handleBack = this.handleBack.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.getSupplierId = this.getSupplierId.bind(this)
    this.getSupplierName = this.getSupplierName.bind(this)
  }

  componentDidMount() {
    let { clearSupplyOrders, fetchSupplier, location, supplier } = this.props
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })

    clearSupplyOrders()

    if (supplier) {
      if (Object.keys(params).length == 1) {
        this.updateUrl(initialState)
      } else {
        this.performSearch()
      }
    } else {
      fetchSupplier(this.getSupplierId())
    }
  }

  componentDidUpdate(prevProps) {
    let { location, supplier } = this.props

    if (
      prevProps.supplier !== supplier ||
      prevProps.location.search !== location.search
    ) {
      let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
      if (Object.keys(params).length == 0) {
        this.updateUrl(initialState)
      } else {
        this.performSearch()
      }
    }
  }

  handleBack() {
    this.props.history.push(`/suppliers/${this.getSupplierName()}`)
  }

  handleChangePage(page) {
    this.updateUrl({ page })
  }

  getSupplierName() {
    return extractIdFromUrl(this.getSupplierId())
  }

  getSupplierId() {
    let currentParams = queryString.parse(this.props.location.search)
    return currentParams.supplierId
  }

  performSearch() {
    let { location, fetchOverdueSupplyOrders, enqueueError, supplier } = this.props

    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let { page, perPage } = params

    page = page ? Number(page) : initialState.page
    perPage = perPage ? Number(perPage) : initialState.perPage

    if (supplier) {
      let overdueSupplyOrdersLink = href({
        links: supplier.links,
        rel: 'overdue_supply_orders',
      })

      fetchOverdueSupplyOrders(overdueSupplyOrdersLink, page, perPage).catch(() => {
        enqueueError({
          message: `We were unable to fetch any overdue supply orders for ${this.getSupplierName()}`,
        })
      })
    }
  }

  updateUrl(updateParams) {
    let {
      location,
      history,
      match: { url },
    } = this.props
    let currentParams = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let params = { ...currentParams, ...updateParams }
    history.push(`${url}?${queryString.stringify(params, { arrayFormat: 'bracket' })}`)
  }

  render() {
    let {
      supplyOrdersRequestState,
      supplyOrders,
      totalResults,
      location,
      match: { url },
    } = this.props
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let page = params.page ? Number(params.page) : 1
    let perPage = params.perPage ? Number(params.perPage) : 10

    return (
      <PageSection>
        <PageHeading
          heading={`Overdue supply orders - ${convertToSentenceCase(
            this.getSupplierName().replace(/-/g, ' ')
          )}`}
          size='h2'
          onAction={this.handleBack}
          actionText='Back'
        />
        <RequestWrapper requestState={supplyOrdersRequestState}>
          {totalResults ? (
            <p className={styles.totalResultsDescription}>
              {pluralize('result', totalResults, true)} found
            </p>
          ) : null}
          {supplyOrders && supplyOrders.length ? (
            <Fragment>
              <SupplyOrdersData
                supplyOrders={supplyOrders}
                link={url}
                location={location}
                supplierName={this.getSupplierName()}
              />
              <Pagination
                border={false}
                currentPage={page}
                items={supplyOrders}
                onChangePage={this.handleChangePage}
                pageSize={perPage}
                totalItems={totalResults}
              />
            </Fragment>
          ) : (
            <h4>No supply orders found</h4>
          )}
        </RequestWrapper>
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  clearSupplyOrders,
  fetchOverdueSupplyOrders,
  fetchSupplier,
}

export { OverdueSupplyOrders }
export default connect(state => {
  return {
    supplyOrders: state.supplyOrders.data.items,
    totalResults: state.supplyOrders.data.total_results,
    supplyOrdersRequestState: state.supplyOrders.requestState,
    supplier: state.supplier.data,
  }
}, mapDispatchToProps)(OverdueSupplyOrders)
