import React from 'react'
import { connect } from 'react-redux'
import { isAdmin, isAgriManager, isCustomer } from 'selectors/userInfoSelectors'

import { Route, Redirect, Switch } from 'react-router-dom'
import ProtectedRoute from './ProtectedRoute'

import Basket from 'components/Basket'
import Baskets from 'components/Baskets'
import BirthIdReservations from 'components/BirthIdReservations'
import CreateDiscount from 'components/CreateDiscount'
import CreateImage from 'components/CreateImage'
import CreateRating from 'components/CreateRating'
import Discount from 'components/Discount'
import Discounts from 'components/Discounts'
import DownForMaintenance from 'components/DownForMaintenance'
import EditImage from 'components/EditImage'
import EditSupplier from 'components/EditSupplier'
import EditBaseProduct from 'components/EditBaseProduct'
import EditCategorisations from 'components/EditCategorisations'
import EditDiscount from 'components/EditDiscount'

import Operation from 'components/Operation'
import Operations from 'components/Operations'
import Order from 'components/Order'
import OrderedProduct from 'components/OrderedProduct'
import Orders from 'components/Orders'
import OrdersForProduct from 'components/OrdersForProduct'
import OrdersForDiscretionaryDiscount from 'components/OrdersForDiscretionaryDiscount'
import OverdueSupplyOrders from 'components/OverdueSupplyOrders'
import Product from 'components/Product'
import ProductRating from 'components/ProductRating'
import Products from 'components/Products'
import RequestedProduct from 'components/RequestedProduct'
import Supplier from 'components/Supplier'
import SupplierQueryBuilder from 'components/SupplierQueryBuilder'
import Suppliers from 'components/Suppliers'
import SupplierCapacity from 'components/SupplierCapacity/SupplierCapacity'
import SupplyOrder from 'components/SupplyOrder'
import SupplyOrders from 'components/SupplyOrders'
import Unauthorised from 'lib/components/Unauthorised'

const IndexRedirect = ({ location, defaultLocation }) => {
  if (location && location.hash && location.hash != '') {
    return <Redirect to={location.hash.slice(1)} />
  } else {
    return <Redirect to={defaultLocation} />
  }
}

const AdminRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute
        path='/baskets/:basket_id/requestedProduct/:id'
        component={RequestedProduct}
      />
      <ProtectedRoute path='/baskets/:id' component={Basket} />
      <ProtectedRoute path='/baskets' component={Baskets} />
      <ProtectedRoute path='/discounts/new' component={CreateDiscount} />
      <ProtectedRoute
        path='/discounts/:id/orders_for_discretionary_discount'
        component={OrdersForDiscretionaryDiscount}
      />
      <ProtectedRoute path='/discounts/:id/edit' component={EditDiscount} />
      <ProtectedRoute path='/discounts/:id' component={Discount} />
      <ProtectedRoute path='/discounts' component={Discounts} />
      <ProtectedRoute path='/operations/:id' component={Operation} />
      <ProtectedRoute path='/operations' component={Operations} />
      <ProtectedRoute
        path='/orders/:order_id/orderedProduct/:id'
        component={OrderedProduct}
      />
      <ProtectedRoute path='/orders/:id' component={Order} />
      <ProtectedRoute path='/orders' component={Orders} />
      <ProtectedRoute path='/orders_for_product/:id' component={OrdersForProduct} />
      <ProtectedRoute path='/products/:id/edit' component={EditBaseProduct} />
      <ProtectedRoute path='/products/:id/image' component={CreateImage} />
      <ProtectedRoute
        path='/products/:id/categorisations/edit'
        component={EditCategorisations}
      />
      <ProtectedRoute path='/products/:id/rating/new' component={CreateRating} />
      <ProtectedRoute path='/products/:id/rating/variants' component={ProductRating} />
      <ProtectedRoute path='/products/:id/rating/details' component={ProductRating} />
      <ProtectedRoute
        path='/products/:product_id/images/:id/edit'
        component={EditImage}
      />
      <ProtectedRoute path='/products/:id' component={Product} />
      <ProtectedRoute path='/products' component={Products} />
      <ProtectedRoute path='/supply_orders/overdue' component={OverdueSupplyOrders} />
      <ProtectedRoute path='/supply_orders/:id' component={SupplyOrder} />
      <ProtectedRoute path='/supply_orders' component={SupplyOrders} />
      <ProtectedRoute path='/suppliers/:id/edit' component={EditSupplier} />
      <ProtectedRoute path='/suppliers/:id' component={Supplier} />
      <ProtectedRoute path='/suppliers' component={Suppliers} />
      <ProtectedRoute path='/birth_id_reservations' component={BirthIdReservations} />
      <ProtectedRoute path='/supplier_query_builder' component={SupplierQueryBuilder} />

      {global.FEATURE_FLAGS.SUPPLIER_CAPACITY && (
        <ProtectedRoute path='/suppliers_capacity' component={SupplierCapacity} />
      )}
      <Route
        exact
        path='/'
        render={props => <IndexRedirect {...props} defaultLocation='/products' />}
      />
    </Switch>
  )
}

const AgriManagerRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute
        path='/orders/:order_id/orderedProduct/:id'
        component={OrderedProduct}
      />
      <ProtectedRoute path='/orders/:id' component={Order} />
      <ProtectedRoute path='/orders' component={Orders} />
      <Route
        exact
        path='/'
        render={props => <IndexRedirect {...props} defaultLocation='/orders' />}
      />
    </Switch>
  )
}

const UnauthorisedRoutes = () => {
  return <Route path='/' component={Unauthorised} />
}

const RootRoute = ({ downForMaintenance, isAdmin, isAgriManager, isCustomer }) => {
  if (downForMaintenance) return <Route path='/' component={DownForMaintenance} />

  let userType
  if (isCustomer) userType = 'customer'
  else if (isAdmin) userType = 'admin'
  else if (isAgriManager) userType = 'agriManager'

  switch (userType) {
    case 'admin':
      return <Route path='/' component={AdminRoutes} />
    case 'agriManager':
      return <Route path='/' component={AgriManagerRoutes} />
    case 'customer':
      return <Route path='/' component={UnauthorisedRoutes} />
    default:
      return <ProtectedRoute path='/' component={UnauthorisedRoutes} />
  }
}

export { AdminRoutes, AgriManagerRoutes, UnauthorisedRoutes, RootRoute }
let connectedRootRoute = connect(state => {
  return {
    downForMaintenance: state.downForMaintenance,
    isAdmin: isAdmin(state),
    isAgriManager: isAgriManager(state),
    isCustomer: isCustomer(state),
  }
})(RootRoute)

export default () => <Route path='/' component={connectedRootRoute} />
