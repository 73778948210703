import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { supplyOrderTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload ? action.payload : INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [supplyOrderTypes.FETCH_SUPPLY_ORDER_FULFILLED]: dataHandler,
  [supplyOrderTypes.CLEAR_FETCH_SUPPLY_ORDER]: dataHandler,
  [supplyOrderTypes.MANUALLY_CANCEL_SUPPLY_ORDER_FULFILLED]: dataHandler,
})

const reducer = combineReducers({
  requestState: requestStateReducer(supplyOrderTypes.FETCH_SUPPLY_ORDER),
  data: dataReducer,
})

export default reducer
