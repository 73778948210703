import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'

import { enqueueError, enqueueInfo } from 'lib/components/GlobalNotifications'
import { fetchOrdersForProduct, clearOrders } from 'actions/ordersActions'
import { formatSlugAsTitle } from 'utils/util'
import { isAdmin } from 'selectors/userInfoSelectors'

import OrdersList from 'components/OrdersList'
import PageSection from 'lib/components/layout/PageSection'
import PageHeading from 'lib/components/headings/PageHeading'
import Pagination from '@licnz/react-pagination'
import RequestWrapper from '@licnz/react-request-wrapper'

const initialState = {
  page: 1,
  per_page: 10,
}

class OrdersForProduct extends Component {
  constructor(props) {
    super(props)

    this.handleBack = this.handleBack.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
  }

  componentDidMount() {
    let { location } = this.props
    let params = queryString.parse(location.search)

    if (!params.page || !params.per_page) {
      this.updateUrl(initialState)
    } else {
      this.getOrders()
    }
  }

  componentDidUpdate(prevProps) {
    let { location } = this.props
    let params = queryString.parse(location.search)

    if (prevProps.location.search !== location.search) {
      if (!params.page || !params.per_page) {
        this.updateUrl(initialState)
      } else {
        this.getOrders()
      }
    }
  }

  componentWillUnmount() {
    this.props.clearOrders()
  }

  updateUrl(updateParams) {
    let {
      location,
      match: {
        params: { id },
      },
    } = this.props
    let currentParams = queryString.parse(location.search)
    let params = { ...currentParams, ...updateParams }
    this.props.history.push(
      `/orders_for_product/${id}?${queryString.stringify(params, {
        arrayFormat: 'bracket',
      })}`
    )
  }

  whitelistedParams() {
    let { page, per_page } = queryString.parse(this.props.location.search)
    let {
      match: {
        params: { id },
      },
    } = this.props

    return { page, per_page, product_slug: id }
  }

  getOrders() {
    let { fetchOrdersForProduct, enqueueError } = this.props
    let params = this.whitelistedParams()
    let {
      match: {
        params: { id },
      },
    } = this.props

    fetchOrdersForProduct(params).catch(() => {
      enqueueError({
        message: `We were unable to fetch any unproduced orders for ${formatSlugAsTitle(
          id
        )}`,
      })
    })
  }

  handleChangePage(page) {
    this.updateUrl({ page })
  }

  handleBack() {
    let {
      history,
      match: {
        params: { id },
      },
    } = this.props
    history.push(`/products/${id}`)
  }

  render() {
    let {
      isAdmin,
      location,
      orders,
      totalResults,
      ordersRequestState,
      match: {
        params: { id },
      },
    } = this.props

    let params = queryString.parse(location.search)
    let page = params.page ? Number(params.page) : 1
    let per_page = params.per_page ? Number(params.per_page) : 10

    return (
      <PageSection>
        <PageHeading
          actionText='Back'
          heading='Unproduced orders'
          onAction={this.handleBack}
          size='h2'
          subheading={formatSlugAsTitle(id)}
        />
        <RequestWrapper requestState={ordersRequestState}>
          {orders && orders.length ? (
            <Fragment>
              <OrdersList orders={orders} isAdmin={isAdmin} />
              <Pagination
                border={false}
                currentPage={page}
                items={orders}
                onChangePage={this.handleChangePage}
                pageSize={per_page}
                totalItems={totalResults}
              />
            </Fragment>
          ) : (
            <h4>{`No unproduced orders found for ${formatSlugAsTitle(id)}`}</h4>
          )}
        </RequestWrapper>
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  clearOrders,
  enqueueError,
  enqueueInfo,
  fetchOrdersForProduct,
}

export { OrdersForProduct }
export default connect(state => {
  return {
    customerContext: state.customerContext.data,
    isAdmin: isAdmin(state),
    orders: state.orders.data.items,
    ordersRequestState: state.orders.requestState,
    totalResults: state.orders.data.total_results,
  }
}, mapDispatchToProps)(OrdersForProduct)
