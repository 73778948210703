import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { href } from '@licnz/js-utils'

import axios from 'lib/utils/axios'
import { enqueueError } from 'lib/components/GlobalNotifications'
import { extractConfigurationOptions } from 'utils/orderedProductConfiguration'
import { extractIdFromUrl, formatSlugAsTitle } from 'utils/util'
import { fetchProduct, clearProduct } from 'actions/productActions'
import {
  fetchRequestedProduct,
  clearRequestedProduct,
} from 'actions/requestedProductActions'

import BlockContent from 'lib/components/layout/BlockContent'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import RequestWrapper from '@licnz/react-request-wrapper'
import RequestedProductConfiguration from './RequestedProductConfiguration'
import RequestedProductReservations from './RequestedProductReservations'

import styles from './styles.scss'

class RequestedProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productComponents: [],
      animalGroupReservations: [],
    }
    this.handleBack = this.handleBack.bind(this)
  }

  componentDidMount() {
    let { enqueueError, match, fetchRequestedProduct } = this.props
    let { basket_id, id } = match.params

    fetchRequestedProduct(
      decodeURIComponent(basket_id),
      decodeURIComponent(id)
    ).catch(() =>
      enqueueError({ message: 'We were unable to fetch this requested product.' })
    )
  }

  componentDidUpdate(prevProps) {
    let { requestedProduct, fetchProduct } = this.props

    if (prevProps.requestedProduct !== requestedProduct) {
      let productComponents = extractConfigurationOptions(requestedProduct)
      this.setState({ productComponents })

      let productLink = href({ links: requestedProduct.links, rel: 'product' })
      let id = extractIdFromUrl(productLink)

      fetchProduct(id).catch(() =>
        enqueueError({ message: 'We were unable to fetch the corresponding product.' })
      )

      this.fetchAnimalGroupReservations(requestedProduct)
    }
  }

  componentWillUnmount() {
    this.props.clearProduct()
    this.props.clearRequestedProduct()
  }

  fetchAnimalGroupReservations(requestedProduct) {
    for (var i = 0; i < requestedProduct.animal_group_reservations.length; i++) {
      axios
        .get(
          `${global.config.UI_PROXY_ENDPOINT}/api/proxy/${encodeURIComponent(
            requestedProduct.animal_group_reservations[i].value
          )}`
        )
        .then(result => {
          let currentState = this.state.animalGroupReservations
          let newResults = {}
          newResults[result.data._type] = result.data.reservedRanges
          currentState.push(newResults)
          this.setState({ animalGroupReservations: currentState })
        })
        .catch(error => {
          this.props.enqueueError({
            message: `Something went wrong fetching the animal group reservation details:\n ${error}`,
          })
        })
    }
  }

  handleBack() {
    this.props.history.goBack()
  }

  renderRequestedProductDetails() {
    let { requestedProduct } = this.props

    return (
      <div className={styles.lineLength}>
        <BlockContent>
          <ul className={styles.details}>
            <li>
              <span>Quantity:</span>
              <span>{requestedProduct.quantity}</span>
            </li>
          </ul>
        </BlockContent>
      </div>
    )
  }

  render() {
    let { requestedProduct, requestedProductRequestState, product } = this.props
    let { animalGroupReservations, productComponents } = this.state

    return (
      <RequestWrapper requestState={requestedProductRequestState}>
        <PageSection>
          <PageHeading
            heading='Requested product'
            subheading={
              requestedProduct &&
              (requestedProduct.name || formatSlugAsTitle(requestedProduct.product_slug))
            }
            onAction={this.handleBack}
            actionText='Back'
          />
          {requestedProduct && (
            <Fragment>
              {this.renderRequestedProductDetails()}
              <RequestedProductConfiguration
                product={product}
                productComponents={productComponents}
              />
              <RequestedProductReservations
                animalGroupReservations={animalGroupReservations}
              />
            </Fragment>
          )}
        </PageSection>
      </RequestWrapper>
    )
  }
}

const mapDispatchToProps = {
  clearProduct,
  clearRequestedProduct,
  enqueueError,
  fetchProduct,
  fetchRequestedProduct,
}

export { RequestedProduct }
export default connect(state => {
  return {
    product: state.product.data,
    requestedProduct: state.requestedProduct.data,
    requestedProductRequestState: state.requestedProduct.requestState,
  }
}, mapDispatchToProps)(RequestedProduct)
