import React from 'react'
import PropTypes from 'prop-types'
import urlPropType from 'url-prop-type'

import { Link } from 'react-router-dom'

import styles from './styles.scss'

const LinkAsButton = ({ className, children, disabled, to }) => {
  const setClasses = () => {
    return (
      className &&
      className
        .split(' ')
        .map(buttonClass => styles[buttonClass])
        .join(' ')
    )
  }

  return (
    <Link to={to} role='button' className={setClasses()} disabled={disabled}>
      {children}
    </Link>
  )
}

LinkAsButton.propTypes = {
  className: (props, propName, componentName) => {
    let error
    const prop = props[propName]
    if (typeof prop !== 'string')
      error = new Error(
        `'${componentName}' expected className of type 'string'. Received ${prop}`
      )
    prop.split(' ').forEach(token => {
      const validClasses = [
        'buttonPrimary',
        'buttonSecondary',
        'buttonOutline',
        'buttonOutlineInverted',
        'buttonSmall',
        'buttonMedium',
        'buttonLarge',
        'buttonBlock',
      ]
      if (!validClasses.includes(token)) {
        error = new Error(
          `'${componentName}' expects all classNames to be one of ${validClasses}. Received ${token}`
        )
      }
    })
    return error
  },
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  to: PropTypes.oneOfType([urlPropType, PropTypes.object]).isRequired,
}

export default LinkAsButton
