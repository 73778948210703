import React from 'react'
import PropTypes from 'prop-types'
import { href } from '@licnz/js-utils'

import { imageLinkForSupplierName } from 'constants/suppliers'

import ImageContainer from 'lib/components/ImageContainer'
import { Link } from 'react-router-dom'

import styles from './styles.scss'

function SupplierCard({ supplier }) {
  const imageLink = supplier && imageLinkForSupplierName(supplier.name)
  const companyLink = supplier && href({ links: supplier.links, rel: 'supplier_company' })

  return supplier ? (
    <Link
      to={`/suppliers/${encodeURIComponent(supplier.id)}`}
      className={`${styles.resultCard} ${styles.supplierCard}`}
    >
      <ImageContainer imageUrl={imageLink} />
      <dl>
        <dt>Name</dt>
        <dd className={styles.name}>{supplier.name === 'Zee Tags' ? global.FEATURE_FLAGS.Z_TAGS_BRANDING ? 'Z Tags' : 'Zee Tags' : supplier.name}</dd>
        <dt>Company Link</dt>
        <dd className={styles.companyLink}>{companyLink}</dd>
      </dl>
    </Link>
  ) : null
}

SupplierCard.propTypes = {
  supplier: PropTypes.object,
}

export default SupplierCard
