import React, { Component } from 'react'
import axios from 'lib/utils/axios'
import { connect } from 'react-redux'
import { href } from '@licnz/js-utils'

import {
  approveProduct,
  clearProduct,
  deleteProduct,
  fetchAdminProduct,
} from 'actions/productActions'
import { enqueueError, enqueueInfo } from 'lib/components/GlobalNotifications'
import { extractIdFromUrl, titleCase } from 'utils/util'
import { proxyUrl } from 'lib/utils/proxy'

import Button from 'lib/components/Button'
import FieldError from 'lib/components/FieldError'
import LinkAsButton from 'lib/components/LinkAsButton'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import ProductAttributes from './ProductAttributes'
import RequestWrapper from '@licnz/react-request-wrapper'
import StatusInfo from 'lib/components/StatusInfo'

import styles from './styles.scss'

class Product extends Component {
  constructor(props) {
    super(props)

    this.handleBack = this.handleBack.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleApproval = this.handleApproval.bind(this)
    this.setAvailableForPurchase = this.setAvailableForPurchase.bind(this)
    this.setUnavailableForPurchase = this.setUnavailableForPurchase.bind(this)
    this.setRestricted = this.setRestricted.bind(this)
    this.setUnrestricted = this.setUnrestricted.bind(this)
  }

  componentDidMount() {
    this.getAdminProduct()
  }

  componentDidUpdate(prevProps) {
    let {
      approveProductRequestState,
      deleteProductRequestState,
      match: {
        params: { id },
      },
    } = this.props

    if (prevProps.match.params.id !== id) {
      this.getAdminProduct()
    }
    if (
      (!prevProps.deleteProductRequestState.fetched &&
        deleteProductRequestState.fetched &&
        deleteProductRequestState.errors === null) ||
      (!prevProps.approveProductRequestState.fetched &&
        approveProductRequestState.fetched &&
        approveProductRequestState.errors === null)
    ) {
      this.handleBack()
    }
  }

  componentWillUnmount() {
    this.props.clearProduct()
  }

  getAdminProduct() {
    let {
      fetchAdminProduct,
      match: {
        params: { id },
      },
    } = this.props

    let extractedId = extractIdFromUrl(id)
    fetchAdminProduct(extractedId)
  }

  handleBack() {
    let { history } = this.props
    history.push(`/products${history.location.search}`)
  }

  handleDelete() {
    let { product, deleteProduct } = this.props
    let extractedId = extractIdFromUrl(product.id)
    deleteProduct(extractedId)
  }

  handleApproval() {
    let { product, approveProduct } = this.props
    let productApprovalLink = href({ links: product.links, rel: 'approve_product' })
    approveProduct(productApprovalLink)
  }

  setAvailableForPurchase() {
    let { enqueueInfo, product } = this.props
    let productAvailableForPurchaseLink = href({
      links: product.links,
      rel: 'product_available_for_purchase',
    })

    return axios
      .post(proxyUrl({ link: productAvailableForPurchaseLink }), {})
      .then(
        enqueueInfo({ message: `${product.name} is now available for purchase.` }) &&
          this.handleBack()
      )
      .catch(err =>
        enqueueError({
          message:
            'We were unable to make the product available for purchase. Error: ' + err,
        })
      )
  }

  setUnavailableForPurchase() {
    let { enqueueInfo, product } = this.props
    let productUnavailableForPurchaseLink = href({
      links: product.links,
      rel: 'product_unavailable_for_purchase',
    })

    return axios
      .post(proxyUrl({ link: productUnavailableForPurchaseLink }), {})
      .then(
        enqueueInfo({ message: `${product.name} is now unavailable for purchase.` }) &&
          this.handleBack()
      )
      .catch(err =>
        enqueueError({
          message:
            'We were unable to make the product unavailable for purchase. Error: ' + err,
        })
      )
  }

  setRestricted() {
    let { enqueueInfo, product } = this.props
    let productRestrictedLink = href({
      links: product.links,
      rel: 'product_restricted',
    })

    return axios
      .post(proxyUrl({ link: productRestrictedLink }), {})
      .then(
        enqueueInfo({ message: `${product.name} is now restricted.` }) &&
          this.handleBack()
      )
      .catch(err =>
        enqueueError({
          message: 'We were unable to make the product restricted. Error: ' + err,
        })
      )
  }

  setUnrestricted() {
    let { enqueueInfo, product } = this.props
    let productUnrestrictedLink = href({
      links: product.links,
      rel: 'product_unrestricted',
    })

    return axios
      .post(proxyUrl({ link: productUnrestrictedLink }), {})
      .then(
        enqueueInfo({ message: `${product.name} is now unrestricted.` }) &&
          this.handleBack()
      )
      .catch(err =>
        enqueueError({
          message: 'We were unable to make the product unrestricted. Error: ' + err,
        })
      )
  }

  render() {
    let { product, productRequestState, approveProductRequestState } = this.props
    let errors = approveProductRequestState.errors
    let subheading = product ? (
      <span>
        Status: <StatusInfo id={product.status} label={titleCase(product.status)} />
      </span>
    ) : null

    return product ? (
      <PageSection>
        <PageHeading
          heading={product.name}
          subheading={subheading}
          size='h2'
          onAction={this.handleBack}
          actionText='Back'
        />
        <div className={styles.buttonToolbar}>
          <LinkAsButton
            to={`/products/${product.slug}/rating/new`}
            className='buttonSecondary'
          >
            Configure new rating
          </LinkAsButton>
          <LinkAsButton
            to={`/products/${product.slug}/rating/variants`}
            className='buttonSecondary'
          >
            Variant prices
          </LinkAsButton>
          <LinkAsButton
            to={`/products/${product.slug}/rating/details`}
            className='buttonSecondary'
          >
            Rating details
          </LinkAsButton>
          <LinkAsButton
            to={`/orders_for_product/${product.slug}`}
            className='buttonSecondary'
          >
            Unproduced orders
          </LinkAsButton>
          {product.available_for_purchase && (
            <Button className='buttonSecondary' onClick={this.setUnavailableForPurchase}>
              Make unavailable for purchase
            </Button>
          )}
          {!product.available_for_purchase && (
            <Button className='buttonSecondary' onClick={this.setAvailableForPurchase}>
              Make available for purchase
            </Button>
          )}
          {product.restricted && (
            <Button className='buttonSecondary' onClick={this.setUnrestricted}>
              Make unrestricted
            </Button>
          )}
          {!product.restricted && (
            <Button className='buttonSecondary' onClick={this.setRestricted}>
              Make restricted
            </Button>
          )}
          {product.status == 'draft' && (
            <Button className='buttonPrimary' onClick={this.handleApproval}>
              Approve
            </Button>
          )}
          {product.status != 'removed' && (
            <Button className='buttonDelete' onClick={this.handleDelete}>
              Remove
            </Button>
          )}
        </div>
        {errors && (
          <FieldError error='Errors occurred whilst approving the product. Please see below' />
        )}
        <RequestWrapper requestState={productRequestState}>
          <ProductAttributes product={product} errors={errors} />
        </RequestWrapper>
      </PageSection>
    ) : null
  }
}

const mapDispatchToProps = {
  approveProduct,
  clearProduct,
  deleteProduct,
  enqueueError,
  enqueueInfo,
  fetchAdminProduct,
}

export { Product }
export default connect(state => {
  return {
    approveProductRequestState: state.product.approveProduct,
    deleteProductRequestState: state.product.deleteProduct,
    product: state.adminProduct.data,
    productRequestState: state.adminProduct.requestState,
  }
}, mapDispatchToProps)(Product)
