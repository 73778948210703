import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import pluralize from 'pluralize'
import queryString from 'query-string'

import { basketsSearch, clearBaskets } from 'actions/basketsSearchActions'
import { enqueueError } from 'lib/components/GlobalNotifications'

import BasketsList from './BasketsList'
import FormField from 'lib/components/FormField'
import FormRow from 'lib/components/FormRow'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import Pagination from '@licnz/react-pagination'
import RequestWrapper from '@licnz/react-request-wrapper'

import styles from './styles.scss'

const initialState = {
  page: 1,
  perPage: 10,
}
class Baskets extends Component {
  constructor(props) {
    super(props)
    this.handleChangeFieldValue = this.handleChangeFieldValue.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleClearFilters = this.handleClearFilters.bind(this)
  }

  componentDidMount() {
    let { location, clearBaskets } = this.props
    clearBaskets()

    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    if (Object.keys(params).length == 0) {
      this.updateUrl(initialState)
    } else {
      this.performSearch()
    }
  }

  componentDidUpdate(prevProps) {
    let { location } = this.props

    if (prevProps.location.search !== location.search) {
      let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
      if (Object.keys(params).length == 0) {
        this.updateUrl(initialState)
      } else {
        this.performSearch()
      }
    }
  }

  handleChangeFieldValue(e) {
    let params = queryString.parse(this.props.location.search, { arrayFormat: 'bracket' })
    let {
      perPage,
      assignToPartyName,
      billToPartyName,
      createdAfter,
      createdBefore,
      customerPurchaseOrderNumber,
      initiatingPartyName,
      purchasingPartyName,
    } = params

    this.updateUrl(
      { page: 1, perPage },
      {
        assignToPartyName,
        billToPartyName,
        createdAfter,
        createdBefore,
        customerPurchaseOrderNumber,
        initiatingPartyName,
        purchasingPartyName,
        [e.target.name]: e.target.value,
      }
    )
  }

  handleChangePage(page) {
    this.updateUrl({ page })
  }

  handleClearFilters() {
    this.updateUrl(initialState, {})
  }

  performSearch() {
    let { enqueueError, basketsSearch, location } = this.props
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let {
      page,
      perPage,
      createdAfter,
      createdBefore,
      assignToPartyName,
      billToPartyName,
      customerPurchaseOrderNumber,
      initiatingPartyName,
      purchasingPartyName,
    } = params

    let filters = {}
    if (assignToPartyName) filters['assign_to_party_name'] = assignToPartyName
    if (billToPartyName) filters['bill_to_party_name'] = billToPartyName
    if (createdBefore) filters['created_at_before'] = moment(createdBefore)
    if (createdAfter) filters['created_at_after'] = moment(createdAfter)
    if (customerPurchaseOrderNumber)
      filters['customer_purchase_order_number'] = customerPurchaseOrderNumber
    if (initiatingPartyName) filters['initiating_party_name'] = initiatingPartyName
    if (purchasingPartyName) filters['purchasing_party_name'] = purchasingPartyName

    page = page ? Number(page) : initialState.page
    perPage = perPage ? Number(perPage) : initialState.perPage
    params = { page, per_page: perPage, ...filters }
    basketsSearch(params).catch(() => {
      enqueueError({ message: 'We were unable to fetch any baskets' })
    })
  }

  updateUrl(updateParams, filters) {
    let { location, history } = this.props
    let currentParams = queryString.parse(location.search, { arrayFormat: 'bracket' })
    if (filters)
      currentParams = {
        page: currentParams.page,
        perPage: currentParams.perPage,
        ...filters,
      }
    let params = { ...currentParams, ...updateParams }

    history.push(`/baskets?${queryString.stringify(params, { arrayFormat: 'bracket' })}`)
  }

  render() {
    let { basketsSearchRequestState, location, searchResults, totalResults } = this.props
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let {
      assignToPartyName,
      billToPartyName,
      createdAfter,
      createdBefore,
      customerPurchaseOrderNumber,
      initiatingPartyName,
      purchasingPartyName,
    } = params
    let page = params.page ? Number(params.page) : 1
    let perPage = params.perPage ? Number(params.perPage) : 10

    return (
      <PageSection>
        <PageHeading heading='Baskets' />
        <FormRow>
          <FormField
            name='createdAfter'
            label='Created after (inclusive)'
            inline
            type='date'
            onChange={this.handleChangeFieldValue}
            value={createdAfter}
          />
          <FormField
            name='createdBefore'
            label='Created before (exclusive)'
            inline
            type='date'
            onChange={this.handleChangeFieldValue}
            value={createdBefore}
          />
          <FormField
            name='customerPurchaseOrderNumber'
            label='Customer Purchase Order Number'
            inline
            type='search'
            onChange={this.handleChangeFieldValue}
            value={customerPurchaseOrderNumber}
          />
        </FormRow>
        <FormRow>
          <FormField
            name='assignToPartyName'
            label='Assign to Party'
            inline
            type='search'
            onChange={this.handleChangeFieldValue}
            value={assignToPartyName}
          />
          <FormField
            name='billToPartyName'
            label='Bill to Party'
            inline
            type='search'
            onChange={this.handleChangeFieldValue}
            value={billToPartyName}
          />
          <FormField
            name='initiatingPartyName'
            label='Initiating Party'
            inline
            type='search'
            onChange={this.handleChangeFieldValue}
            value={initiatingPartyName}
          />
          <FormField
            name='purchasingPartyName'
            label='Purchasing Party'
            inline
            type='search'
            onChange={this.handleChangeFieldValue}
            value={purchasingPartyName}
          />
          <a className={styles.resetSelection} onClick={this.handleClearFilters}>
            Clear all filters
          </a>
        </FormRow>
        <RequestWrapper requestState={basketsSearchRequestState}>
          {totalResults ? (
            <p className={styles.totalResultsDescription}>
              {pluralize('result', totalResults, true)} found
            </p>
          ) : null}
          {searchResults && searchResults.length ? (
            <Fragment>
              <BasketsList baskets={searchResults} />
              <Pagination
                border={false}
                currentPage={page}
                items={searchResults}
                onChangePage={this.handleChangePage}
                pageSize={perPage}
                totalItems={totalResults}
              />
            </Fragment>
          ) : (
            <h4>No baskets found</h4>
          )}
        </RequestWrapper>
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  basketsSearch,
  clearBaskets,
  enqueueError,
}

export { Baskets }
export default connect(state => {
  return {
    searchResults: state.basketsSearch.data.items,
    basketsSearchRequestState: state.basketsSearch.requestState,
    totalResults: state.basketsSearch.data.total_results,
  }
}, mapDispatchToProps)(Baskets)
