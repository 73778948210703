import React, { Component } from 'react'
import { connect } from 'react-redux'

import { fetchSuppliers } from 'actions/suppliersActions'

import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import QueryList from 'lib/components/QueryList'
import RequestWrapper from '@licnz/react-request-wrapper'
import SupplierCard from './SupplierCard'

class Suppliers extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.fetchSuppliers()
  }

  render() {
    let { suppliers, suppliersRequestState } = this.props

    return (
      <PageSection>
        <PageHeading heading='Suppliers' />
        <RequestWrapper requestState={suppliersRequestState}>
          <QueryList>
            {suppliers &&
              suppliers.map(supplier => (
                <SupplierCard key={supplier.id} supplier={supplier} />
              ))}
          </QueryList>
        </RequestWrapper>
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  fetchSuppliers,
}

export { Suppliers }
export default connect(state => {
  return {
    suppliers: state.suppliers.data,
    suppliersRequestState: state.suppliers.requestState,
  }
}, mapDispatchToProps)(Suppliers)
