import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import { fetchDiscount, updateDiscount, clearDiscount } from 'actions/discountActions'
import { enqueueError, enqueueSuccess } from 'lib/components/GlobalNotifications'

import PageSection from 'lib/components/layout/PageSection'
import PageHeading from 'lib/components/headings/PageHeading'
import EditDiscountForm from './EditDiscountForm'


class EditDiscount extends Component {
  constructor(props) {
    super(props)
    this.handleBack = this.handleBack.bind(this)
    this.handleDates = this.handleDates.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
  }

  componentDidMount() {
    let { id } = this.props.match.params
    this.props.fetchDiscount(id)
  }

  componentWillUnmount() {
    this.props.clearDiscount()
  }

  handleBack(e) {
    e.preventDefault()
    this.props.history.goBack()
  }

  handleDates(dateToParse, name) {
    let date = moment(dateToParse)

    // If the valid_from date is today, we want to set the time to the current time, else 00:00 on the date
    // If the date is valid_to, we want to set the time 23:59 on the date
    if (name === 'validFrom') {
      let isToday = date.isSame(moment(), 'day')
      date = isToday ? moment() : date.startOf('day')
    } else {
      date = date.endOf('day')
    }

    return date.toISOString()
  }

  handleUpdate(values) {
    let updateDiscountBody = {}
    if (values) {
      updateDiscountBody = {
        id: values.id,
        discount_rule: {
          valid_from: this.handleDates(values.valid_from, 'validFrom'),
          valid_to: this.handleDates(values.valid_to, 'validTo'),
        }
      }
    }
    return this.props.updateDiscount(updateDiscountBody).then(() => {
      this.props.history.push(`/discounts`)
      enqueueSuccess({ message: 'This discount is now successfully updated.' })
    })
    .catch(() => {
      enqueueError({ message: 'We were unable to update this discount.' })
    })
  }

  render() {
    let { discount } = this.props
    if (discount) {
      let validFromDate = discount.valid_from
      let validToDate = discount.valid_to

      discount.valid_from = moment(validFromDate).format('YYYY-MM-DD').substring(0, 10)
      discount.valid_to = moment(validToDate).format('YYYY-MM-DD').substring(0, 10)
    }

    return discount ? (
      <PageSection>
        <PageHeading
          heading='Edit discount details'
          size='h2'
          onAction={this.handleBack}
          actionText='Cancel'
        />
        <EditDiscountForm
          form='editDiscount'
          initialValues={discount}
          onSubmit={this.handleUpdate}
        />
      </PageSection>
    ) : null
  }
}

const mapDispatchToProps = {
  clearDiscount,
  enqueueError,
  enqueueSuccess,
  fetchDiscount,
  updateDiscount,
}

export { EditDiscount }
export default connect(state => {
  return {
    discount: state.discount.data,
  }
}, mapDispatchToProps)(EditDiscount)
