import React, { Component } from 'react'
import { connect } from 'react-redux'
import { href } from '@licnz/js-utils'

import { fetchAdminProduct, updateProduct } from 'actions/productActions'

import PageSection from 'lib/components/layout/PageSection'
import PageHeading from 'lib/components/headings/PageHeading'
import KeyDetailsFormWrapper from 'components/KeyDetailsFormWrapper'

import { extractIdFromUrl } from 'utils/util'

class EditBaseProduct extends Component {
  constructor(props) {
    super(props)
    this.handleBack = this.handleBack.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    let {
      fetchAdminProduct,
      match: {
        params: { id },
      },
    } = this.props
    let extractedId = extractIdFromUrl(id)
    fetchAdminProduct(extractedId)
  }

  componentDidUpdate(prevProps) {
    let { updateProductRequestState } = this.props
    if (
      !prevProps.updateProductRequestState.fetched &&
      updateProductRequestState.fetched &&
      updateProductRequestState.errors === null
    ) {
      let {
        match: {
          params: { id },
        },
      } = this.props
      this.props.history.push(`/products/${id}`)
    }
  }

  handleBack() {
    this.props.history.push('/products')
  }

  handleSubmit(values) {
    return this.props.updateProduct(values)
  }

  render() {
    let { errors, product } = this.props
    let keyDetailsFormWrapperProps = {}

    if (product) {
      keyDetailsFormWrapperProps = {
        productSlug: product.slug,
        type: product._type,
        errors: errors,
        initialValues: {
          name: product.name,
          offering_type: product.offering_type,
          family: product.family,
          description: product.description,
          invoice_description: product.invoice_description,
          material_code: product.material_code,
          supplier_sku: product.supplier_sku,
          supplier_url: href({ links: product.links, rel: 'supplier' }),
        },
        onSubmit: this.handleSubmit,
      }
    }
    return product ? (
      <PageSection>
        <PageHeading
          heading={product.name}
          size='h2'
          onAction={this.handleBack}
          actionText='Cancel'
        />
        {product && <KeyDetailsFormWrapper {...keyDetailsFormWrapperProps} />}
      </PageSection>
    ) : null
  }
}

const mapDispatchToProps = {
  fetchAdminProduct,
  updateProduct,
}

export { EditBaseProduct }
export default connect(state => {
  return {
    errors: state.product.updateProduct.errors,
    product: state.adminProduct.data,
    updateProductRequestState: state.product.updateProduct,
  }
}, mapDispatchToProps)(EditBaseProduct)
