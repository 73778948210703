import React from 'react'

import styles from './styles.scss'

function PageSection(props) {
  return (
    <div className={`${styles.section} ${styles.sectionStackedBottom}`}>
      <div className={`${styles.gutters} ${styles.gutterNoGutterSmall}`}>
        <div className={`${styles.gridFlex} ${styles.gridColSpacing}`}>
          <div className={styles.widthFullMedium}>
            <div
              className={`${styles.paddedBlock} ${styles.tWhiteBg} ${styles.uMt112Medium}`}
            >
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageSection
