import React from 'react'

import DateTime from 'lib/components/DateTime'

import styles from './styles.scss'

const MiscOrderData = ({ order }) => {
  return (
    <table className={styles.verticalTable}>
      <thead>
        <tr>
          <th>Payment method</th>
          <th>Delivery date</th>
          <th>Order reference</th>
          <th>Sales order number</th>
          <th>Tag only operation</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {/* WTF: should this be retrieved from the order? */}
          <td>Account</td>
          <td>
            {order.timestamps['delivery_time'] ? (
              <DateTime date={order.timestamps['delivery_time']} />
            ) : (
              'Not found'
            )}
          </td>
          <td>{order.order_reference}</td>
          <td>{order.sales_order_number}</td>
          <td>{order.regulated ? 'No' : 'Yes'}</td>
        </tr>
      </tbody>
    </table>
  )
}

export default MiscOrderData
