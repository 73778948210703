import flatten from 'lodash/flatten'
import flattenDeep from 'lodash/flattenDeep'
import isEqual from 'lodash/isEqual'
import unionBy from 'lodash/unionBy'

import { NAIT_LOCATION_URN } from 'constants/productConfiguration'
import { RATING_PRICE_URN_MAPPINGS } from 'constants/priceMappings'

const processProductNode = (queue, configuration = {}) => {
  if (queue.length === 0) return configuration

  let currentNode = queue.pop()

  let config = { name: currentNode.name, slug: currentNode.slug }
  if (currentNode.marking_options && currentNode.marking_options.length) {
    let markingOptionConfigRequirements = []
    currentNode.marking_options.map(option => {
      if (option.config_requirements) {
        markingOptionConfigRequirements.push(option)
      }
    })

    config.markingOptions = [...[].concat.apply([], markingOptionConfigRequirements)]
  }
  if (currentNode.sizes && currentNode.sizes.length) {
    let sizeConfigRequirements = []
    currentNode.sizes.map(size => {
      if (size.identifiers) {
        sizeConfigRequirements.push(size)
      }
    })

    config.sizes = [...[].concat.apply([], sizeConfigRequirements)]
  }
  configuration[currentNode.slug] = config

  currentNode.children.forEach(child => queue.push(child))
  return processProductNode(queue, configuration)
}

const extractConfigurationOptions = product => {
  return processProductNode([product])
}

// Extract the marking_options from queue and queue's children
const processMarkingOptions = (queue, markingOptions = []) => {
  if (queue.length === 0) return markingOptions

  let currentNode = queue.pop()

  if (currentNode.marking_options && currentNode.marking_options.length) {
    let newMarkingOptions = currentNode.marking_options.map(markingOption => {
      return {
        default: markingOption.default,
        name: markingOption.name,
        identity: markingOption.identity,
        configReqIdentityUrns: markingOption.config_requirements
          ? markingOption.config_requirements.map(configReq => configReq.identity)
          : [],
        componentName: currentNode.name,
        componentSlug: currentNode.slug,
      }
    })
    markingOptions.push(newMarkingOptions)
  }

  currentNode.children.forEach(child => queue.push(child))
  return processMarkingOptions(queue, markingOptions)
}

const combine = (a, b) => [].concat(...a.map(d => b.map(e => [].concat(d, e))))
const cartesian = (a, b, ...c) => (b ? cartesian(combine(a, b), ...c) : a)

const extractValidMarkingOptionsCombinations = product => {
  let productMarkingOptions = processMarkingOptions([product])

  let markingOptionsCombinations = []
  if (productMarkingOptions.length) {
    if (productMarkingOptions.length > 1) {
      markingOptionsCombinations = cartesian(...productMarkingOptions)
    } else {
      markingOptionsCombinations = productMarkingOptions[0].map(productMarkingOption =>
        Array.of(productMarkingOption)
      )
    }
  }

  if (product.available_marking_options) {
    let availableCombos = product.available_marking_options.map(
      availableMarkingOption => {
        return availableMarkingOption.component_marking_options.reduce((map, obj) => {
          map[obj.slug] = obj.marking_option
          return map
        }, {})
      }
    )

    return markingOptionsCombinations.filter(combo => {
      let possibleCombo = combo
        .map(markingOption => ({
          marking_option: markingOption.identity,
          slug: markingOption.componentSlug,
        }))
        .reduce((map, obj) => {
          map[obj.slug] = obj.marking_option
          return map
        }, {})

      return availableCombos.find(availableCombo =>
        isEqual(availableCombo, possibleCombo)
      )
    })
  } else {
    return markingOptionsCombinations
  }
}

const processConfigReqs = (queue, configReqs = []) => {
  if (queue.length === 0) return configReqs

  let currentNode = queue.pop()

  let markingOptionsConfigReqs = []
  if (currentNode.marking_options && currentNode.marking_options.length) {
    markingOptionsConfigReqs.push(
      currentNode.marking_options.map(markingOption => {
        return markingOption.config_requirements
          ? markingOption.config_requirements.map(configReq => {
              return {
                name: configReq.name,
                identity: configReq.identity,
                type: 'marking_option',
              }
            })
          : []
      })
    )
  }

  let sizeConfigReqs = []
  if (currentNode.sizes && currentNode.sizes.length) {
    sizeConfigReqs.push(
      currentNode.sizes.map(size => {
        return { name: size.display_name, identity: size.identity, type: 'size' }
      })
    )
  }

  let naitConfigReqs = []
  if (currentNode.config_requirements) {
    currentNode.config_requirements.map(configReq => {
      if (configReq.identity === NAIT_LOCATION_URN) {
        naitConfigReqs.push({
          name: 'NAIT Levy',
          identity: RATING_PRICE_URN_MAPPINGS.CHARGE_LEVY_UNIT_NAIT,
          type: 'charge',
        })
      }
    })
  }

  configReqs = unionBy(
    flattenDeep(markingOptionsConfigReqs),
    flatten(sizeConfigReqs),
    flatten(naitConfigReqs),
    configReqs,
    'identity'
  )

  currentNode.children.forEach(child => queue.push(child))
  return processConfigReqs(queue, configReqs)
}

const generateRatingConfigReqsFromProductTree = product => {
  return product && processConfigReqs([product])
}

const processDefaultMarkingOptions = (queue, markingOptions = {}) => {
  if (queue.length === 0) return markingOptions

  let currentNode = queue.pop()

  if (currentNode.marking_options && currentNode.marking_options.length) {
    markingOptions[currentNode.name] = currentNode.marking_options.find(
      markingOption => markingOption.default
    )
  }

  currentNode.children.forEach(child => queue.push(child))
  return processDefaultMarkingOptions(queue, markingOptions)
}

const extractDefaultMarkingOptions = product => {
  return processDefaultMarkingOptions([product])
}

export {
  extractConfigurationOptions,
  extractDefaultMarkingOptions,
  extractValidMarkingOptionsCombinations,
  generateRatingConfigReqsFromProductTree,
  processMarkingOptions,
  combine,
  cartesian,
}
