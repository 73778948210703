import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

/**
 * FormField is a wrapped <input> element for use both with and without Redux Form
 */
const FormField = ({
  checked,
  className,
  disabled,
  error_pos_fixed,
  inline,
  input,
  label,
  large,
  max,
  medium,
  meta,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  small,
  type,
  value,
  verticallyCentered,
}) => {
  let touched = meta ? meta.touched : undefined
  let error = meta ? meta.error : undefined
  let warning = meta ? meta.warning : undefined
  let inputValue = value || (input && input.value) || ''

  const renderError = () => (
    <span className={`${styles.validation} ${styles.error} ${error_pos_fixed && styles.fixed}`}>{error}</span>
  )
  const renderWarning = () => (
    <span className={`${styles.validation} ${styles.warning}`}>{warning}</span>
  )

  const setFieldClasses = () => {
    let outputClass = [styles.field]
    className && outputClass.push(className)
    if (error_pos_fixed && error && touched) {
      outputClass.push(styles.makeSpaceForErrorMsg)
    }
    inline && outputClass.push(styles.inlineField)
    verticallyCentered && outputClass.push(styles.verticallyCenteredField)

    return outputClass.join(' ')
  }

  const setLabelClasses = () => {
    let outputClass = [styles.label]
    type === 'checkbox' && outputClass.push(styles.labelCheckbox)

    return outputClass.join(' ')
  }

  const setInputClasses = () => {
    let outputClass = [styles.input]
    small && outputClass.push(styles.inputSmall)
    medium && outputClass.push(styles.inputMedium)
    large && outputClass.push(styles.inputLarge)

    return outputClass.join(' ')
  }

  const handleBlur = e => {
    if (onBlur) onBlur(e)
    if (input) input.onBlur(e)
  }

  const handleChange = e => {
    if (onChange) onChange(e)
    if (input) input.onChange(e)
  }

  const handleFocus = e => {
    if (onFocus) onFocus(e)
    if (input) input.onFocus(e)
  }

  let inputProps = {
    ...input,
    checked,
    className: setInputClasses(),
    disabled,
    id: name || input.name,
    max: max || undefined,
    min: min || undefined,
    name: name || input.name,
    onBlur: handleBlur,
    onChange: handleChange,
    onFocus: handleFocus,
    placeholder,
    type,
    value: inputValue,
  }

  return (
    <div className={setFieldClasses()}>
      {label && (
        <label htmlFor={name} className={setLabelClasses()}>
          {label}
        </label>
      )}
      <input {...inputProps} />
      {touched && ((error && renderError()) || (warning && renderWarning()))}
    </div>
  )
}

FormField.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  input: PropTypes.object,
  label: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  small: PropTypes.bool,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  verticallyCentered: PropTypes.bool,
}

export default FormField
