import moment from 'moment-timezone'
import { slugify } from 'utils/util'
import { maxAbsAmount } from 'utils/formValidations'

// This will ensure the user sees the date values in a user-friendly format
const formatDates = val => {
  return moment(val).tz('Pacific/Auckland').format('YYYY-MM-DD')
}

// This will ensure the user sees the value as a positive number
const formatPerUnitValue = value => {
  return Math.abs(value)
}

// Apply Math.abs and * 100 to display value as percentage, e.g. -0.2 => 20%.  Apply toFixed(2) and
// + to handle Javascript's floating point precision, e.g. to prevent the user's entered value of 7
// displaying as 7.000000000000001
const formatPercentageValue = value => {
  return +Math.abs(value * 100).toFixed(2)
}

const generateDiscountRuleFromFormValues = values => {
  let {
    priceType,
    appliesTo,
    authorisations,
    name,
    discountRanges,
    qualifyingProducts,
    validFrom,
    validTo,
    valueMethod,
  } = values

  let authorisationsArray = authorisations === 'discretionary' ? [authorisations] : []

  // Shop Assistant can only create discretionary discounts for the Agri Manager team
  let discretionaryTeams =
    authorisations === 'discretionary' ? ['urn:lic:team:sales:agri_manager'] : []

  let discountId = slugify(name)

  let orderedQuantityVolumeRanges = discountRanges.map(range => {
    return {
      description: generateRangeDescription(range, valueMethod),
      from: valueMethod === 'per_unit' ? range.from : 1,
      to: valueMethod === 'per_unit' ? range.to : null,
      value: range.value,
      value_method: valueMethod,
    }
  })

  let qualifyingProductCohort = qualifyingProducts.map(product => product.id)

  return {
    discount_rule: {
      applied_on_price_pattern: priceType,
      applies_to: appliesTo,
      authorisations: authorisationsArray,
      customer_cohort: '*',
      discount_id: discountId,
      discretionary_teams: discretionaryTeams,
      kind: 'ordered_quantity_volume',
      name,
      ordered_quantity_volume_ranges: orderedQuantityVolumeRanges,
      qualifying_product_cohort: qualifyingProductCohort,
      valid_from: parseDates(validFrom, 'validFrom'),
      valid_to: parseDates(validTo, 'validTo'),
    },
  }
}

const generateRangeDescription = (range, valueMethod) => {
  let { from, to, perUnitOption, value } = range
  let description

  if (valueMethod === 'per_unit') {
    description = `${formatPerUnitValue(
      value
    )} per unit for orders ${perUnitOption} ${from}`
    if (perUnitOption === 'between') description += ` and ${to}`
  } else {
    description = `${formatPercentageValue(value)}% per unit off`
  }

  return description
}

const getDiscountState = (validFrom, validTo) => {
  let discountState

  if (moment().isBefore(validFrom)) discountState = 'not_yet_active'
  else if (moment().isAfter(validFrom) && moment().isBefore(validTo))
    discountState = 'active'
  else discountState = 'expired'

  return discountState
}

// This will ensure the date values value are stored in redux in ISO 8601 format
const parseDates = (val, name) => {
  let date = moment(val)

  // If the validFrom date is today, we want to set the time to the current time, else 00:00 on the date
  // If the date is validTo, we want to set the time 23:59 on the date
  if (name === 'validFrom') {
    let isToday = date.isSame(moment(), 'day')
    date = isToday ? moment() : date.startOf('day')
  } else {
    date = date.endOf('day')
  }

  return date.toISOString()
}

// This will ensure the value is stored in redux as a negative number
const parsePerUnitValue = value => {
  return -Math.abs(value)
}

// This will ensure the value is stored in redux in decimal format (e.g. -0.2)
const parsePercentageValue = value => {
  return value && -Math.abs(value / 100)
}

const maxDiscount = maxAbsAmount(1, `Discount cannot be greater than 100%`)

export {
  formatDates,
  formatPerUnitValue,
  formatPercentageValue,
  generateDiscountRuleFromFormValues,
  getDiscountState,
  parseDates,
  parsePerUnitValue,
  parsePercentageValue,
  maxDiscount,
}
