import React from 'react'
import PropTypes from 'prop-types'
import { href } from '@licnz/js-utils'

import { stringAsDecimal } from 'utils/util'

import Currency from 'lib/components/Currency'
import FieldError from 'lib/components/FieldError'
import ImageContainer from 'lib/components/ImageContainer'
import { Link } from 'react-router-dom'

import styles from './styles.scss'

function ProductCard({ product, price, productLinkFromSlug }) {
  const imageLink = product && href({ links: product.links, rel: 'image_small' })
  let formattedPrice = price != null ? stringAsDecimal(price) : 0.0

  return product && formattedPrice != null ? (
    <Link
      to={productLinkFromSlug(product.slug)}
      className={`${styles.resultCard} ${styles.productCard}`}
    >
      <ImageContainer imageUrl={imageLink} />
      <dl>
        <dt>Name</dt>
        <dd className={styles.name}>{product.title}</dd>
        <dt>Description</dt>
        <dd className={styles.description}>{product.snippet}</dd>
        <dt>Available for purchase</dt>
        <dd className={styles.availableForPurchase}>
          {product.available_for_purchase ? (
            <svg className={styles.availableForPurchaseIcon}>
              <title>Available for purchase</title>
              <use xlinkHref='/images/sprites.svg#available-for-purchase' />
            </svg>
          ) : (
            <svg className={styles.unavailableForPurchaseIcon}>
              <title>Unavailable for purchase</title>
              <use xlinkHref='/images/sprites.svg#unavailable-for-purchase' />
            </svg>
          )}
        </dd>
        <dt>Price</dt>
        <dd className={styles.productCardPrice}>
          From: <Currency amount={formattedPrice} />
        </dd>
        {price == null && product.available_for_purchase && (
          <FieldError error='Price needs to be set' />
        )}
      </dl>
    </Link>
  ) : null
}

ProductCard.propTypes = {
  product: PropTypes.object,
  price: PropTypes.string,
}

export default ProductCard
