import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const FormRow = ({ label, helpText, smallLabel, showError, errorDetail, children }) => {
  let labelClass = smallLabel ? styles.smallLabel : styles.label
  return (
    <div className={styles.formRow}>
      {label && (
        <div className={styles.labelWrapper}>
          <label className={labelClass}>{label}</label>
          {helpText && <small className={styles.helpText}>{helpText}</small>}
          {showError && <small className={styles.error}>{errorDetail}</small>}
        </div>
      )}
      {children}
    </div>
  )
}

FormRow.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  smallLabel: PropTypes.bool,
  showError: PropTypes.bool,
  errorDetail: PropTypes.string,
  children: PropTypes.node,
}

export default FormRow
