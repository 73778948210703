const suppliers = {
  ALLFLEX: {
    name: 'Allflex',
    imageUrl: '/images/allflex.png',
  },
  FLEXA: {
    name: 'Flexa',
    imageUrl: '/images/flexa.png',
  },
  LEADER: {
    name: 'Leader',
    imageUrl: '/images/leader.jpg',
  },
  LIC: {
    name: 'LIC',
    imageUrl: '/images/lic.jpg',
  },
  ZEE_TAGS: {
    name: 'Zee Tags',
    imageUrl: '/images/zee-tags.png',
  },
}

const supplier = supplierName => {
  let key = Object.keys(suppliers).find(key => suppliers[key].name === supplierName)
  return suppliers[key]
}

const imageLinkForSupplierName = supplierName => {
  let obj = supplier(supplierName)
  if (obj) return obj.imageUrl
}

export default suppliers

export { imageLinkForSupplierName }
