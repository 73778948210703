import React from 'react'

import SectionHeading from 'lib/components/headings/SectionHeading'

import styles from './styles.scss'

const RequestedProductReservations = ({ animalGroupReservations }) => {
  if (!animalGroupReservations || !animalGroupReservations.length) return null

  function parseAnimalGroupReservations(animalGroupReservations) {
    for (var i = 0; i < animalGroupReservations.length; i++) {
      let item = animalGroupReservations[i]
      let key = Object.keys(item)[0]
      let ranges = item[key]
      return ranges.map(range => renderAnimalGroupReservationDetails(key, range))
    }
  }

  function renderAnimalGroupReservationDetails(key, range) {
    return (
      <tr key={range.from} className={styles.bodyTableRow}>
        <td>{key}</td>
        <td className={styles.pullLeft}>{range.from + '-' + range.to}</td>
      </tr>
    )
  }

  return (
    <div className={styles.tableContainer}>
      <SectionHeading>Animal group reservations</SectionHeading>
      <table className={styles.table}>
        <thead>
          <tr className={styles.headerTableRow}>
            <th className={styles.pullLeft}>Reservation</th>
            <th className={styles.pullLeft}>Ranges</th>
          </tr>
        </thead>
        <tbody>{parseAnimalGroupReservations(animalGroupReservations)}</tbody>
      </table>
    </div>
  )
}

export default RequestedProductReservations
