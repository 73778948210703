import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { basketTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload ? action.payload : INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [basketTypes.FETCH_BASKET_FULFILLED]: dataHandler,
  [basketTypes.CLEAR_FETCH_BASKET]: dataHandler,
})

const reducer = combineReducers({
  requestState: requestStateReducer(basketTypes.FETCH_BASKET),
  data: dataReducer,
})

export default reducer
