import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { productsTypes } from 'actions/types'

const INITIAL_STATE = []

function addProductFulfilled(state, action) {
  return state.concat({ ...action.payload })
}

function fetchProductsFulfilled(state, action) {
  return action.payload ? action.payload : INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [productsTypes.ADD_PRODUCT_FULFILLED]: addProductFulfilled,
  [productsTypes.ADD_PRODUCT_CATEGORISATIONS_FULFILLED]: addProductFulfilled,
  [productsTypes.CLEAR_FETCH_PRODUCTS]: fetchProductsFulfilled,
  [productsTypes.FETCH_PRODUCTS_FULFILLED]: fetchProductsFulfilled,
})

const reducer = combineReducers({
  addProduct: requestStateReducer(productsTypes.ADD_PRODUCT),
  addProductCategorisations: requestStateReducer(
    productsTypes.ADD_PRODUCT_CATEGORISATIONS
  ),
  data: dataReducer,
  requestState: requestStateReducer(productsTypes.FETCH_PRODUCTS),
})

export default reducer
