import React from 'react'
import PropTypes from 'prop-types'

import { formattedPrice } from 'utils/util'

import styles from './styles.scss'

const currencyCodes = {
  NZD: {
    symbol: '$',
    label: 'New Zealand Dollars',
  },
}
const defaultCurrency = 'NZD'

function Currency({ amount, code, itemProp, showPlusIfPositive = false }) {
  let formattedAmount = formattedPrice(amount)

  function currencyCode() {
    return (
      <abbr className={styles.currencyCode} title={currencyCodes[code].label}>
        {code}
      </abbr>
    )
  }

  function currencySymbol() {
    return currencyCodes[code].symbol
  }

  return (
    <span className={styles.currency} itemProp={itemProp}>
      {showPlusIfPositive && Math.sign(amount) === 1 ? '+' : ''}
      {Math.sign(amount) === -1 ? '-' : ''}
      {currencySymbol()}
      {formattedAmount} {currencyCode()}
    </span>
  )
}

Currency.defaultProps = {
  code: defaultCurrency,
}

Currency.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  code: PropTypes.string,
  itemProp: PropTypes.string,
}

export default Currency
