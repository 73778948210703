import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { supplyOrderErrorsTypes } from 'actions/types'

function fetchSupplyOrderErrors(supplyOrderLink) {
  let baseType = supplyOrderErrorsTypes.FETCH_SUPPLY_ORDER_ERRORS
  let url = proxyUrl({ link: `${supplyOrderLink}/supply_order_errors` })

  return get({ baseType, url })
}

function clearSupplyOrderErrors() {
  return { type: supplyOrderErrorsTypes.CLEAR_FETCH_SUPPLY_ORDER_ERRORS }
}

export { fetchSupplyOrderErrors, clearSupplyOrderErrors }
