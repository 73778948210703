import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Dropdown from 'lib/components/Dropdown'
import MultiSelectDropdown from 'lib/components/MultiSelectDropdown'

import styles from './styles.scss'

class DropdownFilters extends Component {
  constructor() {
    super()

    this.handleUpdateFilters = this.handleUpdateFilters.bind(this)
  }

  handleUpdateFilters(selectedFilter, filterId) {
    let { filters, onChange } = this.props
    let updatedFilters = []
    let nextFilters = []

    let targetFilter = filters.find(filter => filter.id == filterId)

    if (targetFilter.multiSelect) {
      let selectedFilters = targetFilter.selectedOptions

      if (targetFilter.selectedOptions.includes(selectedFilter)) {
        updatedFilters = selectedFilters.filter(filter => filter !== selectedFilter)
      } else {
        updatedFilters = [...selectedFilters, ...[selectedFilter]]
      }

      nextFilters = [...filters]
      nextFilters.find(filter => filter.id == filterId).selectedOptions = updatedFilters
    } else {
      nextFilters = [...filters]
      nextFilters.find(filter => filter.id == filterId).selectedOption = selectedFilter
    }

    onChange(nextFilters)
  }

  render() {
    let { filters, onClearFilters } = this.props

    return filters && filters.length > 0 ? (
      <div className={styles.filtersContainer}>
        <span>Filter by</span>
        <div className={styles.filterItems}>
          {filters.map(filter => {
            return (
              <div className={styles.dropdownContainer} key={filter.id}>
                {filter.multiSelect ? (
                  <MultiSelectDropdown
                    options={filter.options}
                    onSelect={this.handleUpdateFilters}
                    selectedOptions={filter.selectedOptions}
                    identifier={filter.id}
                    selectOptionText={filter.title}
                  />
                ) : (
                  <Dropdown
                    options={filter.options}
                    onSelect={this.handleUpdateFilters}
                    selectedOptionId={filter.selectedOption}
                    identifier={filter.id}
                    selectOptionText={filter.title}
                  />
                )}
              </div>
            )
          })}
        </div>
        <a className={styles.resetSelection} onClick={onClearFilters}>
          Clear all filters
        </a>
      </div>
    ) : null
  }
}

DropdownFilters.propTypes = {
  filters: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
}

export default DropdownFilters
