import React, { Component } from 'react'
import { connect } from 'react-redux'

import { clearOperation, fetchOperation, updateOperation } from 'actions/operationActions'
import { enqueueError, enqueueSuccess } from 'lib/components/GlobalNotifications'

import Button from 'lib/components/Button'
import ButtonToolbar from 'lib/components/ButtonToolbar'
import OperationDetail from './OperationDetail'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import RequestWrapper from '@licnz/react-request-wrapper'

class Operation extends Component {
  constructor(props) {
    super(props)
    this.handleBack = this.handleBack.bind(this)
    this.handleUpdateOperation = this.handleUpdateOperation.bind(this)
  }

  componentDidMount() {
    let { match, fetchOperation } = this.props
    let { id } = match.params
    fetchOperation(decodeURIComponent(id))
  }

  componentWillUnmount() {
    this.props.clearOperation()
  }

  handleBack() {
    this.props.history.goBack()
  }

  handleUpdateOperation(regulated) {
    let { enqueueError, enqueueSuccess, match, operation, updateOperation } = this.props
    let { id } = match.params

    updateOperation({ operation, regulated })
      .then(() => {
        this.props.history.push(`/operations/${id}`)
        enqueueSuccess({ message: 'This operation is now successfully updated.' })
      })
      .catch(() => {
        enqueueError({ message: 'We were unable to update this operation.' })
      })
  }

  renderButtonToolbar() {
    let { operation } = this.props

    return operation ? (
      <ButtonToolbar>
        {operation.metadata.regulated ? (
          <Button
            className='buttonSecondary'
            onClick={() => this.handleUpdateOperation(false)}
          >
            Enable tag only
          </Button>
        ) : (
          <Button
            className='buttonSecondary'
            onClick={() => this.handleUpdateOperation(true)}
          >
            Disable tag only
          </Button>
        )}
      </ButtonToolbar>
    ) : null
  }

  render() {
    let { operation, operationRequestState } = this.props

    return (
      <PageSection>
        <RequestWrapper requestState={operationRequestState}>
          <PageHeading
            heading='Operation details'
            subheading={operation ? operation.metadata.name : ''}
            onAction={this.handleBack}
            actionText='Back'
          />
          {this.renderButtonToolbar()}
          <OperationDetail operation={operation} />
        </RequestWrapper>
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  clearOperation,
  enqueueError,
  enqueueSuccess,
  fetchOperation,
  updateOperation,
}

export { Operation }
export default connect(state => {
  return {
    operation: state.operation.data,
    operationRequestState: state.operation.requestState,
  }
}, mapDispatchToProps)(Operation)
