import React from 'react'
import PropTypes from 'prop-types'

import CustomerData from '../CustomerData'
import OrderTable from '../OrderTable'
import MiscOrderData from '../MiscOrderData'
import SectionHeading from 'lib/components/headings/SectionHeading'
import StatusData from '../StatusData'

import styles from './styles.scss'

const OrderDetail = ({ onCancelOrderedProduct, order, orderErrors }) => {
  if (!order) return null

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <StatusData order={order} orderErrors={orderErrors} />
      </div>
      <div className={styles.section}>
        <SectionHeading>Customer details</SectionHeading>
        <CustomerData order={order} />
      </div>
      <div className={styles.section}>
        <SectionHeading>Other details</SectionHeading>
        <MiscOrderData order={order} />
      </div>
      <div className={styles.section}>
        <SectionHeading>Products purchased</SectionHeading>
        <OrderTable order={order} onCancelOrderedProduct={onCancelOrderedProduct} />
      </div>
    </div>
  )
}

OrderDetail.propTypes = {
  onCancelOrderedProduct: PropTypes.func.isRequired,
  order: PropTypes.object,
  orderErrors: PropTypes.object,
}

export default OrderDetail
