import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { orderTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload ? action.payload : INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [orderTypes.FETCH_ORDER_FULFILLED]: dataHandler,
  [orderTypes.CLEAR_FETCH_ORDER]: dataHandler,
  [orderTypes.CANCEL_ORDER_FULFILLED]: dataHandler,
  [orderTypes.MANUALLY_CANCEL_ORDER_FULFILLED]: dataHandler,
})

const reducer = combineReducers({
  requestState: requestStateReducer(orderTypes.FETCH_ORDER),
  data: dataReducer,
})

export default reducer
