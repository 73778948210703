import React from 'react'
import PropTypes from 'prop-types'
import { href } from '@licnz/js-utils'

import { imageLinkForSupplierName } from 'constants/suppliers'

import ButtonToolbar from 'lib/components/ButtonToolbar'
import LinkAsButton from 'lib/components/LinkAsButton'
import SectionHeading from 'lib/components/headings/SectionHeading'
import { Link } from 'react-router-dom'
import moment from 'moment'

import styles from './styles.scss'

const SUPPLIER_LIC = 'LIC'

const SupplierDetail = ({ supplier }) => {
  const imageLink = supplier && imageLinkForSupplierName(supplier.name)
  const companyLink = supplier && href({ links: supplier.links, rel: 'supplier_company' })

  // Start of Capacity management specific code
  const convertDateObject = dateObj => {
    return moment(dateObj).format('YYYY-MM-DD')
  }

  let orderFrom = convertDateObject(new Date())
  let orderTo = new Date()
  orderTo.setMonth(orderTo.getMonth() + 1)
  orderTo = convertDateObject(orderTo)
  // End of capacity management specific code

  const supplierName =
    supplier?.name === 'Zee Tags'
      ? global.FEATURE_FLAGS.Z_TAGS_BRANDING
        ? 'Z Tags'
        : 'Zee Tags'
      : supplier?.name

  return supplier ? (
    <div className={styles.section}>
      <SectionHeading>Supplier details</SectionHeading>
      <ButtonToolbar>
        <LinkAsButton
          to={`/suppliers/${encodeURIComponent(supplier.id)}/edit`}
          className='buttonSecondary'
        >
          Edit
        </LinkAsButton>
        <LinkAsButton
          to={`/supply_orders?supplierId=${encodeURIComponent(supplier.id)}`}
          className='buttonPrimary'
        >
          Supply Orders
        </LinkAsButton>
        {global.FEATURE_FLAGS.SUPPLIER_CAPACITY && supplier?.name !== SUPPLIER_LIC && (
          <LinkAsButton
            to={`/suppliers_capacity?orderFrom=${orderFrom}&orderTo=${orderTo}&supplierId=${encodeURIComponent(
              supplier.id
            )}`}
            className='buttonPrimary'
          >
            Supplier Capacity
          </LinkAsButton>
        )}
      </ButtonToolbar>
      <table className={styles.horizontalTable}>
        <tbody>
          <tr>
            <th>Name</th>
            <td>{supplierName}</td>
          </tr>
          <tr>
            <th>Region</th>
            <td>{supplier.region}</td>
          </tr>
          <tr>
            <th>Website</th>
            <td>{companyLink}</td>
          </tr>
          <tr>
            <th>Logo</th>
            <td>
              <img
                className={styles.supplierImage}
                src={imageLink}
                alt={`${supplierName} logo`}
              />
            </td>
          </tr>
          <tr>
            <th>Overdue Supply Orders</th>
            <td>
              {supplier.overdue_supply_orders_count ? (
                <Link
                  to={`/supply_orders/overdue?supplierId=${encodeURIComponent(
                    supplier.id
                  )}`}
                >
                  View ({supplier.overdue_supply_orders_count})
                </Link>
              ) : (
                'None found'
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : null
}

SupplierDetail.propTypes = {
  supplier: PropTypes.object,
}

export default SupplierDetail
