import { post } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'

import { productsSearchTypes } from 'actions/types'

function productsSearch(params) {
  let baseType = productsSearchTypes.SEARCH_PRODUCTS
  let link = `${global.config.PRODUCT_SERVICE_ENDPOINT}/admin/products/search`
  let body = params
  let url = proxyUrl({ link })

  return post({ baseType, url, body })
}

function clearProducts() {
  return { type: productsSearchTypes.CLEAR_SEARCH_PRODUCTS }
}

export { clearProducts, productsSearch }
