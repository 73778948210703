import React from 'react'
import PropTypes from 'prop-types'

import TitleUnderline from 'lib/components/headings/TitleUnderline'
import Button from 'lib/components/Button'

import styles from './styles.scss'

function PageHeading({ heading, subheading, size = 'h1', onAction, actionText }) {
  return (
    <div className={styles.pageHeadingWrapper}>
      {onAction && actionText ? (
        <div className={styles.buttonWrapper}>
          <Button className='buttonOutline' onClick={onAction}>
            {actionText}
          </Button>
        </div>
      ) : null}
      <div className={styles.lineLength}>
        <TitleUnderline size={size}>{heading}</TitleUnderline>
        {subheading && <h4 className={styles.subheading}>{subheading}</h4>}
      </div>
    </div>
  )
}

PageHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  size: PropTypes.string,
  onAction: PropTypes.func,
  actionText: PropTypes.string,
}

export default PageHeading
