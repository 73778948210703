import { get, put } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { supplierTypes } from 'actions/types'

function fetchSupplier(supplierLink) {
  let baseType = supplierTypes.FETCH_SUPPLIER
  let url = proxyUrl({ link: supplierLink })

  return get({ baseType, url })
}

function updateSupplier(supplier) {
  let url = proxyUrl({ link: supplier.id })
  let baseType = supplierTypes.UPDATE_SUPPLIER
  let body = supplier

  return put({ url, baseType, body })
}

function clearSupplier() {
  return { type: supplierTypes.CLEAR_FETCH_SUPPLIER }
}

export { fetchSupplier, updateSupplier, clearSupplier }
