import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const AvailableSizesData = ({ sizes }) => {
  return sizes && sizes.length ? (
    <table className={styles.verticalTable}>
      <thead>
        <tr>
          <th>Display Name</th>
          <th>Is Default</th>
        </tr>
      </thead>
      <tbody>
        {sizes.map(size => (
          <tr key={size.display_name}>
            <td>{size.display_name}</td>
            <td>{size.default ? 'Yes' : 'No'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <div>No sizes found</div>
  )
}
AvailableSizesData.propTypes = {
  sizes: PropTypes.array,
}

export default AvailableSizesData
