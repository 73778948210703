import React from 'react'
import PropTypes from 'prop-types'
import { href } from '@licnz/js-utils'

import { formatStatus } from 'utils/util'

import DateTime from 'lib/components/DateTime'
import { Link } from 'react-router-dom'

import styles from './styles.scss'

const SupplyOrdersData = ({ supplyOrders, supplierName }) => {
  const renderSupplyOrderDetails = supplyOrder => {
    let supplyOrderLink = href({ links: supplyOrder.links, rel: 'self' })

    return supplyOrder ? (
      <tr key={supplyOrder.order_reference} className={styles.bodyTableRow}>
        <td>
          <Link to={`/orders/${encodeURIComponent(supplyOrder.order_link)}`}>
            {supplyOrder.order_reference}
          </Link>
        </td>
        <td className={styles.pullLeft}>{formatStatus(supplyOrder.state)}</td>
        <td className={styles.pushCenter}>{supplyOrder.external_operation_code}</td>
        <td className={styles.pushCenter}>
          {supplierName === 'lic'
            ? supplyOrder.sales_order_number
            : supplyOrder.purchase_order_id}
        </td>
        <td className={styles.pushCenter}>
          <DateTime date={supplyOrder.created_at} />
        </td>
        <td className={styles.pushCenter}>
          <DateTime date={supplyOrder.delivery_time} />
        </td>
        <td className={styles.pushCenter}>
          {supplyOrder.last_transition_time ? (
            <DateTime date={supplyOrder.last_transition_time} />
          ) : (
            'Not found'
          )}
        </td>
        <td className={styles.pushCenter}>
          {supplyOrder.shipping_time ? (
            <DateTime date={supplyOrder.shipping_time} />
          ) : (
            'Not found'
          )}
        </td>
        <td>
          <Link to={`/supply_orders/${encodeURIComponent(supplyOrderLink)}`}>View</Link>
        </td>
      </tr>
    ) : null
  }

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.headerTableRow}>
          <th className={styles.pullLeft}>Order reference</th>
          <th className={styles.pullLeft}>Status</th>
          <th className={styles.pullRight}>Farming operation</th>
          <th className={styles.pullRight}>
            {supplierName === 'lic' ? 'SO number' : 'PO number'}
          </th>
          <th className={styles.pullRight}>Created at</th>
          <th className={styles.pullRight}>Delivery</th>
          <th className={styles.pullRight}>Last transition</th>
          <th className={styles.pullRight}>Shipping time</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {supplyOrders.map(supplyOrder => renderSupplyOrderDetails(supplyOrder))}
      </tbody>
    </table>
  )
}

SupplyOrdersData.propTypes = {
  supplyOrders: PropTypes.array.isRequired,
  supplierName: PropTypes.string.isRequired,
}

export default SupplyOrdersData
