import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

import { extractConfigurationOptions } from 'utils/ratingRulesUtils'
import { RATING_PRICE_URN_MAPPINGS } from 'constants/priceMappings'

import Currency from 'lib/components/Currency'
import DateTime from 'lib/components/DateTime'

import styles from './styles.scss'

const {
  BASE_UNIT_COST,
  BASE_UNIT_RETAIL,
  CHARGE_LEVY_UNIT_NAIT,
  CONFIGREQ_UNIT_COST,
  CONFIGREQ_UNIT_RETAIL,
} = RATING_PRICE_URN_MAPPINGS

const RatingVersionTable = ({ product, ratingRules, version, versionOptions = [], updateVersion }) => {
  const productComponents = extractConfigurationOptions(product)

  let selectVersions = versionOptions?.map(versionNumber => {
    return { value: versionNumber, label: `Version ${versionNumber}` }
  })

  const renderBaseProductRules = () => {
    let baseCostRule = ratingRules.find(rule => rule.price_type === BASE_UNIT_COST)
    let baseCostPrice = baseCostRule ? Number(baseCostRule.value) : null
    let baseRetailRule = ratingRules.find(rule => rule.price_type === BASE_UNIT_RETAIL)
    let baseRetailPrice = baseRetailRule ? Number(baseRetailRule.value) : null
    let naitLevyRule = ratingRules.find(rule => rule.price_type === CHARGE_LEVY_UNIT_NAIT)
    let naitLevyPrice = naitLevyRule ? Number(naitLevyRule.value) : null

    return (
      <Fragment>
        <tr>
          <th>Rule</th>
          <th>Cost price</th>
          <th>Retail price</th>
        </tr>
        <tr>
          <td>Base price</td>
          <td>
            <Currency amount={baseCostPrice} />
          </td>
          <td>
            <Currency amount={baseRetailPrice} />
          </td>
        </tr>
        {naitLevyPrice ? (
          <tr>
            <td>NAIT Levy</td>
            <td>-</td>
            <td>
              <Currency amount={naitLevyPrice} showPlusIfPositive />
            </td>
          </tr>
        ) : null}
      </Fragment>
    )
  }

  const renderMarkingOptionRules = component => {
    if (!component.markingOptions) return null

    let markingOptionsToRender = component.markingOptions
      .map(option => {
        let rules = option.config_requirements.map(configReq =>
          ratingRules.filter(ratingRule => ratingRule.rule_id === configReq.identity)
        )
        rules = [...[].concat.apply([], rules)]

        if (rules.length) {
          let costPrice = rules
            .filter(rule => rule.price_type === CONFIGREQ_UNIT_COST)
            .map(rule => Number(rule.value))
            .reduce((a, b) => a + b, 0)

          let retailPrice = rules
            .filter(rule => rule.price_type === CONFIGREQ_UNIT_RETAIL)
            .map(rule => Number(rule.value))
            .reduce((a, b) => a + b, 0)

          return {
            identity: option.identity,
            name: option.name,
            costPrice,
            retailPrice,
          }
        }
      })
      .filter(option => option)

    return markingOptionsToRender.map((option, i) => {
      return (
        option && (
          <Fragment key={component.slug + option.identity}>
            {i === 0 && (
              <tr>
                <th>Marking options</th>
                <th>Cost price</th>
                <th>Retail price</th>
              </tr>
            )}
            <tr>
              <td>{option.name}</td>
              <td>
                <Currency amount={option.costPrice} showPlusIfPositive />
              </td>
              <td>
                <Currency amount={option.retailPrice} showPlusIfPositive />
              </td>
            </tr>
          </Fragment>
        )
      )
    })
  }

  const renderSizeRules = component => {
    if (!component.sizes) return null

    let sizesToRender = component.sizes
      .map(size => {
        let rules = size.identifiers.map(sizeIdentifier =>
          ratingRules.filter(
            ratingRule =>
              ratingRule.rule_id === sizeIdentifier._type &&
              ratingRule.rule_value === sizeIdentifier.identifier
          )
        )
        rules = [...[].concat.apply([], rules)]

        if (rules.length) {
          let costPrice = rules.find(rule => rule.price_type === CONFIGREQ_UNIT_COST)
            .value
          let retailPrice = rules.find(rule => rule.price_type === CONFIGREQ_UNIT_RETAIL)
            .value

          return {
            identity: size.identity,
            name: size.display_name,
            costPrice,
            retailPrice,
          }
        }
      })
      .filter(size => size)

    return sizesToRender.map((size, i) => {
      return (
        size && (
          <Fragment key={component.slug + size.identity}>
            {i === 0 && (
              <tr>
                <th>Sizes</th>
                <th>Cost price</th>
                <th>Retail price</th>
              </tr>
            )}
            <tr>
              <td>{size.name}</td>
              <td>
                <Currency amount={size.costPrice} showPlusIfPositive />
              </td>
              <td>
                <Currency amount={size.retailPrice} showPlusIfPositive />
              </td>
            </tr>
          </Fragment>
        )
      )
    })
  }

  const componentHasNoRatingRules = component => {
    return (
      (!renderMarkingOptionRules(component) ||
        !renderMarkingOptionRules(component).length) &&
      (!renderSizeRules(component) || !renderSizeRules(component).length)
    )
  }

  const renderDetails = (componentKey, i) => {
    let component = productComponents[componentKey]

    return (
      <tbody key={component.slug}>
        {((component.slug && i === 0) || !componentHasNoRatingRules(component)) && (
          <tr>
            <th colSpan='3'>
              <p className={styles.productTitle}>{component.name}</p>
            </th>
          </tr>
        )}
        {component.slug && i === 0 && renderBaseProductRules()}
        {!componentHasNoRatingRules(component) && (
          <Fragment>
            {component.markingOptions && renderMarkingOptionRules(component)}
            {component.sizes && renderSizeRules(component)}
          </Fragment>
        )}
      </tbody>
    )
  }

  // FIXME: This is a hacky and unstable solution, but fits the requirements
  // and works 'good enough'. The more rules we add the more complex it is
  // going to get. This comparator makes sure female components are shown before
  // male components and then also that the front components are show before back components.
  // Instead of sorting the female/front components to the top this instead sorts
  // the male/back components to the bottom. This is because we don't want to redorder
  // the other components that aren't labelled male/female or front/back. This works
  // because these other components are currently at the top, if in future the order of
  // components gets more complicated then this method is likely to break.
  const componentComparator = (firstComponent, secondComponent) => {
    if (!/.*-male.*/i.test(firstComponent) && !/.*-male.*/i.test(secondComponent)) {
      if (!/.*-back.*/i.test(firstComponent) && !/.*-back.*/i.test(secondComponent))
        return 0
      return !/.*-back.*/i.test(firstComponent) ? -1 : 1
    }
    return !/.*-male.*/i.test(firstComponent) ? -1 : 1
  }

  return (
    <table>
      <thead>
        {selectVersions.length > 0 && ratingRules[0].versioned_at ? (
          <tr>
            <th colSpan='1'>
              <Select
                className={styles.versions}
                options={selectVersions}
                value={{ value: version, label: `Version ${version}`}}
                onChange={updateVersion}
              />
            </th>
            <th colSpan='2'>
              {ratingRules[0].versioned_by_name ? `${ratingRules[0].versioned_by_name}: ` : null}<DateTime date={ratingRules[0].versioned_at} format='DD/MM/YYYY, hh:mm A' />
            </th>
          </tr>
        ) : (
          <tr>
            <th colSpan='3'>New rating rules</th>
          </tr>
        )}
      </thead>
      {Object.keys(productComponents)
        .sort(componentComparator)
        .map((componentKey, i) => {
          return renderDetails(componentKey, i)
        })}
    </table>
  )
}

RatingVersionTable.propTypes = {
  product: PropTypes.object.isRequired,
  ratingRules: PropTypes.array,
  version: PropTypes.string,
  versionOptions: PropTypes.array,
  updateVersion: PropTypes.func
}

export default RatingVersionTable
