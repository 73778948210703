import { get, put } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { href } from '@licnz/js-utils'
import { operationTypes } from 'actions/types'

function fetchOperation(operationPartyLink) {
  const baseType = operationTypes.FETCH_OPERATION
  const extractPayload = response => response.data.party
  let url = proxyUrl({ link: operationPartyLink })
  return get({ url, baseType, extractPayload })
}

function clearOperation() {
  return { type: operationTypes.CLEAR_FETCH_OPERATION }
}

function updateOperation({ operation, regulated }) {
  let baseType = operationTypes.UPDATE_OPERATION
  let link = href({ links: operation.links, rel: 'self' })
  let url = proxyUrl({ link })
  operation.metadata.regulated = regulated
  let body = operation

  return put({ baseType, url, body })
}

export { clearOperation, fetchOperation, updateOperation }
