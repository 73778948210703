import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { organisationTypes } from 'actions/types'

function fetchOrganisation(organisationPartyLink) {
  const baseType = organisationTypes.FETCH_ORGANISATION
  const extractPayload = response => response.data.party
  let url = proxyUrl({ link: organisationPartyLink })

  return get({ url, baseType, extractPayload })
}

export { fetchOrganisation }
