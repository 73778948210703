import React from 'react'

import styles from './styles.scss'

function SiteFooter() {
  return (
    <div className={styles.siteFooter}>
      <div className={styles.gutters}>
        <div className={styles.grid}>
          <div className={styles.linksWidth}>
            <ul className={styles.listInline}>
              <li className={styles.listInlineItem}>
                <a
                  href='#'
                  target='_blank'
                  rel='nopener'
                  className={styles.footerMenuLink}
                >
                  Contact
                </a>
              </li>
              <li className={styles.listInlineItem}>
                <a
                  href='#'
                  target='_blank'
                  rel='nopener'
                  className={styles.footerMenuLink}
                >
                  LIC Service Rules
                </a>
              </li>
              <li className={styles.listInlineItem}>
                <a
                  href='#'
                  target='_blank'
                  rel='nopener'
                  className={styles.footerMenuLink}
                >
                  Privacy
                </a>
              </li>
              <li className={styles.listInlineItem}>
                <a
                  href='#'
                  target='_blank'
                  rel='nopener'
                  className={styles.footerMenuLink}
                >
                  Terms of use
                </a>
              </li>
              <li className={styles.listInlineItem}>
                <a
                  href='#'
                  target='_blank'
                  rel='nopener'
                  className={styles.footerMenuLink}
                >
                  Animal ethics
                </a>
              </li>
            </ul>
          </div>
          <div className={styles.dateWrapper}>
            <p className={styles.date}>
              © {new Date().getFullYear()} Copyright LIC • All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SiteFooter
