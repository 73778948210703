import { createSelector } from 'reselect'

const SHOP_ADMIN_ACTIVITIES = {
  PRODUCTS: 'lic:shop_assistant:resource:product:*',
  ORDERS: 'lic:shop_assistant:resource:order:*',
  SUPPLIERS: 'lic:shop_assistant:resource:supplier:*',
  SUPPLY_ORDERS: 'lic:shop_assistant:resource:supply_order:*',
}

const PRIVILEGED_ORDERS_ACTIVITY = 'lic:order:privilege:*:*'
const RESTRICTED_ORDERS_ACTIVITY = 'lic:shop_assistant:resource:order:*'

const userInfoData = createSelector([data => data], state => {
  if (!state.userInfo.data) return {}

  return state.userInfo.data
})

const isAdmin = createSelector([data => data], state => {
  if (!state.userInfo.data) return false
  let { allowed_activities } = state.userInfo.data
  return Object.values(SHOP_ADMIN_ACTIVITIES).every(
    activity => allowed_activities.indexOf(activity) >= 0
  )
})

const isAgriManager = createSelector([data => data], state => {
  if (!state.userInfo.data) return false
  let { allowed_activities } = state.userInfo.data
  return (
    allowed_activities.includes(SHOP_ADMIN_ACTIVITIES.ORDERS) &&
    !allowed_activities.includes(SHOP_ADMIN_ACTIVITIES.PRODUCTS) &&
    !allowed_activities.includes(SHOP_ADMIN_ACTIVITIES.SUPPLIERS) &&
    !allowed_activities.includes(SHOP_ADMIN_ACTIVITIES.SUPPLY_ORDERS)
  )
})

const isCustomer = createSelector([data => data], state => {
  if (!state.userInfo.data) return false
  let { identity_provider } = state.userInfo.data
  let validStaffProviders = [
    'urn:lic:id:idprovider:identity',
    'urn:lic:id:idprovider:lic_ad',
  ]
  return identity_provider && !validStaffProviders.includes(identity_provider)
})

const hasPrivilegedOrderAccess = createSelector([data => data], state => {
  if (!state.userInfo.data) return false
  let { allowed_activities } = state.userInfo.data
  return allowed_activities.includes(PRIVILEGED_ORDERS_ACTIVITY)
})

const hasRestrictedOrderAccess = createSelector([data => data], state => {
  if (!state.userInfo.data) return false
  let { allowed_activities } = state.userInfo.data
  return allowed_activities.includes(RESTRICTED_ORDERS_ACTIVITY)
})

export {
  userInfoData,
  isAdmin,
  isAgriManager,
  isCustomer,
  hasPrivilegedOrderAccess,
  hasRestrictedOrderAccess,
}
