import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { suppliersTypes } from 'actions/types'

function fetchSuppliers() {
  let baseType = suppliersTypes.FETCH_SUPPLIERS
  let suppliersLink = `${global.config.SUPPLIER_SERVICE_ENDPOINT}/suppliers/`
  let url = proxyUrl({ link: suppliersLink })

  return get({ baseType, url, extractPayload: response => response.data.items })
}

function clearSuppliers() {
  return { type: suppliersTypes.CLEAR_FETCH_SUPPLIERS }
}

export { fetchSuppliers, clearSuppliers }
