import React, { Fragment, useState } from 'react'

import styles from './styles.scss'
import JSONTree from 'react-json-tree'

const ErrorDetail = ({ errorList, customStyles }) => {
  const [showErrorDetail, setShowErrorDetail] = useState(false)

  const convertErrorToString = status => {
    try {
      let codes = status.code.split(':')
      let message = codes[codes.length - 1]
        .split('_')
        .join(' ')
        .replace(/^\w/, c => c.toUpperCase())
      return message + ' (' + codes.slice(2, codes.length - 1).join(' ') + ')'
    } catch (error) {
      return null
    }
  }

  return (
    <Fragment>
      <a
        style={customStyles}
        className={styles.showDetailAccordion}
        onClick={() => setShowErrorDetail(!showErrorDetail)}
      >
        <span>{showErrorDetail ? 'Hide' : 'Show'} error detail</span>
        <span className={styles.accordionIcon}>
          <span
            className={`${styles.accordionIconPath} ${styles.accordionIconPathVertical}`}
            data-accordion-open={showErrorDetail}
          ></span>
          <span
            className={`${styles.accordionIconPath} ${styles.accordionIconPathHorizontal}`}
            data-accordion-open={showErrorDetail}
          ></span>
        </span>
      </a>
      {showErrorDetail &&
        errorList.map(error => (
          <dl key={error.id}>
            <dt className={styles.descriptionTitle}>Error status:</dt>
            <dd>
              {(error.error_json && convertErrorToString(error.error_json._status)) ||
                'unknown'}
            </dd>
            <dt className={styles.descriptionTitle}>Failed action:</dt>
            <dd>{error.failed_action || 'unknown'}</dd>
            <dt className={styles.descriptionTitle}>Status code:</dt>
            <dd>{error.http_status_code || 'unknown'}</dd>
            {error.error_json && (
              <dt>
                <JSONTree
                  data={error.error_json}
                  invertTheme={false}
                  hideRoot={true}
                  shouldExpandNode={() => true}
                />
              </dt>
            )}
          </dl>
        ))}
    </Fragment>
  )
}

export default ErrorDetail
