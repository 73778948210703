import React, { Component } from 'react'
import { connect } from 'react-redux'
import { href } from '@licnz/js-utils'

import { cancelOrder, clearOrder, fetchOrder, manuallyCancelOrder } from 'actions/orderActions'
import { clearOrderErrors, fetchOrderErrors } from 'actions/orderErrorsActions'
import { cancelOrderedProduct } from 'actions/orderedProductActions'
import { ORDER_STATE_MAPPINGS } from 'constants/stateMappings'
import { enqueueError, enqueueInfo } from 'lib/components/GlobalNotifications'
import { fetchSuppliers } from 'actions/suppliersActions'
import { getPartyLink } from 'lib/selectors/linkSelectors'
import { getPartyName } from 'lib/selectors/nameSelectors'
import { isAdmin } from 'selectors/userInfoSelectors'

import ButtonToolbar from 'lib/components/ButtonToolbar'
import CancelOrderModal from 'lib/components/CancelOrderModal'
import DateTime from 'lib/components/DateTime'
import OrderDetail from './OrderDetail'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import RequestWrapper from '@licnz/react-request-wrapper'

import styles from './styles.scss'

class Order extends Component {
  constructor(props) {
    super(props)

    this.state = {
      naitCharges: [],
    }

    this.handleBack = this.handleBack.bind(this)
    this.handleCancelOrder = this.handleCancelOrder.bind(this)
    this.handleCancelOrderedProduct = this.handleCancelOrderedProduct.bind(this)
    this.handleManuallyCancelOrder = this.handleManuallyCancelOrder.bind(this)
    this.allowedStatesToRenderCancelButton = this.allowedStatesToRenderCancelButton.bind(this)
  }

  componentDidMount() {
    this.getOrder()
    if (this.props.fetchSuppliers) {
      this.props.fetchSuppliers()
    }
  }

  componentWillUnmount() {
    this.props.clearOrder()
    this.props.clearOrderErrors()
  }

  componentDidUpdate(prevProps) {
    let { order } = this.props
    if(prevProps.order != order) {
      this.getOrderErrors()
    }
  }

  getOrder() {
    let {
      enqueueError,
      fetchOrder,
      match: {
        params: { id },
      },
    } = this.props

    fetchOrder(decodeURIComponent(id)).catch(() =>
      enqueueError({ message: 'We were unable to fetch this order' })
    )
  }

  getOrderErrors() {
    let { fetchOrderErrors, order } = this.props
    fetchOrderErrors(decodeURIComponent(order.id)).catch(() =>
      enqueueError({ message: 'We were unable to fetch the order errors' })
    )
  }

  handleBack() {
    this.props.history.goBack()
  }

  handleCancelOrder() {
    let { cancelOrder, enqueueError, enqueueInfo, order, partyLink, partyName } = this.props

    return cancelOrder({ order, partyLink, partyName })
      .then(() => {
        enqueueInfo({ message: 'A cancellation request has been sent to the supplier. Please refresh the page in a few minutes. The order will then either be cancelled or will shift to in production if unsuccessful.' })
        this.getOrder()
      })
      .catch(() => enqueueError({ message: 'We were unable to cancel this order' }))
  }

  handleManuallyCancelOrder() {
    let { manuallyCancelOrder, enqueueError, enqueueInfo, order, partyLink, partyName } = this.props

    return manuallyCancelOrder({ order, partyLink, partyName })
      .then(() => {
        enqueueInfo({ message: 'The order has been successfully manually cancelled.' })
        this.getOrder()
      })
      .catch(() => enqueueError({ message: 'We were unable to manually cancel this order' }))
  }

  handleCancelOrderedProduct(orderedProduct) {
    let { cancelOrderedProduct, enqueueError } = this.props
    let cancellationLink = href({ links: orderedProduct.links, rel: 'cancellation' })

    cancelOrderedProduct({ cancellationLink })
      .then(() => this.getOrder())
      .catch(() =>
        enqueueError({ message: 'We were unable to cancel this ordered product' })
      )
  }

  renderButtonToolbar() {
    let { order } = this.props
    let cancellationLink = order
      ? href({ links: order.links, rel: 'cancellation' })
      : null
 
    return order && cancellationLink ? (
      <ButtonToolbar>
        {this.renderModals()}
      </ButtonToolbar>
    ) : null
  }

  renderModals() {
    let { isAdmin, order } = this.props
    let cancelOrderModal = (
      order && (this.allowedStatesToRenderCancelButton()) && (
      <CancelOrderModal
        buttonClassName='buttonDelete'
        buttonText='Cancel order'
        handleCancel={this.handleCancelOrder}
        order={order}
        modalTextLines={['Are you sure you want to cancel this order?', 'All items will be removed, and you will need to start a new order.', `(${order.ordered_products_total_quantity} items in order)`]}
      />
      ))
    let manualCancelOrderModal = (
      order && isAdmin && (order.state !== ORDER_STATE_MAPPINGS.CANCELLED) && (order.state !== ORDER_STATE_MAPPINGS.COMPLETED) && (global.FEATURE_FLAGS.MANUAL_CANCELLATION) && (
      <CancelOrderModal
        buttonClassName='buttonSecondary'
        buttonText='Manually cancel order'
        handleCancel={this.handleManuallyCancelOrder}
        order={order}
        modalTextLines={['Are you sure you want to cancel this order?', 'All items will be removed, and you will need to start a new order. Do not forget to manually cancel the corresponding supply orders too via shop assistant as notifications are not sent to the other services.', `(${order.ordered_products_total_quantity} items in order)`]}
      />
      )
    )
    return (
      <div className={styles.toolbarSpacing}>
        { cancelOrderModal }   
        { manualCancelOrderModal }   
      </div>
    )
  }

  allowedStatesToRenderCancelButton() {
    let { isAdmin, order } = this.props
    if (isAdmin) {
      return (order.state === ORDER_STATE_MAPPINGS.ACCEPTED ||
        order.state === ORDER_STATE_MAPPINGS.READY_FOR_PRODUCTION || order.state === ORDER_STATE_MAPPINGS.ERROR)
    }
    else {
      return (order.state === ORDER_STATE_MAPPINGS.ACCEPTED || order.state === ORDER_STATE_MAPPINGS.READY_FOR_PRODUCTION)
    }
  }

  render() {
    let { order, orderRequestState, orderErrors } = this.props
    let createdTime = order && order.timestamps['created_time']

    return (
      <RequestWrapper requestState={orderRequestState}>
        <PageSection>
          <PageHeading
            heading='Customer order'
            subheading={createdTime && <DateTime date={createdTime} format='DD/MM/YY, h:mm A' />}
            onAction={this.handleBack}
            actionText='Back'
          />
          {this.renderButtonToolbar()}
          <OrderDetail
            order={order}
            onCancelOrderedProduct={this.handleCancelOrderedProduct}
            orderErrors={orderErrors}
          />
        </PageSection>
      </RequestWrapper>
    )
  }
}

const mapDispatchToProps = {
  cancelOrder,
  cancelOrderedProduct,
  clearOrder,
  clearOrderErrors,
  enqueueError,
  enqueueInfo,
  fetchOrder,
  fetchOrderErrors,
  fetchSuppliers,
  manuallyCancelOrder,
}

export { Order }
export default connect(
  state => {
    return {
      isAdmin: isAdmin(state),
      order: state.order.data,
      orderErrors: state.orderErrors.data,
      orderRequestState: state.order.requestState,
      orders: state.order,
      partyLink: getPartyLink(state.currentProfile.data),
      partyName: getPartyName(state.currentProfile.data),
      suppliersCount: state.suppliers.data.length,
    }
  },
  mapDispatchToProps,
  // Utilise the mergeProps optional arguments to conditionally fetchSuppliers
  // isAdmin is required, for the OrderTable to utilse supplier data and to decrease errors
  //                      when users don't have permission to access suppliers but can view orders.
  // suppliersCount is utilised to prevent re-requesting data already stored in redux.
  ({ isAdmin, suppliersCount, ...otherStateProps }, { fetchSuppliers, ...otherDispatchProps }, ownProps) => ({
    ...ownProps,
    isAdmin,
    ...otherStateProps,
    ...otherDispatchProps,
    fetchSuppliers: (isAdmin && suppliersCount === 0) ? fetchSuppliers : undefined,
  })
)(Order)
