import React from 'react'
import PropTypes from 'prop-types'

import { id } from 'lib/utils/identifier'

import styles from './styles.scss'

const OperationDetail = ({ operation }) => {
  return operation ? (
    <table className={styles.verticalTable}>
      <tbody>
        <tr>
          <th>Participant code</th>
          <td>{operation.metadata.handle}</td>
        </tr>
        <tr>
          <th>Tag only customer</th>
          <td>{operation.metadata.regulated ? 'No' : 'Yes'}</td>
        </tr>
        <tr>
          <th>Animal group BP</th>
          <td>
            {id({ identifiers: operation.identifiers, type: 'legacy_animal_group_bp' })}
          </td>
        </tr>
      </tbody>
    </table>
  ) : null
}

OperationDetail.propTypes = {
  operation: PropTypes.object,
}

export default OperationDetail
