import React from 'react'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'

import CategorisationsForm from 'components/CategorisationsForm'

const FORM_NAME = 'editCategorisations'

const CategorisationsFormWrapper = ({ handleSubmit, onSubmit }) => {
  const manipulateCategories = categories => {
    if (typeof categories === 'string') {
      // if categories are unchanged values will be returned as a string
      return categories.split(',')
    } else if (typeof categories === 'object') {
      // if categories have been changed values will be returned as an array
      return categories.map(category => {
        return category.name
      })
    }
  }

  const submit = values => {
    let categories =
      values.categories && values.categories.length
        ? manipulateCategories(values.categories)
        : []
    let submitValues = {
      type: 'product_taxonomies',
      categories: categories,
      specification: values.specification === '\n' ? null : values.specification,
    }

    return onSubmit(submitValues)
  }

  return <CategorisationsForm onSubmit={handleSubmit(submit)} />
}

export { CategorisationsFormWrapper }
export default compose(
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
  })
)(CategorisationsFormWrapper)
