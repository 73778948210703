import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const ReorderProductButton = ({ isLoading, isAvailableForPurchase, linkToShop }) => {
  if (isLoading) {
    return 'Loading...'
  } else if (isAvailableForPurchase) {
    return (
      <a className={`${styles.button} ${styles.buttonPrimary}`} href={linkToShop}>
        Reorder this product
      </a>
    )
  } else {
    return 'Product unavailable to reorder'
  }
}

ReorderProductButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isAvailableForPurchase: PropTypes.bool,
  linkToShop: PropTypes.string,
}

export default ReorderProductButton
