const ORDER_STATE_MAPPINGS = {
  NEW: 'new',
  ERROR: 'error',
  ACCEPTED: 'accepted',
  READY_FOR_PRODUCTION: 'ready_for_production',
  IN_PRODUCTION: 'in_production',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
}

const ORDERED_PRODUCT_STATE_MAPPINGS = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  READY_FOR_PRODUCTION: 'ready_for_production',
  IN_PRODUCTION: 'in_production',
  DISPATCHED: 'dispatched',
  ERROR: 'error',
}

const SUPPLY_ORDER_STATE_MAPPINGS = {
  PENDING: 'pending',
  ERROR: 'error',
  READY_FOR_FULFILMENT: 'ready_for_fulfilment',
  IN_FULFILMENT: 'in_fulfilment',
  COMPLETED: 'completed',
  CANCELLED: 'cancelled',
}
export { ORDER_STATE_MAPPINGS, ORDERED_PRODUCT_STATE_MAPPINGS, SUPPLY_ORDER_STATE_MAPPINGS }
