import { combineReducers } from 'redux'

import adminProduct from 'reducers/adminProductReducer'
import basket from 'reducers/basketReducer'
import baskets from 'reducers/basketsReducer'
import basketsSearch from 'reducers/basketsSearchReducer'
import capacityManagement from 'reducers/capacityManagementReducers'
import classifiers from 'reducers/classifiersReducer'
import classifierOptions from 'reducers/classifierOptionsReducer'
import currentProfile from 'reducers/currentProfileReducer'
import customerContext from 'reducers/customerContextReducer'
import customerOrders from 'reducers/customerOrdersReducer'
import downForMaintenance from 'lib/reducers/downForMaintenanceReducer'
import discount from 'reducers/discountReducer'
import discounts from 'reducers/discountsReducer'
import idToken from 'reducers/idTokenReducer'
import loggedIn from 'lib/reducers/loggedInReducer'
import metadata from 'reducers/metadataReducer'
import { notificationsReducer as notifications } from 'lib/components/GlobalNotifications'
import offeringTypes from 'reducers/offeringTypesReducer'
import operationsSearch from 'reducers/operationsSearchReducer'
import operation from 'reducers/operationReducer'
import order from 'reducers/orderReducer'
import orderErrors from 'reducers/orderErrorsReducer'
import supplyOrderErrors from 'reducers/supplyOrderErrorsReducer'
import orders from 'reducers/ordersReducer'
import ordersSearch from 'reducers/ordersSearchReducer'
import orderedProduct from 'reducers/orderedProductReducer'
import productFamilies from 'reducers/productFamiliesReducer'
import product from 'reducers/productReducer'
import products from 'reducers/productsReducer'
import productImage from 'reducers/productImageReducer'
import productsSearch from 'reducers/productsSearchReducer'
import rating from 'reducers/ratingReducer'
import ratingRules from 'reducers/ratingRulesReducer'
import redirect from 'reducers/redirectReducer'
import requestedProduct from 'reducers/requestedProductReducer'
import requestedProducts from 'reducers/requestedProductsReducer'
import shippingRates from 'reducers/shippingRatesReducer'
import supplier from 'reducers/supplierReducer'
import suppliers from 'reducers/suppliersReducer'
import supplyOrder from 'reducers/supplyOrderReducer'
import supplyOrders from 'reducers/supplyOrdersReducer'
import supplyOrdersSearch from 'reducers/supplyOrdersSearchReducer'
import userInfo from 'reducers/userInfoReducer'
import { reducer as formReducer } from 'redux-form'

export default combineReducers({
  adminProduct,
  basket,
  baskets,
  basketsSearch,
  capacityManagement,
  classifiers,
  classifierOptions,
  currentProfile,
  customerContext,
  customerOrders,
  downForMaintenance,
  discount,
  discounts,
  form: formReducer,
  idToken,
  loggedIn,
  metadata,
  notifications,
  offeringTypes,
  operation,
  operationsSearch,
  order,
  orderErrors,
  supplyOrderErrors,
  orders,
  ordersSearch,
  orderedProduct,
  product,
  productFamilies,
  products,
  productImage,
  productsSearch,
  rating,
  ratingRules,
  redirect,
  requestedProduct,
  requestedProducts,
  shippingRates,
  supplier,
  suppliers,
  supplyOrder,
  supplyOrders,
  supplyOrdersSearch,
  userInfo,
})
