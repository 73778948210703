import React from 'react'
import PropTypes from 'prop-types'
import urlPropType from 'url-prop-type'

import { headerTextConstants } from '..'

import Button from 'lib/components/Button'

import styles from './styles.scss'

const Notification = ({ message, label, link, type, onClose }) => {
  let headerText = () => headerTextConstants[type]

  return (
    <div className={styles.modal}>
      <header className={styles.header}>
        <span>{headerText()}</span>
      </header>

      <p className={styles.paragraph}>
        {message}
        {label && link ? (
          <span>
            &nbsp;<a href={link}>{label}</a>
          </span>
        ) : null}
      </p>

      <div className={styles.controls}>
        <div className={styles.buttonContainer}>
          <Button className='buttonSecondary buttonBlock' autoFocus onClick={onClose}>
            Ok
          </Button>
        </div>
      </div>
    </div>
  )
}

Notification.defaultProps = {
  message:
    'Something went wrong, please try again later. If the problem persists, please contact LIC on 0800 2 MINDA (0800 264 632)',
}

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string,
  ctaLink: urlPropType,
  onClose: PropTypes.func.isRequired,
  onToggleDetail: PropTypes.func,
  showDetail: PropTypes.bool,
}

export default Notification
