const supplyOrderStates = [
  { id: '1', name: 'Pending', state: 'pending' },
  { id: '2', name: 'Ready for Fulfilment', state: 'ready_for_fulfilment' },
  { id: '3', name: 'In Fulfilment', state: 'in_fulfilment' },
  { id: '4', name: 'Cancelled', state: 'cancelled' },
  { id: '5', name: 'Completed', state: 'completed' },
  { id: '6', name: 'Error', state: 'error' },
]

const supplierEventStates = [
  { id: '1', name: 'Created', state: 'created' },
  { id: '2', name: 'Processing', state: 'processing' },
  { id: '3', name: 'Completed', state: 'completed' },
  { id: '4', name: 'Ignored', state: 'ignored' },
  { id: '5', name: 'Error', state: 'error' },
]

const reverseOrderOptions = [
  { id: '1', name: 'Yes', value: true },
  { id: '2', name: 'No', value: false },
]

const sortOrderOptions = [
  { id: '1', name: 'Created At', value: 'created_at' },
  { id: '2', name: 'Updated At', value: 'updated_at' },
]

export { reverseOrderOptions, sortOrderOptions, supplyOrderStates, supplierEventStates }
