import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reduxForm, reset } from 'redux-form'
import { extractProductImageIdFromProductImageUrl } from 'utils/productUtils'

import { fetchAdminProduct } from 'actions/productActions'
import { updateProductImage } from 'actions/productImageActions'

import PageSection from 'lib/components/layout/PageSection'
import PageHeading from 'lib/components/headings/PageHeading'
import ImageForm from 'components/ImageForm'
import RequestWrapper from '@licnz/react-request-wrapper'

import { extractIdFromUrl } from 'utils/util'

let EditImage = ({ history, match, handleSubmit: handleReduxFormSubmit }) => {
  const dispatch = useDispatch()

  const adminProduct = useSelector(state => state.adminProduct.data)

  const editImageForm = useSelector(state => state.form.editImage) ?? {
    editImage: { submitting: false, submitSucceeded: false },
  }
  const { submitting, submitSucceeded } = editImageForm

  useEffect(() => {
    const productId = match.params.product_id
    const extractedId = extractIdFromUrl(productId)
    dispatch(fetchAdminProduct(extractedId))
  }, [match.params.product_id])

  useEffect(() => {
    if (submitSucceeded) {
      dispatch(reset('editImage'))
      history.push(`/products/${adminProduct.slug}`)
    }
  }, [submitSucceeded, history])

  const handleBack = e => {
    e.preventDefault()
    history.goBack()
  }

  const getImageObject = () => {
    const imageObj =
      adminProduct !== null
        ? adminProduct.images.find(
            image =>
              extractProductImageIdFromProductImageUrl(image.id) === match.params.id
          )
        : null

    return imageObj
  }

  const handleUpdateProductImage = values => {
    if (values.alt === null) {
      values.alt = adminProduct.name
    }
    return dispatch(updateProductImage(values, match.params.product_id, match.params.id))
  }

  const imageObj = getImageObject()

  const previewImage = imageObj?.links[2]?.href
  const isDefaultImage = imageObj?.is_default_image

  return (
    <PageSection>
      <PageHeading
        heading={adminProduct ? adminProduct.name : ''}
        size='h2'
        onAction={handleBack}
        actionText='Cancel'
      />
      <RequestWrapper loading={submitting}>
        <ImageForm
          productName={adminProduct && adminProduct.name}
          handleReduxFormSubmit={handleReduxFormSubmit}
          productImageAction={handleUpdateProductImage}
          editImage
          previewImage={previewImage}
          isDefaultImage={isDefaultImage}
        />
      </RequestWrapper>
    </PageSection>
  )
}

EditImage = reduxForm({
  form: 'editImage',
  destroyOnUnmount: true,
})(EditImage)
export default EditImage
