import React from 'react'
import PropTypes from 'prop-types'

import Pill from 'lib/components/Pill'

import styles from './styles.scss'

const PillList = ({ items, handleClick }) => {
  return items && items.length > 0 ? (
    <ul className={styles.pillList}>
      {items.map((pill, i) => (
        <li key={`pill${i}`} className={styles.pill}>
          <Pill item={pill} handleClick={handleClick} />
        </li>
      ))}
    </ul>
  ) : null
}

PillList.propTypes = {
  items: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
}

export default PillList
