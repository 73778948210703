import React, { Component, Fragment } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { change, Field, getFormValues, reduxForm } from 'redux-form'
import { withRouter } from 'react-router-dom'
import moment from 'moment-timezone'

import { createDiscount } from 'actions/discountActions'
import { enqueueError, enqueueSuccess } from 'lib/components/GlobalNotifications'
import { required } from 'utils/formValidations'

import Button from 'lib/components/Button'
import DiscountRange from '../DiscountRange'
import FormField from 'lib/components/FormField'
import FormRow from 'lib/components/FormRow'
import ProductAutocomplete from 'components/ProductAutocomplete'
import RadioButtonGroup from 'lib/components/RadioButtonGroup'

import styles from './styles.scss'

const FORM_NAME = 'discount'

// RadioButtonGroup options
const AUTHORISATIONS_OPTIONS = [
  { value: 'automatic', label: 'Automatic' },
  { value: 'discretionary', label: 'Discretionary' },
]

const APPLIES_TO_OPTIONS = [
  { value: 'product', label: 'Product level' },
  { value: 'product_collection', label: 'Basket level' },
]

const PRICE_PATTERN_OPTIONS = [
  {
    value: 'unit:retail',
    label: 'Unit retail',
    description: 'Applied to the configured retail price',
  },
  {
    value: 'unit:cost',
    label: 'Unit cost',
    description: 'Applied to the configured cost price',
  },
  {
    value: 'unit:base:retail',
    label: 'Unit base retail',
    description:
      'Applied to the base retail price but not to any price additions/deductions for non-default sizes or marking options',
  },
  {
    value: 'unit:base:cost',
    label: 'Unit base cost',
    description:
      'Applied to the base cost price but not to any price additions/deductions for non-default sizes or marking options',
  },
]

const VALUE_METHOD_OPTIONS = [
  { value: 'percentage', label: 'Percentage' },
  { value: 'per_unit', label: 'Per unit' },
]

class CreateDiscountForm extends Component {
  constructor() {
    super()

    this.handleAddDiscountRange = this.handleAddDiscountRange.bind(this)
    this.handleChangeFormValueByPath = this.handleChangeFormValueByPath.bind(this)
    this.handleRemoveDiscountRange = this.handleRemoveDiscountRange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidUpdate(prevProps) {
    let { formValues } = this.props

    if (
      prevProps.formValues &&
      prevProps.formValues.valueMethod &&
      prevProps.formValues.valueMethod !== formValues.valueMethod
    ) {
      this.handleChangeFormValueByPath('discountRanges', [{ perUnitOption: 'between' }])
    }
  }

  handleChangeFormValueByPath(pathToField, value) {
    this.props.change(pathToField, value)
  }

  handleAddDiscountRange() {
    let {
      formValues: { discountRanges },
    } = this.props

    let newRanges = [...discountRanges, { perUnitOption: 'between' }]

    this.handleChangeFormValueByPath('discountRanges', newRanges)
  }

  handleRemoveDiscountRange(index) {
    let {
      formValues: { discountRanges },
    } = this.props

    let newRanges = [...discountRanges]
    newRanges.splice(index, 1)

    this.handleChangeFormValueByPath('discountRanges', newRanges)
  }

  handleSubmit() {
    let { createDiscount, enqueueError, enqueueSuccess, formValues } = this.props

    createDiscount(formValues)
      .then(() => {
        enqueueSuccess({ message: `${formValues.name} was successfully created.` })
        this.props.history.push('/discounts')
      })
      .catch(() => enqueueError({ message: 'We were unable to create this discount' }))
  }

  generateValidFromLabel() {
    if (!this.props.formValues || !this.props.formValues.validFrom) return 'From'
    let isToday = moment(this.props.formValues.validFrom).isSame(moment(), 'day')
    return isToday ? 'From (Creation time)' : 'From (00:00)'
  }

  generateValidToLabel() {
    if (!this.props.formValues || !this.props.formValues.validTo) return 'To'
    else return 'To (23:59)'
  }

  render() {
    let { formValues, invalid, submitting } = this.props

    let canSubmit =
      !(invalid || submitting) &&
      formValues &&
      formValues.qualifyingProducts &&
      formValues.qualifyingProducts.length

    return (
      <Fragment>
        <div className={styles.lineLength}>
          <FormRow label='Name'>
            <Field component={FormField} name='name' type='text' validate={required} />
          </FormRow>
          <FormRow
            label='Applied at'
            helpText='Select the level at which this discount is applied'
          >
            <Field
              component={RadioButtonGroup}
              name='appliesTo'
              options={APPLIES_TO_OPTIONS}
              validate={required}
            />
          </FormRow>
          <FormRow
            label='Price type'
            helpText='Select the price type this discount is applied to'
          >
            <Field
              component={RadioButtonGroup}
              fullWidth
              name='priceType'
              options={PRICE_PATTERN_OPTIONS}
              validate={required}
            />
          </FormRow>
          <FormRow
            label='Application type'
            helpText='Select whether this discount is applied automatically or at discretion'
          >
            <Field
              component={RadioButtonGroup}
              name='authorisations'
              options={AUTHORISATIONS_OPTIONS}
              validate={required}
            />
          </FormRow>
          <FormRow
            label='Discount value type'
            helpText='Select whether this discount is applied with a percentage or per unit value'
          >
            <Field
              component={RadioButtonGroup}
              name='valueMethod'
              options={VALUE_METHOD_OPTIONS}
              validate={required}
            />
          </FormRow>
        </div>
        {formValues &&
          formValues.discountRanges &&
          formValues.discountRanges.map((range, i) => {
            return (
              <DiscountRange
                formName={FORM_NAME}
                formValues={formValues}
                handleAddRange={this.handleAddDiscountRange}
                handleChangeFormValueByPath={this.handleChangeFormValueByPath}
                handleRemoveRange={this.handleRemoveDiscountRange}
                index={i}
                key={i}
              />
            )
          })}
        <div className={styles.lineLength}>
          <FormRow
            label='Qualifying products'
            helpText='Select the products that will be eligible for this discount'
          >
            <Field
              component={ProductAutocomplete}
              formName={FORM_NAME}
              formValues={formValues}
              name='qualifyingProducts'
            />
          </FormRow>
          <FormRow
            label='Active dates'
            helpText='Select the dates this discount will be active for'
          >
            <label>
              <small className={styles.helpText}>
                <b className={styles.bold}>Note:</b> Selecting today for the{' '}
                <b className={styles.bold}>From</b> value will set the time to the
                discount creation time. Selecting a future date will set the time for the{' '}
                <b className={styles.bold}>From</b> value to 12:00 AM on that date. The
                time for the <b className={styles.bold}>To</b> value is always set to
                11:59 PM.
              </small>
            </label>
            <Field
              component={FormField}
              inline
              label={this.generateValidFromLabel()}
              name='validFrom'
              type='date'
              validate={required}
            />
            <Field
              component={FormField}
              inline
              label={this.generateValidToLabel()}
              name='validTo'
              type='date'
              validate={required}
            />
          </FormRow>
          <Button
            className='buttonPrimary'
            disabled={!canSubmit}
            onClick={this.handleSubmit}
          >
            Create
          </Button>
        </div>
      </Fragment>
    )
  }
}

const mapDispatchToProps = {
  change,
  createDiscount,
  enqueueError,
  enqueueSuccess,
}

export { CreateDiscountForm }
export default compose(
  withRouter,
  connect(state => {
    return {
      formValues: getFormValues(FORM_NAME)(state),
    }
  }, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME,
  })
)(CreateDiscountForm)
