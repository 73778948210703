import React, { Component } from 'react'
import { connect } from 'react-redux'
import { href } from '@licnz/js-utils'

import { fetchSupplier, updateSupplier, clearSupplier } from 'actions/supplierActions'

import PageSection from 'lib/components/layout/PageSection'
import PageHeading from 'lib/components/headings/PageHeading'
import EditSupplierForm from './EditSupplierForm'

class EditSupplier extends Component {
  constructor(props) {
    super(props)
    this.handleBack = this.handleBack.bind(this)
    this.handleUpdate = this.handleUpdate.bind(this)
  }

  handleBack(e) {
    e.preventDefault()
    this.props.history.goBack()
  }

  handleUpdate(values) {
    this.props.updateSupplier(values)
    this.props.history.push('/suppliers')
  }

  componentDidMount() {
    let { id } = this.props.match.params
    this.props.fetchSupplier(id)
  }

  componentWillUnmount() {
    this.props.clearSupplier()
  }

  render() {
    let { supplier } = this.props
    if (supplier) {
      supplier.supplierCompanyLink = href({
        links: supplier.links,
        rel: 'supplier_company',
      })
    }
    return supplier ? (
      <PageSection>
        <PageHeading
          heading='Edit supplier details'
          size='h2'
          onAction={this.handleBack}
          actionText='Cancel'
        />
        <EditSupplierForm
          form='editSupplier'
          initialValues={supplier}
          onSubmit={this.handleUpdate}
        />
      </PageSection>
    ) : null
  }
}

const mapDispatchToProps = {
  clearSupplier,
  fetchSupplier,
  updateSupplier,
}

export { EditSupplier }
export default connect(state => {
  return {
    supplier: state.supplier.data,
  }
}, mapDispatchToProps)(EditSupplier)
