import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.scss'

function ServiceData({ schedulingStrategy }) {
  return schedulingStrategy ? (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Scheduling strategy</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <code>{schedulingStrategy}</code>
          </td>
        </tr>
      </tbody>
    </table>
  ) : (
    <div>No pricing data found</div>
  )
}

ServiceData.propTypes = {
  schedulingStrategy: PropTypes.string,
}

export default ServiceData
