import React from 'react'

import CreateDiscountForm from './CreateDiscountForm'
import PageSection from 'lib/components/layout/PageSection'
import PageHeading from 'lib/components/headings/PageHeading'

const CreateDiscount = ({ history }) => {
  const handleBack = () => {
    history.goBack()
  }

  const initialValues = {
    discountRanges: [{ perUnitOption: 'between' }],
    qualifyingProducts: [],
    validFrom: null,
    validTo: null,
  }

  return (
    <PageSection>
      <PageHeading heading='Create discount' onAction={handleBack} actionText='Cancel' />
      <CreateDiscountForm initialValues={initialValues} />
    </PageSection>
  )
}

export default CreateDiscount
