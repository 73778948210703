import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Button from 'lib/components/Button'
import FormFieldSet from 'lib/components/FormFieldSet'
import FormRow from 'lib/components/FormRow'
import RenderField from 'lib/components/RenderField'
import WysiwygField from 'lib/components/WysiwygField'

import { Field } from 'redux-form'
import { fetchOfferingTypes, fetchProductFamilies } from 'actions/productActions'

import { href } from '@licnz/js-utils'

const KeyDetailsForm = ({ onSubmit, errors, metadata, type }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    let offeringTypesLink = href({
      links: metadata?.links,
      rel: 'meta_offering_type',
    })
    if (offeringTypesLink) {
      dispatch(fetchOfferingTypes(offeringTypesLink))
    }
    let productFamiliesLink = href({
      links: metadata?.links,
      rel: 'meta_product_family',
    })
    if (productFamiliesLink) {
      dispatch(fetchProductFamilies(productFamiliesLink))
    }
  }, [metadata])

  const offeringTypes = useSelector((state) => state.offeringTypes.data)?.map(data => ({ 'value': data.id, 'label': data.title }))
  const productFamilies = useSelector((state) => state.productFamilies.data)?.map(data => ({ 'value': data.id, 'label': data.title }))

  const renderLabelOptionalIfComponent = (label) => {
    return type !== 'Component' ? label + '*' : label
  }

  return (
    <form>
      <h3>Key Details</h3>
      <FormFieldSet>
        <Field
          name='name'
          type='text'
          component={RenderField}
          label='Name*'
          helpText='The title for your product – viewable by your customers. Needs to be limited to 60 characters to display nicely on search engines'
          errors={errors}
        />
        <FormRow label={renderLabelOptionalIfComponent('Description')}>
          <Field name='description' component={WysiwygField} />
        </FormRow>
        <Field
          name='invoice_description'
          type='text'
          component={RenderField}
          label='Invoice Description*'
          helpText='The description to show in SAP limited to 40 characters'
          errors={errors}
        />
        <Field
          name='supplier_sku'
          type='text'
          component={RenderField}
          label={renderLabelOptionalIfComponent('Supplier SKU')}
          placeholder='e.g. 1003765'
          errors={errors}
        />
        <Field
          name='material_code'
          type='text'
          component={RenderField}
          label={renderLabelOptionalIfComponent('Material Code')}
          placeholder='e.g. 1005531'
          errors={errors}
        />
        <Field
          name='offering_type'
          type='select'
          component={RenderField}
          label={renderLabelOptionalIfComponent('Offering Type')}
          errors={errors}
          options={offeringTypes}
        />
        <Field
          name='family'
          type='select'
          component={RenderField}
          label={renderLabelOptionalIfComponent('Family')}
          errors={errors}
          options={productFamilies}
        />
      </FormFieldSet>
      <Button onClick={onSubmit} className='buttonSecondary'>
        Save
      </Button>
    </form>
  )
}

KeyDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  metadata: PropTypes.object,
}

export default KeyDetailsForm
