import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { orderErrorsTypes } from 'actions/types'

function fetchOrderErrors(orderLink) {
  let baseType = orderErrorsTypes.FETCH_ORDER_ERRORS
  let url = proxyUrl({ link: `${orderLink}/order_errors` })

  return get({ baseType, url })
}

function clearOrderErrors() {
  return { type: orderErrorsTypes.CLEAR_FETCH_ORDER_ERRORS }
}

export { fetchOrderErrors, clearOrderErrors }
