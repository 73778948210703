import React, { Component } from 'react'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import { connect } from 'react-redux'

import KeyDetailsForm from 'components/KeyDetailsForm'
import { fetchProductMetadata } from 'actions/productActions'
class KeyDetailsFormWrapper extends Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
  }

  componentDidMount() {
    let { fetchProductMetadata } = this.props
    fetchProductMetadata()
  }

  submit(values) {
    let { onSubmit, productSlug } = this.props
    let submitValues = { ...values, type: 'product', slug: productSlug }

    return onSubmit(submitValues)
  }

  render() {
    let { errors, handleSubmit, metadata, type } = this.props
    return <KeyDetailsForm onSubmit={handleSubmit(this.submit)} 
                           errors={errors} 
                           metadata={metadata} 
                           type={type} />
  }
}

const mapDispatchToProps = {
  fetchProductMetadata,
}

export { KeyDetailsFormWrapper }
export default compose(
reduxForm({
  form: 'editKeyDetails',
  enableReinitialize: true,
}),
connect(state => {
  return {
    metadata: state.metadata.data,
  }
}, mapDispatchToProps)
)(KeyDetailsFormWrapper)
