import { get, put, destroy, patch } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import {
  supplierThresholdDatesTypes,
  supplierOrderedProductsCapacitiesTypes,
} from 'actions/types'

// valid_from and valid_to must be in ISO 8601 format
const fetchSupplierThresholdDates = (supplierId, validFrom, validTo) => {
  const params = validTo
    ? { valid_from: validFrom, valid_to: validTo }
    : { valid_from: validFrom }

  const getSupplierCapacityDatesUrl = `${
    global.config.SUPPLIER_SERVICE_ENDPOINT
  }/suppliers/${encodeURIComponent(supplierId)}/capacity_thresholds`

  const baseType = supplierThresholdDatesTypes.FETCH_SUPPLIER_THRESHOLD_DATES

  const url = proxyUrl({
    link: getSupplierCapacityDatesUrl,
    params,
  })

  return get({ baseType, url })
}

const addCapacityThresholdDateRange = (supplierId, threshold, validFrom, validTo) => {
  const params = {
    threshold,
    valid_from: validFrom,
  }

  // add valid_to to params if it exits - if ommited then it will set the range to infinity
  if (validTo) {
    params.valid_to = validTo
  }

  const addCapacityThresholdDateRangeUrl = `${global.config.SUPPLIER_SERVICE_ENDPOINT}/suppliers/${supplierId}/capacity_thresholds`

  const baseType = supplierThresholdDatesTypes.ADD_CAPACITY_THRESHOLD_DATE_RANGE

  let body = params
  let url = proxyUrl({ link: addCapacityThresholdDateRangeUrl })

  return patch({ baseType, url, body })
}

// valid_from and valid_to must be in ISO 8601 format
const fetchSupplierOrderedProductsCapacities = (
  supplierUrl,
  validFrom,
  validTo,
  page,
  perPage
) => {
  const params = {
    _type: 'supplier_capacity',
    supplier_url: encodeURIComponent(supplierUrl),
    valid_from: validFrom,
    per_page: perPage,
    page,
  }

  if (validTo) {
    params.valid_to = validTo
  }

  const orderedProductsCapacitiesUrl = `${global.config.ORDER_SERVICE_ENDPOINT}/capacity/for_all_ordered_products`
  const baseType =
    supplierOrderedProductsCapacitiesTypes.FETCH_SUPPLIER_ORDERED_PRODUCTS_CAPACITIES
  const url = proxyUrl({
    link: orderedProductsCapacitiesUrl,
    params,
  })

  return get({ baseType, url })
}

const blockCapacityForDay = (deliveryDate, supplierUrl, id = null) => {
  let startOfDay = new Date(deliveryDate)
  startOfDay.setHours(0, 0, 0, 0)
  let endOfDay = new Date(deliveryDate)
  endOfDay.setHours(23, 59, 59, 999)

  const params = {
    supplier_url: supplierUrl,
    starts_at: startOfDay.toISOString(),
    ends_at: endOfDay.toISOString(),
  }

  // add id to params if it exits - this will update the record rather than creating it
  if (id) {
    params.id = id
  }

  const baseType = supplierOrderedProductsCapacitiesTypes.SET_BLOCKED_DELIVERY_DATE

  const setBlockedDeliveryDateUrl = `${global.config.ORDER_SERVICE_ENDPOINT}/excluded_delivery_time_ranges/update_or_create`

  const url = proxyUrl({
    link: setBlockedDeliveryDateUrl,
    params,
  })

  return put({ baseType, url })
}

const unBlockCapacityForDay = (supplierUrl, id) => {
  const params = {
    supplier_url: supplierUrl,
  }

  const baseType = supplierOrderedProductsCapacitiesTypes.SET_UNBLOCKED_DELIVERY_DATE

  const setUnblockedDeliveryDateUrl = `${global.config.ORDER_SERVICE_ENDPOINT}/excluded_delivery_time_ranges/${id}`

  const url = proxyUrl({
    link: setUnblockedDeliveryDateUrl,
    params,
  })

  return destroy({ baseType, url })
}

export {
  fetchSupplierThresholdDates,
  fetchSupplierOrderedProductsCapacities,
  blockCapacityForDay,
  unBlockCapacityForDay,
  addCapacityThresholdDateRange,
}
