import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'

import styles from './styles.scss'

/**
 * WysiwygField is a wrapped <Editor> component for use with Redux Form
 */
class WysiwygField extends Component {
  constructor(props) {
    super(props)
    const markdownString = this.props.input.value || ''
    const rawData = markdownToDraft(markdownString, { preserveNewlines: true })
    const contentState = convertFromRaw(rawData)
    const editorState = EditorState.createWithContent(contentState)
    this.state = {
      editorState: editorState,
    }

    this.handleEditorStateChange = this.handleEditorStateChange.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    let { editorState } = this.state
    let {
      input: { onChange },
    } = this.props

    if (prevState.editorState !== editorState) {
      const content = editorState.getCurrentContent()
      const rawObject = convertToRaw(content)
      const markdownString = draftToMarkdown(rawObject, { preserveNewLines: true })
      onChange(markdownString)
    }
  }

  handleEditorStateChange(editorState) {
    this.setState({ editorState })
  }

  render() {
    let {
      label,
      input: { name },
    } = this.props

    return (
      <div>
        {label && (
          <label htmlFor={name} className={styles.label}>
            {label}
          </label>
        )}
        <Editor
          id={name}
          editorClassName={styles.editor}
          wrapperClassName={styles.editorWrapper}
          editorState={this.state.editorState}
          onEditorStateChange={this.handleEditorStateChange}
          toolbar={{
            options: ['inline', 'blockType', 'list', 'remove', 'history'],
            inline: {
              options: ['bold'],
            },
            blockType: {
              options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
            },
            list: {
              options: ['unordered', 'ordered'],
            },
          }}
        />
      </div>
    )
  }
}

WysiwygField.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  label: PropTypes.string,
}

export default WysiwygField
