import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment-timezone'

import { enqueueError } from 'lib/components/GlobalNotifications'
import { fetchDiscounts } from 'actions/discountsActions'

import ButtonToolbar from 'lib/components/ButtonToolbar'
import LinkAsButton from 'lib/components/LinkAsButton'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import QueryList from 'lib/components/QueryList'
import RequestWrapper from '@licnz/react-request-wrapper'
import DiscountCard from './DiscountCard'
import FormField from 'lib/components/FormField'

class Discounts extends Component {
  constructor() {
    super()
    this.state = {
      includeInactiveDiscounts: false,
    }
    this.toggleIncludeInactiveDiscounts = this.toggleIncludeInactiveDiscounts.bind(this)
  }

  toggleIncludeInactiveDiscounts() {
    this.setState(prevState => {
      return { includeInactiveDiscounts: !prevState.includeInactiveDiscounts }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    let { enqueueError, fetchDiscounts } = this.props

    if (prevState.includeInactiveDiscounts !== this.state.includeInactiveDiscounts) {
      return fetchDiscounts({
        includeInactiveDiscounts: this.state.includeInactiveDiscounts,
      }).catch(() => {
        enqueueError({ message: 'We were unable to fetch any discounts.' })
      })
    }
  }

  componentDidMount() {
    let { enqueueError, fetchDiscounts } = this.props

    fetchDiscounts().catch(() => {
      enqueueError({ message: 'We were unable to fetch any discounts.' })
    })
  }

  /* NOTE: this function sorts the discounts by their `valid_from` attribute.
    If this attribute is equal, then it will sort alphabetically on their `name` attribute */
  sortDiscountsByStartDate(a, b) {
    if (moment(a.valid_from).isBefore(b.valid_from)) return 1
    if (moment(a.valid_from).isAfter(b.valid_from)) return -1

    return a.name.localeCompare(b.name)
  }

  render() {
    let { discounts, discountsRequestState } = this.props

    let sortedDiscounts = discounts.sort(this.sortDiscountsByStartDate)

    return (
      <PageSection>
        <PageHeading heading='Discounts' />
        <ButtonToolbar>
          <FormField
            name='includeInactiveDiscounts'
            label='Include inactive discounts'
            inline
            verticallyCentered
            type='checkbox'
            onChange={this.toggleIncludeInactiveDiscounts}
            checked={this.state.includeInactiveDiscounts}
          />
          <LinkAsButton to='/discounts/new' className='buttonSecondary'>
            Create discount
          </LinkAsButton>
        </ButtonToolbar>
        <RequestWrapper requestState={discountsRequestState}>
          <QueryList>
            {sortedDiscounts
              ? sortedDiscounts.map(discount => (
                  <DiscountCard key={discount.id} discount={discount} />
                ))
              : null}
          </QueryList>
        </RequestWrapper>
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  enqueueError,
  fetchDiscounts,
}

export { Discounts }
export default connect(state => {
  return {
    discounts: state.discounts.data,
    discountsRequestState: state.discounts.requestState,
  }
}, mapDispatchToProps)(Discounts)
