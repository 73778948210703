import React from 'react'
import { connect } from 'react-redux'

import client from '../../../config/apolloClient'

import { ApolloProvider } from 'react-apollo'
import Routes from 'components/Routes'

const Main = ({ idToken }) => {
  const apiUrl = `${global.config.SUPPLIER_SERVICE_ENDPOINT}/graphql`
  let apolloClient =
    idToken && idToken.token ? client({ apiUrl, idToken: idToken.token }) : null

  return apolloClient ? (
    <ApolloProvider client={apolloClient}>
      <Routes />
    </ApolloProvider>
  ) : (
    <Routes />
  )
}

export { Main }
export default connect(state => {
  return {
    idToken: state.idToken.data,
  }
})(Main)
