import React from 'react'
import PropTypes from 'prop-types'
import styles from './styles.scss'

function ImageContainer({ imageUrl, alt }) {
  return (
    <div className={styles.imageContainer}>
      {imageUrl && <img src={imageUrl} alt={alt} />}
    </div>
  )
}

ImageContainer.defaultProps = {
  alt: '',
}

ImageContainer.propTypes = {
  alt: PropTypes.string,
  imageUrl: PropTypes.string,
}

export default ImageContainer
