import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import queryString from 'query-string'

import { operationsSearchTypes } from 'actions/types'

function operationsSearch(params) {
  let baseType = operationsSearchTypes.SEARCH_OPERATIONS
  let queryParams = {
    limit: Number(params.per_page),
    offset: Number(params.page - 1) * Number(params.per_page),
    q: params.q,
    index_parties: true,
    filter_kind: 'operation',
    tags: params.tags,
  }
  let stringifiedParams = queryString.stringify(queryParams, { arrayFormat: 'bracket' })
  let link = `${global.config.RELATIONSHIP_SERVICE_ENDPOINT}/api/v1/parties/search?${stringifiedParams}`
  let url = proxyUrl({ link })

  return get({ url, baseType })
}

function clearOperations() {
  return { type: operationsSearchTypes.CLEAR_SEARCH_OPERATIONS }
}

export { clearOperations, operationsSearch }
