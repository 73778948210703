import React from 'react'
import PropTypes from 'prop-types'
import { href } from '@licnz/js-utils'
import styles from './styles.scss'

const ProductImage = ({ productImage }) => {
  let imageLink = productImage && href({ links: productImage.links, rel: 'self' })

  return (
    <div className={styles.imageContainer}>
      {productImage && (
        <img src={productImage && imageLink} alt={productImage && productImage.alt} />
      )}
    </div>
  )
}

ProductImage.propTypes = {
  productImage: PropTypes.object,
}

export default ProductImage
