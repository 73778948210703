import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import { IN_PROGRESS } from 'lib/selectors/requestStateSelectors'
import { productsSearch } from 'actions/productsSearchActions'

import Autocomplete, { ReduxFormAutocomplete } from 'lib/components/Autocomplete'

import styles from './styles.scss'

const FIELD_NAME = 'qualifyingProducts'

class ProductAutocomplete extends Component {
  constructor() {
    super()

    this.state = {
      error: null,
      submitting: false,
    }

    this.handleErrors = this.handleErrors.bind(this)
    this.handlePerformSearch = this.handlePerformSearch.bind(this)
    this.selector = this.selector.bind(this)
  }

  componentDidUpdate(prevProps) {
    let { productSearchRequestStatus } = this.props

    if (
      prevProps.productSearchRequestStatus !== productSearchRequestStatus &&
      productSearchRequestStatus !== IN_PROGRESS
    ) {
      this.setState({ submitting: false })
    }
  }

  handlePerformSearch(q) {
    let params = {
      page: 1,
      per_page: 10,
      offering_type: 'urn:lic:id:offering_type:good',
      statuses: ['approved'],
    }
    if (q && q !== '') params.q = q // product service rejects q if empty string

    this.setState({ submitting: true })
    this.props
      .productsSearch(params)
      .then(() => this.setState({ submitting: false }))
      .catch(() => this.setState({ submitting: false }))
  }

  handleErrors() {
    if (!this.props.formValues.qualifyingProducts.length) {
      this.setState({ error: 'Must be at least one product selected' })
    } else {
      this.setState({ error: null })
    }
  }

  selector() {
    return this.props.formValues && this.props.formValues.qualifyingProducts
  }

  render() {
    let { formName, products } = this.props

    let suggestions =
      products && products.map(product => ({ id: product.slug, name: product.title }))

    return (
      <Fragment>
        <ReduxFormAutocomplete
          formName={formName}
          fieldName={FIELD_NAME}
          selector={this.selector}
          render={(onChange, tags) => {
            const handleChange = () => {
              onChange(tags)
              this.handleErrors()
            }

            const handleValidate = newTag => {
              return tags && !tags.filter(tag => tag.id === newTag.id).length
            }

            return (
              <Autocomplete
                {...this.props}
                allowNew={false}
                onAdd={handleChange}
                onBlur={this.handleErrors}
                onChange={this.handlePerformSearch}
                onDelete={handleChange}
                onValidate={handleValidate}
                placeholder='Search products'
                suggestions={suggestions}
                suggestionsFilter={() => true}
                tags={tags}
              />
            )
          }}
        />
        {this.state.error ? (
          <span className={`${styles.validation} ${styles.error}`}>
            {this.state.error}
          </span>
        ) : null}
      </Fragment>
    )
  }
}

const mapDispatchToProps = {
  productsSearch,
}

export { ProductAutocomplete }
export default connect(state => {
  return {
    products: state.productsSearch.data.items,
    productsSearchRequestStatus: state.productsSearch.requestState,
  }
}, mapDispatchToProps)(ProductAutocomplete)
