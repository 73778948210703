import { Component } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'

class ReduxFormAutocomplete extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(tags) {
    let { change, formName, fieldName } = this.props

    change(formName, fieldName, tags)
  }

  render() {
    let { render, tags } = this.props
    return render(this.handleChange, tags)
  }
}

const mapStateToProps = (state, props) => {
  let { selector } = props
  return {
    tags: selector(state),
  }
}

const mapDispatchToProps = { change }

export { ReduxFormAutocomplete }
export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormAutocomplete)
