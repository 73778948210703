import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from 'lib/components/Button'
import ConfirmationModal from 'lib/components/ConfirmationModal'

class CancelOrderModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }

    this.toggleModal = this.toggleModal.bind(this)
    this.handleConfirm = this.handleConfirm.bind(this)
  }

  handleConfirm() {
    let { order, handleCancel } = this.props
    handleCancel(order)
    this.toggleModal()
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal })
  }

  render() {
    let { buttonText, buttonClassName, modalTextLines } = this.props

    return (
      <div>
        <Button onClick={this.toggleModal} className={buttonClassName}>
          {buttonText}
        </Button>
        <ConfirmationModal
          showModal={this.state.showModal}
          onConfirm={this.handleConfirm}
          onClose={this.toggleModal}
        >
        {modalTextLines.map(textLine =>
          <p>{ textLine }</p>
        )}
        </ConfirmationModal>
      </div>
    )
  }
}

CancelOrderModal.propTypes = {
  buttonClassName: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  modalTextLines: PropTypes.array.isRequired,
  order: PropTypes.object.isRequired,
  handleCancel: PropTypes.func.isRequired,
}

export default CancelOrderModal
