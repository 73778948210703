import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

import SectionHeading from 'lib/components/headings/SectionHeading'

function FormFieldSet({ name, subheading, children }) {
  function formRowSectionHeading() {
    return name ? <SectionHeading>{name}</SectionHeading> : null
  }

  function formRowSubHeading() {
    return subheading ? <span className={styles.subHeading}>{subheading}</span> : null
  }
  return (
    <fieldset className={styles.fieldSet}>
      {formRowSectionHeading()}
      {formRowSubHeading()}
      {children}
    </fieldset>
  )
}

FormFieldSet.propTypes = {
  name: PropTypes.string,
  subheading: PropTypes.string,
}

export default FormFieldSet
