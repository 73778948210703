import React from 'react'
import PropTypes from 'prop-types'
import { href } from '@licnz/js-utils'

import ImageContainer from 'lib/components/ImageContainer'
import { Link } from 'react-router-dom'

import styles from './styles.scss'

const OperationCard = ({ operation }) => {
  let selfLink = href({ links: operation.links, rel: 'self' })

  return (
    <Link
      to={{ pathname: `/operations/${encodeURIComponent(selfLink)}` }}
      className={`${styles.resultCard} ${styles.operationCard}`}
    >
      <ImageContainer imageUrl={'/images/operation-placeholder.svg'} />
      <dl>
        <dt>Name</dt>
        <dd className={styles.operationName}>{operation.title}</dd>
        <dt>Participant code</dt>
        <dd className={styles.participantCode}>{operation.snippet}</dd>
      </dl>
    </Link>
  )
}

OperationCard.propTypes = {
  operation: PropTypes.object.isRequired,
}

export { OperationCard }
export default OperationCard
