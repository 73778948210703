import React from 'react'

import FormRow from 'lib/components/FormRow'
import FieldError from 'lib/components/FieldError'
import Select from 'react-select'

import styles from './styles.scss'

const RenderField = ({
  target,
  input,
  label,
  type,
  helpText,
  placeholder,
  step,
  optional,
  inline,
  fieldSize,
  readOnly,
  errors,
  options,
  meta: { touched, error },
}) => {
  if (inline) {
    return (
      <div>
        <label>{label}</label>
        <input
          {...input}
          type={type}
          name={target}
          placeholder={placeholder}
          step={step}
          readOnly={readOnly}
        />
        {touched && error && <div className={styles.validationError}>{error}</div>}
      </div>
    )
  }
  if (type == 'textarea') {
    return (
      <FormRow label={label} helpText={helpText} optional={optional}>
        {touched && error && <div className={styles.validationError}>{error}</div>}
        {errors && errors[input.name] && <FieldError error={errors[input.name]} />}
        <textarea
          {...input}
          className={`${styles.inputField} ${styles[fieldSize]}`}
          name={target}
          placeholder={placeholder}
          readOnly={readOnly}
        />
      </FormRow>
    )
  } else if (type == 'select') {
    return (
      <FormRow label={label} helpText={helpText} optional={optional}>
        {touched && error && <div className={styles.validationError}>{error}</div>}
        {errors && errors[input.name] && <FieldError error={errors[input.name]} />}
        <Select
          {...input}
          className={`${styles.inputField} ${styles[fieldSize]}`}
          name={target}
          placeholder={placeholder}
          readOnly={readOnly}
          options={options}
          value={options.filter(opt => opt.value == input.value)}
          onChange={e => input.onChange(e.value)}
          onBlur={e => input.onBlur(e.value)}
        />
      </FormRow>
    )
  } else {
    return (
      <FormRow label={label} helpText={helpText} optional={optional}>
        {touched && error && <div className={styles.validationError}>{error}</div>}
        {errors && errors[input.name] && <FieldError error={errors[input.name]} />}
        <input
          {...input}
          className={styles.inputField}
          type={type}
          name={target}
          placeholder={placeholder}
          step={step}
          readOnly={readOnly}
        />
      </FormRow>
    )
  }
}

export default RenderField
