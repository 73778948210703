import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { discountTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload ? action.payload : INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [discountTypes.FETCH_DISCOUNT_FULFILLED]: dataHandler,
  [discountTypes.CLEAR_FETCH_DISCOUNT]: dataHandler,
})

const reducer = combineReducers({
  data: dataReducer,
  requestState: requestStateReducer(discountTypes.FETCH_DISCOUNT),
})

export default reducer
