import React from 'react'
import PropTypes from 'prop-types'

import Button from 'lib/components/Button'
import FileUploader from 'lib/components/FileUploader'
import FormFieldSet from 'lib/components/FormFieldSet'
import RenderField from 'lib/components/RenderField'
import { Field } from 'redux-form'

const ImageForm = ({
  handleReduxFormSubmit,
  productImageAction,
  productName,
  editImage = false,
  previewImage,
  isDefaultImage,
}) => {
  return (
    <form>
      <h3>Product Image</h3>
      {editImage && previewImage && <img src={previewImage} alt={productName} />}
      <FormFieldSet>
        <Field
          name='attachment'
          component={FileUploader}
          label='Upload image*'
          helpText='All images uploaded should be 16:9 dimension (e.g. 1280x720)'
        />
        <Field
          name='alt'
          type='text'
          component={RenderField}
          label='Image alternative text*'
          placeholder={productName}
          helpText='Used by screen readers and search engines'
        />
        {editImage && !isDefaultImage && isDefaultImage !== undefined && (
          <Field
            name='is_default_image'
            label='Default Image'
            component={RenderField}
            inline
            verticallyCentered
            type='checkbox'
          />
        )}
      </FormFieldSet>

      <Button
        type='submit'
        onClick={handleReduxFormSubmit(productImageAction)}
        className='buttonSecondary'
      >
        Save
      </Button>
    </form>
  )
}

ImageForm.propTypes = {
  handleReduxFormSubmit: PropTypes.func.isRequired,
  productImageAction: PropTypes.func.isRequired,
  productName: PropTypes.string,
}

export default ImageForm
