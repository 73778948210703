const processConfigurationNode = (queue, configuration = { colours: [], sizes: [] }) => {
  if (queue.length === 0) return configuration

  const existingColours = configuration.colours.map(colour => colour.id)
  const existingSizes = configuration.sizes.map(size => size.identity)

  let currentNode = queue.shift()

  if (currentNode.colours && currentNode.colours.length > 0) {
    for (const colour of currentNode.colours) {
      if (!existingColours.includes(colour.id)) {
        configuration.colours.push(colour)
      }
    }
  }

  if (currentNode.sizes && currentNode.sizes.length > 0) {
    for (const size of currentNode.sizes) {
      if (!existingSizes.includes(size.identity)) {
        configuration.sizes.push(size)
      }
    }
  }

  if (currentNode.children && currentNode.children.length > 0) {
    currentNode.children.forEach(child => queue.push(child))
  }
  return processConfigurationNode(queue, configuration)
}

const extractProductImageIdFromProductImageUrl = url => {
  return url.split('/').pop()
}

const extractProductIdFromProductImageUrl = url => {
  const urlParts = url.split('/')
  return urlParts[urlParts.length - 3]
}

export {
  processConfigurationNode,
  extractProductImageIdFromProductImageUrl,
  extractProductIdFromProductImageUrl
}
