import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { requestedProductTypes } from 'actions/types'

function fetchRequestedProduct(basketLink, requestedProductId) {
  let baseType = requestedProductTypes.FETCH_REQUESTED_PRODUCT
  let requestedProductLink = `${basketLink}/requested_products/${requestedProductId}`
  let url = proxyUrl({ link: requestedProductLink })

  return get({ baseType, url })
}

function clearRequestedProduct() {
  return { type: requestedProductTypes.CLEAR_FETCH_REQUESTED_PRODUCT }
}

export { fetchRequestedProduct, clearRequestedProduct }
