import React from 'react'
import PropTypes from 'prop-types'

import { getPartyName } from 'utils/party'

import DateTime from 'lib/components/DateTime'
import { Link } from 'react-router-dom'

import styles from './styles.scss'

const BasketCard = ({ basket }) => {
  const validBasketDetails = () => {
    return (
      <Link
        to={{
          pathname: `/baskets/${encodeURIComponent(basket.id)}`,
        }}
        className={`${styles.resultCard} ${styles.basketCard}`}
      >
        {basketData(basket)}
      </Link>
    )
  }

  const basketData = () => {
    return (
      <dl>
        <dt>Opened at</dt>
        <dd className={styles.openTime}>
          Opened on: <DateTime date={basket.timestamps['open_time']} />
        </dd>
        <dt>Initiating Party</dt>
        <dd>
          Initiating Party: {getPartyName(basket['urn:lic:predicate:order_initiator'])}
        </dd>
        <dt>Purchasing Party</dt>
        <dd>
          Purchasing Party: {getPartyName(basket['urn:lic:predicate:order_purchaser'])}
        </dd>
        <dt className={styles.basketCount}>Number of products in Basket</dt>
        <dd>{basket.requested_products_total_quantity} Products</dd>
      </dl>
    )
  }

  return basket ? validBasketDetails() : null
}

BasketCard.propTypes = {
  basket: PropTypes.object.isRequired,
}

export { BasketCard }
export default BasketCard
