import React, { Component } from 'react'
import { connect } from 'react-redux'

import { clearDiscount, deleteDiscount, fetchDiscount } from 'actions/discountActions'
import { enqueueError } from 'lib/components/GlobalNotifications'
import { getDiscountState } from 'utils/discountUtils'

import Button from 'lib/components/Button'
import LinkAsButton from 'lib/components/LinkAsButton'
import ConfirmationModal from 'lib/components/ConfirmationModal'
import DiscountDetail from './DiscountDetail'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import RequestWrapper from '@licnz/react-request-wrapper'

import styles from './styles.scss'

class Discount extends Component {
  constructor() {
    super()

    this.state = {
      showModal: false,
    }

    this.handleBack = this.handleBack.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.handleToggleModal = this.handleToggleModal.bind(this)
  }

  componentDidMount() {
    this.getDiscount()
  }

  componentWillUnmount() {
    this.props.clearDiscount()
  }

  handleBack() {
    this.props.history.push('/discounts')
  }

  getDiscount() {
    let {
      enqueueError,
      fetchDiscount,
      match: {
        params: { id },
      },
    } = this.props

    fetchDiscount(id).catch(() => {
      enqueueError({ message: 'We were unable to fetch this discount.' })
    })
  }

  handleDelete() {
    let {
      deleteDiscount,
      enqueueError,
      match: {
        params: { id },
      },
    } = this.props

    deleteDiscount(id)
      .then(() => {
        this.setState({ showModal: false })
        this.getDiscount()
      })
      .catch(() => {
        enqueueError({ message: 'We were unable to set this discount to expired.' })
      })
  }

  handleToggleModal() {
    this.setState({ showModal: !this.state.showModal })
  }

  renderButtonToolbar() {
    let { discount } = this.props

    let discountState = getDiscountState(discount.valid_from, discount.valid_to)
    return (
      <div className={styles.buttonToolbar}>
        { discountState === 'active' && discount.authorisations.includes('discretionary') ?
          (
            <LinkAsButton
              to={`/discounts/${discount.id}/orders_for_discretionary_discount`}
              className='buttonSecondary'
            >
              Discounted orders
            </LinkAsButton>
          ) : null
        }
        <LinkAsButton
          to={`/discounts/${discount.id}/edit`}
          className='buttonSecondary'
        >
          Edit
        </LinkAsButton>
        { discountState === 'active' ?  (
          <Button className='buttonDelete' onClick={this.handleToggleModal}>
            Set expired
          </Button>
          ) : null
        }
      </div>
    )
  }

  renderDeleteModal() {
    return (
      <ConfirmationModal
        onClose={this.handleToggleModal}
        onConfirm={this.handleDelete}
        showModal={this.state.showModal}
      >
        <p>Are you sure you want to set this discount to expired?</p>
      </ConfirmationModal>
    )
  }

  render() {
    let { discount, discountRequestStatus } = this.props

    return (
      <PageSection>
        <PageHeading
          heading={discount ? discount.name : 'Discount'}
          onAction={this.handleBack}
          actionText='Back'
        />
        {discount ? this.renderButtonToolbar() : null}
        <RequestWrapper requestState={discountRequestStatus}>
          {discount ? <DiscountDetail discount={discount} /> : null}
        </RequestWrapper>
        {this.renderDeleteModal()}
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  clearDiscount,
  deleteDiscount,
  enqueueError,
  fetchDiscount,
}

export { Discount }
export default connect(state => {
  return {
    discount: state.discount.data,
    discountRequestStatus: state.discount.requestState,
  }
}, mapDispatchToProps)(Discount)
