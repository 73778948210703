import React from 'react'
import { Field, reduxForm } from 'redux-form'

import Button from 'lib/components/Button'
import FormFieldSet from 'lib/components/FormFieldSet'
import FormRow from 'lib/components/FormRow'

let EditSupplierForm = props => {
  const { handleSubmit, onSubmit } = props
  const regions = ['North Island (NI)', 'South Island (SI)']
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormFieldSet>
        <FormRow label='Name'>
          <Field name='name' component='input' type='text' />
        </FormRow>
        <FormRow label='Company Link'>
          <Field name='supplierCompanyLink' component='input' type='text' />
        </FormRow>
        {
          // TODO: Change to a select with a list of regions
        }
        <FormRow label='Region'>
          <Field name='region' component='select'>
            <option value=''>Select a region...</option>
            {regions.map(region => (
              <option value={region} key={region}>
                {region}
              </option>
            ))}
          </Field>
        </FormRow>
        <Button type='submit' className='buttonPrimary'>
          Update details
        </Button>
      </FormFieldSet>
    </form>
  )
}

export { EditSupplierForm }
export default reduxForm({
  // a unique name for the form
  form: 'editSupplier',
})(EditSupplierForm)
