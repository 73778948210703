import { applyMiddleware, createStore, compose } from 'redux'

import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'

import reducer from 'reducers/index'

const middleware = applyMiddleware(promise, thunk)
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = (initialState = {}) => {
  return createStore(reducer, initialState, composeEnhancers(middleware))
}

export default store
