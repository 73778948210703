import { get, post } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { orderedProductTypes } from 'actions/types'

function cancelOrderedProduct({ cancellationLink }) {
  let baseType = orderedProductTypes.CANCEL_ORDERED_PRODUCT
  let url = proxyUrl({ link: cancellationLink })

  return post({ baseType, url })
}

function clearOrderedProduct() {
  return dispatch => {
    dispatch({ type: orderedProductTypes.CLEAR_FETCH_ORDERED_PRODUCT })
    dispatch({ type: orderedProductTypes.CLEAR_CANCEL_ORDERED_PRODUCT })
  }
}

function fetchOrderedProduct(orderedProductLink) {
  let baseType = orderedProductTypes.FETCH_ORDERED_PRODUCT
  let url = proxyUrl({ link: orderedProductLink })
  return get({ baseType, url })
}

export { cancelOrderedProduct, clearOrderedProduct, fetchOrderedProduct }
