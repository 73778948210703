import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { href } from '@licnz/js-utils'
import Button from 'lib/components/Button'
import LinkAsButton from 'lib/components/LinkAsButton'
import { enqueueSuccess } from 'lib/components/GlobalNotifications'
import { deleteProductImage } from 'actions/productImageActions'
import {
  extractProductImageIdFromProductImageUrl,
  extractProductIdFromProductImageUrl,
} from 'utils/productUtils'

import styles from './styles.scss'

const ImageData = ({ productImages }) => {
  const dispatch = useDispatch()

  const renderImage = image => {
    const imageUrl = href({ links: image.links, rel: 'self' })
    const imageId = encodeURIComponent(image.id)
    const productId = extractProductIdFromProductImageUrl(image.id)
    const productImageId = extractProductImageIdFromProductImageUrl(image.id)

    return (
      <div key={imageId}>
        <table className={styles.horizontalTable}>
          <tbody>
            <tr>
              <th>Image URL</th>
              <td>{imageUrl}</td>
            </tr>
            <tr>
              <th>Image alt</th>
              <td>
                {
                  /* TODO: if there is no image alt entered in the form, then this should probably not be set 'undefined' as a string */
                  image.alt !== 'undefined' ? image.alt : 'No image alt'
                }
                {global.FEATURE_FLAGS.UPDATE_AND_DELETE_PRODUCT_IMAGES && (
                  <span className={styles.btnUpdateDelete}>
                    {image.is_default_image && (
                      <span className={styles.defaultLabel}>Default</span>
                    )}
                    <LinkAsButton
                      className='buttonSecondary'
                      to={`/products/${productId}/images/${productImageId}/edit`}
                    >
                      Edit
                    </LinkAsButton>
                    {productImages.length > 1 ? (
                      <Button
                        className='buttonDelete'
                        onClick={() => {
                          dispatch(
                            enqueueSuccess({ message: 'Image was successfully deleted' })
                          )
                          dispatch(deleteProductImage(productId, productImageId))
                        }}
                      >
                        Delete
                      </Button>
                    ) : null}
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        {imageUrl && (
          <div className={styles.imageContainer}>
            <img src={imageUrl} alt={image.alt} />
          </div>
        )}
      </div>
    )
  }

  return productImages && productImages.length > 0 ? (
    <div>{productImages.map(image => renderImage(image))}</div>
  ) : (
    <div>No images found for this product</div>
  )
}

ImageData.propTypes = {
  productImages: PropTypes.array,
  productId: PropTypes.string,
  productStatus: PropTypes.string,
}

export default ImageData
