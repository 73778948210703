import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const TitleUnderline = ({ children, size }) => {
  if (size === 'h1')
    return (
      <h1 className={styles.titleUnderline}>
        <span className={styles.titleUnderlineSpan}>{children}</span>
      </h1>
    )
  if (size === 'h2')
    return (
      <h2 className={styles.titleUnderline}>
        <span className={styles.titleUnderlineSpan}>{children}</span>
      </h2>
    )
}

TitleUnderline.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
}

export default TitleUnderline
