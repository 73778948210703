import React from 'react'
import PropTypes from 'prop-types'

import { formatStatus, convertToSentenceCase } from 'utils/util'

import DateTime from 'lib/components/DateTime'
import SupplyProductsData from '../SupplyProductsData'
import ErrorDetail from 'components/ErrorDetail'

import styles from './styles.scss'

const SupplyOrderDetail = ({ supplyOrder, supplyOrderErrors }) => {
  let supplierName =
    supplyOrder &&
    supplyOrder.ordered_products &&
    supplyOrder.ordered_products[0].supplier_id.replace(/-/g, ' ')

  return (
    <div className={styles.section}>
      <table className={styles.table}>
        <tbody>
          <tr>
            <th className={styles.boldHeading}>Order reference</th>
            <td className={styles.pullRight}>{supplyOrder.order_reference}</td>
          </tr>
          <tr>
            <th className={styles.boldHeading}>Farming operation</th>
            <td className={styles.pullRight}>{supplyOrder.external_operation_code}</td>
          </tr>
          <tr>
            <th className={styles.boldHeading}>Status</th>
            <td className={styles.pullRight}>{formatStatus(supplyOrder.state)}</td>
          </tr>
          <tr>
            <th className={styles.boldHeading}>Delivery</th>
            <td className={styles.pullRight}>
              <DateTime date={supplyOrder.delivery_time} />
            </td>
          </tr>
          <tr>
            <th className={styles.boldHeading}>Last transition</th>
            <td className={styles.pullRight}>
              {supplyOrder.last_transition_time ? (
                <DateTime date={supplyOrder.last_transition_time} />
              ) : (
                'Not found'
              )}
            </td>
          </tr>
          <tr>
            <th className={styles.boldHeading}>Shipping time</th>
            <td className={styles.pullRight}>
              {supplyOrder.shipping_time ? (
                <DateTime date={supplyOrder.shipping_time} />
              ) : (
                'Not found'
              )}
            </td>
          </tr>
          <tr>
            <th className={styles.boldHeading}>Version</th>
            <td className={styles.pullRight}>{supplyOrder.version}</td>
          </tr>
          <tr>
            <th className={styles.boldHeading}>Created at</th>
            <td className={styles.pullRight}>
              <DateTime date={supplyOrder.created_at} />
            </td>
          </tr>
          <tr>
            <th className={styles.boldHeading}>Supplier</th>
            <td className={styles.pullRight}>{convertToSentenceCase(supplierName)}</td>
          </tr>
        </tbody>
      </table>
      {supplyOrderErrors && supplyOrderErrors.supply_order_errors.length ? (
        <ErrorDetail
          errorList={supplyOrderErrors.supply_order_errors}
          customStyles={{ top: 'unset' }}
        />
      ) : null}

      <SupplyProductsData
        key={supplyOrder.id}
        supplyProducts={supplyOrder.ordered_products}
      />
    </div>
  )
}

SupplyOrderDetail.propTypes = {
  supplyOrder: PropTypes.object.isRequired,
}

export default SupplyOrderDetail
