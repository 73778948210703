import React from 'react'
import PropTypes from 'prop-types'

import JSONTree from 'react-json-tree'

const SupplierQueryBuilderResults = ({ data }) => {
  return <JSONTree data={data} invertTheme={false} />
}

SupplierQueryBuilderResults.propTypes = {
  data: PropTypes.array.isRequired,
}

export default SupplierQueryBuilderResults
