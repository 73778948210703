// 'urn:lic:configreq:birth_id_range' = full birth id (ptpt, year, number)
// 'urn:lic:configreq:birth_id_seq_number_range' = just the number
const BIRTH_ID_RANGE_URN = 'urn:lic:configreq:birth_id_range'
const BIRTH_ID_SEQ_NUMBER_RANGE_URN = 'urn:lic:configreq:birth_id_seq_number_range'
const BIRTH_ID_URNS = [BIRTH_ID_RANGE_URN, BIRTH_ID_SEQ_NUMBER_RANGE_URN]
const MANAGEMENT_NUMBER_RANGE_URN = 'urn:lic:configreq:mgmt_number_range'
const MANAGEMENT_NUMBER_URNS = [MANAGEMENT_NUMBER_RANGE_URN]
const TAG_NUMBER_REQUIREMENT_URNS = [...BIRTH_ID_URNS, ...MANAGEMENT_NUMBER_URNS]
const PARTICIPANT_CODE_REQUIREMENT_URNS = ['urn:lic:configreq:birth_id_prefix']
const TEXT_POSITION_0_URN = 'urn:lic:configreq:text_position_0'
const TEXT_POSITION_1_URN = 'urn:lic:configreq:text_position_1'
const TEXT_POSITION_2_URN = 'urn:lic:configreq:text_position_2'
const TEXT_REQUIREMENT_URNS = [
  TEXT_POSITION_0_URN,
  TEXT_POSITION_1_URN,
  TEXT_POSITION_2_URN,
]
const NAIT_PRODUCTION_TYPE_URN = 'urn:lic:configreq:nait_production_type'
const NAIT_LOCATION_URN = 'urn:lic:configreq:nait_location'
const ANIMAL_COUNT_URN = 'urn:lic:configreq:animal_count'

export {
  BIRTH_ID_RANGE_URN,
  BIRTH_ID_SEQ_NUMBER_RANGE_URN,
  BIRTH_ID_URNS,
  MANAGEMENT_NUMBER_RANGE_URN,
  MANAGEMENT_NUMBER_URNS,
  TAG_NUMBER_REQUIREMENT_URNS,
  PARTICIPANT_CODE_REQUIREMENT_URNS,
  TEXT_POSITION_0_URN,
  TEXT_POSITION_1_URN,
  TEXT_POSITION_2_URN,
  TEXT_REQUIREMENT_URNS,
  NAIT_PRODUCTION_TYPE_URN,
  NAIT_LOCATION_URN,
  ANIMAL_COUNT_URN,
}
