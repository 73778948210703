import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import pluralize from 'pluralize'
import queryString from 'query-string'

import operationFilterConstants from 'constants/operationFilters'
import { clearOperations, operationsSearch } from 'actions/operationsSearchActions'
import { enqueueError } from 'lib/components/GlobalNotifications'

import DropdownFilters from 'lib/components/DropdownFilters'
import OperationsList from 'components/Operations/OperationsList'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import Pagination from '@licnz/react-pagination'
import RequestWrapper from '@licnz/react-request-wrapper'
import SearchBarContainer from 'lib/components/SearchBarContainer'

import styles from './styles.scss'

const initialState = {
  page: 1,
  per_page: 10,
}

class Operations extends Component {
  constructor() {
    super()

    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleClearFilters = this.handleClearFilters.bind(this)
    this.handleUpdateFilters = this.handleUpdateFilters.bind(this)
    this.updateUrl = this.updateUrl.bind(this)

    this.state = {
      ...initialState,
      operationFilters: [],
      operationSearchSubmitting: false,
    }
  }

  componentDidMount() {
    let { location, clearOperations } = this.props
    clearOperations()

    let params = queryString.parse(location.search)
    this.setState({ operationFilters: operationFilterConstants }, () => {
      if (!params.page || !params.per_page) {
        this.updateUrl(initialState)
      } else {
        this.performSearch()
      }
    })
  }

  componentDidUpdate(prevProps) {
    let {
      location: { search },
    } = this.props

    if (prevProps.location.search !== search) {
      let params = queryString.parse(search)

      if (!params.page || !params.per_page) {
        this.updateUrl(initialState)
      } else {
        this.performSearch()
      }
    }
  }

  handleChangePage(page) {
    this.updateUrl({ page })
  }

  handleClearFilters() {
    this.updateUrl(initialState, {})
  }

  handleUpdateFilters(filters) {
    let { location } = this.props
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let { perPage, createdAfter, createdBefore } = params

    let newFilters = {}
    filters.forEach(filter => {
      newFilters[filter.id] = filter.selectedOptions
    })

    this.updateUrl({ perPage, page: 1 }, { ...newFilters, createdAfter, createdBefore })
  }

  performSearch() {
    let { location, enqueueError, operationsSearch } = this.props
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let { operation_type, q, page, per_page } = params

    let filters = {}
    if (operation_type) filters['tags'] = operation_type
    params = { q, page: Number(page), per_page: Number(per_page), ...filters }
    this.setState({ operationSearchSubmitting: true })
    operationsSearch(params)
      .then(() => {
        this.setState({ operationSearchSubmitting: false })
      })
      .catch(err => {
        enqueueError('We were unable to complete your search', err)
        this.setState({ operationSearchSubmitting: false })
      })
  }

  updateUrl(updateParams, filters) {
    let { location, history } = this.props

    let currentParams = queryString.parse(location.search, { arrayFormat: 'bracket' })
    if (filters)
      currentParams = {
        page: currentParams.page,
        perPage: currentParams.perPage,
        ...filters,
      }
    let params = { ...currentParams, ...updateParams }

    history.push(
      `/operations?${queryString.stringify(params, { arrayFormat: 'bracket' })}`
    )
  }

  render() {
    let {
      location,
      operationsSearchRequestState,
      searchResults,
      totalResults,
    } = this.props
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let page = params.page ? Number(params.page) : 1
    let per_page = params.per_page ? Number(params.per_page) : 10
    let newFilters = this.state.operationFilters.map(filter => ({
      ...filter,
      selectedOptions: params[filter.id] || [],
    }))

    return (
      <PageSection>
        <PageHeading heading='Operations' />
        <SearchBarContainer
          placeholder='Search for an Operation...'
          q={params.q || ''}
          searchUrl='/operations'
          submitting={this.state.operationSearchSubmitting}
        />
        <DropdownFilters
          filters={newFilters}
          onChange={this.handleUpdateFilters}
          onClearFilters={this.handleClearFilters}
        />
        <RequestWrapper requestState={operationsSearchRequestState}>
          {searchResults && searchResults.length && (
            <Fragment>
              {totalResults ? (
                <p className={styles.totalResultsDescription}>
                  {pluralize('result', totalResults, true)} found
                </p>
              ) : null}
              <OperationsList operations={searchResults} />
              <Pagination
                border={false}
                currentPage={page}
                onChangePage={this.handleChangePage}
                pageSize={per_page}
                totalItems={totalResults}
              />
            </Fragment>
          )}
        </RequestWrapper>
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  clearOperations,
  enqueueError,
  operationsSearch,
}

export { Operations }
export default connect(state => {
  return {
    operationsSearchRequestState: state.operationsSearch.requestState,
    searchResults: state.operationsSearch.data.items,
    totalResults: state.operationsSearch.data.total_results,
  }
}, mapDispatchToProps)(Operations)
