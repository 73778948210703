import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import uniq from 'lodash/uniq'

import RatingVersionTable from '../RatingVersionTable'

const RatingDetail = ({ product, rules = [] }) => {
  const [versionOptions, setVersionOptions] = useState([])
  const [selectedVersion, setSelectedVersion] = useState('1')
  
  let ratingRules = rules.length > 0 ? rules : useSelector((state) => state.ratingRules.data)

  useEffect(() => {
    getVersions()
  }, [])

  let selectedRatingRules = ratingRules.filter(rule => rule.version === selectedVersion)

  const handleSelectVersion = (version) => {
    setSelectedVersion(version.value)
  }

  const getVersions = () => {
    let uniqueVersions = uniq(ratingRules.map(rule => rule.version))
    let sortedVersions = uniqueVersions.sort((a, b) => b - a)
    setVersionOptions(sortedVersions)
    setSelectedVersion(sortedVersions[0])
  }

  return ratingRules.length && versionOptions.length ? (
    <RatingVersionTable
      product={product}
      ratingRules={selectedRatingRules}
      version={selectedVersion}
      versionOptions={versionOptions}
      updateVersion={handleSelectVersion}
    />
  ) : (
    <div>No rating information found</div>
  )
}

RatingDetail.propTypes = {
  product: PropTypes.object.isRequired,
  rules: PropTypes.array
}

export default RatingDetail
