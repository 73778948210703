import { post } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'

import { ordersSearchTypes } from 'actions/types'

function ordersSearch(params) {
  let baseType = ordersSearchTypes.SEARCH_ORDERS
  let link = `${global.config.ORDER_SERVICE_ENDPOINT}/orders/search`
  let body = params
  let url = proxyUrl({ link })

  return post({ baseType, url, body })
}

function clearOrders() {
  return { type: ordersSearchTypes.CLEAR_SEARCH_ORDERS }
}

export { clearOrders, ordersSearch }
