import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { href } from '@licnz/js-utils'

import { ORDERED_PRODUCT_STATE_MAPPINGS } from 'constants/stateMappings'
import { convertToSentenceCase, extractIdFromUrl } from 'utils/util'
import { enqueueError } from 'lib/components/GlobalNotifications'
import { extractConfigurationOptions } from 'utils/orderedProductConfiguration'
import { fetchOrderedProduct, clearOrderedProduct } from 'actions/orderedProductActions'
import { fetchProduct, clearProduct } from 'actions/productActions'
import OrderedProductConfiguration from './OrderedProductConfiguration'
import OrderedProductDetails from './OrderedProductDetails'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import RequestWrapper from '@licnz/react-request-wrapper'
import SectionHeading from 'lib/components/headings/SectionHeading'

import styles from './styles.scss'

class OrderedProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      productComponents: [],
    }
    this.handleBack = this.handleBack.bind(this)
  }

  componentDidMount() {
    let { enqueueError, match, fetchOrderedProduct } = this.props
    let { id } = match.params
    fetchOrderedProduct(decodeURIComponent(id)).catch(() =>
      enqueueError({ message: 'We were unable to fetch this ordered product.' })
    )
  }

  componentDidUpdate(prevProps) {
    let { orderedProduct, fetchProduct } = this.props
    if (prevProps.orderedProduct !== orderedProduct) {
      let productComponents = extractConfigurationOptions(orderedProduct)
      this.setState({ productComponents })

      let productLink = href({ links: orderedProduct.links, rel: 'product' })
      let id = extractIdFromUrl(productLink)

      fetchProduct(id).catch(() =>
        enqueueError({ message: 'We were unable to fetch the corresponding product.' })
      )
    }
  }

  componentWillUnmount() {
    this.props.clearOrderedProduct()
    this.props.clearProduct()
  }

  handleBack() {
    this.props.history.goBack()
  }

  render() {
    let { orderedProduct, orderedProductRequestState, product } = this.props
    let { productComponents } = this.state

    const isCancelled =
      orderedProduct && orderedProduct.state === ORDERED_PRODUCT_STATE_MAPPINGS.CANCELLED

    return (
      <RequestWrapper requestState={orderedProductRequestState}>
        <PageSection>
          <PageHeading
            heading='Ordered Product'
            onAction={this.handleBack}
            actionText='Back'
          />
          {orderedProduct ? (
            <SectionHeading>
              {orderedProduct.name}
              {orderedProduct.birth_id_replacement ? (
                <svg className={styles.replacementIcon}>
                  {' '}
                  <title>Replacement Tags</title>{' '}
                  <use xlinkHref='/images/sprites.svg#icon_replacement_tag' />{' '}
                </svg>
              ) : null}
              {isCancelled ? (
                <span className={isCancelled ? styles.cancelledState : ''}>
                  {convertToSentenceCase(orderedProduct.state)}
                </span>
              ) : null}
            </SectionHeading>
          ) : null}
          {orderedProduct && product ? (
            <Fragment>
              <OrderedProductDetails orderedProduct={orderedProduct} />
              <OrderedProductConfiguration
                product={product}
                productComponents={productComponents}
              />
            </Fragment>
          ) : null}
        </PageSection>
      </RequestWrapper>
    )
  }
}

const mapDispatchToProps = {
  clearOrderedProduct,
  clearProduct,
  enqueueError,
  fetchOrderedProduct,
  fetchProduct,
}

export { OrderedProduct }
export default connect(state => {
  return {
    orderedProduct: state.orderedProduct.data,
    orderedProductRequestState: state.orderedProduct.requestState,
    product: state.product.data,
  }
}, mapDispatchToProps)(OrderedProduct)
