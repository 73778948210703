import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { renderError, renderInfo } from '@licnz/react-toast-notifications'
import { useMutation } from 'react-query'

import axios from 'lib/utils/axios'
import { proxyUrl } from 'lib/utils/proxy'

import ButtonAsLink from 'lib/components/ButtonAsLink'
import SectionHeading from 'lib/components/headings/SectionHeading'
import styles from './styles.scss'

const cancelBirthIdReservation = reservation => {
  const link = `${global.config.ANIMAL_TIMELINE_SERVICE_ENDPOINT}/birth-id-reservation/${reservation.id}/cancel`
  return axios
    .post(proxyUrl({ link: link }))
    .then(response => response.data)
    .catch(error => {
      throw error
    })
}

const BirthIdReservationsData = ({ birthIdReservations, history }) => {
  const [birthIdReservationCancellationMutation, { data, error }] = useMutation(
    cancelBirthIdReservation,
    {
      onSuccess: () => {
        history.push('/birth_id_reservations?currentPage=1')
      },
    }
  )

  const renderBirthIdReservationsList = reservation => {
    return reservation ? (
      <tr className={styles.bodyTableRow} key={reservation.id}>
        <td className={styles.pullLeft}>{reservation.participantCode}</td>
        <td className={styles.pushCenter}>{reservation.allocationYear}</td>
        <td className={styles.pushCenter}>{reservation.reservedRanges}</td>
        <td className={styles.pushCenter}>{reservation.status}</td>
        <td>
          <ButtonAsLink
            className={styles.option}
            disabled={reservation.status === 'Cancelled'}
            onClick={cancelReservation(reservation)}
          >
            Cancel
          </ButtonAsLink>
        </td>
      </tr>
    ) : null
  }

  const cancelReservation = reservation => () => {
    birthIdReservationCancellationMutation(reservation)
  }

  useEffect(() => {
    if (data) {
      renderInfo({
        message: `Successfully deleted a reservation for participant code ${data.participantCode} with birth year ${data.allocationYear}. Please refresh your page to see changes in reservation status.`,
      })
    }
  }, [data])

  useEffect(() => {
    if (error) {
      renderError({
        message: `An error occurred while trying to delete the reservation ${error}`,
      })
    }
  }, [error])

  return birthIdReservations ? (
    <div>
      <SectionHeading>Reservation Results</SectionHeading>
      <table className={styles.table}>
        <thead>
          <tr className={styles.headerTableRow}>
            <th className={styles.pullLeft}>Participant Code</th>
            <th className={styles.pullRight}>Birth Year</th>
            <th className={styles.pullRight}>Reserved Ranges</th>
            <th className={styles.pullRight}>Status</th>
            <th className={styles.pullRight}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {birthIdReservations.map(reservation =>
            renderBirthIdReservationsList(reservation)
          )}
        </tbody>
      </table>
    </div>
  ) : (
    <div>
      <SectionHeading>No birth id reservations</SectionHeading>
    </div>
  )
}

BirthIdReservationsData.propTypes = {
  birthIdReservations: PropTypes.array,
  history: PropTypes.object,
}

export default BirthIdReservationsData
