import React from 'react'
import Select from 'react-select'
import styles from './styles.scss';

const SelectField = ({
  wrapperClass = styles.select,
  labelClass = styles.label,
  selectClass = styles.search,
  label,
  name,
  options,
  onInputChange,
  onChange,
  value,
  enabled = true,
  isLoading = false,
}) => (
  <div className={wrapperClass}>
    <label className={labelClass}>{label}</label>
    <Select
      className={selectClass}
      isDisabled={!enabled}
      {...{
        isLoading,
        name,
        options,
        onInputChange,
        onChange,
        value
      }}
    />
  </div>
);

export default SelectField;
