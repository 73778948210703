import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { personTypes } from 'actions/types'

function fetchPerson(personPartyLink) {
  let url = proxyUrl({ link: personPartyLink })
  let baseType = personTypes.FETCH_PERSON

  return get({ url, baseType, extractPayload: response => response.data.party })
}

export { fetchPerson }
