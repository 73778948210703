import { get, post } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { supplyOrderTypes } from 'actions/types'

function fetchSupplyOrder(supplyOrderLink) {
  let baseType = supplyOrderTypes.FETCH_SUPPLY_ORDER
  let url = proxyUrl({ link: supplyOrderLink })

  return get({ baseType, url })
}

function clearSupplyOrder() {
  return { type: supplyOrderTypes.CLEAR_FETCH_SUPPLY_ORDER }
}

function manuallyCancelSupplyOrder(manuallyCancelSupplyOrderLink) {
  let baseType = supplyOrderTypes.MANUALLY_CANCEL_SUPPLY_ORDER
  let url = proxyUrl({ link: manuallyCancelSupplyOrderLink })
  let body = {}
  return post({ baseType, url, body })
}

export { fetchSupplyOrder, clearSupplyOrder, manuallyCancelSupplyOrder }
