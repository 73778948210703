import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import ReactModal from 'react-modal'

import axios from 'lib/utils/axios'
import fileDownload from 'js-file-download'
import moment from 'moment-timezone'
import pluralize from 'pluralize'
import queryString from 'query-string'

import { clearSupplyOrders, supplyOrdersSearch } from 'actions/supplyOrdersSearchActions'
import { enqueueError } from 'lib/components/GlobalNotifications'
import { extractIdFromUrl } from 'utils/util'
import { fetchSuppliers } from 'actions/suppliersActions'
import { productsSearch } from 'actions/productsSearchActions'
import { proxyUrl } from 'lib/utils/proxy'
import supplyOrderFilterConstants from 'constants/supplyOrderFilters'

import Button from 'lib/components/Button'
import DropdownFilters from 'lib/components/DropdownFilters'
import FormField from 'lib/components/FormField'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import Pagination from '@licnz/react-pagination'
import RequestWrapper from '@licnz/react-request-wrapper'
import SupplyOrdersData from 'components/SupplyOrdersData'

import styles from './styles.scss'

const customStyles = {
  overlay: {
    animation: 'modal-in 0.15s ease-out 0s backwards',
    backgroundColor: 'rgba(0, 30, 98, .5)',
    zIndex: '4',
  },
}
const initialState = {
  page: 1,
  perPage: 10,
}

const SupplyOrders = ({
  clearSupplyOrders,
  enqueueError,
  fetchSuppliers,
  history,
  location,
  productsSearch,
  searchResults,
  suppliers,
  supplyOrdersSearch,
  supplyOrdersSearchRequestState,
  totalResults,
}) => {
  const [supplyOrderFilters, setSupplyOrderFilters] = useState([])
  const [isExporting, setIsExporting] = useState(false)

  useEffect(() => {
    if (global.FEATURE_FLAGS.SEARCH_PRODUCT) {
      performProductsSearch()
    }
    clearSupplyOrders()
    if (suppliers.length) {
      setSupplyOrderFilters(dropdownFilterOptions(suppliers))
    } else {
      fetchSuppliers().catch(err => enqueueError({ message: err.message }))
    }
  }, [])

  useEffect(() => {
    if (suppliers.length) {
      setSupplyOrderFilters(dropdownFilterOptions(suppliers))
    }
  }, [suppliers])

  useEffect(() => {
    if (Object.keys(params).length == 0) {
      updateUrl(initialState)
    } else {
      performSearch()
      if (global.FEATURE_FLAGS.SEARCH_PRODUCT) {
        performProductsSearch()
      }
    }
  }, [supplyOrderFilters])

  useEffect(() => {
    if (location.search) {
      let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
      if (Object.keys(params).length == 0) {
        updateUrl(initialState)
      } else {
        performSearch()
        if (global.FEATURE_FLAGS.SEARCH_PRODUCT) {
          performProductsSearch()
        }
      }
    }
  }, [location])

  const handleBack = () => {
    history.goBack()
  }

  const performProductsSearch = () => {
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let { page, perPage, supplierId } = params

    let filters = {}

    const supplier = suppliers.find(supplier => supplier.id === supplierId)
    // replace used for Zee tags => zee-tags (with lowercase)
    filters['nested_categories'] = supplier
      ? [[supplier.name.replace(/ /, '-').toLowerCase()]]
      : []

    page = page ? Number(page) : initialState.page
    perPage = perPage ? Number(perPage) : initialState.perPage

    params = { page, per_page: perPage, ...filters }
    productsSearch(params).catch(() => {
      enqueueError({ message: 'We were unable to fetch any supplier specific products' })
    })
  }

  const handleChangeFieldValue = e => {
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let {
      perPage,
      createdAfter,
      createdBefore,
      deliveryAfter,
      deliveryBefore,
      farmingOperation,
      lastTransitionAfter,
      lastTransitionBefore,
      orderReference,
      purchaseOrderId,
      salesOrderNumber,
      status,
      supplierId,
    } = params

    updateUrl(
      { page: 1, perPage: Number(perPage ? Number(perPage) : initialState.perPage) },
      {
        createdAfter,
        createdBefore,
        deliveryAfter,
        deliveryBefore,
        farmingOperation,
        lastTransitionAfter,
        lastTransitionBefore,
        orderReference,
        purchaseOrderId,
        salesOrderNumber,
        status,
        supplierId,
        [e.target.name]: e.target.value,
      }
    )
  }

  const handleChangePage = page => {
    updateUrl({ page })
  }

  const handleClearFilters = () => {
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let { supplierId } = params
    updateUrl(initialState, { supplierId })
  }

  const handleExport = includeProducts => {
    let queryParams = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let { supplierId } = queryParams
    let supplierName = extractIdFromUrl(supplierId)

    let filters = buildSearchFilters()
    let params = { include_products: includeProducts, ...filters }
    let link = `${decodeURIComponent(supplierId)}/supply_orders/export_search_to_csv/`
    let dateTimeString = moment().toISOString().replace(/[-:.]/g, '')

    setIsExporting(true)

    axios
      .post(proxyUrl({ link }), params)
      .then(response => {
        const blob = new Blob([response.data], { type: 'text/csv' })
        fileDownload(blob, `${supplierName}-supply-orders-${dateTimeString}.csv`)
        setIsExporting(false)
      })
      .catch(err =>
        enqueueError({
          message: 'We were unable to export the supply orders. Error: ' + err,
        })
      )
  }

  const handleUpdateFilters = filters => {
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let {
      perPage,
      createdAfter,
      createdBefore,
      deliveryAfter,
      deliveryBefore,
      farmingOperation,
      lastTransitionAfter,
      lastTransitionBefore,
      orderReference,
      purchaseOrderId,
      salesOrderNumber,
    } = params

    let newFilters = {}
    filters.forEach(filter => {
      newFilters[filter.id] = filter.multiSelect
        ? filter.selectedOptions
        : filter.selectedOption
    })

    updateUrl(
      { page: 1, perPage: Number(perPage ? Number(perPage) : initialState.perPage) },
      {
        ...newFilters,
        createdAfter,
        createdBefore,
        deliveryAfter,
        deliveryBefore,
        farmingOperation,
        lastTransitionAfter,
        lastTransitionBefore,
        orderReference,
        purchaseOrderId,
        salesOrderNumber,
      }
    )
  }

  const performSearch = () => {
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let { page, perPage, supplierId } = params
    let filters = buildSearchFilters()

    page = page ? Number(page) : initialState.page
    perPage = perPage ? Number(perPage) : initialState.perPage
    params = { page, per_page: perPage, ...filters }

    supplyOrdersSearch(params, supplierId).catch(() => {
      enqueueError({ message: 'We were unable to fetch any supply orders' })
    })
  }

  const buildSearchFilters = () => {
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let {
      createdAfter,
      createdBefore,
      deliveryAfter,
      deliveryBefore,
      farmingOperation,
      lastTransitionAfter,
      lastTransitionBefore,
      orderReference,
      purchaseOrderId,
      salesOrderNumber,
      status,
    } = params

    let filters = {}
    if (status) filters['statuses'] = status
    if (farmingOperation) filters['external_operation_code'] = farmingOperation
    if (orderReference) filters['order_reference'] = orderReference
    if (purchaseOrderId) filters['purchase_order_id'] = purchaseOrderId
    if (salesOrderNumber) filters['sales_order_number'] = salesOrderNumber
    if (createdBefore) filters['created_at_before'] = moment(createdBefore)
    if (createdAfter) filters['created_at_after'] = moment(createdAfter)
    if (deliveryBefore) filters['delivery_time_before'] = moment(deliveryBefore)
    if (deliveryAfter) filters['delivery_time_after'] = moment(deliveryAfter)
    if (lastTransitionBefore)
      filters['last_transition_before'] = moment(lastTransitionBefore)
    if (lastTransitionAfter)
      filters['last_transition_after'] = moment(lastTransitionAfter)

    return filters
  }

  const updateUrl = (updateParams, filters) => {
    let currentParams = queryString.parse(location.search, { arrayFormat: 'bracket' })
    if (filters)
      currentParams = {
        page: currentParams.page,
        perPage: currentParams.perPage,
        ...filters,
      }
    let params = { ...currentParams, ...updateParams }

    history.push(
      `/supply_orders?${queryString.stringify(params, { arrayFormat: 'bracket' })}`
    )
  }

  const dropdownFilterOptions = suppliers => {
    let filterOptions = [...supplyOrderFilterConstants]

    let supplierOptions = suppliers.map(supplier => {
      return {
        id: supplier.id,
        name:
          supplier.name === 'Zee Tags'
            ? global.FEATURE_FLAGS.Z_TAGS_BRANDING
              ? 'Z Tags'
              : 'Zee Tags'
            : supplier.name,
      }
    })

    filterOptions.unshift({
      title: 'Supplier',
      id: 'supplierId',
      multiSelect: false,
      options: supplierOptions,
    })

    return filterOptions
  }

  let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
  let {
    createdAfter,
    createdBefore,
    deliveryAfter,
    deliveryBefore,
    farmingOperation,
    hideSearchControls,
    hideExportControls,
    lastTransitionAfter,
    lastTransitionBefore,
    orderReference,
    purchaseOrderId,
    salesOrderNumber,
    supplierId,
  } = params
  let page = params.page ? Number(params.page) : 1
  let perPage = params.perPage ? Number(params.perPage) : 10

  let newFilters = supplyOrderFilters.map(filter => {
    let newFilter = { ...filter }

    if (filter.multiSelect) {
      newFilter.selectedOptions = params[filter.id] || []
    } else {
      newFilter.selectedOption = params[filter.id]
    }

    return newFilter
  })

  return (
    <PageSection>
      <PageHeading
        heading='Supply orders'
        size='h2'
        onAction={handleBack}
        actionText='Back'
      />
      {hideSearchControls !== 'yes' ? (
        <Fragment>
          <DropdownFilters
            filters={newFilters}
            onChange={handleUpdateFilters}
            onClearFilters={handleClearFilters}
          />
          <FormField
            name='orderReference'
            label='Order reference'
            inline
            type='text'
            onChange={handleChangeFieldValue}
            value={orderReference}
          />
          <FormField
            name='farmingOperation'
            label='Farming operation'
            inline
            type='text'
            onChange={handleChangeFieldValue}
            value={farmingOperation}
          />
          {extractIdFromUrl(supplierId) === 'lic' ? (
            <FormField
              name='salesOrderNumber'
              label='SO number'
              inline
              type='text'
              onChange={handleChangeFieldValue}
              value={salesOrderNumber}
            />
          ) : (
            <FormField
              name='purchaseOrderId'
              label='PO number'
              inline
              type='text'
              onChange={handleChangeFieldValue}
              value={purchaseOrderId}
            />
          )}
          <FormField
            name='createdAfter'
            label='Created after (inclusive)'
            inline
            type='date'
            onChange={handleChangeFieldValue}
            value={createdAfter}
          />
          <FormField
            name='createdBefore'
            label='Created before (exclusive)'
            inline
            type='date'
            onChange={handleChangeFieldValue}
            value={createdBefore}
          />
          <FormField
            name='deliveryAfter'
            label='Delivery after (inclusive)'
            inline
            type='date'
            onChange={handleChangeFieldValue}
            value={deliveryAfter}
          />
          <FormField
            name='deliveryBefore'
            label='Delivery before (exclusive)'
            inline
            type='date'
            onChange={handleChangeFieldValue}
            value={deliveryBefore}
          />
          <FormField
            name='lastTransitionAfter'
            label='Last transition after (inclusive)'
            inline
            type='date'
            onChange={handleChangeFieldValue}
            value={lastTransitionAfter}
          />
          <FormField
            name='lastTransitionBefore'
            label='Last transition before (exclusive)'
            inline
            type='date'
            onChange={handleChangeFieldValue}
            value={lastTransitionBefore}
          />
        </Fragment>
      ) : null}
      {isExporting && (
        <ReactModal
          isOpen={isExporting}
          contentLabel='Exporting Supply Orders'
          className={styles.content}
          style={customStyles}
          ariaHideApp={false}
        >
          <p>The CSV file is currently being prepared......</p>
          <RequestWrapper loading />
        </ReactModal>
      )}
      <RequestWrapper requestState={supplyOrdersSearchRequestState}>
        {totalResults && hideExportControls !== 'yes' ? (
          <div>
            <p className={styles.totalResultsDescription}>
              {pluralize('result', totalResults, true)} found
            </p>
            <p className={styles.exportButton}>
              <Button className='buttonSecondary' onClick={() => handleExport(false)}>
                Export
              </Button>
              &nbsp;
              <Button className='buttonSecondary' onClick={() => handleExport(true)}>
                Export with products
              </Button>
            </p>
          </div>
        ) : null}
        {searchResults && searchResults.length && !isExporting ? (
          <Fragment>
            <SupplyOrdersData
              supplyOrders={searchResults}
              supplierName={extractIdFromUrl(supplierId)}
            />
            <Pagination
              border={false}
              currentPage={page}
              items={searchResults}
              onChangePage={handleChangePage}
              pageSize={perPage}
              totalItems={totalResults}
            />
          </Fragment>
        ) : (
          <h4>No supply orders found</h4>
        )}
      </RequestWrapper>
    </PageSection>
  )
}

const mapDispatchToProps = {
  clearSupplyOrders,
  enqueueError,
  fetchSuppliers,
  supplyOrdersSearch,
  productsSearch,
}

export { SupplyOrders }
export default connect(state => {
  return {
    searchResults: state.supplyOrdersSearch.data.items,
    suppliers: state.suppliers.data,
    supplyOrdersSearchRequestState: state.supplyOrdersSearch.requestState,
    totalResults: state.supplyOrdersSearch.data.total_results,
  }
}, mapDispatchToProps)(SupplyOrders)
