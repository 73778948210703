import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  hasPrivilegedOrderAccess,
  hasRestrictedOrderAccess,
} from 'selectors/userInfoSelectors'

import Gutters from 'lib/components/layout/Gutters'

import styles from './styles.scss'

const ContextBanner = ({
  downForMaintenance,
  hasPrivilegedOrderAccess,
  hasRestrictedOrderAccess,
  location: { pathname },
  organisation,
  redirect,
}) => {
  return (hasPrivilegedOrderAccess || hasRestrictedOrderAccess) &&
    !downForMaintenance &&
    pathname.startsWith('/orders') ? (
    <div className={`${styles.notificationsWrapper} ${styles.theme}`}>
      <div className={styles.notification}>
        <Gutters noGuttersSmall={false}>
          <div className={styles.notificationContent}>
            <div className={styles.richText}>
              {organisation ? (
                <p>
                  <span>
                    You are currently viewing orders for {organisation.metadata.name}.
                  </span>
                  {redirect && redirect.url && redirect.label && (
                    <span>
                      {' '}
                      <a href={redirect.url}>Back to {redirect.label}</a>
                    </span>
                  )}
                </p>
              ) : (
                <p>
                  {hasPrivilegedOrderAccess ? (
                    <span>You are currently viewing all orders.</span>
                  ) : hasRestrictedOrderAccess ? (
                    <span>You are currently viewing orders that you have initiated.</span>
                  ) : null}
                  <span>
                    {' '}
                    <a href={global.config.LIC_CUSTOMER_ENDPOINT}>Back to Customer</a>
                  </span>
                </p>
              )}
            </div>
          </div>
        </Gutters>
      </div>
    </div>
  ) : null
}

export { ContextBanner }
export default compose(
  withRouter,
  connect(state => {
    return {
      downForMaintenance: state.downForMaintenance,
      hasPrivilegedOrderAccess: hasPrivilegedOrderAccess(state),
      hasRestrictedOrderAccess: hasRestrictedOrderAccess(state),
      organisation: state.customerContext.organisation.data,
      redirect: state.redirect,
    }
  })
)(ContextBanner)
