import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

function StatusInfo({ id, label }) {
  function getRootClass() {
    return styles[`status_${id}`]
  }

  return <span className={getRootClass()}>{label}</span>
}

StatusInfo.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
}

export default StatusInfo
