import React from 'react'
import PropTypes from 'prop-types'

import OperationCard from '../OperationCard'
import QueryList from 'lib/components/QueryList'

function OperationsList({ operations }) {
  return (
    <QueryList>
      {operations.map(operation => (
        <OperationCard key={operation.id} operation={operation} />
      ))}
    </QueryList>
  )
}

OperationsList.propTypes = {
  operations: PropTypes.array.isRequired,
}

export default OperationsList
