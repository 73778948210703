import axios from 'lib/utils/axios'
import { proxyUrl } from 'lib/utils/proxy'

import { PRICE_PATTERN_MAPPINGS, OFFER_PRICE_URN_MAPPINGS } from 'constants/priceMappings'

const {
  offerPriceBaseRetail,
  offerPriceChargeNaitLevy,
  offerPriceDiscount,
  offerPriceDiscountRetail,
  offerPriceRetail,
  offerPriceSell,
  offerPriceUnitRetail,
  offerPriceUnitSell,
} = OFFER_PRICE_URN_MAPPINGS

const { discountRetail, price, retail, unitPrice, unitRetail } = PRICE_PATTERN_MAPPINGS

const transformRequestedProductNodeToRatingNode = (requestedProductNode, ratingNode) => {
  ratingNode.id = requestedProductNode.product_slug || requestedProductNode.id
  ratingNode.value = requestedProductNode.value || ''
  // Create a placeholder child on the ratingNode for each requestedProductNode child
  ratingNode.children =
    requestedProductNode.children.map(() => {
      return {}
    }) || []

  let { colour, size, configuration_properties, marking_option } = requestedProductNode
  if (colour) ratingNode.children.push({ id: colour.id, value: colour.value })
  if (size) ratingNode.children.push({ id: size.id, value: size.value })
  if (marking_option) {
    marking_option.configuration_properties.forEach(cp => {
      ratingNode.children.push({ id: cp.id, value: cp.value })
    })
  }
  if (configuration_properties && configuration_properties.length > 0) {
    configuration_properties.forEach(cp => {
      ratingNode.children.push({ id: cp.id, value: cp.value })
    })
  }
}

const transformRequestedProductToRatingTree = (
  requestedProductNodeQueue,
  ratingNodeQueue
) => {
  if (requestedProductNodeQueue.length === 0) return

  let requestedProductNode = requestedProductNodeQueue.shift()
  if (!requestedProductNode.children) requestedProductNode.children = [] // handle missing `children` key (stripped by the API)
  let ratingNode = ratingNodeQueue.shift()

  transformRequestedProductNodeToRatingNode(requestedProductNode, ratingNode)

  // The first <requestedProductNode.children.length> children of the ratingNode
  // are placeholder children to be 'filled in' in successive transformation passes
  requestedProductNode.children.forEach((child, i) => {
    requestedProductNodeQueue.push(child)
    ratingNodeQueue.push(ratingNode.children[i])
  })

  return transformRequestedProductToRatingTree(requestedProductNodeQueue, ratingNodeQueue)
}

const generateRatingTree = requestedProduct => {
  if (!requestedProduct)
    throw new Error(
      `Error transforming RequestedProduct to Rating: requestedProduct is ${requestedProduct}`
    )

  let ratingTree = {}
  transformRequestedProductToRatingTree([requestedProduct], [ratingTree])

  return ratingTree
}

const createBaseRating = tree => {
  let quantityValue = '1'
  const { charge, priceSell, baseRetail } = PRICE_PATTERN_MAPPINGS
  let pricePatterns = [charge, priceSell, baseRetail]
  let params = getRequestParams(quantityValue, 1, pricePatterns, tree)

  return axios
    .post(params.url, params.body)
    .then(response => {
      let baseRetailPrice = response.data.accumulated_prices.find(
        ap => ap._type === offerPriceBaseRetail
      )
      return baseRetailPrice.price
    }) // TODO: add proper error handling
    .catch(() => console.error('Error fetching pricing information')) // eslint-disable-line no-console
}

const getRequestParams = (
  quantityValue,
  discountQuantityMultiplier,
  pricePatterns,
  tree
) => {
  let link = `${global.config.RATING_SERVICE_ENDPOINT}/api/ratable_products/rate`
  let url = proxyUrl({ link })

  let body = {
    _type: 'ratable_product_tree',
    quantity_units: [
      {
        type: 'ea',
        value: quantityValue,
      },
      {
        type: 'discount_multiplier',
        value: discountQuantityMultiplier,
      },
    ],
    price_patterns: pricePatterns,
    rate_as_at_time: new Date().toISOString(),
    tree,
  }
  return { url, body }
}

const getBasketRating = requestedProducts => {
  const { charge, retail, discountRetail, priceSell } = PRICE_PATTERN_MAPPINGS
  let pricePatterns = [charge, retail, discountRetail, priceSell]

  let ratingUnits = requestedProducts.map(rp => {
    let discretionary_discounts = rp.discretionary_discounts
    let product_tree_identifier = rp.id
    let quantity_units = [
      {
        type: 'ea',
        value: rp.quantity.toString(),
      },
      {
        type: 'discount_multiplier',
        value: rp.discount_quantity_multiplier,
      },
    ]
    let rate_as_at_time = new Date().toISOString()
    let tree = generateRatingTree(rp)
    return {
      discretionary_discounts,
      product_tree_identifier,
      quantity_units,
      rate_as_at_time,
      tree,
    }
  })

  let params = getCollectionRequestParams(pricePatterns, ratingUnits)
  return axios
    .post(params.url, params.body)
    .then(response => {
      let aggregatePrices = response.data.aggregate_prices
      let collectedPrices = response.data.collected_prices

      let basketSummaryPrices = getBasketSummaryRating(aggregatePrices, collectedPrices)

      return { basketSummaryPrices }
    }) // TODO: add proper error handling
    .catch(() => console.error('Error fetching basket summary rating')) // eslint-disable-line no-console
}

const getBasketSummaryRating = (aggregatePrices, collectedPrices) => {
  let retailPriceObj =
    aggregatePrices.accumulated_prices &&
    aggregatePrices.accumulated_prices.find(ap => ap._type === offerPriceRetail)
  let sellPriceObj =
    aggregatePrices.offered_prices &&
    aggregatePrices.offered_prices.find(op => op._type === offerPriceSell)
  let naitChargeObj =
    aggregatePrices.accumulated_charges &&
    aggregatePrices.accumulated_charges.find(ac => ac._type === offerPriceChargeNaitLevy)
  let discounts = {}
  collectedPrices.forEach(cp => {
    cp.accumulated_prices &&
      cp.accumulated_prices.forEach(ap => {
        // NOTE: we need to handle both of these urns as the urn has changed, but we need to handle existing orders
        if (ap._type === offerPriceDiscountRetail || ap._type === offerPriceDiscount) {
          if (discounts[ap.rule]) {
            discounts[ap.rule].total += Number(ap.price)
          } else {
            discounts[ap.rule] = {
              name: ap.name.split(':')[0],
              total: Number(ap.price),
            }
          }
        }
      })
  })

  let retailPrice = retailPriceObj ? Number(retailPriceObj.price) : 0
  let sellPrice = sellPriceObj ? Number(sellPriceObj.price) : 0
  let naitCharge = naitChargeObj ? Number(naitChargeObj.price) : 0

  return { discounts, naitCharge, retailPrice, sellPrice }
}

// const getAccumulatedPrice = (tree, quantity = 1, discretionaryDiscounts = []) => {
//   const { charge, priceSell, baseRetail } = PRICE_PATTERN_MAPPINGS
//   let pricePatterns = [charge, priceSell, baseRetail]
//   let params = getRequestParams(quantity, pricePatterns, tree, discretionaryDiscounts)

//   return axios.post(params.url, params.body).then(response => {
//     let accumulatedPrice =
//       response.data.accumulated_prices &&
//       response.data.accumulated_prices.find(ap => ap._type === offerPriceBaseRetail)
//     return accumulatedPrice ? accumulatedPrice.price : null
//   })
// }

const getBasketProductPrices = (
  tree,
  quantity = 1,
  discretionaryDiscounts = [],
  discountQuantityMultiplier = 1
) => {
  let pricePatterns = [unitRetail, unitPrice, price, retail, discountRetail]
  let params = getRequestParams(
    quantity,
    discountQuantityMultiplier,
    pricePatterns,
    tree,
    discretionaryDiscounts
  )

  return axios
    .post(params.url, params.body)
    .then(response => {
      let unitPrice =
        response.data.unit_prices &&
        response.data.unit_prices.find(up => up._type === offerPriceUnitSell)
      let unitRetailPrice =
        response.data.unit_prices &&
        response.data.unit_prices.find(up => up._type === offerPriceUnitRetail)
      let accumulatedPrices = response.data.accumulated_prices

      return {
        unitPrice: unitPrice ? unitPrice.price : null,
        unitRetailPrice: unitRetailPrice ? unitRetailPrice.price : null,
        accumulatedPrices,
      }
    }) // TODO: add proper error handling
    .catch(() => console.error('Error fetching basket product prices')) // eslint-disable-line no-console
}

const getCollectionRequestParams = (pricePatterns, ratingUnits) => {
  let link = `${global.config.RATING_SERVICE_ENDPOINT}/api/ratable_products/rate_collection`
  let url = proxyUrl({ link })

  let body = {
    _type: 'ratable_product_tree',
    price_patterns: pricePatterns,
    rating_units: ratingUnits,
  }

  return { url, body }
}

export {
  generateRatingTree,
  createBaseRating,
  getBasketProductPrices,
  getBasketRating,
  getBasketSummaryRating,
  getCollectionRequestParams,
}
