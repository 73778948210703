import React, { Component } from 'react'
import { connect } from 'react-redux'
import { href } from '@licnz/js-utils'

import { enqueueError } from 'lib/components/GlobalNotifications'
import { fetchBasket, deleteBasket, clearBasket } from 'actions/basketActions'
import {
  fetchRequestedProducts,
  clearRequestedProducts,
} from 'actions/requestedProductsActions'
import { getBasketRating } from 'utils/rating'

import BasketDetail from './BasketDetail'
import Button from 'lib/components/Button'
import ButtonToolbar from 'lib/components/ButtonToolbar'
import DateTime from 'lib/components/DateTime'
import DeleteBasketModal from 'lib/components/DeleteBasketModal'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import RequestWrapper from '@licnz/react-request-wrapper'

class Basket extends Component {
  constructor(props) {
    super(props)

    this.handleBack = this.handleBack.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.state = {
      naitCharge: null,
      retailPrice: null,
      sellPrice: null,
      showModal: false,
      showDeleteButton: true,
    }
  }

  componentDidMount() {
    let {
      match: {
        params: { id },
      },
      basket,
      fetchBasket,
      fetchRequestedProducts,
    } = this.props

    if (basket) {
      let requestedProductsLink = href({ links: basket.links, rel: 'requested_products' })
      fetchRequestedProducts(requestedProductsLink)
    } else {
      fetchBasket(decodeURIComponent(id))
    }
  }

  componentDidUpdate(prevProps) {
    let { basket, fetchRequestedProducts, requestedProducts } = this.props

    if (prevProps.basket !== basket && basket) {
      let requestedProductsLink = href({ links: basket.links, rel: 'requested_products' })
      fetchRequestedProducts(requestedProductsLink)
    }

    if (prevProps.requestedProducts !== requestedProducts) {
      this.getRatingSummary()
    }
  }

  componentWillUnmount() {
    this.props.clearBasket()
    this.props.clearRequestedProducts()
  }

  getRatingSummary() {
    let { requestedProducts, enqueueError } = this.props

    getBasketRating(requestedProducts)
      .then(({ basketSummaryPrices }) => {
        let { retailPrice, sellPrice, naitCharge } = basketSummaryPrices
        this.setState({ retailPrice, sellPrice, naitCharge })
      })
      .catch(() => {
        enqueueError({ message: 'We were unable to fetch the basket pricing' })
      })
  }

  handleBack() {
    this.props.history.goBack()
  }

  handleDelete() {
    let { basket, deleteBasket, enqueueError, history } = this.props
    let basketLink = href({ links: basket.links, rel: 'self' })
    return deleteBasket(basketLink)
      .then(() => {
        this.setState({ showModal: false })
        this.setState({ showDeleteButton: false })
        history.goBack()
      })
      .catch(() => {
        enqueueError({ message: 'We were unable to delete the basket.' })
      })
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal })
  }

  renderButtonToolbar() {
    let { basket } = this.props

    return (
      basket &&
      this.state.showDeleteButton && (
        <ButtonToolbar>
          <Button className='buttonDelete' onClick={this.toggleModal}>
            Delete
          </Button>
        </ButtonToolbar>
      )
    )
  }

  renderDeleteModal() {
    let { basket } = this.props
    return (
      basket &&
      this.state.showDeleteButton && (
        <DeleteBasketModal
          itemCount={basket.requested_products_total_quantity}
          showModal={this.state.showModal}
          toggleModal={this.toggleModal}
          handleDelete={this.handleDelete}
        />
      )
    )
  }

  render() {
    let { basket, basketRequestState, requestedProducts } = this.props
    let createdTime = basket && basket.timestamps['open_time']
    let prices = {
      naitCharge: this.state.naitCharge,
      retailPrice: this.state.retailPrice,
      sellPrice: this.state.sellPrice,
    }

    return (
      <RequestWrapper requestState={basketRequestState}>
        <PageSection>
          <PageHeading
            heading='Basket'
            subheading={createdTime && <DateTime date={createdTime} />}
            onAction={this.handleBack}
            actionText='Back'
          />
          {this.renderButtonToolbar()}
          {basket ? (
            <BasketDetail
              basket={basket}
              requestedProducts={requestedProducts}
              prices={prices}
            />
          ) : null}
          {this.renderDeleteModal()}
        </PageSection>
      </RequestWrapper>
    )
  }
}

const mapDispatchToProps = {
  clearBasket,
  clearRequestedProducts,
  deleteBasket,
  enqueueError,
  fetchBasket,
  fetchRequestedProducts,
}

export { Basket }
export default connect(state => {
  return {
    basket: state.basket.data,
    basketRequestState: state.basket.requestState,
    requestedProducts: state.requestedProducts.data.items,
  }
}, mapDispatchToProps)(Basket)
