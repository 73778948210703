import React from 'react'

import { href } from '@licnz/js-utils'

import FieldError from 'lib/components/FieldError'
import ReactMarkdown from 'react-markdown/with-html'

import styles from './styles.scss'

const ProductData = ({ product, errors }) => {
  let supplierLink = product.links && href({ links: product.links, rel: 'supplier' })

  const allowNode = node => {
    if (node.type === 'html') {
      return node.value.includes('<p') || node.value.includes('</p>')
    } else return true
  }

  return (
    <table className={styles.horizontalTable}>
      <tbody>
        <tr>
          <th>Supplier URL</th>
          <td>{supplierLink}</td>
          <td>
            {errors && errors['supplier_url'] ? (
              <FieldError error={errors['supplier_url']} />
            ) : null}
          </td>
        </tr>
        <tr>
          <th>Offering type</th>
          <td>{product.offering_type}</td>
          <td>
            {errors && errors['offering_type'] ? (
              <FieldError error={errors['offering_type']} />
            ) : null}
          </td>
        </tr>
        <tr>
          <th>Name</th>
          <td>{product.name}</td>
          <td>
            {errors && errors['name'] ? <FieldError error={errors['name']} /> : null}
          </td>
        </tr>
        <tr>
          <th>Description</th>
          <td>
            <ReactMarkdown
              allowNode={allowNode}
              escapeHtml={false}
              source={product.description}
            />
          </td>
          <td>
            {errors && errors['description'] ? (
              <FieldError error={errors['description']} />
            ) : null}
          </td>
        </tr>
        <tr>
          <th>Invoice Description</th>
          <td>{product.invoice_description}</td>
          <td>
            {errors && errors['invoice_description'] ? (
              <FieldError error={errors['invoice_description']} />
            ) : null}
          </td>
        </tr>
        <tr>
          <th>Family</th>
          <td>{product.family}</td>
          <td>
            {errors && errors['family'] ? <FieldError error={errors['family']} /> : null}
          </td>
        </tr>
        <tr>
          <th>SKU</th>
          <td>{product.supplier_sku}</td>
          <td>
            {errors && errors['supplier_sku'] ? (
              <FieldError error={errors['supplier_sku']} />
            ) : null}
          </td>
        </tr>
        <tr>
          <th>Material code</th>
          <td>{product.material_code}</td>
          <td>
            {errors && errors['material_code'] ? (
              <FieldError error={errors['material_code']} />
            ) : null}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default ProductData
