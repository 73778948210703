import React from 'react'
import PropTypes from 'prop-types'
import urlPropType from 'url-prop-type'

import { findPrice } from 'utils/util'
import { OFFER_PRICE_URN_MAPPINGS } from 'constants/priceMappings'

import Currency from 'lib/components/Currency'
import OrderedProductRow from '../OrderedProductRow'
import { Link } from 'react-router-dom'

import styles from './styles.scss'

const OrderedProductsBySupplier = ({
  onCancelOrderedProduct,
  orderHasCancelledOrderedProducts,
  orderHasCustomerPurchaseOrderReferences,
  orderedProducts,
  orderReference,
  supplierLink,
  supplierName,
}) => {
  if (!orderedProducts.length) return null

  const supplierSubtotalPrice = orderedProducts
    .map(op => {
      let subtotalPrice = findPrice(
        op.offered_prices,
        OFFER_PRICE_URN_MAPPINGS.offerPriceSell
      )
      return subtotalPrice ? Number(subtotalPrice.price) : Number('0.0')
    })
    .reduce((a, b) => a + b, 0)

  let colSpanLength = global.FEATURE_FLAGS.REORDER_PRODUCT_FEATURE ? 8 : 7
  let supplyOrderColSpanLength = 3
  if (orderHasCustomerPurchaseOrderReferences) colSpanLength += 1
  if (orderHasCancelledOrderedProducts) colSpanLength += 1
  if (!global.FEATURE_FLAGS.CANCEL_ORDER_LINE_FEATURE) colSpanLength -= 1

  return (
    <tbody className={styles.tableBody}>
      <tr className={styles.tableRowSupplierName}>
        <th
          colSpan={colSpanLength - supplyOrderColSpanLength}
          className={styles.pullLeft}
        >
          {supplierName} products
        </th>
        <td colSpan={supplyOrderColSpanLength}>
          {supplierLink ? (
            <Link
              to={`/supply_orders?supplierId=${supplierLink}&orderReference=${orderReference}&hideSearchControls=yes&hideExportControls=yes`}
            >
              View {supplierName} supply orders
            </Link>
          ) : null}
        </td>
      </tr>
      {orderedProducts.map(op => {
        return (
          <OrderedProductRow
            key={op.id}
            onCancel={onCancelOrderedProduct}
            orderHasCancelledOrderedProducts={orderHasCancelledOrderedProducts}
            orderHasCustomerPurchaseOrderReferences={
              orderHasCustomerPurchaseOrderReferences
            }
            orderedProduct={op}
          />
        )
      })}
      <tr className={styles.tableRowSupplierSubtotal}>
        <th colSpan={colSpanLength - 1} className={styles.pullRight}>
          {supplierName} subtotal
        </th>
        <td className={styles.pullRight}>
          <Currency amount={supplierSubtotalPrice} />
        </td>
      </tr>
    </tbody>
  )
}

OrderedProductsBySupplier.propTypes = {
  onCancelOrderedProduct: PropTypes.func.isRequired,
  orderedProducts: PropTypes.array,
  supplierLink: urlPropType,
  supplierName: PropTypes.string.isRequired,
}

export default OrderedProductsBySupplier
