import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import organisationReducer from './organisationReducer'
import personReducer from './personReducer'
import { customerContextTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload ? { ...state, ...action.payload } : INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [customerContextTypes.CLEAR_CUSTOMER_CONTEXT]: dataHandler,
  [customerContextTypes.SET_CUSTOMER_CONTEXT]: dataHandler,
})

const reducer = combineReducers({
  data: dataReducer,
  organisation: organisationReducer,
  person: personReducer,
})

export default reducer
