export default [
  {
    title: 'Status',
    id: 'status',
    multiSelect: true,
    options: [
      { id: 'accepted', name: 'Accepted' },
      { id: 'cancelled', name: 'Cancelled' },
      { id: 'completed', name: 'Completed' },
      { id: 'error', name: 'Error' },
      { id: 'in_production', name: 'In production' },
      { id: 'new', name: 'New' },
      { id: 'ready_for_production', name: 'Ready for production' },
    ],
  },
]
