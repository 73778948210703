import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const BlockContent = ({ children }) => {
  return <div className={styles.blockContent}>{children}</div>
}

BlockContent.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BlockContent
