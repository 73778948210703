import React from 'react'

import PageSection from 'lib/components/layout/PageSection'
import PageHeading from 'lib/components/headings/PageHeading'

const DownForMaintenance = () => {
  return (
    <PageSection>
      <PageHeading heading='Down for Maintenance' />
      <p>Please try again later.</p>
    </PageSection>
  )
}

export default DownForMaintenance
