import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { ordersTypes } from 'actions/types'

function fetchOrders(params) {
  let baseType = ordersTypes.FETCH_ORDERS
  let ordersLink = `${global.config.ORDER_SERVICE_ENDPOINT}/orders`

  let url = proxyUrl({ link: ordersLink, params })

  return get({ baseType, url })
}

function fetchOrdersForProduct(params) {
  let baseType = ordersTypes.FETCH_ORDERS
  let ordersLink = `${global.config.ORDER_SERVICE_ENDPOINT}/orders/for_product`
  let url = proxyUrl({ link: ordersLink, params })

  return get({ baseType, url })
}

function fetchOrdersForDiscretionaryDiscount(params) {
  let baseType = ordersTypes.FETCH_ORDERS
  let ordersLink = `${global.config.ORDER_SERVICE_ENDPOINT}/orders/for_discretionary_discount`
  let url = proxyUrl({ link: ordersLink, params })

  return get({ baseType, url })
}

function clearOrders() {
  return { type: ordersTypes.CLEAR_FETCH_ORDERS }
}

export {
  fetchOrders,
  fetchOrdersForProduct,
  fetchOrdersForDiscretionaryDiscount,
  clearOrders,
}
