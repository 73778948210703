import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { href } from '@licnz/js-utils'
import { Link } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'
import axios from 'lib/utils/axios'
import { ORDERED_PRODUCT_STATE_MAPPINGS } from 'constants/stateMappings'
import { OFFER_PRICE_URN_MAPPINGS } from 'constants/priceMappings'
import { enqueueError } from 'lib/components/GlobalNotifications'
import { findPrice, stringAsDecimal, formatStatus, resourceMap } from 'utils/util'

import ProductImage from './ProductImage'
import ReorderProductButton from './ReorderProductButton'
import CancelOrderedProductModal from 'lib/components/CancelOrderedProductModal'
import Currency from 'lib/components/Currency'
import DateTime from 'lib/components/DateTime'
import styles from './styles.scss'

const OrderedProductRow = ({
  onCancel,
  orderedProduct,
  orderHasCustomerPurchaseOrderReferences,
  orderHasCancelledOrderedProducts,
}) => {
  const [product, setProduct] = useState(null)
  const productImage = product?.images?.find(image => image.is_default_image == true)

  useEffect(() => {
    let productLink =
      orderedProduct && href({ links: orderedProduct.links, rel: 'product' })
    const proxiedProductURL = `${
      global.config.UI_PROXY_ENDPOINT
    }/api/proxy/${encodeURIComponent(productLink)}`

    axios
      .get(proxiedProductURL)
      .then(response => {
        setProduct(response.data)
      })
      .catch(() =>
        enqueueError({
          message: `We were unable to fetch product from ${orderedProduct.name}`,
        })
      )
  }, [])

  const shopBasedCustomerContext = useSelector(state => {
    const {
      order: { data },
    } = state

    try {
      return {
        organisation_id: data['urn:lic:predicate:order_purchaser'].identifier.identifier,
        person_id: data['urn:lic:predicate:responsible_party'].identifier.identifier,
      }
    } catch (e) {
      return {}
    }
  })

  const orderProductLink = orderedProduct => {
    const orderedProductLinkMap = resourceMap(orderedProduct)
    const redirectURL = encodeURIComponent(
      `${global.config.SHOP_ASSISTANT_FRONTEND_ENDPOINT}/orders`
    )
    const redirectLabel = 'Shop Assistant'

    return `${global.config.SHOP_FRONTEND_ENDPOINT}/search/${encodeURIComponent(
      orderedProductLinkMap.product
    )}?organisation_id=${shopBasedCustomerContext.organisation_id}&person_id=${
      shopBasedCustomerContext.person_id
    }&redirect_label=${redirectLabel}&redirect_url=${redirectURL}`
  }

  const handleCancel = () => {
    onCancel(orderedProduct)
  }

  const renderCourierDetails = () => {
    const deliveryReference = orderedProduct.delivery_reference

    return deliveryReference ? (
      <Fragment>
        <dl data-testid='deliveryDetails' className={styles.listProductInfo}>
          {deliveryReference.coordinating_courier && (
            <Fragment>
              <dt>Courier name:</dt>
              <dd>{deliveryReference.coordinating_courier.name}</dd>
            </Fragment>
          )}
          <dt>Tracking number:</dt>
          <dd>{deliveryReference.tracking_reference}</dd>
          <dt>Shipped:</dt>
          <dd>
            <DateTime date={deliveryReference.shipping_time} />
          </dd>
        </dl>
        <a href={deliveryReference.tracking_url}>View tracking details</a>
      </Fragment>
    ) : null
  }

  const renderCancellationDetails = () => {
    let canceller = orderedProduct['urn:lic:predicate:ordered_product_canceller']
    let cancelledAt = orderedProduct.timestamps.cancelled_time

    return orderedProduct.state === ORDERED_PRODUCT_STATE_MAPPINGS.CANCELLED ? (
      <Fragment>
        <p className={styles.canceller}>
          {canceller && canceller.name ? canceller.name : 'Canceller not found'}
        </p>
        <p>
          {cancelledAt ? (
            <DateTime date={cancelledAt} format='DD/MM/YY, h:mm:ss A' />
          ) : (
            'Cancelled time not found'
          )}
        </p>
      </Fragment>
    ) : (
      'N/A'
    )
  }

  const renderCancelModal = () => {
    let cancellationLink = href({
      links: orderedProduct.links,
      rel: 'cancellation',
    })

    return cancellationLink ? (
      <CancelOrderedProductModal
        buttonClassName='buttonLink'
        buttonText='Cancel'
        handleCancel={handleCancel}
      />
    ) : null
  }

  const getItemPrice = priceUrn => {
    let foundPrice = findPrice(orderedProduct.offered_prices, priceUrn)
    return foundPrice ? stringAsDecimal(foundPrice.price) : stringAsDecimal('0.00')
  }

  const renderPricingDetails = () => {
    const {
      offerPriceDiscountRetail,
      offerPriceDiscount,
      offerPriceRetail,
      offerPriceSell,
    } = OFFER_PRICE_URN_MAPPINGS

    // NOTE: we need to handle both of these urns as the urn has changed, but we need to handle existing orders
    let discount = orderedProduct
      ? findPrice(orderedProduct.offered_prices, offerPriceDiscountRetail) ||
        findPrice(orderedProduct.offered_prices, offerPriceDiscount)
      : null

    return orderedProduct.state === ORDERED_PRODUCT_STATE_MAPPINGS.CANCELLED ? (
      <s className={styles.strikedPrice}>
        <Currency id='subtotalRetailPrice' amount={getItemPrice(offerPriceSell)} />
      </s>
    ) : (
      <Fragment>
        {discount ? (
          <s className={styles.strikedPrice}>
            <Currency id='subtotalRetailPrice' amount={getItemPrice(offerPriceRetail)} />
          </s>
        ) : null}
        <Currency amount={getItemPrice(offerPriceSell)} />
      </Fragment>
    )
  }

  let orderLink = href({ links: orderedProduct.links, rel: 'up' })

  let poNumberText = orderedProduct.customer_purchase_order_number || 'Not entered'

  let isCancelled = orderedProduct.state === ORDERED_PRODUCT_STATE_MAPPINGS.CANCELLED

  let rowClassName = isCancelled
    ? `${styles.tableRowProduct} ${styles.cancelledOrderedProductRow}`
    : styles.tableRowProduct

  return (
    <tr data-testid='productRow' className={rowClassName}>
      <td className={`${styles.tableCellProductInfo} ${styles.pullLeft}`}>
        <Link
          className={styles.linkProductImage}
          to={`/orders/${encodeURIComponent(
            orderLink
          )}/orderedProduct/${encodeURIComponent(orderedProduct.id)}`}
        >
          <ProductImage productImage={productImage} />
        </Link>
        <div className={styles.productInfo}>
          <Link
            className={styles.linkProductTitle}
            to={`/orders/${encodeURIComponent(
              orderLink
            )}/orderedProduct/${encodeURIComponent(orderedProduct.id)}`}
          >
            {orderedProduct.name}
          </Link>
          {renderCourierDetails()}
        </div>
      </td>
      <td className={styles.pullLeft}>
        <span className={styles.state}>{formatStatus(orderedProduct.state)}</span>
      </td>
      <td className={styles.pullRight}>{orderedProduct.quantity}</td>
      <td className={styles.pullRight}>{renderPricingDetails()}</td>
      <td className={styles.pullLeft}>
        {orderedProduct.birth_id_replacement ? 'Yes' : 'No'}
      </td>
      <td className={styles.pullLeft}>{orderedProduct.purchase_order_number}</td>
      {orderHasCustomerPurchaseOrderReferences ? (
        <td className={styles.pullLeft}>{poNumberText}</td>
      ) : null}
      {orderHasCancelledOrderedProducts ? (
        <td className={styles.pullLeft} colSpan={isCancelled ? 2 : 1}>
          {renderCancellationDetails()}
        </td>
      ) : null}
      {global.FEATURE_FLAGS.CANCEL_ORDER_LINE_FEATURE && !isCancelled ? (
        <td className={styles.pullRight}>{renderCancelModal()}</td>
      ) : null}
      {global.FEATURE_FLAGS.REORDER_PRODUCT_FEATURE && (
        <td data-testid='reorderButton'>
          <div>
            <ReorderProductButton
              isLoading={isEmpty(product)}
              linkToShop={orderProductLink(orderedProduct)}
              isAvailableForPurchase={product?.available_for_purchase}
            />
          </div>
        </td>
      )}
    </tr>
  )
}

OrderedProductRow.propTypes = {
  onCancel: PropTypes.func.isRequired,
  orderedProduct: PropTypes.object.isRequired,
}

const mapDispatchToProps = {
  enqueueError,
}

export { OrderedProductRow }
export default connect(undefined, mapDispatchToProps)(OrderedProductRow)
