import { post } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { supplyOrdersSearchTypes } from 'actions/types'

function supplyOrdersSearch(params, supplierId) {
  let baseType = supplyOrdersSearchTypes.SEARCH_SUPPLY_ORDERS
  let link = `${supplierId}/supply_orders/search`
  let body = params
  let url = proxyUrl({ link })

  return post({ baseType, url, body })
}

function clearSupplyOrders() {
  return { type: supplyOrdersSearchTypes.CLEAR_SEARCH_SUPPLY_ORDERS }
}

export { clearSupplyOrders, supplyOrdersSearch }
