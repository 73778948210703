import gql from 'graphql-tag'

const GET_EVENTS_BY_CORRELATION_ID = gql`
  query EventsByCorrelationId(
    $sortOrder: String
    $reverseOrder: Boolean
    $correlationId: String
  ) {
    Event(
      sort_order: $sortOrder
      reverse_order: $reverseOrder
      correlation_id: $correlationId
    ) {
      id
      correlation_id
      event_type
      created_at
      updated_at
      event_json
      current_state
      event_errors {
        created_at
        failed_action
        error_json
        http_status_code
      }
    }
  }
`

const GET_EVENTS_BY_CURRENT_STATE = gql`
  query EventsByCurrentState(
    $sortOrder: String
    $reverseOrder: Boolean
    $supplierEventCurrentState: String
    $page: Int
  ) {
    Event(
      sort_order: $sortOrder
      reverse_order: $reverseOrder
      current_state: $supplierEventCurrentState
      page: $page
    ) {
      id
      correlation_id
      event_type
      created_at
      updated_at
      event_json
      current_state
      event_errors {
        created_at
        failed_action
        error_json
        http_status_code
      }
    }
  }
`

const GET_SUPPLY_ORDERS_BY_CURRENT_STATE = gql`
  query SupplyOrdersByCurrentState(
    $sortOrder: String
    $reverseOrder: Boolean
    $supplyOrderCurrentState: String
    $page: Int
  ) {
    SupplyOrder(
      sort_order: $sortOrder
      reverse_order: $reverseOrder
      current_state: $supplyOrderCurrentState
      page: $page
    ) {
      id
      order_link
      order_reference
      sales_order_number
      current_state
      created_at
      updated_at
      supply_products {
        ordered_product_link
        coordinating_courier
        shipping_time
      }
      supply_order_transitions {
        to_state
        created_at
      }
      supply_order_errors {
        created_at
        failed_action
        error_json
        http_status_code
      }
      events {
        id
        correlation_id
        event_type
        created_at
        event_json
      }
    }
  }
`

const GET_SUPPLY_ORDERS_BY_ORDER_REFERENCE = gql`
  query SupplyOrdersByOrderReference(
    $sortOrder: String
    $reverseOrder: Boolean
    $orderReference: String
  ) {
    SupplyOrder(
      sort_order: $sortOrder
      reverse_order: $reverseOrder
      order_reference: $orderReference
    ) {
      id
      order_link
      order_reference
      sales_order_number
      current_state
      created_at
      updated_at
      supply_products {
        ordered_product_link
        coordinating_courier
        shipping_time
      }
      supply_order_transitions {
        to_state
        created_at
      }
      supply_order_errors {
        created_at
        failed_action
        error_json
        http_status_code
      }
      events {
        id
        correlation_id
        event_type
        created_at
        event_json
      }
    }
  }
`

const GET_SUPPLY_ORDERS_BY_SALES_ORDER_NUMBER = gql`
  query SupplyOrdersBySalesOrderNumber(
    $sortOrder: String
    $reverseOrder: Boolean
    $salesOrderNumber: String
  ) {
    SupplyOrder(
      sort_order: $sortOrder
      reverse_order: $reverseOrder
      sales_order_number: $salesOrderNumber
    ) {
      id
      order_link
      order_reference
      sales_order_number
      current_state
      created_at
      updated_at
      supply_products {
        ordered_product_link
        coordinating_courier
        shipping_time
      }
      supply_order_transitions {
        to_state
        created_at
      }
      supply_order_errors {
        created_at
        failed_action
        error_json
        http_status_code
      }
      events {
        id
        correlation_id
        event_type
        created_at
        event_json
      }
    }
  }
`

export {
  GET_EVENTS_BY_CORRELATION_ID,
  GET_EVENTS_BY_CURRENT_STATE,
  GET_SUPPLY_ORDERS_BY_CURRENT_STATE,
  GET_SUPPLY_ORDERS_BY_ORDER_REFERENCE,
  GET_SUPPLY_ORDERS_BY_SALES_ORDER_NUMBER,
}
