import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import LinkAsButton from 'lib/components/LinkAsButton'
import AvailableSizesData from '../AvailableSizesData'
import CategorisationsData from '../CategorisationsData'
import ChildrenData from '../ChildrenData'
import FieldError from 'lib/components/FieldError'
import ImageData from '../ImageData'
import MarkingOptionsData from '../MarkingOptionsData'
import ProductData from '../ProductData'
import SectionHeading from 'lib/components/headings/SectionHeading'
import ServiceData from '../ServiceData'
import { fetchAdminProduct } from 'actions/productActions'
import { productImageTypes } from 'actions/types'
import { enqueueError } from 'lib/components/GlobalNotifications'
import { extractIdFromUrl } from 'utils/util'

import styles from './styles.scss'

const ProductAttributes = ({ product, errors }) => {
  const dispatch = useDispatch()

  const deleteProductImage = useSelector(
    state => state.productImage.deleteProductImage
  ) ?? {
    fetched: false,
  }
  const {
    fetched: deletedImageSuccessfully,
    errors: deleteImageError,
  } = deleteProductImage

  if (deleteImageError) {
    dispatch(
      enqueueError({
        message: 'We were unable to delete that image successfully',
        detail: deleteImageError,
      })
    )
  }

  useEffect(() => {
    if (deletedImageSuccessfully) {
      const extractedId = extractIdFromUrl(product.id)
      // Refetch product image data when delete action has succesfully been dispatched
      dispatch(fetchAdminProduct(extractedId))
      dispatch({ type: productImageTypes.CLEAR_DELETE_PRODUCT_IMAGE })
    }
  }, [deletedImageSuccessfully, product.id])

  const productData = () => {
    return (
      <div className={styles.section}>
        <SectionHeading
          button={
            <LinkAsButton
              to={`/products/${encodeURIComponent(product.id)}/edit`}
              className='buttonPrimary'
            >
              Edit
            </LinkAsButton>
          }
        >
          Key details
        </SectionHeading>
        <ProductData product={product} errors={errors} />
      </div>
    )
  }

  const serviceData = () => {
    return product.offering_type.includes('serv') ? (
      <div className={styles.section}>
        <SectionHeading>Service pricing</SectionHeading>
        <ServiceData schedulingStrategy={product.scheduling_strategy} />
      </div>
    ) : null
  }

  const imageData = () => {
    return (
      <div className={styles.section}>
        <SectionHeading
          button={
            <LinkAsButton
              to={`/products/${encodeURIComponent(product.slug)}/image`}
              className='buttonPrimary'
            >
              Upload
            </LinkAsButton>
          }
        >
          Images
        </SectionHeading>
        <ImageData productImages={product.images} />
      </div>
    )
  }

  const childrenData = () => {
    return (
      <div className={styles.section}>
        <SectionHeading>Children</SectionHeading>
        {errors && errors['children'] && <FieldError error={errors['children']} />}
        <ChildrenData productChildren={product.children} />
      </div>
    )
  }

  const availableSizesData = () => {
    return (
      <div className={styles.section}>
        <SectionHeading>Available sizes</SectionHeading>
        {errors && errors['available_sizes'] && (
          <FieldError error={errors['available_sizes']} />
        )}
        <AvailableSizesData sizes={product.available_sizes} />
      </div>
    )
  }

  const markingOptionsData = () => {
    return (
      <div className={styles.section}>
        <SectionHeading>Marking options</SectionHeading>
        {errors && errors['marking_options'] && (
          <FieldError error={errors['marking_options']} />
        )}
        <MarkingOptionsData options={product.marking_options} />
      </div>
    )
  }

  const categorisationsData = () => {
    let productCategorisationsProps = {
      specification: product.specification,
      categories: product.categories,
      available_for_purchase: product.available_for_purchase,
      restricted: product.restricted,
    }
    return (
      <div className={styles.section}>
        <SectionHeading
          button={
            <LinkAsButton
              to={`/products/${encodeURIComponent(product.slug)}/categorisations/edit`}
              className='buttonPrimary'
              disabled={product._type === 'Component'}
            >
              Edit
            </LinkAsButton>
          }
        >
          Product categorisations
        </SectionHeading>
        <CategorisationsData {...productCategorisationsProps} />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {productData()}
      {serviceData()}
      {childrenData()}
      {availableSizesData()}
      {markingOptionsData()}
      {imageData()}
      {categorisationsData()}
    </div>
  )
}

ProductAttributes.propTypes = {
  product: PropTypes.object.isRequired,
  errors: PropTypes.object,
}

export default ProductAttributes
