export default [
  {
    title: 'Status',
    id: 'status',
    multiSelect: true,
    options: [
      { id: 'cancelled', name: 'Cancelled' },
      { id: 'completed', name: 'Completed' },
      { id: 'error', name: 'Error' },
      { id: 'in_fulfilment', name: 'In Fulfilment' },
      { id: 'pending', name: 'Pending' },
      { id: 'ready_for_fulfilment', name: 'Ready for Fulfilment' },
    ],
  },
]
