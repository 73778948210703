import { combineReducers } from 'redux'
import dataReducer from 'lib/reducers/dataReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { organisationTypes } from 'actions/types'

const initialState = null
const baseType = organisationTypes.FETCH_ORGANISATION

const reducer = combineReducers({
  requestState: requestStateReducer(baseType),
  data: dataReducer({ initialState, baseType }),
})

export default reducer
