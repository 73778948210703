import React from 'react'

import { getPartyName } from 'utils/party'
import { formatAddress } from 'utils/formatAddresses'

import styles from './styles.scss'

const CustomerData = ({ order }) => {
  return (
    <table className={styles.verticalTable}>
      <thead>
        <tr>
          <th>Initiating party name</th>
          <th>Purchasing party name</th>
          <th>Order acceptor name</th>
          <th>Shipping address</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{getPartyName(order['urn:lic:predicate:order_initiator'])}</td>
          <td>{getPartyName(order['urn:lic:predicate:order_purchaser'])}</td>
          <td>{getPartyName(order['urn:lic:predicate:order_acceptor'])}</td>
          <td>
            {order.shipping_address ? formatAddress(order.shipping_address) : 'Not found'}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default CustomerData
