import React from 'react'
import { Field, reduxForm } from 'redux-form'

import Button from 'lib/components/Button'
import FormField from 'lib/components/FormField'
import FormFieldSet from 'lib/components/FormFieldSet'
import FormRow from 'lib/components/FormRow'

let EditDiscountForm = props => {
  const { handleSubmit, onSubmit } = props

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormFieldSet>
        <FormRow label='Name'>
          <Field name='name' component='input' type='text' readOnly />
        </FormRow>
        <FormRow label='Applies to'>
          <Field name='applies_to' component='input' type='text' readOnly />
        </FormRow>
        <FormRow label='Price type'>
          <Field name='applied_on_price_pattern' component='input' type='text' readOnly/>
        </FormRow>
        <FormRow label='Active from'>
          <Field
            component={FormField}
            inline
            name='valid_from'
            type='date'
          />
        </FormRow>
        <FormRow label='Active to'>
          <Field
            component={FormField}
            inline
            name='valid_to'
            type='date'
          />
        </FormRow>
        <Button type='submit' className='buttonPrimary'>
          Update details
        </Button>
      </FormFieldSet>
    </form>
  )
}

export { EditDiscountForm }
export default reduxForm({
  // a unique name for the form
  form: 'editDiscount',
})(EditDiscountForm)
