import React from 'react'
import PropTypes from 'prop-types'
import Button from 'lib/components/Button'
import DateTime from 'lib/components/DateTime'
import styles from '../styles.scss'
import { addBusinessDays } from 'utils/util'

const SupplierCapacityData = ({
  tableData,
  handleToggleBlockDeliveryDate,
  supplierUrl,
}) => {
  const formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const blockedImg = (
    <img
      src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNDY4LjI5MyA0NjguMjkzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NjguMjkzIDQ2OC4yOTM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIHN0eWxlPSJmaWxsOiNEMTUyNDE7IiBkPSJNMjM0LjE0NiwwQzEwNC44OTgsMCwwLDEwNC44OTgsMCwyMzQuMTQ2czEwNC44OTgsMjM0LjE0NiwyMzQuMTQ2LDIzNC4xNDYNCglzMjM0LjE0Ni0xMDQuODk4LDIzNC4xNDYtMjM0LjE0NlMzNjMuMzk1LDAsMjM0LjE0NiwweiBNNjYuMTg1LDIzNC4xNDZjMC05My4wMzQsNzUuNTUxLTE2OC41ODUsMTY3Ljk2MS0xNjguNTg1DQoJYzM0Ljk2NiwwLDY4LjA1OSwxMC42MTUsOTQuOTA3LDI5LjM0Nkw5NS41MzIsMzI5LjA1NEM3Ni44LDMwMi4yMDUsNjYuMTg1LDI2OS4xMTIsNjYuMTg1LDIzNC4xNDZ6IE0yMzQuMTQ2LDQwMi4xMDcNCgljLTM0Ljk2NiwwLTY4LjA1OS0xMC42MTUtOTQuOTA3LTI5LjM0NmwyMzMuNTIyLTIzMy41MjJjMTguNzMyLDI2Ljg0OSwyOS4zNDYsNTkuOTQxLDI5LjM0Niw5NC45MDcNCglDNDAyLjEwNywzMjcuMTgsMzI3LjE4LDQwMi4xMDcsMjM0LjE0Niw0MDIuMTA3eiIvPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo='
      alt='blocked'
      className={styles.icon}
    />
  )
  const availableImg = (
    <img
      src='data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjUxMnB0IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im0yNTYgMGMtMTQxLjE2NDA2MiAwLTI1NiAxMTQuODM1OTM4LTI1NiAyNTZzMTE0LjgzNTkzOCAyNTYgMjU2IDI1NiAyNTYtMTE0LjgzNTkzOCAyNTYtMjU2LTExNC44MzU5MzgtMjU2LTI1Ni0yNTZ6bTAgMCIgZmlsbD0iIzIxOTZmMyIvPjxwYXRoIGQ9Im0zODUuNzUgMjAxLjc1LTEzOC42Njc5NjkgMTM4LjY2NDA2MmMtNC4xNjAxNTYgNC4xNjAxNTctOS42MjEwOTMgNi4yNTM5MDctMTUuMDgyMDMxIDYuMjUzOTA3cy0xMC45MjE4NzUtMi4wOTM3NS0xNS4wODIwMzEtNi4yNTM5MDdsLTY5LjMzMjAzMS02OS4zMzIwMzFjLTguMzQzNzUtOC4zMzk4NDMtOC4zNDM3NS0yMS44MjQyMTkgMC0zMC4xNjQwNjIgOC4zMzk4NDMtOC4zNDM3NSAyMS44MjAzMTItOC4zNDM3NSAzMC4xNjQwNjIgMGw1NC4yNSA1NC4yNSAxMjMuNTg1OTM4LTEyMy41ODIwMzFjOC4zMzk4NDMtOC4zNDM3NSAyMS44MjAzMTItOC4zNDM3NSAzMC4xNjQwNjIgMCA4LjMzOTg0NCA4LjMzOTg0MyA4LjMzOTg0NCAyMS44MjAzMTIgMCAzMC4xNjQwNjJ6bTAgMCIgZmlsbD0iI2ZhZmFmYSIvPjwvc3ZnPg=='
      alt='checked'
      className={styles.icon}
    />
  )

  const unavailableImg = (
    <img
      src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgNDY4LjI5MyA0NjguMjkzIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NjguMjkzIDQ2OC4yOTM7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBhdGggc3R5bGU9ImZpbGw6Z3JleTsiIGQ9Ik0yMzQuMTQ2LDBDMTA0Ljg5OCwwLDAsMTA0Ljg5OCwwLDIzNC4xNDZzMTA0Ljg5OCwyMzQuMTQ2LDIzNC4xNDYsMjM0LjE0NgoJczIzNC4xNDYtMTA0Ljg5OCwyMzQuMTQ2LTIzNC4xNDZTMzYzLjM5NSwwLDIzNC4xNDYsMHogTTY2LjE4NSwyMzQuMTQ2YzAtOTMuMDM0LDc1LjU1MS0xNjguNTg1LDE2Ny45NjEtMTY4LjU4NQoJYzM0Ljk2NiwwLDY4LjA1OSwxMC42MTUsOTQuOTA3LDI5LjM0Nkw5NS41MzIsMzI5LjA1NEM3Ni44LDMwMi4yMDUsNjYuMTg1LDI2OS4xMTIsNjYuMTg1LDIzNC4xNDZ6IE0yMzQuMTQ2LDQwMi4xMDcKCWMtMzQuOTY2LDAtNjguMDU5LTEwLjYxNS05NC45MDctMjkuMzQ2bDIzMy41MjItMjMzLjUyMmMxOC43MzIsMjYuODQ5LDI5LjM0Niw1OS45NDEsMjkuMzQ2LDk0LjkwNwoJQzQwMi4xMDcsMzI3LjE4LDMyNy4xOCw0MDIuMTA3LDIzNC4xNDYsNDAyLjEwN3oiLz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg=='
      alt='unavailable'
      className={styles.icon}
    />
  )

  const shouldItemBeDisabled = rowItem => {
    if (rowItem.capacity >= rowItem.threshold) {
      return true
    }

    if (rowItem.delivery_date) {
      const dateToCheck = new Date(rowItem.delivery_date)
      const earliestOrderDate = new Date(addBusinessDays(new Date(), 7, 'YYYY/MM/DD'))
      //if the passed in date is 7 days or less than earliestOrderDate it needs to be blocked for ordering
      if (dateToCheck <= earliestOrderDate) {
        return true
      }
    }
    return false
  }

  const shouldRowBeHighlighted = rowItem => {
    if (rowItem.capacity >= rowItem.threshold) {
      return styles.rowHighlightRed
    } else if (rowItem.capacity >= rowItem.threshold * 0.9) {
      return styles.rowHighlightOrange
    }

    return ''
  }

  const renderStatusIcon = rowItem => {
    if (rowItem.excluded_delivery_time_range_blocked) {
      return blockedImg
    } else if (shouldItemBeDisabled(rowItem)) {
      return unavailableImg
    } else {
      return availableImg
    }
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Date</th>
          <th className={styles.status}>Availability</th>
          <th>Capacity</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {tableData &&
          tableData.map(rowItem => (
            <tr
              key={rowItem.delivery_date}
              className={shouldItemBeDisabled(rowItem) ? styles.disabled : ''}
            >
              <td className={shouldRowBeHighlighted(rowItem)}>
                <DateTime date={rowItem.delivery_date} format='DD/MM/YYYY' />
              </td>
              <td className={styles.status}>{renderStatusIcon(rowItem)}</td>
              <td className={shouldRowBeHighlighted(rowItem)}>
                {`${formatNumber(rowItem.capacity)} / ${formatNumber(rowItem.threshold)}`}
              </td>
              <td className={styles.buttonColumn}>
                <Button
                  disabled={shouldItemBeDisabled(rowItem)}
                  className={
                    rowItem.excluded_delivery_time_range_blocked
                      ? 'buttonOutline'
                      : 'buttonSecondary'
                  }
                  onClick={() =>
                    handleToggleBlockDeliveryDate(
                      rowItem.delivery_date,
                      supplierUrl,
                      rowItem.excluded_delivery_time_range_id,
                      rowItem.excluded_delivery_time_range_blocked
                    )
                  }
                >
                  {rowItem.excluded_delivery_time_range_blocked ? 'Unblock' : 'Block'}
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  )
}

SupplierCapacityData.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleToggleBlockDeliveryDate: PropTypes.func.isRequired,
}

export default SupplierCapacityData
