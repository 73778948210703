import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { classifierOptionsTypes } from 'actions/types'

const INITIAL_STATE = null

function fetchClassifierOptionsFulfilled(state, action) {
  if (action.payload) {
    var key = action.payload.identifier
    var value = action.payload.items
    if (state) {
      return { ...state, [key]: value }
    } else {
      return { [key]: value }
    }
  }
  return INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [classifierOptionsTypes.FETCH_CLASSIFIER_OPTIONS_FULFILLED]: fetchClassifierOptionsFulfilled,
  [classifierOptionsTypes.CLEAR_FETCH_CLASSIFIER_OPTIONS]: fetchClassifierOptionsFulfilled,
})

const reducer = combineReducers({
  requestState: requestStateReducer(classifierOptionsTypes.FETCH_CLASSIFIER_OPTIONS),
  data: dataReducer,
})

export default reducer
