import React from 'react'
import PropTypes from 'prop-types'

import ShippingRate from '../ShippingRatesTableRow'

import styles from './styles.scss'

function ShippingRatesTable({ title, shippingRates }) {
  return title && shippingRates && shippingRates.length ? (
    <div className={styles.container}>
      <h4 className={styles.title}>{title}</h4>
      <table className={styles.table}>
        <thead className={styles.thead}>
          <tr>
            <th>Rate method</th>
            <th className={styles.pullRight}>Lower bound</th>
            <th className={styles.pullRight}>Upper bound</th>
            <th className={styles.pullRight}>Price (NZD)</th>
          </tr>
        </thead>
        <tbody className={styles.tbody}>
          {shippingRates.map(shippingRate => (
            <ShippingRate key={shippingRate.id} shippingRate={shippingRate} />
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    <div className={styles.container}>
      <h4 className={styles.title}>{title}</h4>
      <p>No shipping rates found</p>
    </div>
  )
}

ShippingRatesTable.propTypes = {
  title: PropTypes.string,
  shippingRates: PropTypes.array,
}

export default ShippingRatesTable
