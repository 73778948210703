import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.scss'

const RadioButtonGroup = ({ fullWidth, input, meta, options, onChange }) => {
  const { error, touched, warning } = meta || {}

  const setClasses = () => {
    let outputClass = [styles.radioButton]
    fullWidth && outputClass.push(styles.radioButtonFullWidth)

    return outputClass.join(' ')
  }

  const handleChange = e => {
    if (input) input.onChange(e)
    if (onChange) onChange(e)
  }

  const renderError = () => (
    <span className={classnames(styles.validation, styles.error)}>{error}</span>
  )
  const renderWarning = () => (
    <span className={classnames(styles.validation, styles.warning)}>{warning}</span>
  )

  return (
    <Fragment>
      <ul className={styles.radioButtonGroup}>
        {options.map(option => (
          <li
            className={setClasses()}
            key={option.value}
            aria-checked={option.value === input.value}
          >
            <label className={option.disabled ? styles.disabled : ''}>
              <input
                {...input}
                type='radio'
                value={option.value}
                onChange={handleChange}
                checked={option.value === input.value}
                disabled={option.disabled}
              />
              <div className={styles.labelWrapper}>
                <h6 className={styles.labelTitle}>{option.label}</h6>
                {option.description && (
                  <p className={styles.labelDescription}>{option.description}</p>
                )}
              </div>
            </label>
          </li>
        ))}
      </ul>
      {touched && ((error && renderError()) || (warning && renderWarning()))}
    </Fragment>
  )
}

RadioButtonGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  input: PropTypes.object,
  error: PropTypes.string,
  warning: PropTypes.string,
  onChange: PropTypes.func,
}

export default RadioButtonGroup
