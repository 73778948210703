function typesForBaseType(baseType) {
  return {
    [baseType]: baseType,
    [`${baseType}_FULFILLED`]: `${baseType}_FULFILLED`,
    [`${baseType}_REJECTED`]: `${baseType}_REJECTED`,
    [`CLEAR_${baseType}`]: `CLEAR_${baseType}`,
  }
}

const downForMaintenanceTypes = {
  SET_DOWN_FOR_MAINTENANCE: 'SET_DOWN_FOR_MAINTENANCE',
}

const loggedInTypes = typesForBaseType('FETCH_LOGGED_IN')

export { downForMaintenanceTypes, loggedInTypes }
