import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { supplyOrdersTypes } from 'actions/types'
import queryString from 'query-string'

function fetchSupplyOrders(params, supplierId) {
  let stringifiedParams = queryString.stringify(params)

  let baseType = supplyOrdersTypes.FETCH_SUPPLY_ORDERS
  let supplyOrdersLink = `${
    global.config.SUPPLIER_SERVICE_ENDPOINT
  }/suppliers/${encodeURIComponent(supplierId)}/supply_orders/search?${stringifiedParams}`

  let url = proxyUrl({ link: supplyOrdersLink })

  return get({ baseType, url })
}

function fetchOverdueSupplyOrders(overdueSupplyOrdersLink, currentPage, ordersPerPage) {
  let baseType = supplyOrdersTypes.FETCH_SUPPLY_ORDERS
  let fullLink = `${overdueSupplyOrdersLink}?page=${currentPage}&per_page=${ordersPerPage}`

  let url = proxyUrl({ link: fullLink })

  return get({ baseType, url })
}

function clearSupplyOrders() {
  return { type: supplyOrdersTypes.CLEAR_FETCH_SUPPLY_ORDERS }
}

export { fetchSupplyOrders, fetchOverdueSupplyOrders, clearSupplyOrders }
