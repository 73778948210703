import React from 'react'

import styles from './styles.scss'

function QueryList({ children }) {
  return children && children.length > 0 ? (
    <div className={styles.listResults}>
      <ol>
        {children.map(result => (
          <li key={result.key}>{result}</li>
        ))}
      </ol>
    </div>
  ) : null
}
export default QueryList
