import React from 'react'
import moment from 'moment-timezone'

import ConfirmationModal from 'lib/components/ConfirmationModal'

const ConfirmCreateRatingModal = ({ onClose, onConfirm, showModal, versionedAt, user }) => (
  <ConfirmationModal onClose={onClose} onConfirm={onConfirm} showModal={showModal}>
    <p>
      Are you sure you want to save and create these rating rules
      {versionedAt ? ` for ${moment(versionedAt).format('DD/MM/YYYY, hh:mm A')}` : null}
      {user ? ` as ${user.name}` : null}?
    </p>
  </ConfirmationModal>
)

export default ConfirmCreateRatingModal
