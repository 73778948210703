import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ProductCard from '../ProductCard'

import { createBaseRating } from 'utils/rating'

class ProductCardWithRating extends Component {
  constructor() {
    super()

    this.state = {
      price: null,
    }
  }

  componentDidMount() {
    let { product } = this.props
    let tree = { id: product.slug, value: '', children: [] }
    createBaseRating(tree).then(response => {
      this.setState({ price: response || null })
    })
  }

  render() {
    let { product, productLinkFromSlug } = this.props
    let { price } = this.state
    let productData = { ...product, price }
    return (
      <ProductCard
        product={product}
        price={productData.price}
        productLinkFromSlug={productLinkFromSlug}
      />
    )
  }
}

ProductCardWithRating.propTypes = {
  product: PropTypes.object.isRequired,
}

export default ProductCardWithRating
