import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import fileDownload from 'js-file-download'
import axios from 'lib/utils/axios'
import moment from 'moment-timezone'

import { enqueueError, enqueueInfo } from 'lib/components/GlobalNotifications'
import { clearDiscount, fetchDiscount } from 'actions/discountActions'
import { clearOrders, fetchOrdersForDiscretionaryDiscount } from 'actions/ordersActions'
import { formatSlugAsTitle } from 'utils/util'
import { isAdmin } from 'selectors/userInfoSelectors'
import { proxyUrl } from 'lib/utils/proxy'

import OrdersList from 'components/OrdersList'
import PageSection from 'lib/components/layout/PageSection'
import PageHeading from 'lib/components/headings/PageHeading'
import Button from 'lib/components/Button'
import Pagination from '@licnz/react-pagination'
import RequestWrapper from '@licnz/react-request-wrapper'

const initialState = {
  page: 1,
  per_page: 10,
}
const WHITELISTED_PARAMS = ['page', 'per_page']

class OrdersForDiscretionaryDiscount extends Component {
  constructor(props) {
    super(props)

    this.handleBack = this.handleBack.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleExportOrdersForDiscretionaryDiscount = this.handleExportOrdersForDiscretionaryDiscount.bind(
      this
    )
  }

  componentDidMount() {
    let { location, history } = this.props
    let params = queryString.parse(location.search)
    let {
      enqueueError,
      fetchDiscount,
      match: {
        params: { id },
      },
    } = this.props

    fetchDiscount(id).catch(() => {
      enqueueError({ message: 'We were unable to fetch this discount.' })
    })

    if (!params.page || !params.per_page) {
      let updateParams = this.applyWhitelist({ ...params, ...initialState })
      history.replace(
        `/discounts/${id}/orders_for_discretionary_discount?${queryString.stringify(
          updateParams
        )}`
      )
    }
  }

  componentDidUpdate(prevProps) {
    let {
      discount,
      location,
      fetchOrdersForDiscretionaryDiscount,
      enqueueError,
    } = this.props
    let { discount_id } = discount || {}
    let { page, per_page } = queryString.parse(location.search)

    if (
      discount &&
      (prevProps.discount !== discount || location.search !== prevProps.location.search)
    ) {
      fetchOrdersForDiscretionaryDiscount({ page, per_page, discount_id }).catch(() => {
        enqueueError({
          message: `We were unable to fetch any discounted orders for ${formatSlugAsTitle(
            discount_id
          )}`,
        })
      })
    }
  }

  componentWillUnmount() {
    this.props.clearDiscount()
    this.props.clearOrders()
  }

  handleExportOrdersForDiscretionaryDiscount() {
    const { discount } = this.props
    const { discount_id } = discount || {}
    const ordersLink = `${global.config.ORDER_SERVICE_ENDPOINT}/orders/for_discretionary_discount_to_csv`
    const dateTimeString = moment().toISOString().replace(/[-:.]/g, '')

    axios
      .post(proxyUrl({ link: ordersLink }), { discount_id })
      .then(response => {
        const blob = new Blob([response.data], { type: 'text/csv' })
        fileDownload(blob, `orders-${dateTimeString}.csv`)
      })
      .catch(err =>
        enqueueError({
          message: 'We were unable to export the discounted orders. Error: ' + err,
        })
      )
  }

  updateUrl(updateParams) {
    let {
      location,
      history,
      match: {
        params: { id },
      },
    } = this.props
    let currentParams = queryString.parse(location.search)
    let params = this.applyWhitelist({ ...currentParams, ...updateParams })
    history.push(
      `/discounts/${id}/orders_for_discretionary_discount?${queryString.stringify(
        params
      )}`
    )
  }

  applyWhitelist(params) {
    if (!Object.keys(params).length) return params

    let whitelistedParams = {}
    for (let [key, value] of Object.entries(params)) {
      if (WHITELISTED_PARAMS.includes(key)) whitelistedParams[key] = value
    }

    return whitelistedParams
  }

  handleChangePage(page) {
    this.updateUrl({ page })
  }

  handleBack() {
    let {
      history,
      match: {
        params: { id },
      },
    } = this.props
    history.push(`/discounts/${id}`)
  }

  render() {
    let { discount, isAdmin, location, orders, totalResults, ordersRequestState } = this.props

    let params = queryString.parse(location.search)
    let page = params.page ? Number(params.page) : 1
    let per_page = params.per_page ? Number(params.per_page) : 10

    return discount ? (
      <PageSection>
        <PageHeading
          actionText='Back'
          heading='Discounted orders'
          onAction={this.handleBack}
          size='h2'
          subheading={formatSlugAsTitle(discount.discount_id)}
        />
        <Button onClick={this.handleExportOrdersForDiscretionaryDiscount} className='buttonPrimary'>
          Export to CSV
        </Button>

        <RequestWrapper requestState={ordersRequestState}>
          {orders && orders.length ? (
            <Fragment>
              <OrdersList orders={orders} isAdmin={isAdmin}/>
              <Pagination
                border={false}
                currentPage={page}
                items={orders}
                onChangePage={this.handleChangePage}
                pageSize={per_page}
                totalItems={totalResults}
              />
            </Fragment>
          ) : (
            <h4>{`No discounted orders found for ${formatSlugAsTitle(
              discount.discount_id
            )}`}</h4>
          )}
        </RequestWrapper>
      </PageSection>
    ) : null
  }
}

const mapDispatchToProps = {
  enqueueError,
  enqueueInfo,
  clearDiscount,
  fetchDiscount,
  clearOrders,
  fetchOrdersForDiscretionaryDiscount,
}

export { OrdersForDiscretionaryDiscount }
export default connect(state => {
  return {
    customerContext: state.customerContext.data,
    discount: state.discount.data,
    discountRequestStatus: state.discount.requestState,
    isAdmin: isAdmin(state),
    orders: state.orders.data.items,
    ordersRequestState: state.orders.requestState,
    totalResults: state.orders.data.total_results,
  }
}, mapDispatchToProps)(OrdersForDiscretionaryDiscount)
