const PRICE_PATTERN_MAPPINGS = {
  unitBaseRetail: 'unit:base:retail',
  unitBaseCost: 'unit:base:cost',
  unitCharge: 'unit:charge',
  unitConfigreqRetail: 'unit:configreq:retail',
  unitConfigreqCost: 'unit:configreq:cost',
  baseRetail: 'base:retail',
  baseCost: 'base:cost',
  charge: 'charge',
  configreqRetail: 'configreq:retail',
  configreqCost: 'configreq:cost',
  unitRetail: 'unit:retail',
  unitCost: 'unit:cost',
  retail: 'retail',
  cost: 'cost',
  discountRetail: 'discount:retail',
  discountCost: 'discount:cost',
  unitPrice: 'unit:price',
  unitBuy: 'unit:buy',
  price: 'price',
  priceSell: 'price:sell',
  priceBuy: 'price:buy',
}

const OFFER_PRICE_URN_MAPPINGS = {
  offerPriceBaseRetail: 'urn:lic:offer:price:base:retail',
  offerPriceUnitBaseRetail: 'urn:lic:offer:price:unit:base:retail',
  offerPriceUnitRetail: 'urn:lic:offer:price:unit:retail',
  offerPriceUnitSell: 'urn:lic:offer:price:unit:sell',
  offerPriceRetail: 'urn:lic:offer:price:retail',
  offerPrice: 'urn:lic:offer:price',
  offerPriceDiscountRetail: 'urn:lic:offer:price:discount:retail',
  offerPriceDiscount: 'urn:lic:offer:price:discount',
  offerPriceSell: 'urn:lic:offer:price:sell',
  offerPriceChargeNaitLevy: 'urn:lic:offer:price:charge:levy:nait',
  orderOfferPriceInvoiced: 'urn:lic:order:offer:price:invoiced',
}

const RATING_PRICE_URN_MAPPINGS = {
  BASE_UNIT_COST: 'urn:lic:product:price:base:unit:cost',
  BASE_UNIT_RETAIL: 'urn:lic:product:price:base:unit:retail',
  CHARGE_LEVY_UNIT_NAIT: 'urn:lic:product:price:charge:levy:unit:nait',
  CONFIGREQ_UNIT_COST: 'urn:lic:product:price:configreq:unit:cost',
  CONFIGREQ_UNIT_RETAIL: 'urn:lic:product:price:configreq:unit:retail',
}

export { OFFER_PRICE_URN_MAPPINGS, PRICE_PATTERN_MAPPINGS, RATING_PRICE_URN_MAPPINGS }
