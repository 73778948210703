import React from 'react'

import {
  formatPerUnitValue,
  formatPercentageValue,
  parsePerUnitValue,
  parsePercentageValue,
  maxDiscount,
} from 'utils/discountUtils'
import { required } from 'utils/formValidations'

import Button from 'lib/components/Button'
import Dropdown from 'lib/components/Dropdown'
import FormField from 'lib/components/FormField'
import FormRow from 'lib/components/FormRow'
import { Field } from 'redux-form'
import { ReduxFormDropdown } from 'lib/components/Dropdown/higherOrderComponents'

import styles from './styles.scss'

const PER_UNIT_OPTIONS = [
  { id: 'between', name: 'between' },
  { id: 'over', name: 'over' },
]

const DiscountRange = ({
  formName,
  formValues,
  handleAddRange,
  handleChangeFormValueByPath,
  handleRemoveRange,
  index,
}) => {
  if (!formValues.valueMethod) return null

  const perUnitOptionSelector = () => {
    return formValues ? formValues.discountRanges[index].perUnitOption : null
  }

  const PerUnitOptionDropdown = props => {
    return (
      <ReduxFormDropdown
        fieldName={`discountRanges[${index}].perUnitOption`}
        formName={formName}
        render={(onSelect, selectedOptionId) => {
          const handleSelect = id => {
            onSelect(id)

            // This ensures that the from/to values are reset
            handleChangeFormValueByPath(`discountRanges[${index}].from`, null)
            handleChangeFormValueByPath(`discountRanges[${index}].to`, null)
          }
          return (
            <Dropdown
              {...props}
              onSelect={handleSelect}
              selectedOptionId={selectedOptionId}
            />
          )
        }}
        selector={perUnitOptionSelector}
      />
    )
  }

  const renderPercentageForm = () => {
    return (
      <FormRow label='Discount value'>
        <div className={styles.rowWrapper}>
          <Field
            component={FormField}
            format={formatPercentageValue}
            inline
            medium
            min='0'
            name={`discountRanges[${index}].value`}
            parse={parsePercentageValue}
            type='number'
            validate={[required, maxDiscount]}
            error_pos_fixed={true}
          />
          <span className={styles.descriptionText}>% per unit off</span>
          <Field
            className={styles.a11y}
            component='input'
            min='0'
            name={`discountRanges[${index}].from`}
            type='number'
          />
          <Field
            className={styles.a11y}
            component='input'
            min='0'
            name={`discountRanges[${index}].to`}
            type='number'
          />
        </div>
      </FormRow>
    )
  }

  const renderPerUnitForm = () => {
    let perUnitOption = formValues.discountRanges[index].perUnitOption

    return (
      <FormRow label={index === 0 ? 'Discount value ranges' : null}>
        <div className={styles.rowWrapper}>
          <span className={styles.descriptionText}>$</span>
          <Field
            component={FormField}
            format={formatPerUnitValue}
            inline
            medium
            min='0'
            name={`discountRanges[${index}].value`}
            parse={parsePerUnitValue}
            type='number'
            validate={required}
          />
          <span className={styles.descriptionText}>per unit for orders</span>
          <div className={styles.dropdownWrapper}>
            <Field
              component={PerUnitOptionDropdown}
              name={`discountRanges[${index}].perUnitOption`}
              options={PER_UNIT_OPTIONS}
            />
          </div>
          <Field
            component={FormField}
            inline
            medium
            min='0'
            name={`discountRanges[${index}].from`}
            type='number'
            validate={required}
          />
          {perUnitOption === 'between' ? (
            <span className={styles.descriptionText}>and</span>
          ) : null}
          {perUnitOption === 'between' ? (
            <Field
              component={FormField}
              inline
              medium
              min='0'
              name={`discountRanges[${index}].to`}
              type='number'
              validate={required}
            />
          ) : (
            <Field
              className={styles.a11y}
              component='input'
              min='0'
              name={`discountRanges[${index}].to`}
              type='number'
            />
          )}
          {renderRemoveLink()}
        </div>
      </FormRow>
    )
  }

  const renderRemoveLink = () => {
    return shouldRenderRemoveLink ? (
      <a className={styles.removeLink} onClick={() => handleRemoveRange(index)}>
        <svg className={styles.removeIcon}>
          <use xlinkHref='/images/sprites.svg#cross' />
        </svg>
      </a>
    ) : null
  }

  let shouldRenderAddButton =
    formValues.valueMethod === 'per_unit' &&
    index + 1 === formValues.discountRanges.length
  let shouldRenderRemoveLink =
    formValues.valueMethod === 'per_unit' && formValues.discountRanges.length > 1

  return (
    <div className={styles.wrapper}>
      {formValues.valueMethod === 'percentage'
        ? renderPercentageForm()
        : renderPerUnitForm()}
      {shouldRenderAddButton ? (
        <Button className='buttonSecondary' onClick={handleAddRange}>
          Add another range
        </Button>
      ) : null}
    </div>
  )
}

export default DiscountRange
