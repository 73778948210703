import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

const defaultLocale = 'en-nz'
const defaultFormat = 'DD/MM/YYYY'

function DateTime({ date, format }) {
  let dateObj = new Date(date)

  function getISODate() {
    // returns 2017-09-02T00:00:00.000Z
    return moment(dateObj).toISOString()
  }

  function getHumanDate() {
    // returns 02/12/2017
    moment.locale(defaultLocale)
    return moment(dateObj).format(format || defaultFormat)
  }

  return <time dateTime={getISODate()}>{getHumanDate()}</time>
}

DateTime.defaultProps = {
  format: defaultFormat,
}

DateTime.propTypes = {
  date: PropTypes.string.isRequired,
  format: PropTypes.string,
}

export default DateTime
