import { get, post } from 'lib/reduxHelpers/thunks'
import { href } from '@licnz/js-utils'
import { proxyUrl } from 'lib/utils/proxy'
import { orderTypes } from 'actions/types'

function fetchOrder(orderLink) {
  let baseType = orderTypes.FETCH_ORDER
  let url = proxyUrl({ link: orderLink })

  return get({ baseType, url })
}

function clearOrder() {
  return { type: orderTypes.CLEAR_FETCH_ORDER }
}

function cancelOrder({ order, partyLink, partyName }) {
  let orderInitiatorParty = order['urn:lic:predicate:order_initiator']
  let cancellationLink = href({ links: order.links, rel: 'cancellation' })

  let baseType = orderTypes.CANCEL_ORDER
  let url = proxyUrl({ link: cancellationLink })
  let body = {
    party_link: orderInitiatorParty.identifier.identifier,
    cancelling_party: {
      _type: 'urn:lic:predicate:order_canceller',
      name: partyName,
      identifier: {
        _type: 'urn:lic:id:party',
        identifier: partyLink,
      },
    },
  }

  return post({ baseType, url, body })
}

function manuallyCancelOrder({ order, partyLink, partyName }) {
  let orderInitiatorParty = order['urn:lic:predicate:order_initiator']
  let manuallyCancelOrderLink = href({ links: order.links, rel: 'manual_order_cancellation' })

  let baseType = orderTypes.MANUALLY_CANCEL_ORDER
  let url = proxyUrl({ link: manuallyCancelOrderLink })
  let body = {
    party_link: orderInitiatorParty.identifier.identifier,
    cancelling_party: {
      _type: 'urn:lic:predicate:order_canceller',
      name: partyName,
      identifier: {
        _type: 'urn:lic:id:party',
        identifier: partyLink,
      },
    },
  }

  return post({ baseType, url, body })
}

export { fetchOrder, clearOrder, cancelOrder, manuallyCancelOrder }
