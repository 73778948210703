import React, { useEffect, useState, Fragment } from 'react'
import { useQuery } from 'react-query'
import useStateWithHistory from './hooks/useStateWithHistory'

import { href } from '@licnz/js-utils'
import { renderError } from '@licnz/react-toast-notifications'
import Pagination from '@licnz/react-pagination'
import RequestWrapper from '@licnz/react-request-wrapper'

import axios from 'lib/utils/axios'
import { proxyUrl } from 'lib/utils/proxy'

import Button from 'lib/components/Button'
import FormField from 'lib/components/FormField'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'

import BirthIdReservationsData from './BirthIdReservationsData/BirthIdReservationsData'

const getAllBirthIdReservations = url => {
  return axios
    .post(proxyUrl({ link: url }), {})
    .then(response => {
      return response.data.sort((a, b) => (a.id > b.id ? 1 : -1)).reverse()
    })
    .catch(error => {
      throw error
    })
}

const searchForOperationPartyByParticipantCode = participantCode => {
  const link = `${global.config.RELATIONSHIP_SERVICE_ENDPOINT}/api/v1/parties/match`
  return axios
    .post(proxyUrl({ link }), {
      query: {
        match: {
          identifiers: [
            {
              id: participantCode,
              '@type': 'handle',
            },
          ],
        },
      },
    })
    .then(response => response.data)
    .catch(error => {
      throw error
    })
}

const BirthIdReservations = ({ history }) => {
  const reservationsPerPage = 10
  const [currentPage, setCurrentPage] = useStateWithHistory(0, { name: 'currentPage' })
  const [participantCode, setParticipantCode] = useState(null)
  const [
    birthIdReservationDataListToDisplay,
    setBirthIdReservationDataListToDisplay,
  ] = useState([])

  const {
    data: participantCodeMatchPartyData,
    error: participantCodeMatchPartyError,
    isLoading: participantCodeMatchIsLoading,
    refetch: matchParticipantCode,
  } = useQuery(participantCode, searchForOperationPartyByParticipantCode, {
    enabled: false,
  })

  const handleChangeField = event => {
    setParticipantCode(event.target.value)
  }

  const handleChangePage = page => {
    setCurrentPage(page)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setCurrentPage(1)
    matchParticipantCode()
  }

  const handleClear = e => {
    e.preventDefault()
    setBirthIdReservationDataListToDisplay(null)
    setParticipantCode('')
  }

  const matchingParty = participantCodeMatchPartyData?.parties[0]
  const agopLink =
    matchingParty && href({ links: matchingParty?.links, rel: 'agricultural_operation' })
  const { data: agopData, error: agOpError, isLoading: agopLoading } = useQuery(
    agopLink,
    { enabled: agopLink }
  )
  const herdLink = agopData && href({ links: agopData?.links, rel: 'herds' })
  const {
    data: animalGroupData,
    error: animalGroupError,
    isLoading: animalGroupLoading,
  } = useQuery(herdLink, { enabled: herdLink })
  let reservationsLink = null

  if (animalGroupData && animalGroupData?.items?.length > 0) {
    reservationsLink = `${animalGroupData.items[0].title}/get-all-birth-id-reservations`
  }

  const {
    data: birthIdReservationsData,
    error: birthIdReservationsError,
    isLoading: birthIdReservationsLoading,
  } = useQuery(reservationsLink, getAllBirthIdReservations, {
    enabled: reservationsLink,
  })

  let loading =
    participantCodeMatchIsLoading ||
    agopLoading ||
    animalGroupLoading ||
    birthIdReservationsLoading

  useEffect(() => {
    if (agopData && href({ links: agopData?.links, rel: 'herds' }) === null) {
      renderError({
        message: `No herd found for agricultural operation ${agopLink}`,
      })
    }
  }, [agopData])

  useEffect(() => {
    if (agOpError) {
      renderError({
        message: `An error occurred while trying to fetch the agricultural operation. ${agOpError}`,
      })
    }
  }, [agOpError])

  useEffect(() => {
    if (animalGroupData && animalGroupData?.items?.length === 0) {
      renderError({
        message: `No animal group found for the herd ${herdLink}`,
      })
    }
  }, [animalGroupData])

  useEffect(() => {
    if (animalGroupError) {
      renderError({
        message: `An error occurred while trying to fetch the animal group. ${animalGroupError}`,
      })
    }
  }, [animalGroupError])

  useEffect(() => {
    if (birthIdReservationsData) {
      let data = birthIdReservationsData.slice(
        (currentPage - 1) * reservationsPerPage,
        currentPage * reservationsPerPage
      )
      setBirthIdReservationDataListToDisplay(data)
    }
  }, [birthIdReservationsData, currentPage])

  useEffect(() => {
    if (birthIdReservationsError) {
      renderError({
        message: `An error occurred while trying to fetch the birth id reservations. ${birthIdReservationsError}`,
      })
    }
  }, [birthIdReservationsError])

  useEffect(() => {
    if (participantCodeMatchPartyData) {
      if (participantCodeMatchPartyData.parties.length === 0) {
        renderError({
          message: `No matching operation parties found with the participant code ${participantCode}`,
        })
        setParticipantCode('')
      } else if (
        participantCodeMatchPartyData.parties.length > 0 &&
        href({
          links: participantCodeMatchPartyData?.parties[0].links,
          rel: 'agricultural_operation',
        }) === null
      ) {
        renderError({
          message: `No agricultural operation found for the operation party with participant code ${participantCode}`,
        })
      }
    }
  }, [participantCodeMatchPartyData])

  useEffect(() => {
    if (participantCodeMatchPartyError) {
      renderError({
        message: `An error occurred while trying to fetch the operation party for the participant code ${participantCode}. ${participantCodeMatchPartyError}`,
      })
    }
  }, [participantCodeMatchPartyError])

  let error =
    birthIdReservationsError ||
    participantCodeMatchPartyError ||
    agOpError ||
    animalGroupError

  return (
    <PageSection>
      <PageHeading heading='Birth Id Reservations' size='h2' actionText='Back' />
      <FormField
        name='participantCode'
        inline
        type='text'
        label='Participant code'
        onChange={handleChangeField}
        value={participantCode}
      />
      <Button
        type='submit'
        className='buttonPrimary'
        onClick={handleSubmit}
        disabled={!participantCode}
      >
        Submit
      </Button>
      {birthIdReservationDataListToDisplay &&
        birthIdReservationDataListToDisplay.length > 0 && (
          <Button
            type='submit'
            className='buttonSecondary'
            onClick={handleClear}
            disabled={birthIdReservationDataListToDisplay === null}
          >
            Clear Results
          </Button>
        )}
      <RequestWrapper loading={loading}>
        {birthIdReservationsData && !error && (
          <Fragment>
            <BirthIdReservationsData
              error={birthIdReservationsError}
              birthIdReservations={birthIdReservationDataListToDisplay || []}
              history={history}
            />
            <Pagination
              border={false}
              currentPage={currentPage}
              onChangePage={handleChangePage}
              pageSize={reservationsPerPage}
              totalItems={birthIdReservationsData?.length}
            />
          </Fragment>
        )}
      </RequestWrapper>
    </PageSection>
  )
}

export default BirthIdReservations
