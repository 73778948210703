import { createSelector } from 'reselect'
import { href } from '@licnz/js-utils'

const getPartyLink = createSelector([data => data], data => {
  return data ? href({ links: data.links, rel: 'party' }) : null
})

const getSelfLink = createSelector([data => data], data => {
  return data ? href({ links: data.links, rel: 'self' }) : null
})

export { getPartyLink, getSelfLink }
