import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const MarkingOptionsData = ({ options }) => {
  return options && options.length ? (
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Is Default</th>
        </tr>
      </thead>
      <tbody>
        {options.map(option => (
          <tr key={option.identity}>
            <td>{option.name || option.display_name}</td>
            <td>{option.default ? 'Yes' : 'No'}</td>
          </tr>
        ))}
      </tbody>
    </table>
  ) : (
    <div>No marking options found</div>
  )
}
MarkingOptionsData.propTypes = {
  options: PropTypes.array,
}

export default MarkingOptionsData
