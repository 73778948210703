import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { OFFER_PRICE_URN_MAPPINGS } from 'constants/priceMappings'
import { ORDERED_PRODUCT_STATE_MAPPINGS } from 'constants/stateMappings'
import { findPrice, stringAsDecimal } from 'utils/util'
import { isAdmin } from 'selectors/userInfoSelectors'

import Currency from 'lib/components/Currency'
import OrderedProductsBySupplier from '../OrderedProductsBySupplier'

import { FEATURE_FLAGS } from 'utils/featureFlags'

import styles from './styles.scss'

const SUPPLIER_MAPPINGS = FEATURE_FLAGS.Z_TAGS_BRANDING ? {
  LIC: 'lic',
  Allflex: 'allflex',
  Flexa: 'flexa',
  Leader: 'leader',
  'Z Tags': 'zee-tags',
} : {
  LIC: 'lic',
  Allflex: 'allflex',
  Flexa: 'flexa',
  Leader: 'leader',
  'Zee Tags': 'zee-tags',
}

const {
  offerPriceChargeNaitLevy,
  offerPriceDiscount,
  offerPriceDiscountRetail,
  offerPriceRetail,
  offerPriceSell,
  orderOfferPriceInvoiced,
} = OFFER_PRICE_URN_MAPPINGS

const OrderTable = ({ onCancelOrderedProduct, order, suppliers, isAdmin }) => {
  /* TODO: refactor to pull this figure from the order once it is available in the API. */
  const subtotalPrice = () => {
    return order.ordered_products
      .map(op => {
        let discount = findPrice(op.offered_prices, offerPriceDiscount)
        let retail = findPrice(op.offered_prices, offerPriceRetail)
        let sell = findPrice(op.offered_prices, offerPriceSell)

        return discount ? Number(retail.price) : Number(sell.price)
      })
      .reduce((a, b) => a + b, 0)
  }

  const shippingPrice = () => {
    return order.shipping_rates.reduce((a, b) => Number(a) + Number(b.price), 0)
  }

  const invoicedPrice = () => {
    return stringAsDecimal(findPrice(order.offered_prices, orderOfferPriceInvoiced).price)
  }

  /*
    This function maps through each ordered product to see if a discount price is present.
    It return the value of the total discount applied to the whole order - not per product
    and not per type of discount.
    TODO: refactor to allow separation of discounts e.g. volume vs discretionary once they
    are available in the API.
  */
  const discountPrice = () => {
    // NOTE: we need to handle both of these urns as the urn has changed, but we need to handle existing orders
    let discountPrice =
      findPrice(order.offered_prices, offerPriceDiscountRetail) ||
      findPrice(order.offered_prices, offerPriceDiscount)
    return discountPrice ? stringAsDecimal(discountPrice.price) : null
  }

  /*
    This function maps through each ordered product to see if a NAIT charge is present.
    It return the value of the total NAIT charge applied to the whole order - not per product.
    TODO: refactor to pull this figure from the order once it is available in the API.
  */
  const naitCharges = () => {
    return order.ordered_products
      .map(op => {
        let nait = findPrice(op.charges, offerPriceChargeNaitLevy)
        return nait ? Number(nait.price) : null
      })
      .reduce((a, b) => a + b, 0)
  }

  const findSupplierOrderedProducts = name => {
    return order.ordered_products.filter(op => {
      let supplierLink = op.supplier_url
      let lastIndex = supplierLink.lastIndexOf('/')
      let supplierName = supplierLink.substring(lastIndex + 1)
      return supplierName.includes(name)
    })
  }

  const getSupplierLink = name => {
    let supplier = suppliers.find(supplier => {
      let supplierLink = supplier.id
      let lastIndex = supplierLink.lastIndexOf('/')
      let supplierName = supplierLink.substring(lastIndex + 1)
      return supplierName.includes(name)
    })
    return supplier && supplier.id
  }

  const orderHasCustomerPurchaseOrderReferences = order.ordered_products.some(
    op => op.customer_purchase_order_number
  )
  const orderHasCancelledOrderedProducts = order.ordered_products.some(
    op => op.state === ORDERED_PRODUCT_STATE_MAPPINGS.CANCELLED
  )

  let colSpanLength = global.FEATURE_FLAGS.REORDER_PRODUCT_FEATURE ? 5 : 4
  if (orderHasCustomerPurchaseOrderReferences) colSpanLength += 1
  if (orderHasCancelledOrderedProducts) colSpanLength += 1
  if (!global.FEATURE_FLAGS.CANCEL_ORDER_LINE_FEATURE) colSpanLength -= 1

  return (
    <div className={styles.orderTableWrapper}>
      <table className={styles.table}>
        <thead className={styles.headerTableRow}>
          <tr>
            <th id='product' className={styles.pullLeft}>
              Product
            </th>
            <th id='status' className={styles.pullLeft}>
              Status
            </th>
            <th id='quantity' className={styles.pullRight}>
              Quantity
            </th>
            <th id='price' className={styles.pullRight}>
              Price
            </th>
            <th id='birthIdReplacement' className={styles.pullLeft}>
              Birth ID Replacement
            </th>
            <th id='sapPurchaseOrderNumber' className={styles.pullLeft}>
              SAP PO number
            </th>
            {orderHasCustomerPurchaseOrderReferences ? (
              <th id='customerPurchaseOrderNumber' className={styles.pullLeft}>
                Customer PO number
              </th>
            ) : null}
            {orderHasCancelledOrderedProducts ? (
              <th id='ordered_product_canceller' className={styles.pullLeft}>
                Cancelled by
              </th>
            ) : null}
           {global.FEATURE_FLAGS.CANCEL_ORDER_LINE_FEATURE && <th id='cancellationLink' className={styles.pullRight}></th>}
            <th></th>
          </tr>
        </thead>
        {Object.entries(SUPPLIER_MAPPINGS).map(([key, value]) => {
          return (
            <OrderedProductsBySupplier
              key={key}
              onCancelOrderedProduct={onCancelOrderedProduct}
              orderHasCancelledOrderedProducts={orderHasCancelledOrderedProducts}
              orderHasCustomerPurchaseOrderReferences={
                orderHasCustomerPurchaseOrderReferences
              }
              orderedProducts={findSupplierOrderedProducts(value)}
              orderReference={order.order_reference}
              supplierLink={isAdmin && getSupplierLink(value)}
              supplierName={key}
            />
          )
        })}
        <tfoot className={styles.footerTableRow}>
          <tr>
            <td colSpan={colSpanLength}></td>
            <td className={styles.pullRight}>Subtotal</td>
            <td>
              <Currency amount={subtotalPrice()} />
            </td>
          </tr>
          {discountPrice() ? (
            <tr>
              <td colSpan={colSpanLength}></td>
              <td className={styles.pullRight}>Discounts</td>
              <td>
                <Currency amount={discountPrice()} />
              </td>
            </tr>
          ) : null}
          {naitCharges() ? (
            <tr>
              <td colSpan={colSpanLength}></td>
              <td className={styles.pullRight}>NAIT levy</td>
              <td>
                <Currency amount={naitCharges()} />
              </td>
            </tr>
          ) : null}
          <tr>
            <td colSpan={colSpanLength}></td>
            <td className={styles.pullRight}>Delivery</td>
            <td>
              <Currency amount={shippingPrice()} />
            </td>
          </tr>
          <tr>
            <td colSpan={colSpanLength}></td>
            <td className={styles.pullRight}>Total</td>
            <td>
              <Currency amount={invoicedPrice()} />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

OrderTable.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  onCancelOrderedProduct: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
  suppliers: PropTypes.array.isRequired,
}

export { OrderTable }
export default connect(state => ({
  isAdmin: isAdmin(state),
  suppliers: state.suppliers.data,
}))(OrderTable)
