import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'

import { IN_PROGRESS } from 'lib/selectors/requestStateSelectors'
import { productsSearch, clearProducts } from 'actions/productsSearchActions'
import productFiltersConstants from 'constants/productFilters'

// import ButtonToolbar from 'lib/components/ButtonToolbar'
// import LinkAsButton from 'lib/components/LinkAsButton'
import Button from 'lib/components/Button'
import DropdownFilters from 'lib/components/DropdownFilters'
import DownloadAuditReportModal from 'lib/components/DownloadAuditReportModal'
import PageSection from 'lib/components/layout/PageSection'
import PageHeading from 'lib/components/headings/PageHeading'
import Pagination from '@licnz/react-pagination'
import ProductsList from './ProductsList'
import RequestWrapper from '@licnz/react-request-wrapper'
import { SearchBar } from '@licnz/react-api-search'

import styles from './styles.scss'

const initialState = {
  page: 1,
  perPage: 10,
}

/**
 * This component is driven by the query params in the url,
 * which stores the state of the latest search request
 */

class Products extends Component {
  constructor(props) {
    super(props)

    this.state = {
      q: '',
      productFilters: [],
      submitting: false,
      showModal: false,
    }

    this.handleUpdateFilters = this.handleUpdateFilters.bind(this)
    this.handleClearFilters = this.handleClearFilters.bind(this)
    this.handleResetSearchQuery = this.handleResetSearchQuery.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleNewProduct = this.handleNewProduct.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.productLinkWithQuery = this.productLinkWithQuery.bind(this)
    this.toggleShowModal = this.toggleShowModal.bind(this)
  }

  componentDidMount() {
    let { location, clearProducts } = this.props

    clearProducts()

    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    this.setState({ q: params.q })
    this.setState({ productFilters: productFiltersConstants }, () => {
      if (Object.keys(params).length == 0) {
        this.updateUrl(initialState)
      } else {
        this.performSearch()
      }
    })
  }

  componentDidUpdate(prevProps) {
    let { location, productSearchRequestStatus } = this.props

    if (
      prevProps.productSearchRequestStatus !== productSearchRequestStatus &&
      productSearchRequestStatus !== IN_PROGRESS
    ) {
      this.setState({ submitting: false })
    }

    if (prevProps.location.search !== location.search) {
      let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
      if (Object.keys(params).length == 0) {
        this.updateUrl(initialState)
      } else {
        this.performSearch()
      }
    }
  }

  toggleShowModal() {
    this.setState({ showModal: !this.state.showModal })
  }

  handleResetSearchQuery() {
    this.setState({ q: '' })
  }

  updateUrl(updateParams, filters) {
    let { location, history } = this.props

    let currentParams = queryString.parse(location.search, { arrayFormat: 'bracket' })
    if (filters)
      currentParams = {
        page: currentParams.page,
        perPage: currentParams.perPage,
        ...filters,
      }
    let params = { ...currentParams, ...updateParams }

    history.push(`/products?${queryString.stringify(params, { arrayFormat: 'bracket' })}`)
  }

  performSearch() {
    let { location, productsSearch } = this.props
    let { productFilters } = this.state

    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let { q, page, perPage, status, family } = params

    let filters = {}
    let categories = []
    productFilters &&
      productFilters.forEach(filter => {
        if (filter.id !== 'family' && filter.id !== 'status' && params[filter.id]) {
          categories.push(params[filter.id])
        }
      })
    filters['nested_categories'] = categories
    // product service rejects statuses if empty array
    if (status) filters['statuses'] = status
    // product service rejects families if empty array
    if (family) filters['families'] = family

    page = page ? Number(page) : initialState.page
    perPage = perPage ? Number(perPage) : initialState.perPage

    params = { page, per_page: perPage, ...filters }
    if (q && q !== '') params.q = q // product service rejects q if empty string

    this.setState({ submitting: true })
    productsSearch(params)
      .then(() => {
        this.setState({ submitting: false })
      })
      .catch(() => {
        this.setState({ submitting: false })
      })
  }

  handleClearFilters() {
    let { location } = this.props
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })

    let updateParams = initialState
    updateParams.q = params.q
    this.updateUrl(updateParams, {})
  }

  handleUpdateFilters(filters) {
    let { location } = this.props
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let { q, perPage } = params

    let newFilters = {}
    filters.forEach(filter => {
      newFilters[filter.id] = filter.selectedOptions
    })

    this.updateUrl({ q, perPage, page: 1 }, newFilters)
  }

  handleChangePage(page) {
    this.updateUrl({ page })
  }

  handleSearchChange(q) {
    this.setState({ q })
  }

  handleSubmit() {
    let { q } = this.state
    this.updateUrl({ q })
  }

  productLinkWithQuery(productSlug) {
    return `/products/${productSlug}${this.props.history.location.search}`
  }

  handleNewProduct() {
    this.props.history.push('/products/new')
  }

  render() {
    let { q, productFilters, submitting } = this.state
    let { location, products, totalResults, productsSearchRequestStatus } = this.props
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })

    let page = params.page ? Number(params.page) : 1
    let perPage = params.perPage ? Number(params.perPage) : 10

    let newFilters = productFilters.map(filter => ({
      ...filter,
      selectedOptions: params[filter.id] || [],
    }))

    return (
      <PageSection>
        <PageHeading heading='Products' />
        <div className={styles.lineLength}>
          <div className={styles.searchGrid}>
            <SearchBar
              value={q}
              onChange={this.handleSearchChange}
              onReset={this.handleResetSearchQuery}
              onSubmit={this.handleSubmit}
              submitting={submitting}
              placeholder='Enter product search term'
            />
          </div>
          <div className={styles.ratingGrid}>
            <Button onClick={this.toggleShowModal} className='buttonPrimary'>
              Export Rating Details
            </Button>
          </div>
        </div>
        {/* <ButtonToolbar>
          <LinkAsButton to='/products/new' className='buttonSecondary'>Add product</LinkAsButton>
        </ButtonToolbar> */}
        <DropdownFilters
          filters={newFilters}
          onChange={this.handleUpdateFilters}
          onClearFilters={this.handleClearFilters}
        />
        <RequestWrapper requestState={productsSearchRequestStatus}>
          {products && products.length ? (
            <Fragment>
              <ProductsList
                products={products}
                productLinkFromSlug={this.productLinkWithQuery}
              />
              <Pagination
                border={false}
                currentPage={page}
                items={products}
                onChangePage={this.handleChangePage}
                pageSize={perPage}
                totalItems={totalResults}
              />
            </Fragment>
          ) : (
            <h4>No products found</h4>
          )}
          <DownloadAuditReportModal
            showModal={this.state.showModal}
            onClose={this.toggleShowModal}
          />
        </RequestWrapper>
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  clearProducts,
  productsSearch,
}

export { Products }
export default connect(state => {
  return {
    products: state.productsSearch.data.items,
    productsSearchRequestStatus: state.productsSearch.requestState,
    totalResults: state.productsSearch.data.total_results,
  }
}, mapDispatchToProps)(Products)
