function id({ identifiers, type }) {
  let foundIdentifier = (identifiers || []).find(identifier => {
    return (
      identifier.type === type ||
      identifier._type === type ||
      identifier['@type'] === type
    )
  })

  return foundIdentifier ? foundIdentifier.id : null
}

export { id }
