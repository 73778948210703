import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { discountsTypes } from 'actions/types'

function fetchDiscounts({ includeInactiveDiscounts = false } = {}) {
  let baseType = discountsTypes.FETCH_DISCOUNTS
  let link = `${global.config.RATING_SERVICE_ENDPOINT}/api/ratable_products/discount_rules`

  let params = {}
  if (!includeInactiveDiscounts) params.valid_at = new Date().toISOString()
  let url = proxyUrl({ link, params })

  return get({ baseType, url, extractPayload: response => response.data.items })
}

function clearDiscounts() {
  return { type: discountsTypes.CLEAR_FETCH_DISCOUNTS }
}

export { fetchDiscounts, clearDiscounts }
