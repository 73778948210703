import React from 'react'
import PropTypes from 'prop-types'

import BasketCard from '../BasketCard'
import QueryList from 'lib/components/QueryList'

const BasketsList = ({ baskets }) => {
  return (
    <QueryList>
      {baskets.map(basket => (
        <BasketCard key={basket.id} basket={basket} />
      ))}
    </QueryList>
  )
}

BasketsList.propTypes = {
  baskets: PropTypes.array.isRequired,
}

export default BasketsList
