import { Component } from 'react'
import { connect } from 'react-redux'
import { change } from 'redux-form'

class ReduxFormTagAutocomplete extends Component {
  constructor(props) {
    super(props)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  handleAdd(tags) {
    let { change, formName, fieldName } = this.props
    change(formName, fieldName, tags)
  }

  handleDelete(tags) {
    let { change, formName, fieldName } = this.props
    change(formName, fieldName, tags)
  }

  render() {
    let { render, tags } = this.props
    return render(this.handleAdd, this.handleDelete, tags)
  }
}

const mapStateToProps = (state, props) => {
  let { selector } = props
  return {
    tags: selector(state),
  }
}

const mapDispatchToProps = { change }

export { ReduxFormTagAutocomplete }
export default connect(mapStateToProps, mapDispatchToProps)(ReduxFormTagAutocomplete)
