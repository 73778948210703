import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FormRow from 'lib/components/FormRow'

import styles from './styles.scss'

class FileUploader extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const {
      input: { onChange },
    } = this.props
    onChange(e.target.files[0])
  }

  render() {
    const { input, label, helpText } = this.props // props sent to the component from redux-form Field
    return (
      <FormRow label={label} helpText={helpText}>
        <input
          type='file'
          name={input.name}
          accept='.jpg, .png, .jpeg'
          onChange={this.handleChange}
          className={styles.fileUpload}
          data-testid='file-uploader'
        />
      </FormRow>
    )
  }
}

FileUploader.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  helpText: PropTypes.string,
}

export default FileUploader
