import { FEATURE_FLAGS } from 'utils/featureFlags'
export default [
  {
    title: 'Status',
    id: 'status',
    multiSelect: true,
    options: [
      { id: 'approved', name: 'Approved' },
      { id: 'draft', name: 'Draft' },
      { id: 'removed', name: 'Removed' },
    ],
  },
  {
    title: 'Family',
    id: 'family',
    multiSelect: true,
    options: [
      { id: 'urn:lic:id:product_family:tag', name: 'Tags' },
      { id: 'urn:lic:id:product_family:heat_detection', name: 'Heat detection' },
      { id: 'urn:lic:id:product_family:ab_diy_equipment', name: 'AB DIY' },
      { id: 'urn:lic:id:product_family:dna_testing', name: 'DNA testing' },
      { id: 'urn:lic:id:product_family:herd_testing', name: 'Herd testing' },
      { id: 'urn:lic:id:product_family:farm_accessories', name: 'Farm Accessories' },
      {
        id: 'urn:lic:id:product_family:eid_readers',
        name: 'EID Readers',
      },
    ],
  },
  {
    title: 'Supplier',
    id: 'supplier',
    multiSelect: true,
    options: [
      { id: 'lic', name: 'LIC' },
      { id: 'allflex', name: 'Allflex' },
      { id: 'flexa', name: 'Flexa' },
      { id: 'leader', name: 'Leader' },
      { id: 'zee-tags', name: FEATURE_FLAGS.Z_TAGS_BRANDING ? 'Z Tags' : 'Zee Tags' },
    ],
  },
  {
    title: 'Animal type',
    id: 'animal_type',
    multiSelect: true,
    options: [
      { id: 'calf', name: 'Calf' },
      { id: 'cow', name: 'Cow' },
      { id: 'deer', name: 'Deer' },
      { id: 'goat', name: 'Goat' },
      { id: 'beef', name: 'Beef' },
    ],
  },
  {
    title: 'Tag type',
    id: 'tag_type',
    multiSelect: true,
    options: [
      { id: 'eid', name: 'EID' },
      { id: 'premium', name: 'Premium' },
      { id: 'economy', name: 'Economy' },
      { id: 'accessory', name: 'Accessory' },
    ],
  },
]
