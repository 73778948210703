import React from 'react'
import PropTypes from 'prop-types'

import { formatStatus } from 'utils/util'

import DateTime from 'lib/components/DateTime'
import SectionHeading from 'lib/components/headings/SectionHeading'

import styles from './styles.scss'

const SupplyProductsData = ({ supplyProducts }) => {
  const renderSupplyProductDetails = sp => {
    return sp ? (
      <tr key={sp.id} className={styles.bodyTableRow}>
        <td className={styles.productInfo}>
          <p>{sp.supply_product.name}</p>
          <p>Quantity: {sp.supply_product.quantity}</p>
          <p>Status: {formatStatus(sp.state)}</p>
        </td>
        <td className={styles.pushCenter}>
          {sp.purchase_order_id ? sp.purchase_order_id : 'Not found'}
        </td>
        <td className={styles.pullLeft}>
          {sp.shipping_time ? <DateTime date={sp.shipping_time} /> : 'Not found'}
        </td>
        <td className={styles.pullLeft}>
          {sp.tracking_reference ? sp.tracking_reference : 'Not found'}
        </td>
        <td className={styles.pullLeft}>
          {sp.tracking_url ? (
            <a href={sp.tracking_url}>{sp.tracking_url}</a>
          ) : (
            'Not found'
          )}
        </td>
        <td className={styles.pullLeft}>
          {sp.invoice_number ? sp.invoice_number : 'Not found'}
        </td>
        <td className={styles.pullLeft}>
          {sp.coordinating_courier
            ? `${sp.coordinating_courier.name}: ${sp.coordinating_courier.contact_phone}`
            : 'Not found'}
        </td>
      </tr>
    ) : null
  }

  return supplyProducts && supplyProducts.length > 0 ? (
    <div className={styles.tableContainer}>
      <SectionHeading>Supply products data</SectionHeading>
      <table className={styles.table}>
        <thead>
          <tr className={styles.headerTableRow}>
            <th className={styles.pullLeft}>Product</th>
            <th className={styles.pulRight}>PO number</th>
            <th className={styles.pullLeft}>Shipping time</th>
            <th className={styles.pullLeft}>Tracking reference</th>
            <th className={styles.pullLeft}>Tracking URL</th>
            <th className={styles.pullLeft}>Invoice number</th>
            <th className={styles.pullLeft}>Coordinating courier</th>
          </tr>
        </thead>
        <tbody>
          {supplyProducts.map(supplyProduct => renderSupplyProductDetails(supplyProduct))}
        </tbody>
      </table>
    </div>
  ) : (
    <div>No supply products found</div>
  )
}

SupplyProductsData.propTypes = {
  supplyProducts: PropTypes.array,
}

export default SupplyProductsData
