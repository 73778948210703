import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { isAdmin, isAgriManager } from 'selectors/userInfoSelectors'
import { NavLink } from 'react-router-dom'

import styles from './styles.scss'

const AppNav = ({ isAdmin, isAgriManager, idToken }) => {
  const linkArray = () => {
    let array = []
    if (isAgriManager) array = [{ id: 'orders', url: '/orders', label: 'Orders' }]
    else if (isAdmin) {
      array = [
        { id: 'products', url: '/products', label: 'Products' },
        { id: 'orders', url: '/orders', label: 'Orders' },
        { id: 'suppliers', url: '/suppliers', label: 'Suppliers' },
        { id: 'baskets', url: '/baskets', label: 'Baskets' },
        { id: 'operations', url: '/operations', label: 'Operations' },
        { id: 'discounts', url: '/discounts', label: 'Discounts' },
        { id: 'birth_id_reservations', url: '/birth_id_reservations', label: 'Birth Id Reservations' },

      ]
      if (idToken && idToken.token) {
        array.push({
          id: 'supplier_query_builder',
          url: '/supplier_query_builder',
          label: 'Query Builder',
        })
      }
    }
    return array
  }

  const isActive = (match, location) => {
    if (!match) return false

    return match.isExact || (match.path != '/' && location.pathname.includes(match.path))
  }

  return (
    <ul className={styles.navList}>
      {linkArray().map(item => (
        <li className={styles.navListItem} key={item.id}>
          <NavLink
            className={styles.navLink}
            activeClassName={styles.linkStateActive}
            isActive={isActive}
            to={item.url}
          >
            {item.label}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}

AppNav.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  isAgriManager: PropTypes.bool.isRequired,
  idToken: PropTypes.object,
}

export { AppNav }
export default connect(state => {
  return {
    idToken: state.idToken.data,
    isAdmin: isAdmin(state),
    isAgriManager: isAgriManager(state),
  }
})(AppNav)
