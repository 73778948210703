import React from 'react'
import PropTypes from 'prop-types'

const ButtonAsLink = ({ children, disabled, onClick, type }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  )
}

ButtonAsLink.defaultProps = {
  type: 'button',
}

ButtonAsLink.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
}

export default ButtonAsLink
