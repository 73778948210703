import { get, post } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'

import { ratingTypes } from 'actions/types'

function fetchRating({ tree, quantity = 1, discountQuantityMultiplier = 1 }) {
  let baseType = ratingTypes.FETCH_RATING
  let link = `${global.config.RATING_SERVICE_ENDPOINT}/api/ratable_products/rate`
  let body = {
    _type: 'ratable_product_tree',
    quantity_units: [
      {
        type: 'ea',
        value: quantity,
      },
      {
        type: 'discount_multiplier',
        value: discountQuantityMultiplier,
      },
    ],
    price_patterns: ['_all'],
    rate_as_at_time: new Date().toISOString(),
    tree,
  }
  let url = proxyUrl({ link })

  return post({ baseType, body, url })
}

function fetchRatingRules(productSlug) {
  let baseType = ratingTypes.FETCH_RATING_RULES
  let link = `${global.config.RATING_SERVICE_ENDPOINT}/api/ratable_products?root_product_id=${productSlug}`
  let url = proxyUrl({ link })

  return get({ baseType, url, extractPayload: response => response.data.items })
}

function clearRating() {
  return { type: ratingTypes.CLEAR_FETCH_RATING }
}

function clearRatingRules() {
  return { type: ratingTypes.CLEAR_FETCH_RATING_RULES }
}

export { clearRating, clearRatingRules, fetchRating, fetchRatingRules }
