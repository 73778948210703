function typesForBaseType(baseType) {
  return {
    [baseType]: baseType,
    [`${baseType}_FULFILLED`]: `${baseType}_FULFILLED`,
    [`${baseType}_REJECTED`]: `${baseType}_REJECTED`,
    [`CLEAR_${baseType}`]: `CLEAR_${baseType}`,
  }
}

const basketTypes = {
  ...typesForBaseType('FETCH_BASKET'),
  ...typesForBaseType('DELETE_BASKET'),
}

const basketsTypes = typesForBaseType('FETCH_BASKETS')

const basketsSearchTypes = typesForBaseType('SEARCH_BASKETS')

const classifiersTypes = typesForBaseType('FETCH_CLASSIFIERS')

const classifierOptionsTypes = typesForBaseType('FETCH_CLASSIFIER_OPTIONS')

const currentProfileTypes = typesForBaseType('FETCH_CURRENT_PROFILE')

const customerContextTypes = {
  CLEAR_CUSTOMER_CONTEXT: 'CLEAR_CUSTOMER_CONTEXT',
  SET_CUSTOMER_CONTEXT: 'SET_CUSTOMER_CONTEXT',
}

const customerOrdersTypes = typesForBaseType('FETCH_CUSTOMER_ORDERS')

const discountTypes = {
  ...typesForBaseType('CREATE_DISCOUNT'),
  ...typesForBaseType('DELETE_DISCOUNT'),
  ...typesForBaseType('FETCH_DISCOUNT'),
  ...typesForBaseType('UPDATE_DISCOUNT'),
}

const discountsTypes = typesForBaseType('FETCH_DISCOUNTS')

const idTokenTypes = typesForBaseType('FETCH_ID_TOKEN')

const operationsSearchTypes = typesForBaseType('SEARCH_OPERATIONS')

const operationTypes = {
  ...typesForBaseType('FETCH_OPERATION'),
  ...typesForBaseType('UPDATE_OPERATION'),
}

const orderTypes = {
  ...typesForBaseType('CANCEL_ORDER'),
  ...typesForBaseType('FETCH_ORDER'),
  ...typesForBaseType('MANUALLY_CANCEL_ORDER'),
}

const orderErrorsTypes = typesForBaseType('FETCH_ORDER_ERRORS')

const supplyOrderErrorsTypes = typesForBaseType('FETCH_SUPPLY_ORDER_ERRORS')

const ordersTypes = typesForBaseType('FETCH_ORDERS')

const ordersSearchTypes = typesForBaseType('SEARCH_ORDERS')

const orderedProductTypes = {
  ...typesForBaseType('CANCEL_ORDERED_PRODUCT'),
  ...typesForBaseType('FETCH_ORDERED_PRODUCT'),
}

const organisationTypes = typesForBaseType('FETCH_ORGANISATION')

const personTypes = typesForBaseType('FETCH_PERSON')

const productTypes = {
  ...typesForBaseType('APPROVE_PRODUCT'),
  ...typesForBaseType('DELETE_PRODUCT'),
  ...typesForBaseType('FETCH_ADMIN_PRODUCT'),
  ...typesForBaseType('FETCH_METADATA'),
  ...typesForBaseType('FETCH_OFFERING_TYPES'),
  ...typesForBaseType('FETCH_PRODUCT'),
  ...typesForBaseType('FETCH_PRODUCT_FAMILIES'),
  ...typesForBaseType('UPDATE_PRODUCT'),
  ...typesForBaseType('UPDATE_PRODUCT_CATEGORISATIONS'),
}

const productsTypes = {
  ...typesForBaseType('ADD_PRODUCT'),
  ...typesForBaseType('ADD_PRODUCT_CATEGORISATIONS'),
  ...typesForBaseType('FETCH_PRODUCTS'),
}

const productImageTypes = {
  ...typesForBaseType('ADD_PRODUCT_IMAGE'),
  ...typesForBaseType('UPDATE_PRODUCT_IMAGE'),
  ...typesForBaseType('DELETE_PRODUCT_IMAGE'),
}

const productsSearchTypes = typesForBaseType('SEARCH_PRODUCTS')

const ratingTypes = {
  ...typesForBaseType('FETCH_RATING'),
  ...typesForBaseType('FETCH_RATING_RULES'),
}

const ratingRulesTypes = {
  ...typesForBaseType('FETCH_RATING_RULES'),
  ...typesForBaseType('CREATE_RATING_RULES'),
  ...typesForBaseType('EXPORT_RATING_RULES'),
}

const redirectTypes = {
  SET_REDIRECT_LABEL: 'SET_REDIRECT_LABEL',
  SET_REDIRECT_URL: 'SET_REDIRECT_URL',
}

const requestedProductTypes = typesForBaseType('FETCH_REQUESTED_PRODUCT')

const requestedProductsTypes = typesForBaseType('FETCH_REQUESTED_PRODUCTS')

const shippingRatesTypes = typesForBaseType('FETCH_SHIPPING_RATES')

const supplierTypes = {
  ...typesForBaseType('FETCH_SUPPLIER'),
  ...typesForBaseType('UPDATE_SUPPLIER'),
}

const suppliersTypes = typesForBaseType('FETCH_SUPPLIERS')

const supplierThresholdDatesTypes = {
  ...typesForBaseType('FETCH_SUPPLIER_THRESHOLD_DATES'),
  ...typesForBaseType('ADD_CAPACITY_THRESHOLD_DATE_RANGE'),
}

const supplierOrderedProductsCapacitiesTypes = {
  ...typesForBaseType('FETCH_SUPPLIER_ORDERED_PRODUCTS_CAPACITIES'),
  ...typesForBaseType('SET_BLOCKED_DELIVERY_DATE'),
  ...typesForBaseType('SET_UNBLOCKED_DELIVERY_DATE'),
}

const supplyOrderTypes = {
  ...typesForBaseType('FETCH_SUPPLY_ORDER'),
  ...typesForBaseType('MANUALLY_CANCEL_SUPPLY_ORDER'),
}

const supplyOrdersTypes = typesForBaseType('FETCH_SUPPLY_ORDERS')

const supplyOrdersSearchTypes = typesForBaseType('SEARCH_SUPPLY_ORDERS')

const userInfoTypes = typesForBaseType('FETCH_USER_INFO')

export {
  basketTypes,
  basketsSearchTypes,
  basketsTypes,
  classifierOptionsTypes,
  classifiersTypes,
  currentProfileTypes,
  customerContextTypes,
  customerOrdersTypes,
  discountTypes,
  discountsTypes,
  idTokenTypes,
  operationTypes,
  operationsSearchTypes,
  orderTypes,
  orderedProductTypes,
  ordersSearchTypes,
  ordersTypes,
  orderErrorsTypes,
  supplyOrderErrorsTypes,
  organisationTypes,
  personTypes,
  productTypes,
  productsSearchTypes,
  productsTypes,
  ratingTypes,
  ratingRulesTypes,
  redirectTypes,
  requestedProductTypes,
  requestedProductsTypes,
  shippingRatesTypes,
  supplierTypes,
  suppliersTypes,
  supplierThresholdDatesTypes,
  supplierOrderedProductsCapacitiesTypes,
  supplyOrderTypes,
  supplyOrdersSearchTypes,
  supplyOrdersTypes,
  userInfoTypes,
  productImageTypes,
}
