import { get } from 'lib/reduxHelpers/thunks'
import { currentProfileTypes } from 'actions/types'

function fetchCurrentProfile() {
  let baseType = currentProfileTypes.FETCH_CURRENT_PROFILE
  let url = `${global.config.UI_PROXY_ENDPOINT}/api/profile/for_current_subject`

  return get({ baseType, url })
}

function clearCurrentProfile() {
  return { type: currentProfileTypes.CLEAR_FETCH_CURRENT_PROFILE }
}

export { fetchCurrentProfile, clearCurrentProfile }
