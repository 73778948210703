import { useEffect, useState } from 'react'
import useReactRouter from 'use-react-router'
import queryString from 'query-string'

const stripNullEntries = obj => {
  let pruned = {}
  Object.entries(obj).forEach(([key, value]) => {
    if (value) pruned[key] = value
  })
  return pruned
}

/**
 * A thin wrapper on React's `useState`, extending it to write  the state into the url's
 * query string.
 *
 * @param {*} initialValue
 * @param {object} options
 */
const useStateWithHistory = (
  initialValue,
  {
    name,
    transformNumbers = true,
    pushToHistory = false,
    transform = value => value,
  } = {}
) => {
  const {
    history,
    location,
    location: { search },
  } = useReactRouter()
  const [currentValue, setCurrentValue] = useState(initialValue)

  const paramsFromPath = () => queryString.parse(search)

  // Update the local state whenever the route's params change
  useEffect(() => {
    let nextValue = transform(paramsFromPath()[name])

    if (nextValue) {
      if (transformNumbers && !isNaN(nextValue)) nextValue = Number(nextValue)
      if (nextValue === 'true' || nextValue === 'false') nextValue = JSON.parse(nextValue)
    } else {
      nextValue = initialValue //TODO is this a good idea?
    }

    setCurrentValue(nextValue)
  }, [search])

  // Update the route's params
  const updateParam = nextValue => {
    // Remove nulls from the params to avoid query params with no value in the url
    let nextSearch = queryString.stringify(
      stripNullEntries({ ...paramsFromPath(), [name]: nextValue })
    )
    let path = `?${nextSearch}`

    if (pushToHistory) history.push({ ...location, search: path })
    else history.replace({ ...location, search: path })
  }

  return [currentValue, updateParam]
}

export default useStateWithHistory
