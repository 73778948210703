import React from 'react'
import PropTypes from 'prop-types'

import styles from './styles.scss'

const DropdownCheckboxOption = ({ id, name, checked, onSelect }) => {
  const handleSelect = e => {
    e.preventDefault()
    onSelect(id)
  }

  return (
    <li key={id}>
      <label className={styles.label}>
        {name}
        <input
          className={styles.checkbox}
          type='checkbox'
          checked={checked}
          onChange={handleSelect}
        />
      </label>
    </li>
  )
}

DropdownCheckboxOption.propTypes = {
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}

export default DropdownCheckboxOption
