import React, { Component } from 'react'
import { connect } from 'react-redux'

import { fetchCurrentProfile } from 'actions/currentProfileActions'
import { fetchIdToken } from 'actions/idTokenActions'
import { fetchLoggedIn } from 'lib/actions/loggedInActions'
import { fetchUserInfo } from 'actions/userInfoActions'
import { getRequestStatus } from 'lib/selectors/requestStateSelectors'
import { isCustomer } from 'selectors/userInfoSelectors'

import AppNav from './AppNav'
import UserDropdown from '@licnz/react-user-dropdown'

import styles from './styles.scss'

class AppBar extends Component {
  componentDidMount() {
    this.props.fetchLoggedIn()
  }

  componentDidUpdate(prevProps) {
    let {
      currentProfile,
      currentProfileRequestStatus,
      enqueueError,
      fetchCurrentProfile,
      fetchIdToken,
      fetchUserInfo,
      idToken,
      idTokenRequestStatus,
      isLoggedIn,
    } = this.props

    if (prevProps.isLoggedIn !== isLoggedIn) {
      fetchUserInfo()

      if (!currentProfile && !currentProfileRequestStatus) {
        fetchCurrentProfile().catch(error => {
          if (error.response.status === 404) {
            if (
              confirm(
                'We were unable to find your profile. You will be sent to the profile application to create one.'
              )
            ) {
              window.location = `${
                global.config.LIC_CUSTOMER_ENDPOINT
              }/profile/create?redirect_url=${encodeURIComponent(
                global.config.SHOP_ASSISTANT_FRONTEND_ENDPOINT
              )}&redirect_label=Shop%20Assistant`
            }
          }
        })
      }

      if (!idToken && !idTokenRequestStatus) {
        fetchIdToken().catch(err => {
          enqueueError({
            message: 'We were unable to fetch your authentication token',
            detail: err,
          })
        })
      }
    }
  }

  userDropdownOptions() {
    return [
      { label: 'My Profile', link: `${global.config.LIC_CUSTOMER_ENDPOINT}/profile` },
      {
        label: 'Sign out',
        link: `${global.config.IDENTITY_FRONTEND_ENDPOINT}/logout?logout_redirect_uri=${global.config.UI_PROXY_ENDPOINT}/logout`,
      },
    ]
  }

  render() {
    let { currentProfile, downForMaintenance, isCustomer } = this.props

    let logoPath = '/images/logo_lic.svg'

    let name = currentProfile
      ? currentProfile.preferred_name || currentProfile.preferred_username
      : null

    return (
      <div className={styles.header}>
        <div className={styles.gutters}>
          <div className={styles.headerContent}>
            <a className={styles.headerLogo} href='/'>
              <img src={logoPath} alt='LIC Logo' />
            </a>
            {!downForMaintenance ? (
              <div className={styles.headerMenu}>
                <div className={styles.headerMenuLarge}>
                  {!isCustomer ? <AppNav /> : null}
                  <div className={styles.headerMenuSecondary}>
                    <UserDropdown
                      userName={name}
                      menuOptions={this.userDropdownOptions()}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  fetchCurrentProfile,
  fetchLoggedIn,
  fetchUserInfo,
  fetchIdToken,
}

export { AppBar }

export default connect(state => {
  return {
    currentProfile: state.currentProfile.data,
    currentProfileRequestStatus: getRequestStatus(state.currentProfile.requestState),
    downForMaintenance: state.downForMaintenance,
    idToken: state.idToken.data,
    idTokenRequestStatus: getRequestStatus(state.idToken.requestState),
    isCustomer: isCustomer(state),
    isLoggedIn: state.loggedIn.data.isLoggedIn,
  }
}, mapDispatchToProps)(AppBar)
