import React, { Fragment } from 'react'
import moment from 'moment-timezone'

import TIMELINE_STATE_MAPPINGS from 'constants/timelineStateMappings'
import { ORDER_STATE_MAPPINGS } from 'constants/stateMappings'
import { convertToSentenceCase } from 'utils/util'

import OrderStatusTimeline from '../OrderStatusTimeline'
import SectionHeading from 'lib/components/headings/SectionHeading'
import ErrorDetail from '../../ErrorDetail'

import styles from './styles.scss'

const StatusData = ({ order, orderErrors }) => {
  const setOrderStatus = timestamps => {
    const timestampKeys = [
      'created_time',
      'ready_for_production_time',
      'in_production_time',
      'completed_time',
    ]
    const timestampLabels = [
      'Accepted',
      'Ready for production',
      'In production',
      'Completed',
    ]

    let orderStatus = timestampKeys.map((key, index) => {
      return {
        time: timestamps[key] || '',
        state: timestamps[key]
          ? TIMELINE_STATE_MAPPINGS.COMPLETE
          : TIMELINE_STATE_MAPPINGS.INCOMPLETE,
        text: timestampLabels[index],
      }
    })

    if (timestamps.cancelled_time) {
      // This inserts the cancelled timestamp in the correct place
      let cancelledTimeIndex = orderStatus.filter(status => {
        return moment(status.time).isBefore(timestamps.cancelled_time)
      }).length

      let cancelledTimeObject = {
        time: timestamps.cancelled_time,
        state: TIMELINE_STATE_MAPPINGS.COMPLETE,
        text: 'Cancelled',
      }

      orderStatus.splice(cancelledTimeIndex, 0, cancelledTimeObject)
    }

    return orderStatus
  }

  if (order.state === ORDER_STATE_MAPPINGS.CANCELLED)
    return (
      <SectionHeading>
        Order status: <span className={styles.cancelled}>Cancelled</span>
      </SectionHeading>
    )
  else if (order.state === ORDER_STATE_MAPPINGS.ERROR)
    return (
      <Fragment>
        <SectionHeading>
          Order status: <span className={styles.cancelled}>Error</span>
        </SectionHeading>
        {orderErrors && orderErrors.order_errors.length ? (
          <ErrorDetail errorList={orderErrors.order_errors} />
        ) : null}
      </Fragment>
    )
  else
    return (
      <Fragment>
        <SectionHeading>
          Order status: {convertToSentenceCase(order.state)}
        </SectionHeading>
        <OrderStatusTimeline orderStatus={setOrderStatus(order.timestamps)} />
      </Fragment>
    )
}

export default StatusData
