import React from 'react'
import PropTypes from 'prop-types'

import PillList from 'lib/components/PillList'
import ReactMarkdown from 'react-markdown/with-html'

import styles from './styles.scss'

const CategorisationsData = ({
  categories,
  specification,
  available_for_purchase,
  restricted,
}) => {
  const allowNode = node => {
    if (node.type === 'html') {
      return node.value.includes('<p') || node.value.includes('</p>')
    } else return true
  }

  return (
    <table className={styles.horizontalTable}>
      <tbody>
        <tr>
          <th>Categories</th>
          <td>
            {categories && categories.length > 0 ? (
              <PillList items={categories} />
            ) : (
              'No categories found for this product'
            )}
          </td>
        </tr>
        <tr>
          <th>Features</th>
          <td>
            {specification ? (
              <ReactMarkdown
                allowNode={allowNode}
                escapeHtml={false}
                source={specification}
              />
            ) : (
              'No features found for this product'
            )}
          </td>
        </tr>
        <tr>
          <th>Available for Purchase</th>
          <td>
            {available_for_purchase
              ? 'This product is available for purchase'
              : 'This product is not available for purchase'}
          </td>
        </tr>
        <tr>
          <th>Restricted</th>
          <td>
            {restricted ? 'This product is restricted' : 'This product is not restricted'}
          </td>
        </tr>
      </tbody>
    </table>
  )
}

CategorisationsData.propTypes = {
  specification: PropTypes.string,
  categories: PropTypes.array,
  available_for_purchase: PropTypes.bool,
  restricted: PropTypes.bool,
}

export default CategorisationsData
