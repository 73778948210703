import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  fetchSupplierThresholdDates,
  addCapacityThresholdDateRange,
} from 'actions/capacityManagementActions'
import { enqueueError } from 'lib/components/GlobalNotifications'

import { addBusinessDays } from 'utils/util'

import DateTime from 'lib/components/DateTime'
import moment from 'moment-timezone'

import ReactModal from 'react-modal'
import RequestWrapper from '@licnz/react-request-wrapper'
import FormField from 'lib/components/FormField'

import styles from './styles.scss'

const customStyles = {
  overlay: {
    animation: 'modal-in 0.15s ease-out 0s backwards',
    backgroundColor: 'rgba(0, 30, 98, .5)',
    zIndex: '4',
  },
}

const FROM_DATE = 'setValidFrom'
const TO_DATE = 'setValidTo'
const BASE_THRESHOLD = 90_000

const SupplierCapacityThresholdModal = ({ showModal, onClose, supplier }) => {
  const [validFrom, setValidFrom] = useState('')
  const [validTo, setValidTo] = useState('')
  const [maxCapacityThreshold, setMaxCapacityThreshold] = useState(BASE_THRESHOLD)
  const [validToMeta, setValidToMeta] = useState({})
  const [validFromMeta, setValidFromMeta] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const thresholdDates =
    useSelector(state => state.capacityManagement?.thresholds?.data) || {}

  let validFromAsIso = new Date().toISOString()
  let dateDependency = new Date().toISOString().split('T')[0]
  useEffect(() => {
    dispatch(fetchSupplierThresholdDates(supplier, validFromAsIso, null)).catch(err =>
      dispatch(enqueueError({ message: err.message }))
    )
  }, [dateDependency, supplier])

  const formatNumber = num => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const formatSupplierName = supplierName => {
    if (supplierName === 'zee-tags') {
      return 'Z Tags'
    }
    return supplierName
      .toLowerCase()
      .split('-')
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')
  }

  const handleOnClose = () => {
    // Clear state
    setValidFrom('')
    setValidTo('')
    setMaxCapacityThreshold(BASE_THRESHOLD)
    setValidToMeta({})
    setValidFromMeta({})
    // Close modal
    onClose()
  }

  const handleDateChange = e => {
    e.preventDefault()

    if (e.target.id === TO_DATE && validToMeta) {
      setValidToMeta({})
    }

    if (e.target.id === FROM_DATE && validFromMeta) {
      setValidFromMeta({})
    }

    const dateToBeSet = new Date(e.target.value)
    // Admins are only allowed to set capacity ranges 7 business days from the current date
    const earliestDateToSetRange = new Date(addBusinessDays(new Date(), 7, 'YYYY/MM/DD'))
    if (dateToBeSet < earliestDateToSetRange) {
      if (e.target.id === FROM_DATE) {
        setValidFrom(e.target.value)
        setValidFromMeta({
          touched: true,
          error: 'Must be 7 or more business days from today',
        })
      } else if (e.target.id === TO_DATE) {
        setValidTo(e.target.value)
        setValidToMeta({
          touched: true,
          error: 'Must be 8 or more business days from today',
        })
      }

      return
    }

    if (e.target.id === FROM_DATE) {
      setValidFrom(e.target.value)
    } else if (e.target.id === TO_DATE) {
      setValidTo(e.target.value)
    }
  }

  const dispatchAddCapacityAndRefresh = (fromDateISO, toDateISO = null) => {
    setIsLoading(true)
    dispatch(
      addCapacityThresholdDateRange(
        supplier,
        maxCapacityThreshold,
        fromDateISO,
        toDateISO
      )
    )
      .then(() =>
        dispatch(fetchSupplierThresholdDates(supplier, validFromAsIso, null))
          .then(() => setIsLoading(false))
          .catch(err => dispatch(enqueueError({ message: err.message })))
      )
      .catch(err => dispatch(enqueueError({ message: err.message })))
    setValidFrom('')
    setValidTo('')
    setMaxCapacityThreshold(BASE_THRESHOLD)
  }

  const addThresholdRange = () => {
    setValidToMeta({})
    setValidFromMeta({})

    const earliestDateToSetRange = new Date()
    // Admins are only allowed to set capacity ranges 7 days from the current date
    earliestDateToSetRange.setDate(earliestDateToSetRange.getDate() + 7)

    const fromDateObj = moment(validFrom) || null
    const toDateObj = moment(validTo) || null

    if (validFrom !== '' && validTo !== '' && fromDateObj > earliestDateToSetRange) {
      if (fromDateObj < toDateObj) {
        dispatchAddCapacityAndRefresh(fromDateObj, toDateObj)
      } else {
        setValidToMeta({ touched: true, error: 'Date to must be after date from' })
      }
    } else if (
      validFrom !== '' &&
      validTo === '' &&
      fromDateObj > earliestDateToSetRange
    ) {
      dispatchAddCapacityAndRefresh(fromDateObj, null)
    } else if (validFrom === '' && validTo !== '') {
      setValidFromMeta({ touched: true, error: 'Date from field must be filled' })
    } else {
      if (fromDateObj < earliestDateToSetRange) {
        setValidFromMeta({
          touched: true,
          error: 'Must be 7 or more business days from today',
        })
      } else {
        setValidFromMeta({ touched: true, error: 'Date from field must be filled' })
        setValidToMeta({
          touched: true,
          warning: 'Date to field is optionally filled',
        })
      }
    }
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel='Set Supplier Capacity Threshold Modal'
      onRequestClose={handleOnClose}
      className={styles.content}
      style={customStyles}
      ariaHideApp={false}
    >
      <img
        class={styles.closeButton}
        onClick={handleOnClose}
        src='/images/close-icon.svg'
        alt='close button'
      />
      <h4 className={styles.titleUnderline}>
        <span className={styles.titleUnderlineSpan}>{`Set ${formatSupplierName(
          supplier
        )} capacity threshold`}</span>
      </h4>
      <div className={styles.controls}>
        <FormField
          name={FROM_DATE}
          label='From'
          inline
          type='date'
          onChange={handleDateChange}
          value={validFrom}
          meta={validFromMeta}
          className={styles.formControl}
        />
        <FormField
          name={TO_DATE}
          label='To'
          className={styles.pullLeft}
          inline
          type='date'
          onChange={handleDateChange}
          value={validTo}
          meta={validToMeta}
          className={styles.formControl}
        />
        <FormField
          name='capacityThresholdAmount'
          label='Threshold'
          className={styles.pullLeft}
          inline
          type='number'
          onChange={e => {
            e.preventDefault()
            setMaxCapacityThreshold(e.target.value)
          }}
          value={maxCapacityThreshold}
          className={styles.formControl}
        />
        <button
          className={['buttonSecondary', styles.setButton, styles.formControl].join(' ')}
          onClick={addThresholdRange}
        >
          Set
        </button>
      </div>
      <hr />
      <RequestWrapper loading={isLoading}>
        <table>
          <thead>
            <tr>
              <th>From</th>
              <th className={styles.pullLeft}>To</th>
              <th className={styles.pullLeft}>Threshold</th>
            </tr>
          </thead>
          <tbody>
            {thresholdDates.items &&
              thresholdDates.items.map(row => {
                return (
                  <tr key={row.id}>
                    <td>
                      <DateTime date={row.valid_from} format='DD/MM/YYYY' />
                    </td>
                    <td className={styles.pullLeft}>
                      {row.valid_to ? (
                        <DateTime date={row.valid_to} format='DD/MM/YYYY' />
                      ) : (
                        '-'
                      )}
                    </td>
                    <td className={styles.pullLeft}>{formatNumber(row.threshold)}</td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </RequestWrapper>
    </ReactModal>
  )
}

SupplierCapacityThresholdModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  supplier: PropTypes.string,
}

export default SupplierCapacityThresholdModal
