import { customerContextTypes } from 'actions/types'

function setCustomerContext(customerContext) {
  return {
    type: customerContextTypes.SET_CUSTOMER_CONTEXT,
    payload: customerContext,
  }
}

function clearCustomerContext() {
  return { type: customerContextTypes.CLEAR_CUSTOMER_CONTEXT }
}

export { clearCustomerContext, setCustomerContext }
