import React from 'react'
import PropTypes from 'prop-types'

import OrderCard from './OrderCard'
import QueryList from 'lib/components/QueryList'

function OrdersList({ orders, isAdmin }) {
  return (
    <QueryList>
      {orders.map(order => (
        <OrderCard key={order.id} order={order} isAdmin={isAdmin} />
      ))}
    </QueryList>
  )
}

OrdersList.propTypes = {
  orders: PropTypes.array.isRequired,
  isAdmin: PropTypes.bool.isRequired,
}

export default OrdersList
