import React from 'react'
import PropTypes from 'prop-types'

import RequestWrapper from '@licnz/react-request-wrapper'

import styles from './styles.scss'

const Button = ({
  autoFocus,
  children,
  className,
  disabled,
  onClick,
  requestState,
  type,
}) => {
  const setClasses = () => {
    return (
      className &&
      className
        .split(' ')
        .map(buttonClass => styles[buttonClass])
        .join(' ')
    )
  }

  return (
    <button
      type={type || 'button'}
      className={setClasses()}
      onClick={onClick}
      disabled={disabled}
      autoFocus={autoFocus}
    >
      <RequestWrapper requestState={requestState} color='white' size={3}>
        {children}
      </RequestWrapper>
    </button>
  )
}

Button.propTypes = {
  autoFocus: PropTypes.bool,
  className: (props, propName, componentName) => {
    let error
    const prop = props[propName]
    if (typeof prop !== 'string')
      error = new Error(
        `'${componentName}' expected className of type 'string'. Received ${prop}`
      )
    prop.split(' ').forEach(token => {
      const validClasses = [
        'buttonPrimary',
        'buttonSecondary',
        'buttonOutline',
        'buttonOutlineInverted',
        'buttonSmall',
        'buttonMedium',
        'buttonLarge',
        'buttonBlock',
        'buttonLink',
        'buttonDelete',
      ]
      if (!validClasses.includes(token)) {
        error = new Error(
          `'${componentName}' expects all classNames to be one of ${validClasses}. Received ${token}`
        )
      }
    })
    return error
  },
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  requestState: PropTypes.string,
  type: PropTypes.string,
}

export default Button
