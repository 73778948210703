import { combineReducers } from 'redux'
import dataReducer from 'lib/reducers/dataReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import supplierCapacityThresholdReducers from '../supplierCapacityThresholdReducers'
import { supplierOrderedProductsCapacitiesTypes } from 'actions/types'

const initialState = null //{data: }

const baseType =
  supplierOrderedProductsCapacitiesTypes.FETCH_SUPPLIER_ORDERED_PRODUCTS_CAPACITIES

const reducer = combineReducers({
  data: dataReducer({ initialState, baseType }),
  requestState: requestStateReducer(baseType),
  thresholds: supplierCapacityThresholdReducers,
})

export default reducer
