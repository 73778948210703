import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import pluralize from 'pluralize'

import { convertToSentenceCase, titleCase } from 'utils/util'
import {
  formatPercentageValue,
  formatPerUnitValue,
  getDiscountState,
} from 'utils/discountUtils'

import DateTime from 'lib/components/DateTime'
import Currency from 'lib/components/Currency'
import SectionHeading from 'lib/components/headings/SectionHeading'
import StatusInfo from 'lib/components/StatusInfo'
import { Link } from 'react-router-dom'

import styles from './styles.scss'

class DiscountDetail extends Component {
  getRangeDescription(range) {
    let { from, to, value, value_method } = range

    if (value_method === 'per_unit') {
      if (to) {
        return (
          <Fragment>
            <Currency amount={formatPerUnitValue(value)} /> per unit for orders between{' '}
            {from} and {to}
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            <Currency amount={formatPerUnitValue(value)} /> per unit for orders over{' '}
            {from}
          </Fragment>
        )
      }
    } else {
      return <Fragment>{formatPercentageValue(value)}% per unit off</Fragment>
    }
  }

  render() {
    let { discount } = this.props
    if (!discount) return null

    let discountState = getDiscountState(discount.valid_from, discount.valid_to)
    let pricePattern = discount.applied_on_price_pattern.split(':').join(' ')

    return (
      <div className={styles.section}>
        <SectionHeading>Discount details</SectionHeading>
        <table className={styles.horizontalTable}>
          <tbody>
            <tr>
              <th>Name</th>
              <td>{discount.name}</td>
            </tr>
            <tr>
              <th>Price applied to</th>
              <td>{convertToSentenceCase(pricePattern)}</td>
            </tr>
            <tr>
              <th>Status</th>
              <td>
                <StatusInfo
                  id={discountState}
                  label={convertToSentenceCase(discountState)}
                />
              </td>
            </tr>
            <tr>
              <th>Active from</th>
              <td>
                <DateTime date={discount.valid_from} format='DD/MM/YY, h:mm A' />
              </td>
            </tr>
            <tr>
              <th>Active to</th>
              <td>
                <DateTime date={discount.valid_to} format='DD/MM/YY, h:mm A' />
              </td>
            </tr>
            <tr>
              <th>Application type</th>
              <td>
                {discount.authorisations.length
                  ? titleCase(discount.authorisations.join(', '))
                  : 'Automatic'}
              </td>
            </tr>
            {discount.ordered_quantity_volume_ranges &&
            discount.ordered_quantity_volume_ranges.length ? (
              <tr>
                <th>
                  {pluralize(
                    'Discount value',
                    discount.ordered_quantity_volume_ranges.length
                  )}
                </th>
                <td>
                  <ul>
                    {discount.ordered_quantity_volume_ranges
                      .sort((a, b) => a.from - b.from)
                      .map(range => {
                        let key = `${range.from}-${range.to}`
                        return <li key={key}>{this.getRangeDescription(range)}</li>
                      })}
                  </ul>
                </td>
              </tr>
            ) : null}
            <tr>
              <th>Qualifying products</th>
              <td>
                <ul>
                  {discount.qualifying_product_cohort.map(slug => {
                    return (
                      <li key={slug}>
                        <Link to={{ pathname: `/products/${slug}` }}>{slug}</Link>
                      </li>
                    )
                  })}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

DiscountDetail.propTypes = {
  discount: PropTypes.object,
}

export default DiscountDetail
