import { combineReducers } from 'redux'
import dataReducer from 'lib/reducers/dataReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { productTypes } from 'actions/types'

const initialState = null
const baseType = productTypes.FETCH_PRODUCT
const deleteProductBaseType = productTypes.DELETE_PRODUCT
const updateProductBaseType = productTypes.UPDATE_PRODUCT
const approveProductBaseType = productTypes.APPROVE_PRODUCT

const reducer = combineReducers({
  requestState: requestStateReducer(baseType),
  data: dataReducer({ initialState, baseType }),
  deleteProduct: requestStateReducer(deleteProductBaseType),
  updateProduct: requestStateReducer(updateProductBaseType),
  approveProduct: requestStateReducer(approveProductBaseType),
})

export default reducer
