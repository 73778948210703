import { post, get } from 'lib/reduxHelpers/thunks'
import axios from 'lib/utils/axios'
import csv from 'csvtojson'
import { proxyUrl } from 'lib/utils/proxy'

import { ratingRulesTypes } from 'actions/types'
import { setDownForMaintenance } from 'lib/actions/downForMaintenanceActions'

function fetchRatingRules() {
  let baseType = ratingRulesTypes.FETCH_RATING_RULES
  let link = `${global.config.RATING_SERVICE_ENDPOINT}/api/ratable_products/export`
  let url = proxyUrl({ link })

  return function (dispatch) {
    dispatch({ type: baseType })
    return axios
      .get(url)
      .then(response => {
        let csvText = response.data
        return csv().fromString(csvText)
      })
      .then(allRatingRules => {
        dispatch({ type: `${baseType}_FULFILLED`, payload: allRatingRules })
        return allRatingRules
      })
      .catch(err => {
        dispatch({ type: `${baseType}_REJECTED`, payload: err })

        if (err.response.status === 503) {
          dispatch(setDownForMaintenance(true))
        } else {
          throw err
        }
      })
  }
}

function createRatingRules({ rootProductId, rules, versionedAt }) {
  let baseType = ratingRulesTypes.CREATE_RATING_RULES
  let link = `${global.config.RATING_SERVICE_ENDPOINT}/api/ratable_products/create_collection`
  let url = proxyUrl({ link })
  let body = {
    product_rules: {
      root_product_id: rootProductId,
      rules,
    },
  }
  if (versionedAt) body.product_rules.versioned_at = versionedAt

  return post({ baseType, body, url })
}

function exportRatingRules(versionedFrom = '', versionedTo = '') {
  const baseType = ratingRulesTypes.EXPORT_RATING_RULES
  const link = `${global.config.RATING_SERVICE_ENDPOINT}/api/ratable_products/export`

  let params = {}

  if (versionedFrom !== '') {
    params.versioned_from = new Date(versionedFrom).toISOString()
  }
  if (versionedTo !== '') {
    params.versioned_to = new Date(versionedTo).toISOString()
  }

  const url = proxyUrl({ link, params })

  return get({ baseType, url })
}

function clearRatingRules() {
  return { type: ratingRulesTypes.CLEAR_FETCH_RATING_RULES }
}

export { clearRatingRules, createRatingRules, fetchRatingRules, exportRatingRules }
