import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from 'lib/components/Button'
import ConfirmationModal from 'lib/components/ConfirmationModal'

class CancelOrderedProductModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }

    this.handleConfirm = this.handleConfirm.bind(this)
    this.handleToggleModal = this.handleToggleModal.bind(this)
  }

  handleConfirm() {
    this.props.handleCancel()
    this.handleToggleModal()
  }

  handleToggleModal() {
    this.setState({ showModal: !this.state.showModal })
  }

  render() {
    let { buttonClassName, buttonText } = this.props

    return (
      <div>
        <Button onClick={this.handleToggleModal} className={buttonClassName}>
          {buttonText}
        </Button>
        <ConfirmationModal
          onClose={this.handleToggleModal}
          onConfirm={this.handleConfirm}
          showModal={this.state.showModal}
        >
          <p>Are you sure you want to cancel this ordered product?</p>
          {/* <p> TODO: extra copy needed for cancelling singular ordered products </p> */}
        </ConfirmationModal>
      </div>
    )
  }
}

CancelOrderedProductModal.propTypes = {
  buttonClassName: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  handleCancel: PropTypes.func.isRequired,
}

export default CancelOrderedProductModal
