import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import queryString from 'query-string'

import { enqueueError } from 'lib/components/GlobalNotifications'
import { fetchSuppliers } from 'actions/suppliersActions'

import DropdownFilters from 'lib/components/DropdownFilters'
import FormField from 'lib/components/FormField'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import Pagination from '@licnz/react-pagination'
import SupplierCapacityData from './SupplierCapacityData/SupplierCapacityData'
import SupplierCapacityThresholdModal from 'lib/components/SupplierCapacityThresholdModal'
import { extractIdFromUrl } from 'utils/util'
import {
  fetchSupplierOrderedProductsCapacities,
  blockCapacityForDay,
  unBlockCapacityForDay,
} from 'actions/capacityManagementActions'

import styles from './styles.scss'

const initialState = {
  page: 1,
  perPage: 20,
}

const SUPPLIER_LIC = 'LIC'

const SupplierCapacity = ({ history, location }) => {
  const [supplierCapacityFilters, setSupplierCapacityFilters] = useState([])
  const [showModal, setShowModal] = useState(false)

  const dispatch = useDispatch()

  const suppliers = useSelector(state => state.suppliers.data)
  const capacityManagement = useSelector(state => state.capacityManagement)

  let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
  let { orderFrom, orderTo, hideSearchControls, supplierId } = params

  const fetchOrderedProductsCapacities = () => {
    let { page, perPage } = params

    const validFrom = new Date(orderFrom)
    const validTo = orderTo ? new Date(orderTo) : null
    if (validFrom < validTo || (!validTo && validFrom)) {
      dispatch(
        fetchSupplierOrderedProductsCapacities(
          supplierId,
          validFrom.toISOString(),
          validTo?.toISOString(),
          page ?? initialState.page,
          perPage ?? initialState.perPage
        )
      ).catch(err => dispatch(enqueueError({ message: err.message })))
    }
  }

  useEffect(() => {
    if (supplierId && (orderFrom || orderTo)) {
      fetchOrderedProductsCapacities()
    }
  }, [location.search])

  useEffect(() => {
    if (location.search) {
      let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
      if (Object.keys(params).length === 0) {
        updateUrl(initialState)
      }
    }
  }, [location])

  useEffect(() => {
    if (suppliers.length) {
      setSupplierCapacityFilters(dropdownFilterOptions(suppliers))
    } else {
      dispatch(fetchSuppliers()).catch(err =>
        dispatch(enqueueError({ message: err.message }))
      )
    }
  }, [suppliers])

  const handleToggleBlockDeliveryDate = (
    deliveryDate,
    supplierUrl,
    id,
    blocked = false
  ) => {
    if (!blocked) {
      dispatch(blockCapacityForDay(deliveryDate, supplierUrl, id))
        .then(() => fetchOrderedProductsCapacities())
        .catch(err => dispatch(enqueueError({ message: err.message })))
    } else if (id) {
      dispatch(unBlockCapacityForDay(supplierUrl, id))
        .then(() => fetchOrderedProductsCapacities())
        .catch(err => dispatch(enqueueError({ message: err.message })))
    }
  }

  const handleBack = () => {
    history.goBack()
  }

  const handleToggleModal = () => {
    // Before modal closes fetch ordered products capacities
    // Done before state is updated because setState is async and cant be relied
    // upon to be up to date in this situaltion
    if (showModal) {
      fetchOrderedProductsCapacities()
    }
    setShowModal(!showModal)
  }

  const handleChangeFieldValue = e => {
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let { perPage, orderFrom, orderTo, status, supplierId } = params

    updateUrl(
      { page: 1, perPage: Number(perPage ? Number(perPage) : initialState.perPage) },
      {
        orderFrom,
        orderTo,
        status,
        supplierId,
        [e.target.name]: e.target.value,
      }
    )
  }

  const handleClearFilters = () => {
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let { supplierId } = params
    updateUrl(initialState, { supplierId })
  }

  const handleUpdateFilters = filters => {
    let params = queryString.parse(location.search, { arrayFormat: 'bracket' })
    let { perPage, orderFrom, orderTo } = params

    let newFilters = {}
    filters.forEach(filter => {
      newFilters[filter.id] = filter.multiSelect
        ? filter.selectedOptions
        : filter.selectedOption
    })

    updateUrl(
      { page: 1, perPage: Number(perPage ? Number(perPage) : initialState.perPage) },
      {
        ...newFilters,
        orderFrom,
        orderTo,
      }
    )
  }

  const updateUrl = (updateParams, filters) => {
    let currentParams = queryString.parse(location.search, { arrayFormat: 'bracket' })
    if (filters)
      currentParams = {
        page: currentParams.page,
        perPage: currentParams.perPage,
        ...filters,
      }
    let params = { ...currentParams, ...updateParams }

    history.push(
      `/suppliers_capacity?${queryString.stringify(params, { arrayFormat: 'bracket' })}`
    )
  }

  const handleChangePage = page => {
    updateUrl({ page })
  }

  const dropdownFilterOptions = suppliers => {
    let filterOptions = []

    let suppliersFiltered = suppliers.filter(supplier => supplier.name !== SUPPLIER_LIC)

    let supplierOptions = suppliersFiltered.map(supplier => {
      return {
        id: supplier.id,
        name: global.FEATURE_FLAGS.Z_TAGS_BRANDING
          ? supplier.name === 'Zee Tags'
            ? 'Z Tags'
            : supplier.name
          : supplier.name,
      }
    })

    filterOptions.unshift({
      title: 'Supplier',
      id: 'supplierId',
      multiSelect: false,
      options: supplierOptions,
    })

    return filterOptions
  }

  let page = params.page ? Number(params.page) : initialState.page
  let perPage = params.perPage ? Number(params.perPage) : initialState.perPage

  let newFilters = supplierCapacityFilters.map(filter => {
    let newFilter = { ...filter }

    if (filter.multiSelect) {
      newFilter.selectedOptions = params[filter.id] || []
    } else {
      newFilter.selectedOption = params[filter.id]
    }
    return newFilter
  })

  return (
    <PageSection>
      <PageHeading
        heading='Capacity management'
        size='h2'
        onAction={handleBack}
        actionText='Back'
      />
      {hideSearchControls !== 'yes' ? (
        <>
          <DropdownFilters
            filters={newFilters}
            onChange={handleUpdateFilters}
            onClearFilters={handleClearFilters}
          />
          <span className={styles.controls}>
            <FormField
              name='orderFrom'
              label='Order from'
              inline
              type='date'
              onChange={handleChangeFieldValue}
              value={orderFrom}
            />
            <FormField
              name='orderTo'
              label='Order to'
              inline
              type='date'
              onChange={handleChangeFieldValue}
              value={orderTo}
            />

            <button
              className={['buttonPrimary', styles.button].join(' ')}
              onClick={handleToggleModal}
            >
              Set Supplier Threshold
            </button>
          </span>
          <hr />
        </>
      ) : null}
      <SupplierCapacityThresholdModal
        showModal={showModal}
        onClose={handleToggleModal}
        supplier={extractIdFromUrl(supplierId)}
      />
      <SupplierCapacityData
        tableData={capacityManagement.data?.items || []}
        handleToggleBlockDeliveryDate={handleToggleBlockDeliveryDate}
        supplierUrl={supplierId}
      />
      <Pagination
        border={false}
        currentPage={page}
        onChangePage={handleChangePage}
        pageSize={perPage}
        totalItems={capacityManagement.data?.total_results || 0}
      />
    </PageSection>
  )
}

export default SupplierCapacity
