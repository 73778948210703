import React from 'react'

import ReactTags from 'react-tag-autocomplete'

import styles from './styles.scss'

const Autocomplete = ({
  allowNew,
  onAdd,
  onBlur,
  onChange,
  onDelete,
  onValidate,
  placeholder,
  suggestions,
  suggestionsFilter,
  tags,
}) => {
  const handleAddition = tag => {
    tags.push(tag)
    onAdd(tags)
  }

  const handleDelete = i => {
    tags.splice(i, 1)
    onDelete(tags)
  }

  const reactTagsClassNames = {
    root: styles.reactTags,
    selected: styles.reactTagsSelected,
    selectedTag: styles.reactTagsSelectedTag,
    selectedTagName: styles.reactTagsSelectedTagName,
    search: styles.reactTagsSearch,
    searchInput: styles.reactTagsSearchInput,
    suggestions: styles.reactTagsSuggestions,
  }

  return (
    <ReactTags
      allowNew={allowNew}
      autofocus={false}
      classNames={reactTagsClassNames}
      handleAddition={handleAddition}
      handleBlur={onBlur}
      handleDelete={handleDelete}
      handleInputChange={onChange}
      handleValidate={onValidate}
      maxSuggestionsLength={10}
      placeholder={placeholder}
      suggestions={suggestions}
      suggestionsFilter={suggestionsFilter}
      tags={tags}
    />
  )
}

export default Autocomplete
