// Importing `fetch` due to `apollo-link-http` raising
// a warning of not having `fetch` globally available.
// @see https://github.com/apollographql/apollo-link/issues/493
import { fetch } from 'apollo-env'
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

function httpLink({ apiUrl, idToken }) {
  return new HttpLink({
    uri: apiUrl,
    headers: {
      authorization: `Bearer ${idToken}`,
    },
    fetch,
  })
}

function client({ apiUrl, idToken }) {
  return new ApolloClient({
    link: httpLink({ apiUrl, idToken }),
    cache: new InMemoryCache(),
  })
}

export default client
