import { useState, useEffect, useCallback } from 'react';
const cache = {};

const useCachedState = (key, defaultVal = null) => {
  // This hooks is similar to useState, but cache's the value in an in memory variable.
  const [state, _setState] = useState(cache[key] !== undefined ? cache[key] : defaultVal);
  useEffect(() => {
    if (cache[key] == undefined) {
      cache[key] = defaultVal;
    }
  }, [key])
  const setState = useCallback((val) => {
    cache[key] = val;
    _setState(val);
  }, [key]);

  return [state, setState];
};
export default useCachedState;