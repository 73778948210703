import { get, post, destroy, put } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { discountTypes } from 'actions/types'
import { generateDiscountRuleFromFormValues } from 'utils/discountUtils'

function fetchDiscount(id) {
  let baseType = discountTypes.FETCH_DISCOUNT
  let link = `${global.config.RATING_SERVICE_ENDPOINT}/api/ratable_products/discount_rules/${id}`
  let url = proxyUrl({ link })

  return get({ baseType, url })
}

function clearDiscount() {
  return { type: discountTypes.CLEAR_FETCH_DISCOUNT }
}

function createDiscount(formValues) {
  let baseType = discountTypes.CREATE_DISCOUNT
  let link = `${global.config.RATING_SERVICE_ENDPOINT}/api/ratable_products/discount_rules`
  let url = proxyUrl({ link })
  let body = generateDiscountRuleFromFormValues(formValues)

  return post({ baseType, url, body })
}

function deleteDiscount(id) {
  let baseType = discountTypes.DELETE_DISCOUNT
  let link = `${global.config.RATING_SERVICE_ENDPOINT}/api/ratable_products/discount_rules/${id}`
  let url = proxyUrl({ link })

  return destroy({ baseType, url })
}

function updateDiscount(values) {
  let baseType = discountTypes.UPDATE_DISCOUNT
  let link = `${global.config.RATING_SERVICE_ENDPOINT}/api/ratable_products/discount_rules/${values.id}`
  let url = proxyUrl({ link })
  let body = values
  return put({ baseType, url, body })
}

export { clearDiscount, createDiscount, deleteDiscount, updateDiscount, fetchDiscount }
