import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

import { SearchBar } from '@licnz/react-api-search'

import styles from './styles.scss'

class SearchBarContainer extends Component {
  constructor() {
    super()

    this.state = {
      q: '',
      page: 1,
      per_page: 10,
    }

    this.handleResetSearchQuery = this.handleResetSearchQuery.bind(this)
    this.handleSearchChange = this.handleSearchChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    let { q } = this.props
    this.setState({ q })
  }

  componentDidUpdate(prevProps) {
    let { q } = this.props

    if (prevProps.q !== q) {
      this.setState({ q })
    }
  }

  updateUrl(updateParams) {
    let { location, searchUrl } = this.props
    let currentParams = queryString.parse(location.search)

    if (updateParams.q === '') {
      delete updateParams.q
      delete currentParams.q
    }

    let params = { ...currentParams, ...updateParams }

    this.props.history.push(`${searchUrl}?${queryString.stringify(params)}`)
  }

  handleSearchChange(q) {
    this.setState({ q })
    if (q === '') {
      this.updateUrl({ q })
    }
  }

  handleResetSearchQuery() {
    this.setState({ q: '' })
  }

  handleSubmit() {
    let { q, page, per_page } = this.state
    this.updateUrl({ q, page, per_page })
  }

  render() {
    let { q } = this.state
    let { placeholder, submitting } = this.props

    return (
      <div className={styles.searchBarContent}>
        <SearchBar
          onChange={this.handleSearchChange}
          onReset={this.handleResetSearchQuery}
          onSubmit={this.handleSubmit}
          placeholder={placeholder || 'Search...'}
          value={q}
          submitting={submitting}
        />
      </div>
    )
  }
}

SearchBarContainer.propTypes = {
  placeholder: PropTypes.string,
  q: PropTypes.string.isRequired,
  searchUrl: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
}

export { SearchBarContainer }
export default withRouter(SearchBarContainer)
