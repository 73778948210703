import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { userInfoTypes } from 'actions/types'

function fetchUserInfo() {
  let baseType = userInfoTypes.FETCH_USER_INFO
  let link = `${global.config.IDENTITY_FRONTEND_ENDPOINT}/userinfo`
  let url = proxyUrl({ link })

  return get({ baseType, url })
}

function clearUserInfo() {
  return { type: userInfoTypes.CLEAR_FETCH_USER_INFO }
}

export { fetchUserInfo, clearUserInfo }
