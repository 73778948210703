import React, { Fragment } from 'react'

import { formatSlugAsTitle } from 'utils/util'
import { findNodeInTree } from 'utils/treeUtils'
import {
  BIRTH_ID_URNS,
  BIRTH_ID_RANGE_URN,
  BIRTH_ID_SEQ_NUMBER_RANGE_URN,
  MANAGEMENT_NUMBER_URNS,
  MANAGEMENT_NUMBER_RANGE_URN,
  NAIT_PRODUCTION_TYPE_URN,
} from 'constants/productConfiguration'

import SectionHeading from 'lib/components/headings/SectionHeading'

import styles from './styles.scss'

const RequestedProductConfiguration = ({ product, productComponents }) => {
  if (!productComponents) return null

  // Check to see that there are any configuration details to be displayed
  let configurableComponents = Object.values(productComponents).filter(component => {
    return component.configProperties || component.colour || component.size
  })
  if (!configurableComponents.length) return null

  const renderComponentTitle = ({ name, slug }) => {
    return <p className={styles.componentTitle}>{name || formatSlugAsTitle(slug)}</p>
  }

  const shouldRenderComponent = component => {
    return (
      component.productSlug &&
      (component.size || component.colour || component.configProperties)
    )
  }

  const renderColour = component => {
    let key = `${component.productSlug}-${component.colour.id}`
    return (
      <li key={key}>
        <span>Colour:</span>
        <span>{component.colour.name}</span>
      </li>
    )
  }

  const renderSize = component => {
    let key = `${component.productSlug}-${component.size.id}`
    return (
      <li key={key}>
        <span>Size:</span>
        <span>{component.size.name}</span>
      </li>
    )
  }

  const renderBirthIdProperties = component => {
    let birthIds = component.configProperties.filter(property => {
      return property.id === BIRTH_ID_RANGE_URN
    })
    if (!birthIds.length) return null

    let ranges = birthIds.map(property => property.value)
    let rangeValue = ranges.join(', ')

    return (
      <Fragment>
        <li>
          <span>Participant code:</span>
          <span>{birthIds[0].prefix}</span>
        </li>
        <li>
          <span>Birth ID year:</span>
          <span>{birthIds[0].year}</span>
        </li>
        <li>
          <span>Birth ID numbers:</span>
          <span>{rangeValue}</span>
        </li>
      </Fragment>
    )
  }

  const renderBirthIdSequence = component => {
    let birthIds = component.configProperties.filter(property => {
      return property.id === BIRTH_ID_SEQ_NUMBER_RANGE_URN
    })
    if (!birthIds.length) return null

    let rangeValue = birthIds.map(property => property.value).join(', ')

    return (
      <li>
        <span>Birth ID numbers:</span>
        <span>{rangeValue}</span>
      </li>
    )
  }

  const renderManagementNumbers = component => {
    let managementNumbers = component.configProperties.filter(property => {
      return property.id === MANAGEMENT_NUMBER_RANGE_URN
    })
    if (!managementNumbers.length) return null

    let rangeValue = managementNumbers.map(property => property.value).join(', ')

    return (
      <li>
        <span>Management numbers:</span>
        <span>{rangeValue}</span>
      </li>
    )
  }

  const renderConfigurationProperties = component => {
    return component.configProperties.map(property => {
      let isNumberRange =
        BIRTH_ID_URNS.includes(property.id) ||
        MANAGEMENT_NUMBER_URNS.includes(property.id)

      if (!property.value || !property.displayName || isNumberRange) return null
      let key = `${component.productSlug}-${property.id}`

      let value = property.value

      // Retrieves the production type display name from the product
      if (property.id === NAIT_PRODUCTION_TYPE_URN) {
        let node = findNodeInTree({
          tree: product,
          keyToMatch: 'slug',
          valueToMatch: component.productSlug,
        })
        let configReq =
          node &&
          node.config_requirements.find(cr => {
            return cr.identity === NAIT_PRODUCTION_TYPE_URN
          })
        let enumeration =
          configReq &&
          configReq.enumeration.find(currentEnum => {
            return currentEnum.id === property.value
          })
        value = enumeration && enumeration.value
      }

      return (
        <li key={key}>
          <span>{`${property.displayName}:`}</span>
          <span>{value}</span>
        </li>
      )
    })
  }

  return (
    <div className={styles.productConfiguration}>
      <SectionHeading>Product customisation</SectionHeading>
      {Object.keys(productComponents).map(componentKey => {
        let component = productComponents[componentKey]
        if (shouldRenderComponent(component)) {
          return (
            <Fragment key={component.productSlug}>
              {renderComponentTitle({
                name: component.name,
                slug: component.productSlug,
              })}
              <ul>
                {component.colour && renderColour(component)}
                {component.size && renderSize(component)}
                {component.configProperties && renderConfigurationProperties(component)}
                {component.configProperties && renderBirthIdProperties(component)}
                {component.configProperties && renderBirthIdSequence(component)}
                {component.configProperties && renderManagementNumbers(component)}
              </ul>
            </Fragment>
          )
        }
      })}
    </div>
  )
}

export default RequestedProductConfiguration
