import React, { Component } from 'react'
import axios from 'axios'

import ReactModal from 'react-modal'
import Button from 'lib/components/Button'

import styles from './styles.scss'

const POLL_INTERVAL = 3000
const customStyles = {
  overlay: {
    animation: 'modal-in 0.15s ease-out 0s backwards',
    backgroundColor: 'rgba(0, 30, 98, .5)',
    zIndex: '4',
  },
}

/**
 * This component is responsible for keeping the UI up to date with the latest
 * deployed version. It fetchs a `version.json` file on mount, stores that version
 * in local state, then regularly polls the `version.json` file to check for changes.
 * If a change occurs (i.e. an new version has been deployed), the user is alerted
 * and the browser is refreshed to fetch the latest version.
 */
class VersionManager extends Component {
  constructor() {
    super()

    this.state = {
      pollingIntervalId: null,
      currentAppVersion: undefined,
      appIsUpToDate: true,
    }

    this.refreshBrowser = this.refreshBrowser.bind(this)
  }

  componentDidMount() {
    this.getVersion().then(currentAppVersion => {
      let pollingIntervalId = setInterval(() => this.checkVersion(), POLL_INTERVAL)
      this.setState({ currentAppVersion, pollingIntervalId })
    })
  }

  componentDidUpdate(prevProps, prevState) {
    let { appIsUpToDate, pollingIntervalId } = this.state

    if (prevState.appIsUpToDate && !appIsUpToDate) {
      clearInterval(pollingIntervalId)
    }
  }

  checkVersion() {
    return this.getVersion().then(latestAppVersion => {
      if (this.state.currentAppVersion !== latestAppVersion) {
        this.setState({ appIsUpToDate: false })
      }
    })
  }

  getVersion() {
    return axios.get('/version.json').then(response => response.data.version)
  }

  refreshBrowser() {
    location.reload()
  }

  render() {
    return (
      <ReactModal
        isOpen={!this.state.appIsUpToDate}
        contentLabel='Outdated Application'
        className={styles.content}
        style={customStyles}
        ariaHideApp={false}
      >
        <p>
          Your LIC Shop Assistant application is out of date. We will now refresh your
          browser to update.
        </p>
        <Button className='buttonPrimary buttonBlock' onClick={this.refreshBrowser}>
          Ok
        </Button>
      </ReactModal>
    )
  }
}

export default VersionManager
