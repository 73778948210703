import { combineReducers } from 'redux'
import createReducer from 'lib/reducers/createReducer'
import requestStateReducer from 'lib/reducers/requestStateReducer'
import { supplyOrderErrorsTypes } from 'actions/types'

const INITIAL_STATE = null

function dataHandler(state, action) {
  return action.payload ? action.payload : INITIAL_STATE
}

const dataReducer = createReducer(INITIAL_STATE, {
  [supplyOrderErrorsTypes.FETCH_SUPPLY_ORDER_ERRORS_FULFILLED]: dataHandler,
  [supplyOrderErrorsTypes.CLEAR_FETCH_SUPPLY_ORDER_ERRORS]: dataHandler,
})

const reducer = combineReducers({
  requestState: requestStateReducer(supplyOrderErrorsTypes.FETCH_SUPPLY_ORDER_ERRORS),
  data: dataReducer,
})

export default reducer
