import React, { Component } from 'react'
import { connect } from 'react-redux'

import { enqueueError } from 'lib/components/GlobalNotifications'
import { extractIdFromUrl } from 'utils/util'
import { fetchProduct } from 'actions/productActions'
import { fetchRatingRules } from 'actions/ratingActions'
import { getRequestStatus, IN_PROGRESS } from 'lib/selectors/requestStateSelectors'

import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import RatingDetail from './RatingDetail'
import VariantPrices from './VariantPrices'
import RequestWrapper from '@licnz/react-request-wrapper'

class ProductRating extends Component {
  constructor(props) {
    super(props)

    this.handleBack = this.handleBack.bind(this)
  }

  componentDidMount() {
    this.getProduct()
  }

  componentDidUpdate(prevProps) {
    let {
      enqueueError,
      fetchRatingRules,
      match: {
        params: { id },
      },
      product,
    } = this.props

    if (prevProps.match.params.id !== id) {
      this.getProduct()
    }

    if (prevProps.product !== product && product && product._type != 'component') {
      fetchRatingRules(product.slug).catch(err => {
        enqueueError({
          message: 'We were unable to fetch the rating rules for this product',
          detail: err,
        })
      })
    }
  }

  getProduct() {
    let {
      enqueueError,
      fetchProduct,
      match: {
        params: { id },
      },
    } = this.props

    let extractedId = extractIdFromUrl(id)
    fetchProduct(extractedId).catch(() =>
      enqueueError({
        message: "We were unable to fetch the product to view it's rating rules",
      })
    )
  }

  handleBack() {
    this.props.history.push(`/products/${this.props.product.slug}`)
  }

  render() {
    let { location, product, productRequestStatus, ratingRulesRequestStatus } = this.props
    let requestState =
      productRequestStatus === IN_PROGRESS || ratingRulesRequestStatus === IN_PROGRESS
        ? IN_PROGRESS
        : null

    let route = location.pathname.split('/')[4]
    let heading = route === 'variants' ? 'Variant prices' : 'Rating details'

    return (
      <PageSection>
        <PageHeading
          heading={heading}
          subheading={product ? product.name : 'Product'}
          size='h2'
          onAction={this.handleBack}
          actionText='Back'
        />
        <RequestWrapper requestState={requestState}>
          {product ? (
            route === 'variants' ? (
              <VariantPrices product={product} />
            ) : (
              <RatingDetail product={product} />
            )
          ) : null}
        </RequestWrapper>
      </PageSection>
    )
  }
}

const mapDispatchToProps = {
  enqueueError,
  fetchProduct,
  fetchRatingRules,
}

export { ProductRating }
export default connect(state => {
  return {
    product: state.product.data,
    productRequestStatus: getRequestStatus(state.adminProduct.requestState),
    ratingRulesRequestStatus: getRequestStatus(state.ratingRules.requestState),
  }
}, mapDispatchToProps)(ProductRating)
