/**
 * FEATURE_FLAGS
 * Feature flags set here should always be linked to a task in Jira or Trello. They
 * get set to global.FEATURE_FLAGS so that they can be referenced and used to turn features
 * on or off. They should always resolve to a true or false, respective to the environments
 * in which they are intended to be applied.
 */

import { environment, ENVIRONMENTS } from './env' // eslint-disable-line

export const FEATURE_FLAGS = {
  // SHOP-20
  SEARCH_PRODUCT: (() => {
    return true
  })(),
  // SHOP-146
  REORDER_PRODUCT_FEATURE: (() => {
    return true
  })(),
  // SHOP-98
  Z_TAGS_BRANDING: (() => {
    return true
  })(),
  CANCEL_ORDER_LINE_FEATURE: (() => {
    // SHOP-214
    return ![ENVIRONMENTS.PROD].includes(environment)
  })(),
  // SHOP-205
  UPDATE_AND_DELETE_PRODUCT_IMAGES: (() => {
    return true
  })(),
  // SHOP-270
  SUPPLIER_CAPACITY: (() => {
    return true
  })(),
  MANUAL_CANCELLATION: (() => {
    return true
  })(),

}
