import React from 'react'

import styles from './styles.scss'

const Banner = () => (
  <div className={styles.banner}>
    <div className={styles.bannerStripe}></div>
  </div>
)

export default Banner
