import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import flatten from 'lodash/flatten'
import Select from 'react-select'

import {
  extractValidMarkingOptionsCombinations,
  processMarkingOptions,
} from 'utils/ratingRulesUtils'
import { RATING_PRICE_URN_MAPPINGS } from 'constants/priceMappings'

import Currency from 'lib/components/Currency'
import DateTime from 'lib/components/DateTime'

import styles from './styles.scss'

const {
  BASE_UNIT_COST,
  BASE_UNIT_RETAIL,
  CHARGE_LEVY_UNIT_NAIT,
  CONFIGREQ_UNIT_COST,
  CONFIGREQ_UNIT_RETAIL,
} = RATING_PRICE_URN_MAPPINGS

const VariantsTable = ({ product, ratingRules, version, versionOptions = [], updateVersion, user }) => {
  const availableSizes = [...product.available_sizes]
  const defaultSize = availableSizes.find(size => size.default)

  const productMarkingOptions = processMarkingOptions([product])
  const markingOptionsMatrix = extractValidMarkingOptionsCombinations(product)
  const defaultMarkingOptions = markingOptionsMatrix.find(markingOptionCombination =>
    markingOptionCombination.every(markingOption => markingOption.default)
  )

  let selectVersions = versionOptions?.map(versionNumber => {
    return { value: versionNumber, label: `Version ${versionNumber}` }
  })

  const buildDefaultVariantTableRow = () => {
    let markingOptionsNames =
      defaultMarkingOptions &&
      defaultMarkingOptions.map(markingOption => (
        <Fragment key={markingOption.componentSlug}>
          <dt>{markingOption.componentName}:</dt>
          <dd>{markingOption.name}</dd>
        </Fragment>
      ))

    let baseCostRule = ratingRules.find(rule => rule.price_type === BASE_UNIT_COST)
    let baseCostPrice = baseCostRule ? Number(baseCostRule.value) : 0
    let baseRetailRule = ratingRules.find(rule => rule.price_type === BASE_UNIT_RETAIL)
    let baseRetailPrice = baseRetailRule ? Number(baseRetailRule.value) : 0
    let naitLevyRule = ratingRules.find(rule => rule.price_type === CHARGE_LEVY_UNIT_NAIT)
    let naitLevyPrice = naitLevyRule ? Number(naitLevyRule.value) : 0
    let totalPrice = baseRetailPrice + naitLevyPrice

    return (
      <tr>
        <td>{defaultSize ? defaultSize.display_name : 'Not applicable'}</td>
        <td className={styles.markingOptions}>
          {markingOptionsNames ? (
            <dl>{markingOptionsNames.map(name => name)}</dl>
          ) : (
            'Not applicable'
          )}
        </td>
        <td>
          <Currency amount={baseCostPrice} />
        </td>
        <td>
          <Currency amount={baseRetailPrice} />
        </td>
        <td>
          <Currency amount={naitLevyPrice} />
        </td>
        <td>
          <Currency amount={totalPrice} />
        </td>
      </tr>
    )
  }

  const retrievePriceFromUrns = (urns, priceType, matchAttribute) => {
    let rules = flatten(
      urns.map(urn =>
        ratingRules.filter(
          rule => rule.price_type === priceType && urn === rule[matchAttribute]
        )
      )
    )

    let price = rules.map(rule => Number(rule.value)).reduce((a, b) => a + b, 0)

    return price
  }

  const buildVariantTableRow = (size, markingOptions, i) => {
    if (size === defaultSize && markingOptions === defaultMarkingOptions) return null

    let componentsWithMultipleMarkingOptions = flatten(
      productMarkingOptions
        .filter(componentMarkingOptions => componentMarkingOptions.length > 1)
        .map(componentMarkingOptions =>
          componentMarkingOptions.map(markingOption => markingOption.componentName)
        )
    )

    let markingOptionsNames = markingOptions
      .filter(markingOption => {
        return componentsWithMultipleMarkingOptions.includes(markingOption.componentName)
      })
      .map(markingOption => (
        <Fragment key={markingOption.componentSlug}>
          <dt>{markingOption.componentName}:</dt>
          <dd>{markingOption.name}</dd>
        </Fragment>
      ))

    let baseCostRule = ratingRules.find(rule => rule.price_type === BASE_UNIT_COST)
    let baseCostPrice = baseCostRule ? Number(baseCostRule.value) : 0
    let baseRetailRule = ratingRules.find(rule => rule.price_type === BASE_UNIT_RETAIL)
    let baseRetailPrice = baseRetailRule ? Number(baseRetailRule.value) : 0
    let naitLevyRule = ratingRules.find(rule => rule.price_type === CHARGE_LEVY_UNIT_NAIT)
    let naitLevyPrice = naitLevyRule ? Number(naitLevyRule.value) : 0

    let sizeUrns = size.component_sizes.map(componentSize => componentSize.size)
    let markingOptionsUrns = flatten(
      markingOptions.map(markingOption => markingOption.configReqIdentityUrns)
    )

    let sizeCostPrice = retrievePriceFromUrns(sizeUrns, CONFIGREQ_UNIT_COST, 'rule_value')
    let sizeRetailPrice = retrievePriceFromUrns(
      sizeUrns,
      CONFIGREQ_UNIT_RETAIL,
      'rule_value'
    )
    let markingOptionsCostPrice = retrievePriceFromUrns(
      markingOptionsUrns,
      CONFIGREQ_UNIT_COST,
      'rule_id'
    )
    let markingOptionsRetailPrice = retrievePriceFromUrns(
      markingOptionsUrns,
      CONFIGREQ_UNIT_RETAIL,
      'rule_id'
    )

    let totalCostPrice = baseCostPrice + sizeCostPrice + markingOptionsCostPrice
    let totalRetailPrice = baseRetailPrice + sizeRetailPrice + markingOptionsRetailPrice
    let totalPrice = totalRetailPrice + naitLevyPrice

    return (
      <tr key={i}>
        <td>{size.display_name}</td>
        <td className={styles.markingOptions}>
          <dl>{markingOptionsNames.map(name => name)}</dl>
        </td>
        <td>
          <Currency amount={totalCostPrice} />
        </td>
        <td>
          <Currency amount={totalRetailPrice} />
        </td>
        <td>
          <Currency amount={naitLevyPrice} />
        </td>
        <td>
          <Currency amount={totalPrice} />
        </td>
      </tr>
    )
  }

  return (
    <table>
      <thead>
        {selectVersions.length > 0 && ratingRules[0].versioned_at ? (
          <tr>
            <th colSpan='2'>
              <Select
                className={styles.versions}
                options={selectVersions}
                value={{ value: version, label: `Version ${version}`}}
                onChange={updateVersion}
              />
            </th>
            <th colSpan='4'>
              {ratingRules[0].versioned_by_name ? `${ratingRules[0].versioned_by_name}: ` : null}<DateTime date={ratingRules[0].versioned_at} format='DD/MM/YYYY, hh:mm A' />
            </th>
          </tr>
        ) : (
          <tr>
            <th colSpan='6'>New rating rules {user ? `by ${user.name}` : null}</th>
          </tr>
        )}
      </thead>
      <tbody>
        <tr>
          <th colSpan='6' className={styles.title}>
            Default
          </th>
        </tr>
        <tr>
          <th>Size</th>
          <th className={styles.markingOptions}>Marking options</th>
          <th>Cost price</th>
          <th>Retail price</th>
          <th>NAIT levy</th>
          <th>Total price</th>
        </tr>
        {buildDefaultVariantTableRow()}
      </tbody>
      {availableSizes.length ? (
        <tbody>
          <tr>
            <th colSpan='6' className={styles.title}>
              Variants
            </th>
          </tr>
          <tr>
            <th>Size</th>
            <th className={styles.markingOptions}>Marking options</th>
            <th>Cost price</th>
            <th>Retail price</th>
            <th>NAIT levy</th>
            <th>Total price</th>
          </tr>
          {availableSizes.map(size => {
            return markingOptionsMatrix.map((markingOptions, i) => {
              return buildVariantTableRow(size, markingOptions, i)
            })
          })}
        </tbody>
      ) : null}
    </table>
  )
}

VariantsTable.propTypes = {
  product: PropTypes.object.isRequired,
  ratingRules: PropTypes.array,
  version: PropTypes.string,
  versionOptions: PropTypes.array,
  updateVersion: PropTypes.func,
  user: PropTypes.object
}

export default VariantsTable
