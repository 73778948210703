import { proxyUrl } from 'lib/utils/proxy'
import { productTypes, productsTypes } from 'actions/types'
import { get, post, put, destroy } from 'lib/reduxHelpers/thunks'

function fetchProduct(productName) {
  let baseType = productTypes.FETCH_PRODUCT
  let productUrl = `${global.config.PRODUCT_SERVICE_ENDPOINT}/products/${productName}`

  let url = proxyUrl({ link: productUrl })

  return get({ baseType, url })
}

function fetchAdminProduct(productSlug) {
  let baseType = productTypes.FETCH_ADMIN_PRODUCT
  let productUrl = `${
    global.config.PRODUCT_SERVICE_ENDPOINT
  }/admin/products/${encodeURIComponent(productSlug)}`
  let url = proxyUrl({ link: productUrl })

  return get({ baseType, url })
}

function fetchProductMetadata() {
  let baseType = productTypes.FETCH_METADATA
  let metadataUrl = `${global.config.PRODUCT_SERVICE_ENDPOINT}/admin/meta`
  let url = proxyUrl({ link: metadataUrl })

  return get({ baseType, url })
}

function fetchOfferingTypes(offeringTypesLink) {
  let baseType = productTypes.FETCH_OFFERING_TYPES
  let url = proxyUrl({ link: offeringTypesLink })

  return get({ baseType, url, extractPayload: response => response.data.items })
}

function fetchProductFamilies(productFamiliesLink) {
  let baseType = productTypes.FETCH_PRODUCT_FAMILIES
  let url = proxyUrl({ link: productFamiliesLink })

  return get({ baseType, url, extractPayload: response => response.data.items })
}

function updateProduct(product) {
  let baseType = productTypes.UPDATE_PRODUCT
  let productsUrl = `${global.config.PRODUCT_SERVICE_ENDPOINT}/admin/products/${product.slug}`
  let url = proxyUrl({ link: productsUrl })

  let body = product

  return put({ url, baseType, body })
}

function updateProductCategorisations(product, link) {
  let baseType = productTypes.UPDATE_PRODUCT_CATEGORISATIONS
  let url = proxyUrl({ link: link })

  let body = product

  return put({ url, baseType, body })
}

function approveProduct(productApprovalLink) {
  let baseType = productTypes.APPROVE_PRODUCT
  let url = proxyUrl({ link: productApprovalLink })
  let body = null

  return post({ baseType, url, body })
}

function deleteProduct(productId) {
  const baseType = productTypes.DELETE_PRODUCT
  let productUrl = `${
    global.config.PRODUCT_SERVICE_ENDPOINT
  }/admin/products/${encodeURIComponent(productId)}`
  let url = proxyUrl({ link: productUrl })

  return destroy({ baseType, url })
}

function clearProduct() {
  return function (dispatch) {
    dispatch({ type: productTypes.CLEAR_FETCH_PRODUCT })
    dispatch({ type: productTypes.CLEAR_FETCH_ADMIN_PRODUCT })
    dispatch({ type: productsTypes.CLEAR_ADD_PRODUCT })
    dispatch({ type: productTypes.CLEAR_APPROVE_PRODUCT })
    dispatch({ type: productTypes.CLEAR_DELETE_PRODUCT })
    dispatch({ type: productTypes.CLEAR_UPDATE_PRODUCT })
    dispatch({ type: productTypes.CLEAR_UPDATE_PRODUCT_CATEGORISATIONS })
  }
}

export {
  fetchProduct,
  fetchAdminProduct,
  fetchProductMetadata,
  fetchOfferingTypes,
  fetchProductFamilies,
  updateProduct,
  updateProductCategorisations,
  approveProduct,
  deleteProduct,
  clearProduct,
}
