import React from 'react'
import PropTypes from 'prop-types'

import TIMELINE_STATE_MAPPINGS from 'constants/timelineStateMappings'
import { titleCase } from 'utils/util'

import DateTime from 'lib/components/DateTime'

import styles from './styles.scss'

const OrderStatusTimeline = ({ orderStatus }) => {
  const renderStep = (status, index) => {
    let indexClass = 'step' + (index + 1)
    let stateClass = 'step' + titleCase(status.state)
    let timelineClass =
      status.state === TIMELINE_STATE_MAPPINGS.COMPLETE ? 'timelineSectionComplete' : ''

    return (
      <li key={index} className={styles[timelineClass]}>
        <div
          className={`${styles.step} ${styles[indexClass]} ${styles[stateClass]}`}
        ></div>
        <div>
          <p className={styles[stateClass + 'Text']}>{status.text}</p>
          <small>
            {status.time && <DateTime date={status.time} format={'DD/MM/YYYY, h:mm A'} />}
          </small>
        </div>
      </li>
    )
  }

  return (
    <section className={styles.timeline}>
      <ol>{orderStatus.map((status, index) => renderStep(status, index))}</ol>
    </section>
  )
}

OrderStatusTimeline.propTypes = {
  orderStatus: PropTypes.array.isRequired,
}

export default OrderStatusTimeline
