import { get } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { shippingRatesTypes } from 'actions/types'

function fetchShippingRates(supplierSlug) {
  let baseType = shippingRatesTypes.FETCH_SHIPPING_RATES
  let link = `${global.config.RATING_SERVICE_ENDPOINT}/api/shipping/shipping_rates?supplier=${supplierSlug}`
  let url = proxyUrl({ link })

  return get({ baseType, url, extractPayload: response => response.data.items })
}

function clearShippingRates() {
  return { type: shippingRatesTypes.CLEAR_FETCH_SHIPPING_RATES }
}

export { fetchShippingRates, clearShippingRates }
