import * as Sentry from '@sentry/browser'
import { environment, version, isHostedEnv } from './env'

export const intializeMonitoring = function intializeMonitoringWithSentry() {
  if (isHostedEnv()) {
    Sentry.init({
      dsn: 'https://de54ef7754094e0ebe1d196134c50140@o346404.ingest.sentry.io/5227725',
      environment,
      release: `lic-shop-assistant@${version}`,
    })
  }
}

export const setUserScope = function setUserScopeWithSentry(userInfo) {
  if (isHostedEnv()) {
    Sentry.configureScope(function (scope) {
      scope.setUser({ id: userInfo.sub, email: userInfo.email || userInfo.preferred_username })
    })
  }
}

export const sentryEnhance401Error = (error) => {
  // Returns true if it's a 401 error and has been enhanced and processed.
  // Returns false if the error hasn't been handled.

  if (error.response.status === 401 && error.response.data && typeof (error.response.data) === 'object') {
    // When http status code of the failed request is a 401 (unauthorised)
    // extract some of the returned response and enhance the sentry logs.

    const sentryExtraData = {};
    const { code, attributes, table } = error.response.data;

    if (code) {
      sentryExtraData.errorCode = code;
    }
    if (!table && attributes) {
      if (attributes.every(attr => attr.attribute = 'message' && attr.message)) {
        sentryExtraData.errorMessages = attributes.map(({ message }) => message);
      }
    }
    if (table && table.errors && !attributes) {
      sentryExtraData.errorMessages = table.errors;
    }

    if (Object.keys(sentryExtraData).length > 0) {
      Sentry.captureException(
        // If Sentry hasn't been initialised (such as when running locally), this will silently do nothing.
        error,
        {
          extra: sentryExtraData,
        }
      );
    }
    return true;
  }
  return false;
}