import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { href } from '@licnz/js-utils'

import axios from 'lib/utils/axios'
import { extractIdFromUrl } from 'utils/util'
import { generateRatingTree, getBasketProductPrices } from 'utils/rating'
import { OFFER_PRICE_URN_MAPPINGS } from 'constants/priceMappings'

import Currency from 'lib/components/Currency'
import { Link } from 'react-router-dom'

import styles from './styles.scss'

class RequestedProductsTableRow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      productImage: null,
      discount: null,
      subtotalPrice: null,
      subtotalRetailPrice: null,
      unitRetailPrice: null,
    }
  }

  componentDidMount() {
    this.fetchProductImage()

    let { requestedProduct } = this.props

    let ratingTree = generateRatingTree(requestedProduct)

    getBasketProductPrices(
      ratingTree,
      requestedProduct.quantity,
      requestedProduct.discretionary_discounts,
      requestedProduct.discount_quantity_multiplier
    ).then(productPrices => {
      this.setPrices(productPrices)
    })
  }

  fetchProductImage() {
    let { requestedProduct } = this.props

    let productLink =
      requestedProduct && href({ links: requestedProduct.links, rel: 'product' })
    axios
      .get(
        `${global.config.UI_PROXY_ENDPOINT}/api/proxy/${encodeURIComponent(productLink)}`
      )
      .then(product => {
        this.setState({
          productImage: product.data.images.find(image => image.is_default_image == true),
        })
      })
      .catch(error => {
        console.error(`Something went wrong fetching this Product:\n ${error}`) // eslint-disable-line no-console
      })
  }

  renderImage() {
    let { productImage } = this.state

    let imageLink = productImage && href({ links: productImage.links, rel: 'self' })
    if (productImage != null) {
      return (
        <div className={styles.imageContainer}>
          <img src={productImage && imageLink} alt={productImage && productImage.alt} />
        </div>
      )
    }
  }

  renderPrices() {
    let { discount, subtotalRetailPrice, subtotalPrice } = this.state
    return subtotalRetailPrice && subtotalPrice ? (
      <Fragment>
        {discount ? (
          <s className={styles.strikedPrice}>
            <Currency id={'subtotalRetailPrice'} amount={subtotalRetailPrice} />
          </s>
        ) : null}
        <Currency id={'subtotalPrice'} amount={subtotalPrice} />
      </Fragment>
    ) : null
  }

  setPrices(productPrices) {
    let unitRetailPrice = productPrices.unitRetailPrice
      ? Number(productPrices.unitRetailPrice)
      : null
    const {
      offerPrice,
      offerPriceDiscount,
      offerPriceDiscountRetail,
      offerPriceRetail,
    } = OFFER_PRICE_URN_MAPPINGS

    let subtotalRetailPriceObj = productPrices.accumulatedPrices.find(
      ap => ap._type === offerPriceRetail
    )
    let subtotalRetailPrice = subtotalRetailPriceObj
      ? Number(subtotalRetailPriceObj.price)
      : null

    let subtotalPriceObj = productPrices.accumulatedPrices.find(
      ap => ap._type === offerPrice
    )
    let subtotalPrice = subtotalPriceObj ? Number(subtotalPriceObj.price) : null

    let discount =
      productPrices.accumulatedPrices
        // NOTE: we need to handle both of these urns as the urn has changed, but we need to handle existing orders
        .filter(
          ap => ap._type === offerPriceDiscountRetail || ap._type === offerPriceDiscount
        )
        .reduce((acc, dpo) => acc + Number(dpo.price), 0) || null

    this.setState({
      discount: Math.abs(discount),
      subtotalPrice,
      subtotalRetailPrice,
      unitRetailPrice,
    })
  }

  render() {
    let { requestedProduct } = this.props
    let basketLink = href({ links: requestedProduct.links, rel: 'up' })
    let requestedProductSelfLink = href({ links: requestedProduct.links, rel: 'self' })
    let requestedProductId = extractIdFromUrl(requestedProductSelfLink)
    let requestedProductLink =
      '/baskets/' +
      encodeURIComponent(basketLink) +
      '/requestedProduct/' +
      encodeURIComponent(requestedProductId)

    return (
      <tr className={styles.tableRowProduct}>
        <td className={styles.tableCellProductInfo}>
          <Link className={styles.linkProductImage} to={requestedProductLink}>
            {this.renderImage()}
          </Link>
          <div className={styles.productInfo}>
            <Link className={styles.linkProductTitle} to={requestedProductLink}>
              {this.state.productImage && this.state.productImage.alt}
            </Link>
          </div>
        </td>
        <td>{requestedProduct.quantity}</td>
        {<td className={styles.pricesTableCell}>{this.renderPrices()}</td>}
        <td>{requestedProduct.birth_id_replacement ? 'Yes' : 'No'}</td>
        <td>
          {requestedProduct.customer_purchase_order_number
            ? requestedProduct.customer_purchase_order_number
            : 'Not found'}
        </td>
        <td className={styles.pullRight}>
          {requestedProduct.animal_group_reservations &&
          requestedProduct.animal_group_reservations.length > 0
            ? 'Yes'
            : 'No'}
        </td>
      </tr>
    )
  }
}

RequestedProductsTableRow.propTypes = {
  requestedProduct: PropTypes.object.isRequired,
}

export default RequestedProductsTableRow
