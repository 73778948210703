import {
  ANIMAL_COUNT_URN,
  BIRTH_ID_URNS,
  MANAGEMENT_NUMBER_URNS,
  NAIT_PRODUCTION_TYPE_URN,
  NAIT_LOCATION_URN,
  TEXT_POSITION_0_URN,
  TEXT_POSITION_1_URN,
  TEXT_POSITION_2_URN,
  TAG_NUMBER_REQUIREMENT_URNS,
} from 'constants/productConfiguration'

const mapConfigRequirementsToDisplayNames = property => {
  if (BIRTH_ID_URNS.includes(property.id)) {
    property.displayName = 'Birth ID numbers'
  } else if (MANAGEMENT_NUMBER_URNS.includes(property.id)) {
    property.displayName = 'Management numbers'
  } else if (property.id === TEXT_POSITION_0_URN) {
    property.displayName = 'Text line'
  } else if (property.id === TEXT_POSITION_1_URN) {
    property.displayName = 'Text line 1'
  } else if (property.id === TEXT_POSITION_2_URN) {
    property.displayName = 'Text line 2'
  } else if (property.id === NAIT_PRODUCTION_TYPE_URN) {
    property.displayName = 'Herd type'
  } else if (property.id === NAIT_LOCATION_URN) {
    property.displayName = 'NAIT number'
  } else if (property.id === ANIMAL_COUNT_URN) {
    property.displayName = 'Highest tag number'
  }
  return property
}

const extractDuplicateTagNumbers = propertyValues => {
  return propertyValues.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        v =>
          v.from.sequence === value.from.sequence && v.to.sequence === value.to.sequence
      )
  )
}

const formatConfigurationProperties = configProperties => {
  let properties = []

  configProperties.map(prop => {
    let property = mapConfigRequirementsToDisplayNames(prop)

    if (property.value === '') return

    if (TAG_NUMBER_REQUIREMENT_URNS.includes(property.id)) {
      return extractDuplicateTagNumbers(property.value).map(value => {
        let configProperty = {
          id: property.id,
          displayName: property.displayName,
          year: value.from.year,
          prefix: value.from.prefix,
          value: `${value.from.sequence} - ${value.to.sequence}`,
        }
        properties.push(configProperty)
      })
    } else {
      properties.push(property)
    }
  })

  return properties
}

const processConfigurationNode = (queue, configuration = {}) => {
  if (queue.length === 0) return configuration

  let currentNode = queue.shift()

  let config = { name: currentNode.name, productSlug: currentNode.product_slug }
  if (currentNode.size) config.size = currentNode.size
  if (currentNode.colour) config.colour = currentNode.colour
  if (
    currentNode.configuration_properties &&
    currentNode.configuration_properties.length > 0
  )
    config.configProperties = formatConfigurationProperties(
      currentNode.configuration_properties
    )
  configuration[currentNode.product_slug] = config

  if (currentNode.children) {
    currentNode.children.forEach(child => queue.push(child))
  }
  return processConfigurationNode(queue, configuration)
}

const extractConfigurationOptions = product => {
  if (!product)
    throw new Error(
      `Error extracting configuration options from Product: product is ${product}`
    )

  return processConfigurationNode([product])
}

export { extractConfigurationOptions }
