import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ReactQueryDevtools } from 'react-query-devtools'

import axios from 'lib/utils/axios'
import createStore from './store/createStore'
import { FEATURE_FLAGS } from './utils/featureFlags'
import { intializeMonitoring } from './utils/monitoringClient'

import ConfiguredReactQueryProvider from 'components/ConfiguredReactQueryProvider'
import AppRoot from './AppRoot'

const getAppConfig = () => {
  return new Promise((resolve, reject) => {
    // timestamp is a timestamp. Set to the current time to force a re-read of the config. In the future this will be done via deploy scripts
    axios
      .get('/config.json?timestamp=1589765113')
      .then(response => {
        return resolve(response.data)
      })
      .catch(err => {
        return reject(err)
      })
  })
}

getAppConfig().then(config => {
  global.config = config

  // set feature flags
  global.FEATURE_FLAGS = FEATURE_FLAGS

  intializeMonitoring()

  ReactDOM.render(
    <Provider store={createStore()}>
      <ConfiguredReactQueryProvider>
        <AppRoot />
        <ReactQueryDevtools initialIsOpen={false} />
      </ConfiguredReactQueryProvider>
    </Provider>,
    document.querySelector('#root')
  )
})
