import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getRequestStatus, SUCCESS } from 'lib/selectors/requestStateSelectors'
import injectContext from 'components/higherOrderComponents/ContextInjector'

const ProtectedRoute = ({
  component: Component,
  isLoggedIn,
  loggedInRequestStatus,
  ...rest
}) => {
  const loginUrl = `${global.config.UI_PROXY_ENDPOINT}/proxy/connect/identity`

  const renderRoute = props => {
    if (loggedInRequestStatus && loggedInRequestStatus === SUCCESS) {
      if (isLoggedIn) {
        return <Component {...props} />
      } else {
        window.location.assign(loginUrl) // = loginUrl
      }
    }

    return null
  }

  return <Route {...rest} render={renderRoute} />
}

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
}

export { ProtectedRoute }
export default compose(
  injectContext(),
  connect(state => {
    return {
      isLoggedIn: state.loggedIn.data.isLoggedIn,
      loggedInRequestStatus: getRequestStatus(state.loggedIn.requestState),
    }
  })
)(ProtectedRoute)
