import React from 'react'
import PropTypes from 'prop-types'

const Pill = ({ item, handleClick }) => {
  return handleClick ? (
    <span>
      {item}
      <span className='remove_pill' onClick={e => handleClick(e, item)}>
        X
      </span>
    </span>
  ) : (
    <span>{item}</span>
  )
}

Pill.propTypes = {
  item: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
}

export default Pill
