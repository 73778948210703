import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useSelector } from 'react-redux'

import axios from 'lib/utils/axios'
import { href } from '@licnz/js-utils'
import { proxyUrl } from 'lib/utils/proxy'
import { processConfigurationNode } from 'utils/productUtils'

import classnames from 'classnames';
import styles from './styles.scss';
import SelectField from './SelectField';
import useProductsSearch from './useProductsSearch';

const SelectProductAutocomplete = forwardRef(({
  onChange,
  enqueueError,
}, ref) => {
  const [selectedProductOption, setSelectedProductOption] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [selectedProductConfigOptions, setSelectedProductConfigOptions] = useState(null)
  const [selectedColourOption, setSelectedColourOption] = useState(null)
  const [selectedSizeOption, setSelectedSizeOption] = useState(null)

  const { setQ, isLoading, enabled } = useProductsSearch();

  let colourSuggestions = []
  let sizeSuggestions = []

  // clearSearch is referenced to the parent component and can be called from the parent component using 'useImperativeHandle'
  useImperativeHandle(ref, () => {
    return {
      clearSearch: clearSearch
    }
  });

  useEffect(() => {
    if (selectedProductOption && selectedProductOption.value) {
      const productUrl = proxyUrl({ link: selectedProductOption.value })
      axios.get(productUrl)
        .then(productResponse => setSelectedProduct(productResponse.data))
    }
  }, [selectedProductOption])

  useEffect(() => {
    setSelectedProductConfigOptions(extractColourAndSize(selectedProduct))
  }, [selectedProduct])

  const products = useSelector((state) => state.productsSearch.data.items)
  let productSuggestions =
    products && products.map(product => {
      let productLink = href({ links: product.links, rel: 'self' })
      return { value: productLink, label: product.title, field: 'productSearch' }
    })

  const handleProductInputChange = (q) => {
    setQ(q && q !== '' ? q : null);
  }

  const handleProductOptionUpdate = (option) => {
    setSelectedProductOption(option)
    updateSearchOnChange(option)
  }

  const handleColourOptionUpdate = (option) => {
    setSelectedColourOption(option)
    updateSearchOnChange(option)
  }

  const handleSizeOptionUpdate = (option) => {
    setSelectedSizeOption(option)
    updateSearchOnChange(option)
  }

  const extractColourAndSize = product => {
    if (!product) {
      enqueueError({ message: `Unable to get colour or size property from the product.` })
      return null
    }
    return processConfigurationNode([product])
  }

  const updateSearchOnChange = (option) => {
    let event = {
      target: {
        name: option.field,
        value: option.value
      }
    }
    onChange(event)
  }

  const clearSearch = () => {
    updateSearchOnChange({ value: undefined, field: 'productSearch' })
    setSelectedProduct(null)
    setSelectedProductOption(null)
    setSelectedColourOption(null)
    setSelectedSizeOption(null)
  }

  const setDefaultProductColour = (colours) => {
    let defaultColour = null
    if (colours.length === 1) {
      defaultColour = colours[0]
    } else {
      defaultColour = colours.filter(colour => colour.label === 'Yellow')[0]
    }
    if (defaultColour.value) {
      handleColourOptionUpdate(defaultColour)
    }
    return defaultColour
  }

  if (selectedProductConfigOptions) {
    colourSuggestions =
      selectedProductConfigOptions.colours && selectedProductConfigOptions.colours.map(colour => ({ value: colour.identifiers[0].identifier, label: colour.display_name, field: 'productColour' }))

    sizeSuggestions =
      selectedProductConfigOptions.sizes && selectedProductConfigOptions.sizes.map(size => ({ value: size.identity, label: size.display_name, field: 'productSize' }))
  }

  if (selectedProduct && !selectedColourOption && colourSuggestions.length > 0) {
    setDefaultProductColour(colourSuggestions)
  }

  return (
    <div className={styles.filterControls}>
      <SelectField
        label={<>By Product <a onClick={clearSearch}>(Clear)</a></>}
        name='productSearch'
        options={productSuggestions}
        onInputChange={handleProductInputChange}
        onChange={handleProductOptionUpdate}
        value={selectedProduct ? selectedProduct.title : ''}
        {...{ enabled, isLoading }}
      />
      {colourSuggestions.length > 0 &&
        <SelectField
          selectClass={classnames(styles.search, styles.property)}
          label='Colours'
          name='productColour'
          options={colourSuggestions}
          onChange={handleColourOptionUpdate}
          value={selectedColourOption !== null ? selectedColourOption : ''}
        />
      }
      {sizeSuggestions.length > 0 &&
        <SelectField
          selectClass={classnames(styles.search, styles.property)}
          label='Sizes'
          name='productSize'
          options={sizeSuggestions}
          onChange={handleSizeOptionUpdate}
          value={selectedSizeOption !== null ? selectedSizeOption : ''}
        />
      }
    </div>
  )
})

export default SelectProductAutocomplete
