const searchTree = (queue, comparator) => {
  if (!queue.length) return null

  let node = queue.shift()
  if (comparator(node)) return node

  if (node && node.children) node.children.forEach(child => queue.push(child))
  return searchTree(queue, comparator)
}

const findNodeInTree = ({ tree, keyToMatch, valueToMatch, comparator }) => {
  if (!comparator) {
    if (keyToMatch && valueToMatch) {
      comparator = node => node && node[keyToMatch] === valueToMatch
    } else {
      throw new Error(
        'findNodeInTree was not provided a comparator or the keyToMatch and valueToMatch attributes.'
      )
    }
  }

  return searchTree([tree], comparator)
}

export { findNodeInTree }
