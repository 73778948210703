import React, { Component, Fragment } from 'react'
import { ApolloConsumer } from 'react-apollo'

import {
  GET_EVENTS_BY_CORRELATION_ID,
  GET_EVENTS_BY_CURRENT_STATE,
  GET_SUPPLY_ORDERS_BY_CURRENT_STATE,
  GET_SUPPLY_ORDERS_BY_ORDER_REFERENCE,
  GET_SUPPLY_ORDERS_BY_SALES_ORDER_NUMBER,
} from './queries'
import {
  reverseOrderOptions,
  sortOrderOptions,
  supplierEventStates,
  supplyOrderStates,
} from 'constants/supplierGraphqlFilters'

import Button from 'lib/components/Button'
import Dropdown from 'lib/components/Dropdown'
import FormField from 'lib/components/FormField'
import FormRow from 'lib/components/FormRow'
import PageHeading from 'lib/components/headings/PageHeading'
import PageSection from 'lib/components/layout/PageSection'
import SupplierQueryBuilderResults from './SupplierQueryBuilderResults'

import styles from './styles.scss'

const initialState = {
  correlationId: null,
  orderReference: null,
  page: 1,
  query: null,
  queryResults: null,
  reverseOrder: '2',
  salesOrderNumber: null,
  sortOrder: '1',
  supplierEventCurrentState: null,
  supplyOrderCurrentState: null,
}
class SupplierQueryBuilder extends Component {
  constructor() {
    super()

    this.state = {
      ...initialState,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleFetchedResults = this.handleFetchedResults.bind(this)
    this.handleQuerySelect = this.handleQuerySelect.bind(this)
    this.handleQuerySubmission = this.handleQuerySubmission.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.handleReverseOrderSelect = this.handleReverseOrderSelect.bind(this)
    this.handleSortOrderSelect = this.handleSortOrderSelect.bind(this)
    this.handleSupplierEventCurrentStateSelect = this.handleSupplierEventCurrentStateSelect.bind(
      this
    )
    this.handleSupplyOrderCurrentStateSelect = this.handleSupplyOrderCurrentStateSelect.bind(
      this
    )
    this.showPaginationControls = this.showPaginationControls.bind(this)
  }

  defaultQueryVariables() {
    let { reverseOrder, sortOrder } = this.state
    let reverseOrderItem = reverseOrderOptions.find(option => option.id === reverseOrder)
    let sortOrderItem = sortOrderOptions.find(option => option.id === sortOrder)

    return {
      reverseOrder: reverseOrderItem && reverseOrderItem.value,
      sortOrder: sortOrderItem && sortOrderItem.value,
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleFetchedResults(queryResults, pageNumber) {
    if (this.state.page != pageNumber) {
      this.setState({ page: pageNumber })
    }

    if (queryResults) {
      this.setState(() => ({ queryResults }))
    }
  }

  handleQuerySelect(queryId) {
    this.setState({ query: queryId })
  }

  handleQuerySubmission(queryItem) {
    return (
      <ApolloConsumer>
        {client => (
          <Button
            className='buttonPrimary'
            onClick={async () => {
              const results = await client.query({
                query: queryItem && queryItem.query,
                variables: queryItem && queryItem.queryVariables,
              })
              this.handleFetchedResults(results, 1)
            }}
          >
            Submit
          </Button>
        )}
      </ApolloConsumer>
    )
  }

  handleReset(e) {
    e.preventDefault()
    this.setState(initialState)
  }

  handleReverseOrderSelect(reverseOrderOptionId) {
    this.setState({ reverseOrder: reverseOrderOptionId })
  }

  handleSortOrderSelect(sortOrderFieldId) {
    this.setState({ sortOrder: sortOrderFieldId })
  }

  handleSupplierEventCurrentStateSelect(stateId) {
    this.setState({ supplierEventCurrentState: stateId })
  }

  handleSupplyOrderCurrentStateSelect(stateId) {
    this.setState({ supplyOrderCurrentState: stateId })
  }

  queries() {
    let { correlationId, orderReference, salesOrderNumber } = this.state
    return [
      {
        query: GET_EVENTS_BY_CORRELATION_ID,
        id: '1',
        name: 'Supplier Events by Correlation ID',
        queryVariables: { correlationId: correlationId, ...this.defaultQueryVariables() },
      },
      {
        query: GET_EVENTS_BY_CURRENT_STATE,
        id: '2',
        name: 'Supplier Events by Current State',
        queryVariables: this.supplierEventQueryVariables(),
      },
      {
        query: GET_SUPPLY_ORDERS_BY_CURRENT_STATE,
        id: '3',
        name: 'Supply Orders by Current State',
        queryVariables: this.supplyOrderQueryVariables(),
      },
      {
        query: GET_SUPPLY_ORDERS_BY_ORDER_REFERENCE,
        id: '4',
        name: 'Supply Orders by Order Reference',
        queryVariables: {
          orderReference: orderReference,
          ...this.defaultQueryVariables(),
        },
      },
      {
        query: GET_SUPPLY_ORDERS_BY_SALES_ORDER_NUMBER,
        id: '5',
        name: 'Supply Orders by Sales Order Number',
        queryVariables: {
          salesOrderNumber: salesOrderNumber,
          ...this.defaultQueryVariables(),
        },
      },
    ]
  }

  shouldRenderField(queryItem, propertyName) {
    return queryItem && Object.keys(queryItem.queryVariables).includes(propertyName)
  }

  showPaginationControls(page, queryItem, queryResults) {
    let data = queryResults
      ? queryResults.data.SupplyOrder || queryResults.data.Event
      : null
    return (
      <ApolloConsumer>
        {client => (
          <div className={styles.buttonToolbar}>
            {data && page > 1 && (
              <Button
                className='buttonSecondary'
                onClick={async () => {
                  queryItem.queryVariables.page = queryItem.queryVariables.page - 1
                  const results = await client.query({
                    query: queryItem && queryItem.query,
                    variables: queryItem && queryItem.queryVariables,
                  })
                  this.handleFetchedResults(results, queryItem.queryVariables.page)
                }}
              >
                {'< Prev '}
              </Button>
            )}
            {data && data.length == 20 && (
              <Button
                className='buttonSecondary'
                onClick={async () => {
                  queryItem.queryVariables.page = queryItem.queryVariables.page + 1
                  const results = await client.query({
                    query: queryItem && queryItem.query,
                    variables: queryItem && queryItem.queryVariables,
                  })
                  this.handleFetchedResults(results, queryItem.queryVariables.page)
                }}
              >
                {'Next >'}
              </Button>
            )}
          </div>
        )}
      </ApolloConsumer>
    )
  }

  showQueryParams(queryItem) {
    let {
      correlationId,
      orderReference,
      reverseOrder,
      salesOrderNumber,
      sortOrder,
      supplierEventCurrentState,
      supplyOrderCurrentState,
    } = this.state
    let renderCorrelationId = this.shouldRenderField(queryItem, 'correlationId')
    let renderSupplierEventCurrentState = this.shouldRenderField(
      queryItem,
      'supplierEventCurrentState'
    )
    let renderOrderReference = this.shouldRenderField(queryItem, 'orderReference')
    let renderSalesOrderNumber = this.shouldRenderField(queryItem, 'salesOrderNumber')
    let renderSupplyOrderCurrentState = this.shouldRenderField(
      queryItem,
      'supplyOrderCurrentState'
    )

    return (
      <FormRow label='Query Params'>
        {renderOrderReference && (
          <FormField
            name='orderReference'
            label='Order Reference'
            inline={true}
            type='textarea'
            onChange={this.handleChange}
            value={orderReference}
          />
        )}
        {renderSalesOrderNumber && (
          <FormField
            name='salesOrderNumber'
            label='Sales Order Number'
            inline={true}
            type='textarea'
            onChange={this.handleChange}
            value={salesOrderNumber}
          />
        )}
        {renderCorrelationId && (
          <FormField
            name='correlationId'
            label='Supplier Event Correlation ID'
            inline={true}
            type='textarea'
            onChange={this.handleChange}
            value={correlationId}
          />
        )}
        {renderSupplierEventCurrentState && (
          <div className={styles.dropdownWrapper}>
            <label className={styles.label}>Supplier Event Current State</label>
            <Dropdown
              onSelect={this.handleSupplierEventCurrentStateSelect}
              options={supplierEventStates}
              selectedOptionId={supplierEventCurrentState}
              selectOptionText='Select State'
            />
          </div>
        )}
        {renderSupplyOrderCurrentState && (
          <div className={styles.dropdownWrapper}>
            <label className={styles.label}>Supply Order Current State</label>
            <Dropdown
              onSelect={this.handleSupplyOrderCurrentStateSelect}
              options={supplyOrderStates}
              selectedOptionId={supplyOrderCurrentState}
              selectOptionText='Select State'
            />
          </div>
        )}
        <div className={styles.dropdownWrapper}>
          <label className={styles.label}>Sort Field</label>
          <Dropdown
            onSelect={this.handleSortOrderSelect}
            options={sortOrderOptions}
            selectedOptionId={sortOrder}
            selectOptionText='Select Sort Field'
          />
        </div>
        <div className={styles.dropdownWrapper}>
          <label className={styles.label}>Reverse Sort Order</label>
          <Dropdown
            onSelect={this.handleReverseOrderSelect}
            options={reverseOrderOptions}
            selectedOptionId={reverseOrder}
            selectOptionText='Select Reverse Sort Order'
          />
        </div>
      </FormRow>
    )
  }

  supplierEventQueryVariables() {
    let { supplierEventCurrentState, page } = this.state
    let supplierEventCurrentStateItem = supplierEventStates.find(
      state => state.id === supplierEventCurrentState
    )
    let newProperties = {
      page: page,
      supplierEventCurrentState:
        supplierEventCurrentStateItem && supplierEventCurrentStateItem.state,
    }
    return { ...this.defaultQueryVariables(), ...newProperties }
  }

  supplyOrderQueryVariables() {
    let { supplyOrderCurrentState, page } = this.state
    let supplyOrderCurrentStateItem = supplyOrderStates.find(
      state => state.id === supplyOrderCurrentState
    )
    let newProperties = {
      page: page,
      supplyOrderCurrentState:
        supplyOrderCurrentStateItem && supplyOrderCurrentStateItem.state,
    }
    return { ...this.defaultQueryVariables(), ...newProperties }
  }

  render() {
    let { page, query, queryResults } = this.state
    let queryItem = this.queries().find(savedQuery => savedQuery.id == query)
    let data = queryResults
      ? queryResults.data.SupplyOrder || queryResults.data.Event
      : null

    return (
      <PageSection>
        <PageHeading
          heading='GraphQL Query Builder'
          onAction={this.handleReset}
          actionText='Reset'
        />
        <FormRow label='Choose Query'>
          <div className={styles.dropdownWrapper}>
            <Dropdown
              onSelect={this.handleQuerySelect}
              options={this.queries()}
              selectedOptionId={query}
              selectOptionText='Select Query Option'
            />
          </div>
        </FormRow>
        {queryItem && (
          <div>
            {this.showQueryParams(queryItem)}
            {this.handleQuerySubmission(queryItem)}
          </div>
        )}
        <div className={styles.queryResults}>
          {queryItem && queryResults ? (
            data.length ? (
              <Fragment>
                <label className={styles.label}>{'Query Results: Page ' + page}</label>
                {this.showPaginationControls(page, queryItem, queryResults)}
                <SupplierQueryBuilderResults data={data} />
              </Fragment>
            ) : (
              'No results found'
            )
          ) : null}
        </div>
      </PageSection>
    )
  }
}

export default SupplierQueryBuilder
