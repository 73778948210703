import React, { Component } from 'react'
import PropTypes from 'prop-types'

import DropdownTrigger from './DropdownTrigger'
import DropdownOption from './DropdownOption'

import styles from './styles.scss'

class Dropdown extends Component {
  constructor() {
    super()

    this.state = {
      isOpen: false,
    }

    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.handleReset = this.handleReset.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleToggle = this.handleToggle.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this)
  }

  componentDidMount() {
    window.addEventListener('click', this.handleDocumentClick)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleDocumentClick)
  }

  handleDocumentClick(e) {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target) && this.state.isOpen) {
      this.handleToggle()
    } else {
      e.stopPropagation()
    }
  }

  handleSelect(option) {
    let { identifier, onSelect } = this.props
    onSelect(option, identifier)
    this.setState({ isOpen: false })
  }

  handleToggle() {
    this.setState({ isOpen: !this.state.isOpen })
  }

  handleReset() {
    let { identifier, onReset } = this.props
    onReset(identifier)
    this.handleToggle()
  }

  renderReset() {
    let { onReset, options, selectedOptionId } = this.props
    let selectedOption = options.find(opt => opt.id === selectedOptionId)

    return (
      selectedOption &&
      onReset && (
        <li className={styles.reset}>
          <a onClick={this.handleReset}>
            <small>Reset</small>
          </a>
        </li>
      )
    )
  }

  renderOptionComponents() {
    return this.props.options.map(opt => {
      return (
        <DropdownOption
          key={opt.id}
          id={opt.id}
          onSelect={this.handleSelect}
          name={opt.name}
          children={opt.children}
        />
      )
    })
  }

  selectedOptionName() {
    let { noOptionsText, options, selectedOptionId, selectOptionText } = this.props
    let name

    if (options && options.length > 0) {
      let selectedOption = options.find(opt => opt.id === selectedOptionId)
      if (selectedOption) {
        name = selectOptionText
          ? `${selectOptionText}: ${selectedOption.name}`
          : selectedOption.name
      } else {
        name = selectOptionText || 'Select an option'
      }
    } else {
      name = noOptionsText || 'No options found'
    }

    return name
  }

  setWrapperRef(node) {
    this.wrapperRef = node
  }

  render() {
    let { options, identifier, label } = this.props
    let { isOpen } = this.state
    return (
      <div>
        {label && (
          <label className={styles.label} htmlFor={identifier}>
            {label}
          </label>
        )}
        <nav ref={this.setWrapperRef} className={styles.nav} id={identifier}>
          <DropdownTrigger
            identifier={identifier}
            onToggle={this.handleToggle}
            name={this.selectedOptionName()}
            enabled={options && options.length > 0}
            active={isOpen}
          />
          <ul className={`${styles.ul} ${isOpen ? styles.active : ''}`}>
            {this.renderReset()}
            {this.renderOptionComponents()}
          </ul>
        </nav>
      </div>
    )
  }
}

Dropdown.propTypes = {
  identifier: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.node,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOptionId: PropTypes.string,
  noOptionsText: PropTypes.string,
  selectOptionText: PropTypes.string,
}

export default Dropdown
