import React from 'react'

import ReactTags from 'react-tag-autocomplete'

import styles from './styles.scss'

const TagAutocomplete = ({ tags, placeholder, onAdd, onDelete }) => {
  const handleAddition = tag => {
    tags.push(tag)
    onAdd(tags)
  }

  const handleDelete = i => {
    tags.splice(i, 1)
    onDelete(tags)
  }

  //TO DO: These suggestions are just an example. These will be replaced with an api call.
  const suggestions = [
    { name: 'economy' },
    { name: 'tag' },
    { name: 'calf_pack' },
    { name: 'premium' },
  ]

  const reactTagsClassNames = {
    root: styles.reactTags,
    selected: styles.reactTagsSelected,
    selectedTag: styles.reactTagsSelectedTag,
    selectedTagName: styles.reactTagsSelectedTagName,
    search: styles.reactTagsSearch,
    searchInput: styles.reactTagsSearchInput,
    suggestions: styles.reactTagsSuggestions,
  }

  return (
    <ReactTags
      tags={tags}
      suggestions={suggestions}
      handleDelete={handleDelete}
      handleAddition={handleAddition}
      autofocus={false}
      allowNew
      placeholder={placeholder}
      classNames={reactTagsClassNames}
    />
  )
}

export default TagAutocomplete
