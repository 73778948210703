import React, { Component } from 'react'
import { compose } from 'redux'
import { withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import queryString from 'query-string'

/**
 * This HoC can be used to wrap client side routes to carry the organisation and person context
 * on those routes. This is to support browser refreshes - otherwise the selected organisation
 * or person's customer context would be lost on refresh.
 */

//sets the party links and organisation link when refresh when navigate around shop assistant, load context back up
const ContextInjector = ({ testMode = false } = {}) => {
  return ComposedComponent => {
    class ControlledComponent extends Component {
      constructor(props) {
        super(props)

        this.state = {
          redirectWithContextParams: false,
        }
      }

      paramsContainContext() {
        let {
          location: { search },
        } = this.props
        let params = queryString.parse(search)

        return params.person_id && params.organisation_id
      }

      paramsWithContext() {
        let {
          customerContext,
          location: { search },
          redirect,
        } = this.props
        let params = queryString.parse(search)
        if (customerContext) {
          ;(params.person_id = customerContext.party_link),
            (params.organisation_id = customerContext.organisation_party_link)
        }
        if (redirect) {
          ;(params.redirect_url = redirect.url), (params.redirect_label = redirect.label)
        }

        return params
      }

      render() {
        let {
          location: { pathname, state },
          customerContext,
        } = this.props

        let nextSearch = `?${queryString.stringify(this.paramsWithContext())}`
        let shouldInjectContext = !this.paramsContainContext() && !!customerContext

        return shouldInjectContext ? (
          <Redirect to={{ pathname, search: nextSearch, state }} />
        ) : (
          <ComposedComponent {...this.props} />
        )
      }
    }

    if (testMode) return ControlledComponent

    return compose(
      connect(state => {
        return {
          customerContext: state.customerContext.data,
          redirect: state.redirect,
        }
      }),
      withRouter
    )(ControlledComponent)
  }
}

export default ContextInjector
