import { href } from '@licnz/js-utils'

const getAddressFromLink = (addressLink, addresses) => {
  return addresses.find(address => {
    let link = href({ links: address.links, rel: 'self' })
    return link === addressLink
  })
}

const getAddressFromId = (id, addresses) => {
  return addresses.find(address => {
    return address.custom_id == id || address.pxid == id
  })
}

const formatAddress = address => {
  return [
    address.address_line_1,
    address.address_line_2,
    address.suburb,
    address.city,
    address.postcode,
  ]
    .filter(Boolean)
    .join(', ')
}

export { getAddressFromId, getAddressFromLink, formatAddress }
