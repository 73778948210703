import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { href } from '@licnz/js-utils'
import { renderError, renderInfo } from '@licnz/react-toast-notifications'
import { useMutation } from 'react-query'

import axios from 'lib/utils/axios'
import { ORDER_STATE_MAPPINGS } from 'constants/stateMappings'
import { OFFER_PRICE_URN_MAPPINGS } from 'constants/priceMappings'
import { findPrice, stringAsDecimal, convertToSentenceCase } from 'utils/util'
import { getPartyName } from 'utils/party'
import { proxyUrl } from 'lib/utils/proxy'

import ButtonAsLink from 'lib/components/ButtonAsLink'
import Currency from 'lib/components/Currency'
import DateTime from 'lib/components/DateTime'
import FieldError from 'lib/components/FieldError'
import StatusInfo from 'lib/components/StatusInfo'
import { Link } from 'react-router-dom'

import styles from './styles.scss'

const newOrderManualCancellation = order => {
  const link = order && href({ links: order?.links, rel: 'manual_order_cancellation' })
  return axios
    .post(proxyUrl({ link: link }))
    .then(response => response.data)
    .catch(error => {
      throw error
    })
}

const OrderCard = ({ order, isAdmin }) => {
  const orderState = 'order_' + order.state

  const [newOrderCancellationMutation, { data, error }] = useMutation(newOrderManualCancellation)

  useEffect(() => {
    if (data) {
      renderInfo({
        message: `Successfully manually cancelled order with order reference ${order.order_reference}.`,
      })
    }
  }, [data])

  useEffect(() => {
    if (error) {
      renderError({
        message: `An error occurred while trying to manually cancel the order with order reference ${order.order_reference} ${error}`,
      })
    }
  }, [error])

  let price = 0.0
  if (order && order.offered_prices && order.offered_prices.length > 0) {
    price = stringAsDecimal(
      findPrice(order.offered_prices, OFFER_PRICE_URN_MAPPINGS.orderOfferPriceInvoiced)[
        'price'
      ]
    )
  }
  const validOrderDetails = () => {
    return (
      <Link
        to={{
          pathname: `/orders/${encodeURIComponent(order.id)}`,
        }}
        className={`${styles.resultCard} ${styles.orderCard}`}
      >
        {orderData()}
      </Link>
    )
  }

  const invalidOrderDetails = () => {
    return <div className={`${styles.resultCard} ${styles.orderCard}`}>{orderData()}</div>
  }

  const manuallyCancelOrder = order => () => {
    newOrderCancellationMutation(order)
  }

  const orderData = () => {
    let warningMessage = null
    if (order.ordered_products_in_errored_state_count == 1) {
      warningMessage =
        'WARNING: This order contains ' +
        order.ordered_products_in_errored_state_count +
        ' ordered product in error state'
    } else if (order.ordered_products_in_errored_state_count > 1) {
      warningMessage =
        'WARNING: This order contains ' +
        order.ordered_products_in_errored_state_count +
        ' ordered products in error state'
    }
    return (
      <dl>
        <dt>Order reference</dt>
        <dd className={styles.orderReference}>Reference: {order.order_reference}</dd>
        <dt>Opened at</dt>
        <dd className={styles.openTime}>
          Opened on:{' '}
          <DateTime
            date={order.timestamps['created_time']}
            format={'DD/MM/YYYY, h:mm A'}
          />
        </dd>
        <dt>Initiating Party</dt>
        <dd>
          Initiating Party: {getPartyName(order['urn:lic:predicate:order_initiator'])}
        </dd>
        <dt>Purchasing Party</dt>
        <dd>
          Purchasing Party: {getPartyName(order['urn:lic:predicate:order_purchaser'])}
        </dd>
        <dt>Status</dt>
        <dd className={styles.status}>
          <StatusInfo id={orderState} label={convertToSentenceCase(order.state)} />
        </dd>
        <dt>Price</dt>
        <dd className={styles.price}>
          <Currency amount={price} />
        </dd>
        {order.ordered_products_in_errored_state_count > 0 && (
          <FieldError error={warningMessage} />
        )}
        { order.state == 'new' && isAdmin && global.FEATURE_FLAGS.MANUAL_CANCELLATION && <dt>Manually Cancel Order Button</dt> }
        { order.state == 'new' && isAdmin && global.FEATURE_FLAGS.MANUAL_CANCELLATION &&
        <dd className={styles.manually_cancel_button}>
          <ButtonAsLink
            className={styles.option}
            onClick={manuallyCancelOrder(order)}
          >
            Manually Cancel Order
        </ButtonAsLink> 
        </dd>
        }
      </dl>
    )
  }

  const invalidOrder = order.state === ORDER_STATE_MAPPINGS.NEW

  return order ? (invalidOrder ? invalidOrderDetails() : validOrderDetails()) : null
}

OrderCard.propTypes = {
  order: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
}

export { OrderCard }
export default OrderCard
