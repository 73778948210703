import { actionTypes, notificationTypes } from '../constants'

function enqueueNotification({ message, label, link, type }) {
  let notification = { message, label, link, type }

  return {
    type: actionTypes.ENQUEUE_NOTIFICATION,
    payload: notification,
  }
}

// Take some error input and ship a notification banner to the user
function enqueueError(error) {
  if (error instanceof Error) {
    return enqueueNotification({
      message: error.message,
      type: notificationTypes.ERROR,
    })
  } else if (typeof error === 'string') {
    return enqueueNotification({
      message: error,
      type: notificationTypes.ERROR,
    })
  } else if (Object.prototype.hasOwnProperty.call(error, 'message')) {
    return enqueueNotification({
      message: error.message,
      label: error.label,
      link: error.link,
      type: notificationTypes.ERROR,
    })
  } else {
    return enqueueNotification({
      type: notificationTypes.ERROR,
    })
  }
}

function enqueueInfo({ message }) {
  return enqueueNotification({ message, type: notificationTypes.INFO })
}

function enqueueSuccess({ message }) {
  return enqueueNotification({ message, type: notificationTypes.SUCCESS })
}

function enqueueWarning({ message }) {
  return enqueueNotification({ message, type: notificationTypes.WARNING })
}

function dequeueNotification() {
  return {
    type: actionTypes.DEQUEUE_NOTIFICATION,
  }
}

export {
  enqueueError,
  enqueueInfo,
  enqueueNotification,
  enqueueSuccess,
  enqueueWarning,
  dequeueNotification,
}
