import { get, destroy } from 'lib/reduxHelpers/thunks'
import { proxyUrl } from 'lib/utils/proxy'
import { basketTypes } from 'actions/types'

function fetchBasket(basketLink) {
  let baseType = basketTypes.FETCH_BASKET
  let url = proxyUrl({ link: basketLink })

  return get({ baseType, url })
}

function deleteBasket(link) {
  let baseType = basketTypes.DELETE_BASKET
  let url = proxyUrl({ link })

  return destroy({ baseType, url })
}

function clearBasket() {
  return { type: basketTypes.CLEAR_FETCH_BASKET }
}

export { fetchBasket, deleteBasket, clearBasket }
