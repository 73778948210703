import React from 'react'
import PropTypes from 'prop-types'

import SectionHeading from 'lib/components/headings/SectionHeading'
import ShippingRatesTable from '../ShippingRatesTable'

import styles from './styles.scss'

// Note: the figures in the "Lower bound" and "Upper bound" columns of this table refer to the
// *cost* price of the order, not the retail price.
const ShippingRatesDetail = ({ shippingRates }) => {
  function filterByRegion(shippingRates) {
    const northIslandShippingRates =
      (shippingRates &&
        shippingRates.filter(shippingRate => shippingRate.zone == 'NI')) ||
      []
    const southIslandShippingRates =
      (shippingRates &&
        shippingRates.filter(shippingRate => shippingRate.zone == 'SI')) ||
      []
    return { northIslandShippingRates, southIslandShippingRates }
  }

  const filteredShippingRates = filterByRegion(shippingRates)

  return filteredShippingRates ? (
    <div className={styles.section}>
      <SectionHeading>Shipping details</SectionHeading>
      <ShippingRatesTable
        title='North Island Shipping Rates'
        shippingRates={filteredShippingRates.northIslandShippingRates}
      />
      <ShippingRatesTable
        title='South Island Shipping Rates'
        shippingRates={filteredShippingRates.southIslandShippingRates}
      />
    </div>
  ) : null
}

ShippingRatesDetail.propTypes = {
  shippingRates: PropTypes.array,
}

export default ShippingRatesDetail
